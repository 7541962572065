import React, { PureComponent } from "react";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
  Label,
  Brush,
  BarChart,
} from "recharts";
import { CHART } from "../../constants/constants";
import { getMax } from "../../Utilities/common-utill";

export default class ComposedBarChart extends PureComponent {
  CustomYAxisTickRight = (props) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          textAnchor="start"
          dy={"0.3em"}
          fontSize="12"
          fontFamily={CHART.fontFamily}
          fontWeight={CHART.fontWeight}
          fill="#666"
        >
          {payload.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
        </text>
      </g>
    );
  };

  CustomYAxisTickLeft = (props) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          textAnchor="end"
          fontSize="12"
          dy={"0.3em"}
          fontFamily={CHART.fontFamily}
          fontWeight={CHART.fontWeight}
          fill="#666"
        >
          {payload.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
        </text>
      </g>
    );
  };

  render() {
    const {
      xKey,
      leftYKey,
      rightYKey,
      barKey,
      lineKey,
    } = this.props.chartDetails;

    const roundFig = this.props.roundFigYaxis || 100;
    const roundNum = (number) => Math.ceil(number / roundFig) * roundFig;
    const maxYLeft = roundNum(
      getMax(this.props.composedBarChartData, leftYKey)
    );

    const maxYRightValue = getMax(this.props.composedBarChartData, rightYKey);
    const maxYRight =
      maxYRightValue < roundFig / 2 ? maxYRightValue : roundNum(maxYRightValue);

    const data = this.props.composedBarChartData;

    const dxForRightLabel = 10 + 8 * (maxYRight.toString().length / 2);
    const dxForLeftLabel = 10 + 8 * (maxYLeft.toString().length / 2);

    return (
      <ComposedChart
        width={this.props.width}
        height={this.props.height}
        margin={this.props.margin}
        data={this.props.composedBarChartData}
      >
        {/* <CartesianGrid stroke="#f5f5f5" /> */}
        <XAxis
          dataKey={xKey}
          tick={{
            fontSize: 12,
            fontFamily: CHART.fontFamily,
            fontWeight: CHART.fontWeight,
          }}
          angle={-45}
          interval={0}
          padding={{ top: 20 }}
          textAnchor="end"
          allowDataOverflow={true}
        />
        {/* <YAxis yAxisId1="left" label="amt"></YAxis> */}

        <YAxis
          yAxisId="left"
          domain={[0, maxYLeft]}
          tick={this.CustomYAxisTickLeft}
          // tickCount={maxYLeft < 10 ? null : 10}
          label={{
            value: this.props.chartDetails.axisLabel.yLeft,
            angle: -90,
            position: "center",
            dx: -dxForLeftLabel,
            style: {
              fontSize: 14,
              fontFamily: CHART.fontFamily,
              fontWeight: CHART.fontWeight,
              fill: "#758088",
            },
          }}
          orientation="left"
        />
        {this.props.chartDetails.axisLabel.yRight ? (
          <YAxis
            yAxisId="right"
            domain={[0, maxYRight]}
            tick={this.CustomYAxisTickRight}
            //ticks={[0,15,20,25,50,60]}
            //tickCount={20}
            label={{
              value: this.props.chartDetails.axisLabel.yRight,
              angle: -90,
              position: "center",
              dx: dxForRightLabel,
              style: {
                fontSize: 14,
                fontFamily: CHART.fontFamily,
                fontWeight: CHART.fontWeight,
                fill: "#758088",
              },
            }}
            orientation="right"
          />
        ) : null}

        {/* <Tooltip /> */}
        <Tooltip
          formatter={(value, name, props) => [
            Number(value)
              .toFixed(this.props.showDecimal ? this.props.decimalPoints : 0)
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"),
            name,
          ]}
        />

        {this.props.chartDetails.hideLegends ? null : (
          <Legend
            verticalAlign="top"
            height={36}
            layout="horizontal"
            align={"center"}
            margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
            wrapperStyle={{ marginTop: "-40px" }}
          />
        )}

        {/* <Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
        {/* 120 condition is added for ref */}
        {data.length > 12 ? (
          <Brush
            height={20}
            width={this.props.width - 50}
            y={this.props.height - 20}
            x={0}
            startIndex={this.props.chartDetails.dataLength - 12}
          >
            <BarChart>
              <Bar
                dataKey={barKey}
                barSize={20}
                fill={this.props.chartDetails.barStroke}
                yAxisId="left"
              />
            </BarChart>
          </Brush>
        ) : null}
        <Bar
          dataKey={barKey}
          barSize={20}
          fill={this.props.chartDetails.barStroke}
          yAxisId="left"
          name={this.props.chartDetails.axisLabel.bar}
        />
        <Line
          type="monotone"
          dataKey={lineKey}
          stroke={this.props.chartDetails.lineStroke}
          yAxisId="right"
          dot={{ strokeWidth: "1" }}
          strokeWidth={2}
          name={this.props.chartDetails.axisLabel.line}
        />
        {/* <Scatter dataKey="cnt" fill="red" /> */}
      </ComposedChart>
    );
  }
}
