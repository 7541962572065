import Api from "../Utilities/Api/ApiMethods";

const getCompaniesDropdown = async payload => {
  try {
    const response = await Api.get(`/auth/user/companies?isfrom=${payload.isfrom}`);
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};

const getDepartmentDropdown = async payload => {
  
  try {
    let queryUrl = payload ? `/auth/user/departments?org_id=${JSON.stringify([...payload.org_id])}` : "/auth/user/departments";
    const response = await Api.get( queryUrl );
    return { response: { ...response.data } };
  } catch ( error ) {
    return {
      error: {
        ...error
      }
    };
  }
};

const getEmployeesDropdown = async payload => {
  try {
    const filterByUserId = payload.filterByUserId ? true: false;
    let queryString = `/auth/user/employees?search_term=${payload.search_term ? payload.search_term : ""}&org_id=${payload.org_id ? JSON.stringify(payload.org_id) : ""}&dept_id=${payload.dept_id ? JSON.stringify(payload.dept_id) : ""}&role=${payload.role ? JSON.stringify(payload.role) : ""}&filterByUserId=${filterByUserId}`;
    const response = await Api.get(queryString);
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};

const getAgeGroupDropdown = async payload => {
  try {
    const response = await Api.get("/auth/user/age_group");
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};

const getRoleDropdown = async payload => {
  try {
    const response = await Api.get("/auth/user/roles");
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};

const getTilesData = async payload => {
  try {
    let apiPayload = {
      ...payload,
      offset: 3,
      start: 0,
      order_by: "desc"
    };
    const response = await Api.post("/auth/graph/tiles", apiPayload);
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};

const getHistogramData = async payload => {
  try {
    const response = await Api.post("/auth/graph/histogram", payload);
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};

const getHistogramBenchMarkData = async payload => {
  try {
    const response = await Api.post("/auth/graph/histogram/benchmark", payload );
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};

const getCompanyDrilldown = async payload => {
  try {
    const response = await Api.post("/auth/graph/histogram/specific", payload);
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};

const getDepartmentDrilldown = async payload => {
  try {
    const response = await Api.post("/auth/graph/histogram/specific", payload);
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};

const getWordCloudData = async payload => {
  try {
    let updatedPayload = { ...payload, from: 0, offset: 20 }; // To fetch top 20
    const response = await Api.post("/auth/graph/info", updatedPayload);
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};

const getSpiderData = async payload => {
  try {
    const response = await Api.post("/auth/graph/spider", payload);
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};

const getAppreciationFeed = async payload => {
  try {
    const response = await Api.post("/auth/graph/feed", payload);
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};

const getSunburstdata = async payload => {
  try {
    const response = await Api.post("/auth/graph/sunburst", payload);
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};

const getStrengthEmployeeListdata = async payload => {
  try {
    const response = await Api.post("/auth/graph/sunburst", payload);
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};
const getTreeMapData = async payload => {
  try {
    let updatedPayload = { ...payload, from: 0, offset: 20 }; // To fetch top 20
    const response = await Api.post("/auth/graph/treemap", updatedPayload);
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};

const getAppreciationFlowData = async payload => {
  try {
    const response = await Api.post("/auth/graph/appreciation_flow", payload);
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};

export default {
  getCompaniesDropdown,
  getDepartmentDropdown,
  getEmployeesDropdown,
  getAgeGroupDropdown,
  getRoleDropdown,
  getHistogramData,
  getTilesData,
  getCompanyDrilldown,
  getDepartmentDrilldown,
  getWordCloudData,
  getSpiderData,
  getAppreciationFeed,
  getSunburstdata,
  getStrengthEmployeeListdata,
  getTreeMapData,
  getAppreciationFlowData,
  getHistogramBenchMarkData
};