export const GET_TILES_REQUEST = "GET_TILES_REQUEST";
export const GET_TILES_SUCCESS = "GET_TILES_SUCCESS";
export const GET_TILES_FAIL = "GET_TILES_FAIL";

export const GET_BUDGET_SNAPSHOT_REQUEST = "GET_BUDGET_SNAPSHOT_REQUEST";
export const GET_BUDGET_SNAPSHOT_SUCCESS = "GET_BUDGET_SNAPSHOT_SUCCESS";
export const GET_BUDGET_SNAPSHOT_FAIL = "GET_BUDGET_SNAPSHOT_FAIL";
export const GET_MERCHANT_DATA_REQUEST = "GET_MERCHANT_DATA_REQUEST";
export const GET_MERCHANT_DATA_SUCCESS = "GET_MERCHANT_DATA_SUCCESS";
export const GET_MERCHANT_DATA_FAIL = "GET_MERCHANT_DATA_FAIL";

export const GET_BUDGET_TREND_REQUEST = "GET_BUDGET_TREND_REQUEST";
export const GET_BUDGET_TREND_SUCCESS = "GET_BUDGET_TREND_SUCCESS";
export const GET_BUDGET_TREND_FAIL = "GET_BUDGET_TREND_FAIL";

export const GET_CATEGORY_DATA_REQUEST = "GET_CATEGORY_DATA_REQUEST";
export const GET_CATEGORY_DATA_SUCCESS = "GET_CATEGORY_DATA_SUCCESS";
export const GET_CATEGORY_DATA_FAIL = "GET_CATEGORY_DATA_FAIL";

export const GET_POINTS_EARNED_REDEEMED_REQUEST = "GET_POINTS_EARNED_REDEEMED_REQUEST";
export const GET_POINTS_EARNED_REDEEMED_SUCCESS = "GET_POINTS_EARNED_REDEEMED_SUCCESS";
export const GET_POINTS_EARNED_REDEEMED_FAIL = "GET_POINTS_EARNED_REDEEMED_FAIL";


export const GET_TIME_REDEEMED_REQUEST = "GET_TIME_REDEEMED_REQUEST"
export const GET_TIME_REDEEMED_SUCCESS = "GET_TIME_REDEEMED_SUCCESS"
export const GET_TIME_REDEEMED_FAIL = "GET_TIME_REDEEMED_FAIL"


export const GET_POINTS_EARNED_REDEEMED_BY_DRIVERS_REQUEST  = "GET_POINTS_EARNED_REDEEMED_BY_DRIVERS_REQUEST";
export const GET_POINTS_EARNED_REDEEMED_BY_DRIVERS_SUCCESS  = "GET_POINTS_EARNED_REDEEMED_BY_DRIVERS_SUCCESS";
export const GET_POINTS_EARNED_REDEEMED_BY_DRIVERS_FAIL     = "GET_POINTS_EARNED_REDEEMED_BY_DRIVERS_FAIL";

export const GET_TIME_OF_REDEMPTION_BY_HOUR_REQUEST    = "GET_TIME_OF_REDEMPTION_BY_HOUR_REQUEST";
export const GET_TIME_OF_REDEMPTION_BY_HOUR_SUCCESS    = "GET_TIME_OF_REDEMPTION_BY_HOUR_SUCCESS";
export const GET_TIME_OF_REDEMPTION_BY_HOUR_FAIL       = "GET_TIME_OF_REDEMPTION_BY_HOUR_FAIL";

export const GET_AGE_OF_REDEMPTION_BY_SNAPSHOT_REQUEST      = "GET_AGE_OF_REDEMPTION_BY_SNAPSHOT_REQUEST";
export const GET_AGE_OF_REDEMPTION_BY_SNAPSHOT_SUCCESS      = "GET_AGE_OF_REDEMPTION_BY_SNAPSHOT_SUCCESS";
export const GET_AGE_OF_REDEMPTION_BY_SNAPSHOT_FAIL         = "GET_AGE_OF_REDEMPTION_BY_SNAPSHOT_FAIL"

export const GET_AGE_OF_REDEMPTION_BY_TREND_REQUEST     = "GET_AGE_OF_REDEMPTION_BY_TREND_REQUEST";
export const GET_AGE_OF_REDEMPTION_BY_TREND_SUCCESS     = "GET_AGE_OF_REDEMPTION_BY_TREND_SUCCESS";
export const GET_AGE_OF_REDEMPTION_BY_TREND_FAIL        = "GET_AGE_OF_REDEMPTION_BY_TREND_FAIL"

export const GET_AGE_OF_REDEMPTION_BY_FREQUENCY_REQUEST     = "GET_AGE_OF_REDEMPTION_BY_FREQUENCY_REQUEST";
export const GET_AGE_OF_REDEMPTION_BY_FREQUENCY_SUCCESS     = "GET_AGE_OF_REDEMPTION_BY_FREQUENCY_SUCCESS";
export const GET_AGE_OF_REDEMPTION_BY_FREQUENCY_FAIL        = "GET_AGE_OF_REDEMPTION_BY_FREQUENCY_FAIL"

export const GET_REDEMPTION_USER_AGE_DATA_REQUEST           = "GET_REDEMPTION_USER_AGE_DATA_REQUEST";
export const GET_REDEMPTION_USER_AGE_DATA_SUCCESS           = "GET_REDEMPTION_USER_AGE_DATA_SUCCESS";
export const GET_REDEMPTION_USER_AGE_DATA_FAIL              = "GET_REDEMPTION_USER_AGE_DATA_FAIL"

// export const GET_REDCATEGORY_FILTER_DROPDOWN_REQUEST    = "GET_REDCATEGORY_FILTER_DROPDOWN_REQUEST";
// export const GET_REDCATEGORY_FILTER_DROPDOWN_SUCCESS    = "GET_REDCATEGORY_FILTER_DROPDOWN_SUCCESS";
// export const GET_REDCATEGORY_FILTER_DROPDOWN_FAIL       = "GET_REDCATEGORY_FILTER_DROPDOWN_FAIL";

// export const GET_FILTER_REDMODE_DROPDOWN_REQUEST    = "GET_FILTER_REDMODE_DROPDOWN_REQUEST";
// export const GET_FILTER_REDMODE_DROPDOWN_SUCCESS    = "GET_FILTER_REDMODE_DROPDOWN_SUCCESS";
// export const GET_FILTER_REDMODE_DROPDOWN_FAIL       = "GET_FILTER_REDMODE_DROPDOWN_FAIL";