import { SHOW_EVENT_MODAL, HIDE_EVENT_MODAL, SHOW_DASHBOARD_OVERLAY, HIDE_DASHBOARD_OVERLAY, EXPAND_SIDE_PANEL, SHRINK_SIDE_PANEL, SET_SCREEN_HEIGHT_WIDTH, START_PDF_EXPORT, END_PDF_EXPORT, SHOW_FILTER_COMPONENT, HIDE_FILTER_COMPONENT } from "./CommonActionTypes";

const initialState = {

  showEventModal: false,
  eventModalTitle: "",
  eventModalBody: "",
  showEventModalButtonOne: false,
  showEventModalButtonTwo: false,
  buttonOneEventModalHandler: null,
  buttonTwoEventModalHandler: null,
  buttonOneEventModalName: "",
  buttonTwoEventModalName: "",
  graphContent: null,
  graphTitle: "",
  showGraph: false,
  graphName: "",
  graphSubTitle: "",
  graphId: "",
  
  dashboardOverlay: false,

  expandedSideBar: true,
  showFilterComponent: true,
  screenResolution: {
    height: 0,
    width: 0
  },
  pdfExport: false,
  isChromeBasedBrowser: window && !!window.chrome

};

const CommonReducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case SHOW_EVENT_MODAL:
      return {
        ...state,
        showEventModal: action.payload.showEventModal,
        eventModalTitle: action.payload.eventModalTitle,
        eventModalBody: action.payload.eventModalBody,
        showEventModalButtonOne: action.payload.showEventModalButtonOne,
        showEventModalButtonTwo: action.payload.showEventModalButtonTwo,
        buttonOneEventModalHandler: action.payload.buttonOneEventModalHandler,
        buttonTwoEventModalHandler: action.payload.buttonTwoEventModalHandler,
        buttonOneEventModalName: action.payload.buttonOneEventModalName,
        buttonTwoEventModalName: action.payload.buttonTwoEventModalName,
        graphContent: action.payload.graphContent,
        graphTitle: action.payload.graphTitle,
        showGraph: action.payload.showGraph,
        graphName: action.payload.graphName,
        graphSubTitle: action.payload.graphSubTitle,
        graphId: action.payload.graphId
      };
    case HIDE_EVENT_MODAL:
      
      return {
        ...state,
        showEventModal: false,
        eventModalTitle: "",
        eventModalBody: "",
        showEventModalButtonOne: false,
        showEventModalButtonTwo: false,
        buttonOneEventModalHandler: null,
        buttonTwoEventModalHandler: null,
        buttonOneEventModalName: "",
        buttonTwoEventModalName: "",
        graphContent: null,
        graphTitle: "",
        showGraph: false,
        graphName: "",
        graphSubTitle: "",
        graphId: ""
      };
    case SHOW_DASHBOARD_OVERLAY: 
      return {
        ...state,
        dashboardOverlay: true 
      };
    case HIDE_DASHBOARD_OVERLAY:
      return {
        ...state,
        dashboardOverlay: false
      };
    case EXPAND_SIDE_PANEL: 
      return {
        ...state,
        expandedSideBar: true 
      };
    case SHRINK_SIDE_PANEL:
      return {
        ...state,
        expandedSideBar: false
      };
    case SET_SCREEN_HEIGHT_WIDTH: 
      return {
        ...state,
        screenResolution:{
          height: action.payload.height,
          width: action.payload.width
        }
      }
    case START_PDF_EXPORT: {
      return {
        ...state,
        pdfExport: true
      }
    }
    case END_PDF_EXPORT: {
      return {
        ...state,
        pdfExport: false
      }
    }
    case SHOW_FILTER_COMPONENT: {
      return {
        ...state,
        showFilterComponent: true
      }
    }
    case HIDE_FILTER_COMPONENT: {
      return {
        ...state,
        showFilterComponent: false
      }
    }         
    default: 
      return {
        ...state
      };
  }   
};

export default CommonReducer;