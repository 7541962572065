import React from "react";

const SankeyChartLoader = props => {
  return (
    <div className="animated-background lg-width" style={{ height: props.height, width: props.width }} >
            
    </div>
  );
};

export default SankeyChartLoader;