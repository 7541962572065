import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  ComposedChart,
  ResponsiveContainer,
  Label,
  Brush,
} from "recharts";
import React, { PureComponent } from "react";
import { CHART } from "../../constants/constants";
const renderLegend = (props) => {
  const { payload } = props;
  return (
    <ul>
      {payload.map((entry, index) => {
        return (
          <li
            key={`item-${index}`}
            style={{
              fontSize: "12",
              fontFamily: CHART.fontFamily,
              fontWeight: CHART.fontWeight,
            }}
          >
            {" "}
            <span style={{ backgroundColor: entry.color }}></span> {entry.value}
          </li>
        );
      })}
    </ul>
  );
};

const CustomYAxisTick = (props, parentProps) => {
  const { x, y, payload } = props;
  const breakText = parentProps.breakTextLength
    ? parentProps.breakTextLength
    : 15;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        textAnchor="end"
        fontSize="12"
        dy={"0.3em"}
        fontFamily={CHART.fontFamily}
        fontWeight="600"
        fill="#666"
      >
        {payload.value.length > breakText && !parentProps.expanded
          ? `${payload.value.slice(0, breakText - 3)}...`
          : payload.value}
      </text>
      <title> {payload.value} </title>
    </g>
  );
};

const CustomXAxisTick = (props, parentProps) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={10}
        textAnchor="middle"
        fontSize="12"
        fontFamily={CHART.fontFamily}
        fontWeight="600"
        fill="#666"
      >
        {payload.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
      </text>
      {/* <title> {payload.value} </title> */}
    </g>
  );
};
class StackedBarChart extends React.Component {
  state = {
    activeBarIndex: -1,
  };
  customBrushTickFormatter = (props) => { };

  barClickHandler = (data, index) => {
    if (this.props.isOnClickEvent) {
      this.props.clickHandler(data);
    }
    if (this.props.showActiveBar) {
      this.setState({
        ...this.state,
        activeBarIndex: index,
      });
    }
  };

  render() {
    return (
      <BarChart
        width={this.props.width}
        height={
          this.props.showBrush ? this.props.height : this.props.height - 20
        }
        data={this.props.data}
        margin={this.props.margin}
        layout="vertical"
      >
        <XAxis
          type="number"
          tick={(axisProps) => CustomXAxisTick(axisProps, this.props)}
          angle={this.props.xAxisAngle}
          interval={0}
          padding={{ top: 20 }}
          textAnchor="middle"
        >
          {this.props.xAxisLabel ? (
            <Label
              value={this.props.xAxisLabel}
              style={{
                fontSize: 14,
                fontFamily: CHART.fontFamily,
                fontWeight: CHART.fontWeight,
                fill: "#758088",
              }}
              offset={-40}
              position="insideBottom"
            />
          ) : null}
        </XAxis>

        <YAxis
          dataKey={this.props.xAxisNameId}
          tick={(axisProps) => CustomYAxisTick(axisProps, this.props)}
          type="category"
          textAnchor="middle"
          interval={0}
        // label={<AxisLabel axisType='yAxis'>Cost / Customer</AxisLabel>}
        // <YAxis type="number">
        // {/* </YAxis> */}
        // label={ this.props.yAxisLabel ? { value: this.props.yAxisLabel, angle: -90, position: "insideLeft", offset: this.props.expanded ? -this.props.margin.left + 20 : -60, transform : "rotate(-90, 40, 172.8)", dy: -2, style: { fontSize: 14, fontFamily: "roboto", fontWeight: 500, fill: "#758088" } } : null }
        >
          {this.props.yAxisLabel ? (
            <Label
              value={this.props.yAxisLabel}
              position="insideLeft"
              angle={-90}
              offset={this.props.expanded ? -this.props.margin.left + 20 : -60}
              style={{
                textAnchor: "middle",
                fontSize: 14,
                fontFamily: CHART.fontFamily,
                fontWeight: CHART.fontWeight,
                fill: "#758088",
              }}
            />
          ) : null}
        </YAxis>

        <Tooltip
          wrapperStyle={{
            margin: "0px",
            backgroundColor: "rgb(255, 255, 255)",
            whiteSpace: "nowrap",
            fontStyle: CHART.fontFamily,
            fontSize: "14px",
            fontWeight: CHART.fontWeight,
          }}
          formatter={(value, name, props) => [
            this.props.showDecimal ?
              Number(value)
                .toFixed(2)
                .toString()
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
              : value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
            ,
            name,
          ]}
        />

        {this.props.skipLegend ? null : (
          <Legend
            verticalAlign="top"
            height={36}
            width={this.props.width}
            layout="horizontal"
            align="center"
            margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
            wrapperStyle={{
              marginTop: this.props.isFromImage ? "-20px" : null,
              left: 0
            }}
          // content={renderLegend}
          />
        )}
        {/* <Legend 
          verticalAlign="top" 
          height={36} 
          layout="horizontal" 
          align="center" 
          margin = { { top: 0, left: 0, right: 0, bottom: 0 } } 
          // content={renderLegend} 
        />        */}
        {this.props.xAxisNames.map((name, parentIndex) => (
          <Bar
            key={parentIndex}
            dataKey={name}
            stackId="x"
            fill={this.props.colors[parentIndex]}
            barSize={30}
            onClick={this.props.isOnClickEvent ? this.barClickHandler : null}
          >
            {this.props.data.map((entry, index) => (
              <Cell
                cursor={this.props.isOnClickEvent ? "pointer" : ""}
                fill={
                  index === this.state.activeBarIndex
                    ? this.props.activeBarColor
                    : this.props.colors[parentIndex]
                }
                key={`cell-${index}`}
              />
            ))}
          </Bar>
        ))}

        {this.props.showBrush ? (
          <Brush
            dataKey={this.props.xAxisNameId}
            height={20}
            width={this.props.brushWidth - this.props.margin.right}
            stroke="#0b2d44"
            y={this.props.height - this.props.brushHeight}
            x={0}
            startIndex={0}
            endIndex={9}
            travellerWidth={0}
            tickFormatter={this.customBrushTickFormatter}
          >
            <BarChart>
              {this.props.xAxisNames.map((name, index) => (
                <Bar
                  key={index}
                  dataKey={name}
                  stackId="x"
                  fill={this.props.colors[index]}
                  barSize={30}
                  onClick={
                    this.props.isOnClickEvent
                      ? (e) => this.props.clickHandler(e)
                      : null
                  }
                />
              ))}
            </BarChart>
          </Brush>
        ) : null}
      </BarChart>
    );
  }
}

export default StackedBarChart;
