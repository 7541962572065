import React from "react";
import { Tile, TileLoader } from "../../../../../UI";
import { TILES_DECIMAL_POINT } from "../../../../../constants/constants";
import { connect } from "react-redux";
class TilesSection extends React.Component {
  render () {
    let appreciationReceivedPercentage = "";
    let appreciationGivenPercentage = "";
    let totalAppreciationReceived = "";
    let topReceiver = "";
    let averageNoOfStrengths = "";
    let topStrength = "";
    if(this.props.tilesData) {
      if(this.props.tilesData.appreciation_received_percentage) {
        appreciationReceivedPercentage = this.props.tilesData.appreciation_received_percentage;
      }
      if(this.props.tilesData.appreciation_given_percentage) {
        appreciationGivenPercentage = this.props.tilesData.appreciation_given_percentage;
      }
      if(this.props.tilesData.appreciation_received_count) { totalAppreciationReceived = this.props.tilesData.appreciation_received_count.appreciation_count; }
      if(this.props.tilesData.appreciation_receiver_info) { topReceiver = this.props.tilesData.appreciation_receiver_info.result; }
      if(this.props.tilesData.avg_strength_per_emp) { averageNoOfStrengths = this.props.tilesData.avg_strength_per_emp.average; }
      if(this.props.tilesData.stregths_data) { topStrength = this.props.tilesData.stregths_data.result; }
    }

    return (
      <div className="tilesSection" id="tilesSection" >
        <div className="row">
          <div className="col-12 col-md-6 col-lg-3">

            {!this.props.loader
              ? <Tile
                className={"lighter-skyblue"}
                text={"Total Appreciations Received"}
                value={totalAppreciationReceived}
                showDecimal={false}
              /> : <TileLoader />}
          </div>
          <div className="col-12 col-md-6 col-lg-3 ">
            {!this.props.loader ? <Tile className={"lighter-pink"}
              text={"Employees have Given an Appreciation"}
              suffix={"%"}
              showAbsoluteValue={true}
              absoluteValue={this.props.tilesData.totalGiversCount}
              value={appreciationGivenPercentage}
              showDecimal={ true }
              decimalPoint = {TILES_DECIMAL_POINT}
              ingoreRound={true}
            /> : <TileLoader />}
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            {!this.props.loader ? <Tile
              className={"lighter-green"}
              text={"Employees have Received an Appreciation"}
              suffix={"%"}
              showAbsoluteValue={true}
              absoluteValue={this.props.tilesData.totalReceiversCount}
              value={appreciationReceivedPercentage}
              showDecimal={ true }
              decimalPoint = {TILES_DECIMAL_POINT}
              ingoreRound={true}
            /> : <TileLoader /> } 
          </div>   
          <div className="col-12 col-md-6 col-lg-3">
            {!this.props.loader ? <Tile className={"lighter-yellow"}
              text={"Strengths (on average) per Appreciated Employee"}
              value={averageNoOfStrengths}
              showDecimal={ true }
              decimalPoint = {TILES_DECIMAL_POINT}
              ingoreRound={true}
            /> : <TileLoader /> }
          </div> 
          <div className="col-12 col-md-6 col-lg-6">
            {!this.props.loader ? <Tile className={"light-green"}
              showTopThree={true}
              title={"Top 3 Receivers"}
              pointOne={topReceiver.length > 0 ? topReceiver[0].receiver_first_name ? `${topReceiver[0].receiver_first_name} ${topReceiver[0].receiver_last_name}` : "Anonymous" : ""}
              valueOne={topReceiver.length > 0 ? topReceiver[0].count : ""}
              pointTwo={topReceiver.length > 1 ? topReceiver[1].receiver_first_name ? `${topReceiver[1].receiver_first_name} ${topReceiver[1].receiver_last_name}` : "Anonymous" : ""}
              valueTwo={topReceiver.length > 1 ? topReceiver[1].count : ""}
              pointThree={topReceiver.length > 2 ? topReceiver[2].receiver_first_name ? `${topReceiver[2].receiver_first_name} ${topReceiver[2].receiver_last_name}` : "Anonymous" : ""}
              valueThree={topReceiver.length > 2 ? topReceiver[2].count : ""}
              showDecimal={false}
            /> : <TileLoader />}
          </div>
          <div className="col-12 col-md-6 col-lg-6">
            {!this.props.loader ? <Tile className={"light-pink"}
              showTopThree={true}
              title={"Top 3 Strengths"}
              pointOne={topStrength.length > 0 ? topStrength[0].name ? topStrength[0].name : "Anonymous" : ""}
              valueOne={topStrength.length > 0 ? topStrength[0].count : ""}
              pointTwo={topStrength.length > 1 ? topStrength[1].name ? topStrength[1].name : "Anonymous" : ""}
              valueTwo={topStrength.length > 1 ? topStrength[1].count : ""}
              pointThree={topStrength.length > 2 ? topStrength[2].name ? topStrength[2].name : "Anonymous" : ""}
              valueThree={topStrength.length > 2 ? topStrength[2].count : ""}
              showDecimal={false}
            /> : <TileLoader />}
          </div>

        </div>
      </div>
    );
  }
};

const mapStateToProps = state => {
  return {
    tilesData: state.AppreciationDashboard.tiles,
    loader: state.AppreciationDashboard.tiles.loader
  };
};

export default connect(mapStateToProps)(TilesSection);
