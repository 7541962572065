import React from "react";
import * as d3 from "d3";
// import Piechart from "./PieChart";

class PieChart extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      currentDrillDown: "Strengths",
      totalStrengthCount: 0,
      counter: 0
    };
  }

  componentWillMount () {
    this.setState({
      ...this.state,
      currentDrillDown: "Strengths",
      totalStrengthCount: this.props.data.totalStrengthCount
    });
  }

  componentWillReceiveProps (nextProps) {
    if(this.props.data.totalStrengthCount !== nextProps.data.totalStrengthCount) {
      this.setState({
        ...this.state,
        currentDrillDown: "Strengths",
        totalStrengthCount: nextProps.data.totalStrengthCount
      }, () => this.loadChart());
    }
  }

  componentDidMount () {
    this.loadChart();
  }

  componentDidUpdate () {
    if(!this.props.ClickedEvent && this.state.counter === 1 && this.props.childrenData.strength_name) {
      this.loadReChart();
    }
  }

  loadChart = () => {
    const width = this.props.width;
    const radius = width / 8;

    const arc = d3.arc()
      .startAngle(d => d.x0)
      .endAngle(d => d.x1)
      .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
      .padRadius(radius * 1.5)
      .innerRadius(d => d.y0 * radius)
      .outerRadius(d => Math.max(d.y0 * radius, d.y1 * radius - 1));

    const partition = data => {
      const root = d3.hierarchy(data)
        .sum(d => d.size)
        .sort((a, b) => b.value - a.value);
      return d3.partition()
        .size([2 * Math.PI, root.height + 1])
        (root);
    };

    var data = this.props.childrenData.name && this.state.counter === 0 ? this.props.childrenData : this.props.data;
    const root = partition(data);

    root.each(d => { d.current = d; });

    let svgHeight = this.props.heightConstant ? (this.props.height / 1.4) : this.props.height;
    const svg = d3.select(this.node)
      .style("width", `${this.props.width}px`)
      .style("height", `${svgHeight}px`)
      .style("font", "10px sans-serif");

    svg.selectAll("g").remove();

    const g = svg.append("g")
      .attr("transform", `translate(${width / 2.5},${this.props.height / (this.props.heightConstant || 2)})`);

    const path = g.append("g")
      .selectAll("path")
      .data(root.descendants().slice(1))
      .join("path")
      .attr("fill", d => d.data.color)
      .attr("id", (d, i) => { return "pie-chart-svg-" + i; })
      .attr("fill-opacity", d => arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0)
      .attr("d", d => arc(d.current))
      .style("cursor", "pointer")
      .on("click", (d) => {
        this.setState({ ...this.state, counter: ++this.state.counter }, () => {
          deptClicked(d, { ...this.props });
        }, () => this.loadReChart());
      });

    // For drilldown effect
    path.filter(d => d.children)
      .style("cursor", "pointer")
      .on("click", (d) => {
        this.setState({ ...this.state, counter: ++this.state.counter }, () => {
          clicked(d, { ...this.props });
        }, () => this.loadReChart());
      });

    // All the test which is render on sunburst
    path.append("title")
      .text(d => renderCustomTitleContent(d, this.state));
    const label = g.append("g")
      .attr("pointer-events", "none")
      .attr("text-anchor", "middle")
      .style("user-select", "none")
      .selectAll("text")
      .data(root.descendants().slice(1))
      .join("text")
      .attr("dy", "0.35em")
      .attr("text-anchor", "middle")
      .style("font-family", "roboto")
      .style("font-size", 10)
      .style("font-weight", 600)
      .style("fill", "#758088")
      .attr("fill-opacity", d => +labelVisible(d.current))
      .attr("transform", d => labelTransform(d.current))
      .text(d => renderCustomLabelContent(d, this.state, this.props.legendDepartmentList))
      .call(wrap, radius);

    function wrap (text, width) {
      text.each(function () {
        var text = d3.select(this);
        var words = text.text().split(/\s+/).reverse();
        var word;
        var line = [];
        var lineNumber = 0;
        var lineHeight = 1.1; // ems
        var y = text.attr("y");
        var dy = parseFloat(text.attr("dy"));
        var tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");
        // eslint-disable-next-line no-cond-assign
        while(word = words.pop()) {
          line.push(word);
          tspan.text(line.join(" "));
          if(tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
          }
        }
      });
    }
    // function renderCustomPercent (d, state) {
    //   if(!d.data.parentStrength) {
    //     return ` (${((d.data.count / state.totalStrengthCount) * 100).toFixed(1)}%)`;
    //   }
    // }
    function renderCustomTitleContent (d, state) {
      if(d.data.parentStrength) {
        return `${d.data.name}: ${((parseInt(d.data.count) / d.parent.data.count) * 100).toFixed(1)}% (${d.data.count.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")})`;
      } else {
        return `${d.data.name}: ${((d.data.count / state.totalStrengthCount) * 100).toFixed(1)}% (${d.data.count.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")})`;
      }
    }

    function renderCustomLabelContent (d, state, legendDepartmentList) {
      if(d.data.parentStrength) {
        return "D" + (legendDepartmentList.indexOf(d.data.name) + 1);
      } else {
        return d.data.name;
      }
    }

    const parent = g.append("circle")
      .datum(root)
      .attr("r", radius)
      .attr("fill", "white")
      .attr("pointer-events", "all")
      .style("cursor", "pointer")
      .style("filter", "url(#pieChartDropshadow)")
      .on("click", (d) => {
        this.setState({ ...this.state, counter: ++this.state.counter }, () => {
          clicked(d, { ...this.props });
        }, () => this.loadReChart());
      });

    // let middleCirclePoints = parent.node().getBoundingClientRect();

    // Inner circle strengths
    g.append("text")
      .datum(root)
      .attr("x", 0)
      .attr("y", 0)
      .style("font-family", "roboto")
      .style("font-size", 14)
      .style("font-weight", 600)
      .style("fill", "#758088")
      .style("cursor", "pointer")
      .attr("text-anchor", "middle")
      .on("click", (d) => {
        this.setState({ ...this.state, counter: ++this.state.counter }, () => {
          clicked(d, { ...this.props });
        }, () => this.loadReChart());
      })
      .text("Strengths");

    function clicked (p, props) {
      parent.datum(p.parent || root);
      root.each(d => {
        d.target = {
          x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
          x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
          y0: Math.max(0, d.y0 - p.depth),
          y1: Math.max(0, d.y1 - p.depth)
        };
      });
      // const t = g.transition().duration(750)
      const t = g.transition().duration(750).on("end", function () {
        if(props.ClickedEvent) {
          if(p.parent) {
            props.handleClicked(p.data);
          } else {
            props.handleClicked();
          }
        }
      });
      // Transition the data on all arcs, even the ones that aren’t visible,
      // so that if this transition is interrupted, entering arcs will start
      // the next transition from the desired position.
      path.transition(t)
        .tween("data", d => {
          const i = d3.interpolate(d.current, d.target);
          return t => { d.current = i(t); };
        })
        .filter(function (d) {
          return +this.getAttribute("fill-opacity") || arcVisible(d.target);
        })
        .attr("fill-opacity", d => arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0)
        .attrTween("d", d => () => arc(d.current));

      label.filter(function (d) {
        return +this.getAttribute("fill-opacity") || labelVisible(d.target);
      }).transition(t)
        .attr("fill-opacity", d => +labelVisible(d.target))
        .attrTween("transform", d => () => labelTransform(d.current));
    }

    function deptClicked (p, props) {
      let p1 = {};
      root.current.children.forEach(function (item) {
        if(item.data.name === p.data.parentStrength) {
          p1 = item;
        }
      });
      parent.datum(p1.parent || root);
      root.each(d => {
        d.target = {
          x0: Math.max(0, Math.min(1, (d.x0 - p1.x0) / (p1.x1 - p1.x0))) * 2 * Math.PI,
          x1: Math.max(0, Math.min(1, (d.x1 - p1.x0) / (p1.x1 - p1.x0))) * 2 * Math.PI,
          y0: Math.max(0, d.y0 - p1.depth),
          y1: Math.max(0, d.y1 - p1.depth)
        };
      });
      const t = g.transition().duration(750).on("end", function () {
        if(props.ClickedEvent) {
          props.handleClicked(p.data);
        }
      });

      path.transition(t)
        .tween("data", d => {
          const i = d3.interpolate(d.current, d.target);
          return t => { d.current = i(t); };
        })
        .filter(function (d) {
          return +this.getAttribute("fill-opacity") || arcVisible(d.target);
        })
        .attr("fill-opacity", d => arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0)
        .attrTween("d", d => () => arc(d.current));

      label.filter(function (d) {
        return +this.getAttribute("fill-opacity") || labelVisible(d.target);
      }).transition(t)
        .attr("fill-opacity", d => +labelVisible(d.target))
        .attrTween("transform", d => () => labelTransform(d.current));
    }

    function arcVisible (d) {
      return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
    }

    function labelVisible (d) {
      return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.035;
    }

    function labelTransform (d) {
      const x = (d.x0 + d.x1) / 2 * 180 / Math.PI;
      const y = (d.y0 + d.y1) / 2 * radius;
      return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
    }
  }

  loadReChart = () => {
    const width = this.props.width;
    const radius = width / 8;

    const arc = d3.arc()
      .startAngle(d => d.x0)
      .endAngle(d => d.x1)
      .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
      .padRadius(radius * 1.5)
      .innerRadius(d => d.y0 * radius)
      .outerRadius(d => Math.max(d.y0 * radius, d.y1 * radius - 1));

    const partition = data => {
      const root = d3.hierarchy(data)
        .sum(d => d.size)
        .sort((a, b) => b.value - a.value);
      return d3.partition()
        .size([2 * Math.PI, root.height + 1])
        (root);
    };

    var data = this.props.data;
    const root = partition(data);
    root.each(d => { d.current = d; });

    let svgHeight = this.props.heightConstant ? (this.props.height / 1.4) : this.props.height;
    const svg = d3.select(this.node)
      .style("width", `${this.props.width}px`)
      .style("height", `${svgHeight}px`)
      .style("font", "10px sans-serif");

    svg.selectAll("g").remove();

    const g = svg.append("g")
      .attr("transform", `translate(${width / 2.5},${this.props.height / (this.props.heightConstant || 2)})`);

    const path = g.append("g")
      .selectAll("path")
      .data(root.descendants().slice(1))
      .join("path")
      .attr("fill", d => d.data.color)
      .attr("id", (d, i) => { return "pie-chart-svg-" + i; })
      .attr("fill-opacity", d => arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0)
      .attr("d", d => arc(d.current))
      .style("cursor", "pointer")
      .on("click", (d) => {
        this.setState({ ...this.state, counter: ++this.state.counter }, () => {
          deptClicked(d, { ...this.props });
        }, () => this.loadChart());
      });

    // For drilldown effect
    path.filter(d => d.children)
      .style("cursor", "pointer")
      .on("click", (d) => {
        this.setState({ ...this.state, counter: ++this.state.counter }, () => {
          clicked(d, { ...this.props });
        }, () => this.loadChart());
      });

    // All the test which is render on sunburst
    path.append("title")
      .text(d => renderCustomTitleContent(d, this.state));
    const label = g.append("g")
      .attr("pointer-events", "none")
      .attr("text-anchor", "middle")
      .style("user-select", "none")
      .selectAll("text")
      .data(root.descendants().slice(1))
      .join("text")
      .attr("dy", "0.35em")
      .attr("text-anchor", "middle")
      .style("font-family", "roboto")
      .style("font-size", 10)
      .style("font-weight", 600)
      .style("fill", "#758088")
      .attr("fill-opacity", d => +labelVisible(d.current))
      .attr("transform", d => labelTransform(d.current))
      .text(d => renderCustomLabelContent(d, this.state, this.props.legendDepartmentList))
      .call(wrap, radius);

    function wrap (text, width) {
      text.each(function () {
        var text = d3.select(this);
        var words = text.text().split(/\s+/).reverse();
        var word;
        var line = [];
        var lineNumber = 0;
        var lineHeight = 1.1; // ems
        var y = text.attr("y");
        var dy = parseFloat(text.attr("dy"));
        var tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");
        while(word = words.pop()) {
          line.push(word);
          tspan.text(line.join(" "));
          if(tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
          }
        }
      });
    }
    // function renderCustomPercent (d, state) {
    //   if (!d.data.parentStrength) {
    //     return ` (${((d.data.count / state.totalStrengthCount) * 100).toFixed(1)}%)`;
    //   }
    // }
    function renderCustomTitleContent (d, state) {
      if (d.data.parentStrength) {
        return `${d.data.name}: ${((parseInt(d.data.count) / d.parent.data.count) * 100).toFixed(1)}% (${d.data.count.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")})`;
      } else {
        return `${d.data.name}: ${((d.data.count / state.totalStrengthCount) * 100).toFixed(1)}% (${d.data.count.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")})`;
      }
    }

    function renderCustomLabelContent (d, state, legendDepartmentList) {
      if (d.data.parentStrength) {
        return "D" + (legendDepartmentList.indexOf(d.data.name) + 1);
      } else {
        return d.data.name;
      }
    }

    const parent = g.append("circle")
      .datum(root)
      .attr("r", radius)
      .attr("fill", "white")
      .attr("pointer-events", "all")
      .style("cursor", "pointer")
      .style("filter", "url(#pieChartDropshadow)")
      .on("click", (d) => {
        this.setState({ ...this.state, counter: ++this.state.counter }, () => {
          clicked(d, { ...this.props });
        }, () => this.loadChart());
      });

    // let middleCirclePoints = parent.node().getBoundingClientRect();

    // Inner circle strengths
    g.append("text")
      .datum(root)
      .attr("x", 0)
      .attr("y", 0)
      .style("font-family", "roboto")
      .style("font-size", 14)
      .style("font-weight", 600)
      .style("fill", "#758088")
      .style("cursor", "pointer")
      .attr("text-anchor", "middle")
      .on("click", (d) => {
        this.setState({ ...this.state, counter: ++this.state.counter }, () => {
          clicked(d, { ...this.props });
        }, () => this.loadChart());
      })
      .text("Strengths");

    function clicked (p, props) {
      parent.datum(p.parent || root);
      root.each(d => {
        d.target = {
          x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
          x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
          y0: Math.max(0, d.y0 - p.depth),
          y1: Math.max(0, d.y1 - p.depth)
        };
      });
      // const t = g.transition().duration(750)
      const t = g.transition().duration(750).on("end", function () {
        if(props.ClickedEvent) {
          if(p.parent) {
            props.handleClicked(p.data);
          } else {
            props.handleClicked();
          }
        }
      });
      // Transition the data on all arcs, even the ones that aren’t visible,
      // so that if this transition is interrupted, entering arcs will start
      // the next transition from the desired position.
      path.transition(t)
        .tween("data", d => {
          const i = d3.interpolate(d.current, d.target);
          return t => { d.current = i(t); };
        })
        .filter(function (d) {
          return +this.getAttribute("fill-opacity") || arcVisible(d.target);
        })
        .attr("fill-opacity", d => arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0)
        .attrTween("d", d => () => arc(d.current));

      label.filter(function (d) {
        return +this.getAttribute("fill-opacity") || labelVisible(d.target);
      }).transition(t)
        .attr("fill-opacity", d => +labelVisible(d.target))
        .attrTween("transform", d => () => labelTransform(d.current));
    }

    function deptClicked (p, props) {
      let p1 = {};
      root.current.children.forEach(function (item) {
        if(item.data.name === p.data.parentStrength) {
          p1 = item;
        }
      });
      parent.datum(p1.parent || root);
      root.each(d => {
        d.target = {
          x0: Math.max(0, Math.min(1, (d.x0 - p1.x0) / (p1.x1 - p1.x0))) * 2 * Math.PI,
          x1: Math.max(0, Math.min(1, (d.x1 - p1.x0) / (p1.x1 - p1.x0))) * 2 * Math.PI,
          y0: Math.max(0, d.y0 - p1.depth),
          y1: Math.max(0, d.y1 - p1.depth)
        };
      });
      const t = g.transition().duration(750).on("end", function () {
        if(props.ClickedEvent) {
          props.handleClicked(p.data);
        }
      });

      path.transition(t)
        .tween("data", d => {
          const i = d3.interpolate(d.current, d.target);
          return t => { d.current = i(t); };
        })
        .filter(function (d) {
          return +this.getAttribute("fill-opacity") || arcVisible(d.target);
        })
        .attr("fill-opacity", d => arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0)
        .attrTween("d", d => () => arc(d.current));

      label.filter(function (d) {
        return +this.getAttribute("fill-opacity") || labelVisible(d.target);
      }).transition(t)
        .attr("fill-opacity", d => +labelVisible(d.target))
        .attrTween("transform", d => () => labelTransform(d.current));
    }

    function arcVisible (d) {
      return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
    }

    function labelVisible (d) {
      return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.035;
    }

    function labelTransform (d) {
      const x = (d.x0 + d.x1) / 2 * 180 / Math.PI;
      const y = (d.y0 + d.y1) / 2 * radius;
      return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
    }
  }
  render () {
    return (
      <svg ref={(node) => { this.node = node; } } >
        <defs>
          <filter id="pieChartDropshadow" x="-40%" y="-40%" width="180%" height="180%" filterUnits="userSpaceOnUse">
            <feGaussianBlur in="SourceAlpha" stdDeviation="2" />
            <feOffset dx="5" dy="5" result="offsetblur" />
            <feOffset dx="-5" dy="-5" result="offsetblur" />
            <feMerge>
              <feMergeNode />
              <feMergeNode in="SourceGraphic" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
      </svg>
    );
  }
}

export default PieChart;