import { takeEvery, put, call } from "redux-saga/effects";
import * as RedemptionActionTypes from "../Actions/RedemptionActionTypes";
import RedemptionDashboardApi from "../../../../../Api/RedemptionDashboardApi";
import * as RedemptionActions from "../Actions/RedemptionActions";
import convertDataToDropDown from "../../../../../Utilities/convertDataForDropdown";
import { convertDataForStackedBarChart } from "../../../../../Utilities/convertDataForStackedBarChart";

// export function * getRedCategoryFilterAsync (action) {
//   const { response, error } = yield call(
//     RedemptionDashboardApi.getRedCategoryDropdown,
//     action.payload
//   );
//   if(response) {
//     if(response.status_code === 200) {
//       let filterCategorydata = yield convertDataToDropDown(response.data.filterCategories);
//       yield put(RedemptionActions.redCategoryFilterDropdownSuccessAction(filterCategorydata));
//       // let filterModesdata = yield convertDataToDropDown(response.data.filterModes);
//       // yield put(RedemptionActions.redModeFilterDropdownSuccessAction(filterModesdata));
//     } else if(response.status_code === 500) {
//       yield put(RedemptionActions.redCategoryFilterDropdownFailAction());
//       // yield put(RedemptionActions.redModeFilterDropdownFailAction());
//     } 
//   } else {
//     yield put(RedemptionActions.redCategoryFilterDropdownFailAction());
//     // yield put(RedemptionActions.redModeFilterDropdownFailAction());
//   }
// }

// getResponse
// export function * getRedCategoryFilterAsync (action) {
//   const { response, error } = yield call(
//     RedemptionDashboardApi.getRedCategoryDropdown,
//     action.payload
//   );
//   if(response) {
//     if(response.status_code === 200) {
//       // let filterModesdata = yield convertDataToDropDown(response.data.filterModes);
//       yield put(RedemptionActions.redModeFilterDropdownSuccessAction(response.data));
//       // let filterCategorydata = yield convertDataToDropDown(response.data.filterCategories);
//       // yield put(RedemptionActions.redCategoryFilterDropdownSuccessAction(filterCategorydata));
//     } else if(response.status_code === 500) {
//       yield put(RedemptionActions.redCategoryFilterDropdownFailAction());
//     } 
//   } else {
//     yield put(RedemptionActions.redCategoryFilterDropdownFailAction());
//   }
// }



export function* getTilesDetails(action) {
  const { response, error } = yield call(
    RedemptionDashboardApi.getRedemeptionTilesDetails,
    action.payload
  );

  if (response) {
    if (response.status_code === 200) {
      yield put(RedemptionActions.redemptionTilesSuccessAction(response.data));
    } else if (response.status_code === 400) {
      yield put(RedemptionActions.redemptionTilesFailAction());
    }
  } else {
    yield put(RedemptionActions.redemptionTilesFailAction());
  }
}

export function* getSnapshotDetails(action) {
  const { response, error } = yield call(
    RedemptionDashboardApi.getRedemeptionSnapshotDetails,
    action.payload
  );

  if (response) {
    if (response.status_code === 200) {
      yield put(RedemptionActions.redemptionBudgetSnapshotSuccessAction(response.data));
    } else if (response.status_code === 400) {
      yield put(RedemptionActions.redemptionBudgetSnapshotFailAction());
    }
  } else {
    yield put(RedemptionActions.redemptionBudgetSnapshotFailAction());
  }
}

export function* getTrendDetails(action) {
  const { response, error } = yield call(
    RedemptionDashboardApi.getRedemeptionTrendDetails,
    action.payload
  );

  if (response) {
    if (response.status_code === 200) {
      yield put(RedemptionActions.redemptionBudgetTrendSuccessAction(response.data));
    } else if (response.status_code === 400) {
      yield put(RedemptionActions.redemptionBudgetTrendFailAction());
    }
  } else {
    yield put(RedemptionActions.redemptionBudgetTrendFailAction());
  }
}

let modifyMarchantReposnePayload = (merchantsDetails) => {
  let MerchantData = merchantsDetails.data.result.map(el => {
  return { "id" : el.id , "Merchant Name" : el.merchant_name , "Number Of Redemptions" : el.merchant_redemption_count, "Redemption Value (points)" : el.merchant_redemption_count }
  } )
  return MerchantData ;
}

export function* getMerchantDetails(action) {
  const { response, error } = yield call(
    RedemptionDashboardApi.getRedemeptionMerchantDetails,
    action.payload
  );

  if (response) {
    if (response.status_code === 200) {
      let merchantData = modifyMarchantReposnePayload(response)
      response.data.result = merchantData
      yield put(RedemptionActions.redemptionMerchantSuccessAction(response.data));
    } else if(response.status_code === 400) {
      yield put(RedemptionActions.redemptionMerchantFailAction());
    }
  } else {
    yield put(RedemptionActions.redemptionMerchantFailAction());
  }
}

let modifyCategoryReposnePayload = categoryDetails => {
  let categoryData = categoryDetails.data && categoryDetails.data.result && categoryDetails.data.result.length > 0 ? categoryDetails.data.result.map(el => {
    return {
      id: el.id,
      "Category Name": el.category_name,
      "Number Of Redemptions": el.category_count,
      "Redemption Value (points)" : el.category_count
    };
  }) : null;
  return categoryData;
};

export function* getCategoryDetails(action) {
  const { response, error } = yield call(
    RedemptionDashboardApi.getRedemeptionCategoryDetails,
    action.payload
  );

  if (response) {
    if (response.status_code === 200) {
      let merchantData = modifyCategoryReposnePayload(response);
      response.data.result = merchantData;
      yield put(
        RedemptionActions.redemptionCategorySuccessAction(response.data)
      );
    } else if (response.status_code === 400) {
      yield put(RedemptionActions.redemptionCategoryFailAction());
    }
  } else {
    yield put(RedemptionActions.redemptionCategoryFailAction());
  }
}

export function* getPointsEarnedRedeemedDetails(action) {
  const { response, error } = yield call(
    RedemptionDashboardApi.getRedemeptionPointsEarnedRedeemedDetails,
    action.payload
  );

  if (response) {
    if (response.status_code === 200) {
      yield put(RedemptionActions.redemptionPointsEarnedRedeemedSuccessAction(response.data));
    } else if (response.status_code === 400) {
      yield put(RedemptionActions.redemptionPointsEarnedRedeemedFailAction());
    }
  } else {
    yield put(RedemptionActions.redemptionPointsEarnedRedeemedFailAction());
  }
}

export function * getPointsEarnedRedeemedByDriversDetails (action) {
  const { response, error } = yield call(
    RedemptionDashboardApi.getRedemeptionPointsEarnedRedeemedByDriversDetails,
    action.payload
  );

  if(response) {
    if(response.status_code === 200) {
      yield put(RedemptionActions.redemptionPointsEarnedRedeemedByDriversSuccessAction(response.data));
    } else if(response.status_code === 400) {
      yield put(RedemptionActions.redemptionPointsEarnedRedeemedByDriversFailAction());
    }
  } else {
    yield put(RedemptionActions.redemptionPointsEarnedRedeemedByDriversFailAction());
  }
}

export function * getTimeOfRedemptionByHourDetails (action) {
  const { response, error } = yield call(
    RedemptionDashboardApi.getRedemeptionTimeOfRedemptionByHourDetails,
    action.payload
  );

  if(response) {
    if(response.status_code === 200) {
      yield put(RedemptionActions.redemptionTimeOfRedemptionByHourSuccessAction(response.data));
    } else if(response.status_code === 400) {
      yield put(RedemptionActions.redemptionTimeOfRedemptionByHourFailAction());
    }
  } else {
    yield put(RedemptionActions.redemptionTimeOfRedemptionByHourFailAction());
  }
}

export function* getTimeOfRedemptionDetails(action) {

  const { response, error } = yield call(
    RedemptionDashboardApi.getTimeOfRedemptionDetails,
    action.payload
  );

  if (response) {
    if (response.status_code === 200) {
      yield put(RedemptionActions.timeRedemptionSuccessAction(response.data));
    } else if (response.status_code === 400) {
      yield put(RedemptionActions.timeRedemptionFailAction());
    }
  } else {
    yield put(RedemptionActions.timeRedemptionFailAction());
  }

}

export function* getAgeOfRedemptionBySnapshotDetails(action) {

  const { response, error } = yield call(
    RedemptionDashboardApi.getAgeOfRedemptionBySnapshotDetails,
    action.payload
  );

  if (response) {
    if (response.status_code === 200) {
      yield put(RedemptionActions.ageOfRedemptionSnapshotSuccessAction(response.data));
    } else if (response.status_code === 400) {
      yield put(RedemptionActions.ageOfRedemptionSnapshotFailAction());
    }
  } else {
    yield put(RedemptionActions.ageOfRedemptionSnapshotFailAction());
  }

}

export function* getAgeOfRedemptionByTrendDetails(action) {
  const { response, error } = yield call(
    RedemptionDashboardApi.getAgeOfRedemptionByTrendDetails,
    action.payload
  );

  if (response) {
    if (response.status_code === 200) {
      yield put(RedemptionActions.ageOfRedemptionTrendSuccessAction(response.data));
    } else if (response.status_code === 400) {
      yield put(RedemptionActions.ageOfRedemptionTrendFailAction());
    }
  } else {
    yield put(RedemptionActions.ageOfRedemptionTrendFailAction());
  }
}

export function* getAgeOfRedemptionByFrequencyDetails(action) {

  const { response, error } = yield call(
    RedemptionDashboardApi.getAgeOfRedemptionByFrequencyDetails,
    action.payload
  );

  if (response) {
    if (response.status_code === 200) {
      yield put(RedemptionActions.ageOfRedemptionFrequencySuccessAction(response.data));
    } else if (response.status_code === 400) {
      yield put(RedemptionActions.ageOfRedemptionFrequencyFailAction());
    }
  } else {
    yield put(RedemptionActions.ageOfRedemptionFrequencyFailAction());
  }

}

export function* getRedemptionUserAgeDataDetails(action) {

  const { response, error } = yield call(
    RedemptionDashboardApi.getRedemptionUserAgeDataDetails,
    action.payload
  );

  if (response) {
    if (response.status_code === 200) {
      yield put(RedemptionActions.redemptionUserAgeDataSuccessAction(response.data));
    } else if (response.status_code === 400) {
      yield put(RedemptionActions.redemptionUserAgeDataFailAction());
    }
  } else {
    yield put(RedemptionActions.redemptionUserAgeDataFailAction());
  }

}

export default function* rootRedemptionSaga() {
  // yield takeEvery(RedemptionActionTypes.GET_REDCATEGORY_FILTER_DROPDOWN_REQUEST, getRedCategoryFilterAsync);
  yield takeEvery(RedemptionActionTypes.GET_TILES_REQUEST, getTilesDetails);
  yield takeEvery(RedemptionActionTypes.GET_BUDGET_SNAPSHOT_REQUEST, getSnapshotDetails);
  yield takeEvery(RedemptionActionTypes.GET_BUDGET_TREND_REQUEST, getTrendDetails);
  yield takeEvery(RedemptionActionTypes.GET_MERCHANT_DATA_REQUEST, getMerchantDetails);
  yield takeEvery(RedemptionActionTypes.GET_CATEGORY_DATA_REQUEST, getCategoryDetails);
  yield takeEvery(RedemptionActionTypes.GET_POINTS_EARNED_REDEEMED_REQUEST, getPointsEarnedRedeemedDetails);
  yield takeEvery(RedemptionActionTypes.GET_POINTS_EARNED_REDEEMED_REQUEST, getPointsEarnedRedeemedByDriversDetails);
  yield takeEvery(RedemptionActionTypes.GET_TIME_OF_REDEMPTION_BY_HOUR_REQUEST, getTimeOfRedemptionByHourDetails);
  yield takeEvery(RedemptionActionTypes.GET_TIME_REDEEMED_REQUEST, getTimeOfRedemptionDetails);
  yield takeEvery(RedemptionActionTypes.GET_AGE_OF_REDEMPTION_BY_SNAPSHOT_REQUEST, getAgeOfRedemptionBySnapshotDetails);
  yield takeEvery(RedemptionActionTypes.GET_AGE_OF_REDEMPTION_BY_TREND_REQUEST, getAgeOfRedemptionByTrendDetails);
  yield takeEvery(RedemptionActionTypes.GET_AGE_OF_REDEMPTION_BY_FREQUENCY_REQUEST, getAgeOfRedemptionByFrequencyDetails);
  yield takeEvery(RedemptionActionTypes.GET_REDEMPTION_USER_AGE_DATA_REQUEST, getRedemptionUserAgeDataDetails);
}