import React from "react";
import Chart from "react-google-charts";

class SankeyChart extends React.Component {
  constructor( props ) {
    super( props );
  }

  render() {
    const chartEvents = [
      {
        eventName: "select",
        callback( chartWrapper ) {
        }
      }
    ];

    const data = [
      ["From", "To", "Appreciation"],
      ["HR", "HR ", 3],
      ["HR", "Finance ", 2],
      ["Delivery", "Finance ", 2],
      ["Finance", "HR ", 2],
      ["Admin", "Delivery ", 2],
      ["Admin", "Admin ", 2],
      ["Admin", "Finance ", 1],
      ["Finance", "Admin ", 3],
      ["Finance", "Finance ", 1]

    ];
    var colors = ["#a6cee3", "#b2df8a", "#fb9a99", "#fdbf6f",
      "#cab2d6", "#ffff99", "#1f78b4", "#33a02c"];

    var options = {    
      sankey: {
        node: {
          colors: colors
        },
        link: {
          colorMode: "normal",
          colors: colors
        }
      }
    };
    return ( 
      <Chart
        chartType="Sankey"
        width={ this.props.width }
        height={ this.props.height }
        data={data}
        options={options}
        chartEvents={chartEvents}
      />
    );
  }
}

export default SankeyChart;
