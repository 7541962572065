import Api from "../Utilities/Api/ApiMethods";
import APIS from "../Utilities/Api/Apis";

const getIsEventsEnabledOnOrg = async (payload) => {
  try {
    const response = await Api.post(
      APIS.ENGAGEMENTS.IS_EVENTS_ENABLED,
      payload
    );
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error,
      },
    };
  }
};

const getEngagementTilesDataDetails = async (payload) => {
  try {
    const response = await Api.post(
      APIS.ENGAGEMENTS.TILES,
      payload
    );
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error,
      },
    };
  }
};

const getEngagementNumOfUniqueUsersDetails = async (payload) => {
  try {
    const response = await Api.post(
      APIS.ENGAGEMENTS.NUMBER_OF_UNIQUE_USERS,
      payload
    );
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error,
      },
    };
  }
};
const getengagementProportionOfUniqueUsersDetails = async (payload) => {
  try {
    const response = await Api.post(
      APIS.ENGAGEMENTS.PROPORTION_OF_UNIQUE_USER,
      payload
    );
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error,
      },
    };
  }
};

const getFrequencyOfAppUsesDetails = async (payload) => {
  try {
    const response = await Api.post(
      APIS.ENGAGEMENTS.FREQUENCY_OF_APP_USES,
      payload
    );
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error,
      },
    };
  }
};

const getFrequencyOfFeatureUsesDetails = async (payload) => {
  try {
    const response = await Api.post(
      APIS.ENGAGEMENTS.FREQUENCY_OF_FEATURE_USES,
      payload
    );
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error,
      },
    };
  }
};

const getPointEarningCategoryDetails = async (payload) => {
  try {
    const response = await Api.post(
      APIS.ENGAGEMENTS.POINT_EARNING_CATEGORIES,
      payload
    );
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error,
      },
    };
  }
};

const getPointEarningCategoryTrendDetails = async (payload) => {
  try {
    const response = await Api.post(
      APIS.ENGAGEMENTS.POINT_EARNING_CATEGORIES_TREND,
      payload
    );
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error,
      },
    };
  }
};

const getTopPointEarnerDetails = async (payload) => {
  const apiEndingWith = payload.apiEndingWith;
  try {
    const response = await Api.post(
      APIS.ENGAGEMENTS.TOP_POINT_EARNING+ apiEndingWith,
      payload
    );
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error,
      },
    };
  }
};


const getTopPointEarnerBreakDownDetails = async (payload) => {
  const apiEndingWith = payload.apiEndingWith;
  try {
    const response = await Api.post(
      APIS.ENGAGEMENTS.TOP_POINT_EARNING+ apiEndingWith+"&is_break_down=true",
      payload
    );
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error,
      },
    };
  }
};

const getAvgAppUsersByDayAndTimeDetails = async (payload) => {
  try {
    const response = await Api.post(
      APIS.ENGAGEMENTS.AVG_APP_USERS_BY_DAY_AND_TIME,
      payload
    );
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error,
      },
    };
  }
};

const getEventsResponsesDetails = async (payload) => {
  try {
    const response = await Api.post(
      APIS.ENGAGEMENTS.EVENTS_RESPONSES,
      payload
    );
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error,
      },
    };
  }
};

const getNotificationsDetails = async (payload) => {
  try {
    const response = await Api.post(
      APIS.ENGAGEMENTS.NOTIFICATIONS,
      payload
    );
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error,
      },
    };
  }
};

export default {
  getIsEventsEnabledOnOrg,
  getEngagementTilesDataDetails,
  getPointEarningCategoryDetails,
  getPointEarningCategoryTrendDetails,
  getTopPointEarnerDetails,
  getTopPointEarnerBreakDownDetails,
  getFrequencyOfFeatureUsesDetails,
  getFrequencyOfAppUsesDetails,
  getEngagementNumOfUniqueUsersDetails,
  getengagementProportionOfUniqueUsersDetails,
  getAvgAppUsersByDayAndTimeDetails,
  getEventsResponsesDetails,
  getNotificationsDetails
};
