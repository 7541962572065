import React from "react";
import { TableLoader } from "../";
// Table Component
class TableContainer extends React.Component {
  thousandsWithCommas = (amount) => {
    if (!amount || amount < 0) return 0
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  render () {
    let disableClick = this.props.disableClick ? true : false;
    return (
      <div className="tableModule table-responsive">
        <table className="table table-hover">
          {/* For strip table have 'table-striped' class by replacing 'table-hover' class in above div mentioned */}
          <thead>
            <tr>
              { this.props.tableHeading.map((heading) =>
                <th scope="col" key={`tableHeading-${heading}`} >{heading}</th>
              ) }
            </tr>
          </thead>
          <tbody>
            
            { 
              this.props.data.length > 0
              ? this.props.data.map(employee => {
                  return (
                    <tr onClick={ () => !disableClick ? this.props.rowClickHandler(employee) : "" } key={`tableContent-${employee.receiver_email}`} >
                      <td> { employee.receiver_first_name ? `${employee.receiver_first_name} ${employee.receiver_last_name}` : "Anonymous" }</td>                 
                      {employee.points ? <td>{this.thousandsWithCommas(employee.points)}</td> : ''}
                      <td> {this.props.fromredemption ? <span class="vis-hidden">abcdefgf</span>: ''}{employee.count}</td>
                      <td>{employee.receiver_email}</td>
                    </tr>    
                  ); 
                }) : null }
          </tbody>
        </table>
        { !this.props.data.length > 0 ? <div className="dataNotFound">  
        Pending Selection
        </div> : null }
      </div>
    );
  }
}

export default TableContainer;