import { combineReducers } from "redux";

import LoginReducer from "../Modules/Login/Reducers/LoginReducers";
import CommonReducer from "../Common/CommonStore/CommonReducer";
import AppreciationDashboardReducer from "../Modules/Dashboard/Components/Appreciation/Reducers/AppreciationReducers";
import RedemptionDashboardReducer from "../Modules/Dashboard/Components/RedemptionPattern/Reducers/RedemptionReducers";
import EngagementDashboardReducer from "../Modules/Dashboard/Components/Engagement/Reducers/EngagementReducers";

export default combineReducers( {
  Login: LoginReducer,
  Common: CommonReducer,
  AppreciationDashboard: AppreciationDashboardReducer,
  RedemptionDashboard: RedemptionDashboardReducer,
  EngagementDashboard: EngagementDashboardReducer
} );
