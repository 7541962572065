import React from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";

import deleteChips from "../../static/images/delete.png";

class MultiSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: [],
      showExpanded: false,
      ForcecloseExpandedView: false,
    };
  }

  filterColors = (inputValue) => {
    return this.props.data.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.filterColors(inputValue));
      }, 1000);
    });

  render() {
    const isMultiSelect = this.props.isMultiSelect === undefined ? true : false;
    const showAllOption = this.props.showAllOption === undefined ? true : false;

    let expandedViewClass = "SelectedInput";
    if (this.props.showOptions) {
      expandedViewClass = "SelectedInput show";
    }
    if (this.props.showMore) {
      expandedViewClass = "SelectedInput show showMore";
    }
    return (
      <React.Fragment>
        <div className="selectInput">
          {this.props.isSearchable ? (
            <AsyncSelect
              isMulti={true}
              classNamePrefix="multi-select"
              defaultOptions
              isClearable={false}
              options={this.props.data}
              loadOptions={(input) =>
                this.props.handleChange(input, this.props.name)
              }
              placeholder={this.props.placeholder}
              onChange={(value) =>
                this.props.handleMultiSelect(this.props.name, value)
              }
              value={this.props.selectedValue}
              getOptionValue={(option) =>
                option.id ? `${option.id}` : `${option.name}`
              }
            />
          ) : (
            <Select
              isMulti={isMultiSelect}
              cacheOptions
              defaultOptions
              isClearable={false}
              options={this.props.data}
              placeholder={showAllOption ?"All": null}
              onChange={(value) =>
                this.props.handleMultiSelect(this.props.name, value)
              }
              value={this.props.selectedValue}
              getOptionValue={(option) =>
                option.id ? `${option.id}` : `${option.name}`
              }
            />
          )}

          {isMultiSelect ? (
            <div className={expandedViewClass}>
              <div
                className="clear-btn"
                onClick={() => this.props.clearAllSelected(this.props.name)}
              >
                <img src={deleteChips} title="clearAll"></img>
              </div>
              {this.props.selectedValue.map((selectedOption, index) => {
                return (
                  <span
                    key={index}
                    className={
                      this.props.inValidSelection
                        ? this.props.inValidSelection.includes(
                            selectedOption.id
                          )
                          ? "invalidSelection"
                          : ""
                        : null
                    }
                  >
                    {selectedOption.label}
                    <span
                      onClick={() =>
                        this.props.handleDeleteOptions(this.props.name, index)
                      }
                    >
                      <span className="chipsclear">&times;</span>
                    </span>
                  </span>
                );
              })}
              {this.props.selectedValue.length > 2 ? (
                <div
                  className="moreBtn"
                  onClick={() => this.props.handleShowMore(this.props.name)}
                >
                  +{this.props.selectedValue.length - 2} more
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

export default MultiSelect;
