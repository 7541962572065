import React, { PureComponent } from "react";
import {
  Radar, RadarChart, PolarGrid, Legend,
  PolarAngleAxis, PolarRadiusAxis, Tooltip, Text
} from "recharts";
export default class Spider extends PureComponent {
  customTicks = (props) => {
    return <g>
      <circle cx={props.x} 
        cy={props.y} r={9} stroke="#ffffff" fill="#ffffff" ></circle>
      <text x={props.x - 5} 
        y={props.y + 5} 
        fontSize= {14} 
        fontFamily= "roboto, sans-serif" 
        fontWeight={600} 
        fill = "#333333" >
        <tspan className="test class" >{ props.payload.value }
        </tspan>
      </text>
    </g>;
  }

  customTickFormatter = (name) => {
    if(name.length > 15) {
      return `${name.slice(0, 12)}...`;
    } else {
      return name;
    }
  }

  customLabel = (props) => {
    return (<Text
      x={props.x} 
      y={props.y} 
      textAnchor={props.textAnchor}
      id={`radarLabel${props.index}`}
      width={100}
      fill={"#595959"}
      fontSize={14}
      fontWeight={500}
    > 
      {props.payload.value}
    </Text>);
  }

  render () {
    return (
      <RadarChart cx={this.props.width * 0.5 } cy={this.props.height * 0.5 } outerRadius={this.props.width * 0.25} width={ this.props.width } height={ this.props.height } data={this.props.data} >
        <Tooltip wrapperStyle={{ margin: "0px",
          backgroundColor: "rgb(255, 255, 255)",
          "white-space": "nowrap",
          "font-style": "roboto, sans-serif",
          fontSize: "14px",
          fontWeight: "500px"
          
        }}
        formatter = { (value, name, props) =>  [ value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"), name ]  }
        />        
         
        <PolarGrid 
          gridType={ this.props.data.length > 2 ? "polygon" : "circle" } 
          stroke="#d0d0d0"
          strokeWidth="2"

        />
      
        <PolarAngleAxis 
          dataKey="name"
          tick={this.customLabel} 
          axisLineType="circle"
          // tickFormatter={this.customTickFormatter}
       />
        
        <Radar name="Benchmark" dataKey="expected" stroke="#098141" fill="#098141" fillOpacity={0.8} />
        <Radar name="Achieved" dataKey="user_appreciation_count" stroke="#54d390" fill="#54d390" fillOpacity={0.4} />
        <Legend layout="vertical" align={this.props.legendAlign} verticalAlign="top" margin = { { top: 0, left: 0, right: 0, bottom: 0 } } />
        <PolarRadiusAxis 
          angle={90} 
          domain={[0, this.props.maxDomain]}
          tick={{ fontSize: 12, fontFamily: "roboto, sans-serif", fontWeight: 600, fill: "#333333" }}
          // scale={linear}
          orientation="middle"
          axisLine={false}
          tick={ this.customTicks }
        
        />
        
      </RadarChart>
    );
  }
}
