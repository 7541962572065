import * as LoginActionTypes from "./LoginActionTypes";

export function loginRequestAction( data ) {

  return {
    type: LoginActionTypes.LOGIN_REQUEST,
    payload: data
  };
};

export function loginSuccessAction( data ) {
  return {
    type: LoginActionTypes.LOGIN_SUCCESS,
    payload: data
  };
};

export function loginFailAction( data ) {
  return {
    type: LoginActionTypes.LOGIN_FAIL,
    payload: data
  };
};

export function logoutAction( ) {
  return {
    type: LoginActionTypes.LOGOUT_REQUEST
  };
};

export function loginErrorClearAction() {
  return {
    type: LoginActionTypes.LOGIN_ERROR_CLEAR
  }
}