import React from "react";
import { StackedBarChartVertical } from "../../../../../Widgets";
import StackedBarChartLoader from "../../../../../Widgets/StackedBarChart/StackedBarChartLoader";
import { connect } from "react-redux";
import { GraphActions } from "../../../../../UI";
import { THEME_COLORS } from "../../../../../constants/constants";
// import Switch from "react-switch";

let colors = ["#0d2d46", "#ff7b85"];

const handleExpandChart = (props, graphName) => {
  let colors = ["#0d2d46", "#ff7b85"];

  let graph = "";
  if (props.graphTitle === "Top Merchants") {
    let marginLeft = 0;

    props.merchantData.result.forEach(Merchant => {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      ctx.font = "12px Roboto";
      let width = ctx.measureText(Merchant["Merchant Name"]).width;
      marginLeft = width > marginLeft ? width : marginLeft;
    });
    graph = (
      <StackedBarChartVertical
        width={window.innerWidth * 0.8}
        height={window.screen.height * 0.6}
        xAxisNames={props.brandSwitch ? ["Number Of Redemptions"] : ["Redemption Value (points)"]}
        data={props.merchantData.result}
        margin={{ top: 40, right: 40, left: marginLeft, bottom: 70 }}
        colors={ props.brandSwitch ? [colors[0]]: [THEME_COLORS[1]] }
        showRightYAxis={false}
        expanded={true}
        isOnClickEvent={false}
        xAxisNameId={"Merchant Name"}
        skipLegend={false}
        xAxisAngle={0}
        textAnchor="middle"
        yAxisLabel="Merchant"
        xAxisLabel={props.brandSwitch ? "Number Of Redemptions" : "Redemption Value (points)"}
        showBrush={
          window.screen.height * 0.8 < props.merchantData.result.length * 35
        }
        brushWidth={window.innerWidth * 0.8}
      />
    );
  } else if (props.graphTitle === "Top Reward Categories") {
    let marginLeft = 0;

    props.categoryData.result.forEach(Category => {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      ctx.font = "12px Roboto";
      let width = ctx.measureText(Category["Category Name"]).width;
      marginLeft = width > marginLeft ? width : marginLeft;
    });
    graph = (
      <div className="redemption-category">
      <StackedBarChartVertical
        width={window.innerWidth * 0.8}
        height={window.screen.height * 0.6}
        xAxisNames={props.categorySwitch ? ["Number Of Redemptions"] : ["Redemption Value (points)"]}
        data={props.categoryData.result}
        margin={{ top: 40, right: 40, left: marginLeft, bottom: 70 }}
        colors={ props.categorySwitch ? [colors[0]]: [THEME_COLORS[1]] }
        showRightYAxis={false}
        expanded={true}
        isOnClickEvent={false}
        xAxisNameId={"Category Name"}
        skipLegend={false}
        xAxisAngle={0}
        textAnchor="middle"
        yAxisLabel="Reward Category"
        xAxisLabel={props.categorySwitch ? "Number Of Redemptions" : "Redemption Value (points)"}
        showBrush={
          window.screen.height * 0.8 < props.categoryData.result.length * 35
        }
        brushWidth={window.innerWidth * 0.8}
      />
      </div>
    );
  }

  props.showEventModal({
    showEventModal: true,
    eventModalTitle: "",
    eventModalBody: "",
    showEventModalButtonOne: false,
    showEventModalButtonTwo: false,
    buttonOneEventModalHandler: () => props.hideEventModal(),
    buttonTwoEventModalHandler: null,
    buttonOneEventModalName: "",
    buttonTwoEventModalName: "",
    graphContent: graph,
    graphTitle: props.graphTitle,
    graphSubTitle: props.graphSubTitle,
    showGraph: true,
    graphName: "Merchant Point Based Redemption",
    graphId: ""
  });
};

class MerchantBarChartWrapper extends React.Component {
  constructor(props) {
    super();
  }

  getBrandFilterObjectData = () => {
    let filterObjectData = {
      ...this.props.getFilterObject(),
      byNumberOfRedeemption: this.props.brandSwitch
    }
    return filterObjectData;
  }

  getCategoryFilterObjectData = () => {
    let filterObjectData = {
      ...this.props.getFilterObject(),
      byNumberOfRedeemption: this.props.categorySwitch
    }
    return filterObjectData;
  }

  render() {
    let screenWidth = window.innerWidth;
    // const onSwitchColorCode = "#888888";
    return (
      <div className="budgetUtilization borderTopContainer">
        <div className="row">
          <div className="col-sm-12">
            <div className="titleOuter">
              <h2>Redemption Preferences & Behaviour</h2>
            </div>
          </div>
        </div>
        {/* Start point based redemptions of Merchant */}
        <div className="row">
          <div className="col-sm-6">
            <div className="graphsActionContainer">
              <h3> Top Merchants </h3>
              <div className="exportContainer">
                <GraphActions
                  showExpansion={
                    !this.props.merchantData.loader &&
                    this.props.merchantData.result &&
                    this.props.merchantData.result.length > 0
                  }
                  showCSVExport={
                    !this.props.merchantData.loader &&
                    this.props.merchantData.result &&
                    this.props.merchantData.result.length > 0
                  }
                  showImageExport={
                    !this.props.merchantData.loader &&
                    this.props.merchantData.result &&
                    this.props.merchantData.result.length > 0
                  }
                  showInfo={
                    this.props.merchantData.result &&
                    this.props.merchantData.result.length > 0
                  }
                  from={!this.props.brandSwitch ? "Top Merchants (by redemption value)" : "Top Merchants (by number of redemptions)"}
                  apiUrl={"auth/redemption/graph/histogram"}
                  filterObjectForCSV={this.getBrandFilterObjectData}
                  graphId="merchantPointBasedRedemptionsContainer"
                  imageName="Merchant PointBased Redemptions Snapshot"
                  infoMessage="Identify the top rewards based on popularity (# of redemption transactions) and value redeemed."
                  handleExpand={graphId =>
                    handleExpandChart({
                      ...this.props,
                      graphTitle:
                        this.props.merchantData.result &&
                        this.props.merchantData.result.length > 0
                          ? "Top Merchants"
                          : " "
                    })
                  }
                />
              </div>
            </div>

            {/* <div className="graphInfoNote">
              <span>Note: </span> Please Hover to see details of the merchant redemption
            </div> */}

            <div className="tabContainer">
              <div className="tablinkouter">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a className={`nav-link tab-recive ${!this.props.brandSwitch ? "active" : ""}`} role="tab" data-toggle="tab" onClick={ () => this.props.pointBasedRedemptionHandler(false, true ) } >By Redemption Value </a>
                  </li>
                  <li className="nav-item">
                    <a className={`nav-link tab-given ${this.props.brandSwitch ? "active" : ""}`} role="tab" data-toggle="tab" onClick={ () => this.props.pointBasedRedemptionHandler(true, true ) }> By Number of Redemptions </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* <div className="titlewithSlider d-flex align-items-center">
              <span className="d-inline-block mr-2"><strong> {this.props.brandSwitch ? "By Number of redemption" : "By Redemption Value" }: </strong></span>
                <label className="m-0">
                  <Switch onChange={ () => this.props.pointBasedRedemptionHandler(!this.props.brandSwitch, true )}
                    checked={this.props.brandSwitch}
                    uncheckedIcon={null}
                    checkedIcon={null}
                    onColor={onSwitchColorCode}
                  />
                </label>
            </div> */}
            {this.props.merchantData.loader ? (
              <StackedBarChartLoader
                width={screenWidth * 0.4}
                height={window.screen.height * 0.45}
              />
            ) : this.props.merchantData.result &&
              this.props.merchantData.result.length > 0 ? (
              <div>
                <StackedBarChartVertical
                  width={screenWidth * 0.4}
                  height={window.screen.height * 0.45}
                  xAxisNames={this.props.brandSwitch ? ["Number Of Redemptions"] : ["Redemption Value (points)"]}
                  data={this.props.merchantData.result}
                  margin={{ top: 40, right: 50, left: 70, bottom: 70 }}
                  colors={ this.props.brandSwitch ? [colors[0]]: [THEME_COLORS[1]] }
                  showRightYAxis={false}
                  isOnClickEvent={false}
                  xAxisNameId={"Merchant Name"}
                  skipLegend={false}
                  xAxisAngle={0}
                  textAnchor="middle"
                  yAxisLabel="Merchant"
                  xAxisLabel={this.props.brandSwitch ? "Number Of Redemptions" : "Redemption Value (points)"}
                  showBrush={this.props.merchantData.result.length > 12}
                  brushWidth={screenWidth * 0.4}
                />

                <div
                  id="merchantPointBasedRedemptionsContainer"
                  className="d-none"
                >
                  <StackedBarChartVertical
                    width={screenWidth * 0.4}
                    height={window.screen.height * 0.45}
                    xAxisNames={this.props.brandSwitch ? ["Number Of Redemptions"] : ["Redemption Value (points)"]}
                    data={this.props.merchantData.result}
                    margin={{ top: 40, right: 50, left: 70, bottom: 70 }}
                    colors={ this.props.brandSwitch ? [colors[0]]: [THEME_COLORS[1]] }
                    showRightYAxis={false}
                    isOnClickEvent={false}
                    xAxisNameId={"Merchant Name"}
                    skipLegend={false}
                    xAxisAngle={0}
                    textAnchor="middle"
                    yAxisLabel="Merchant"
                    xAxisLabel={this.props.brandSwitch ? "Number Of Redemptions" : "Redemption Value (points)"}
                    showBrush={false}
                    isFromImage={true}
                    brushWidth={screenWidth * 0.4}
                  />
                </div>
              </div>
            ) : (
              <div className="dataNotFound">No Data Found</div>
            )}
          </div>
          {/* End  point based redemptions of Merchant  */}

          {/* Start point based redemptions of Category */}
          <div className="col-sm-6">
            <div className="graphsActionContainer">
              <h3> Top Reward Categories </h3>
              <div className="exportContainer">
                <GraphActions
                  showExpansion={
                    !this.props.categoryData.loader &&
                    this.props.categoryData.result &&
                    this.props.categoryData.result.length > 0
                  }
                  showCSVExport={
                    !this.props.categoryData.loader &&
                    this.props.categoryData.result &&
                    this.props.categoryData.result.length > 0
                  }
                  showImageExport={
                    !this.props.categoryData.loader &&
                    this.props.categoryData.result &&
                    this.props.categoryData.result.length > 0
                  }
                  showInfo={
                    this.props.categoryData.result &&
                    this.props.categoryData.result.length > 0
                  }
                  from={!this.props.categorySwitch ? "Top Reward Categories (by redemption value)" : "Top Reward Categories (by number of redemptions)"}
                  apiUrl={"auth/redemption/graph/category"}
                  filterObjectForCSV={this.getCategoryFilterObjectData}
                  graphId="categoryPointBasedRedemptionsContainer"
                  imageName="Category PointBased Redemptions Snapshot"
                  infoMessage="Identify the top reward categories based on popularity (# of redemption transactions) and value redeemed."
                  handleExpand={graphId =>
                    handleExpandChart({
                      ...this.props,
                      graphTitle:
                        this.props.categoryData.result &&
                        this.props.categoryData.result.length > 0
                          ? "Top Reward Categories"
                          : " "
                    })
                  }
                />
              </div>
            </div>
            {/* <div className="graphInfoNote">
              <span>Note: </span> Please Hover to see details of the category redemption
            </div> */}

            <div className="tabContainer">
              <div className="tablinkouter">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a className={`nav-link tab-recive ${!this.props.categorySwitch ? "active" : ""}`} role="tab" data-toggle="tab" onClick={ () => this.props.pointBasedRedemptionHandler(false, false ) } >By Redemption Value </a>
                  </li>
                  <li className="nav-item">
                    <a className={`nav-link tab-given ${this.props.categorySwitch ? "active" : ""}`} role="tab" data-toggle="tab" onClick={ () => this.props.pointBasedRedemptionHandler(true, false ) }> By Number of Redemptions </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* <div className="titlewithSlider d-flex align-items-center">
              <span className="d-inline-block mr-2"><strong> {this.props.categorySwitch ? "By Number of redemption" : "By Redemption Value" }: </strong></span>
                <label className="m-0">
                  <Switch onChange={ () => this.props.pointBasedRedemptionHandler(!this.props.categorySwitch, false )}
                    checked={this.props.categorySwitch}
                    uncheckedIcon={null}
                    checkedIcon={null}
                    onColor={onSwitchColorCode}
                  />
                </label>
            </div> */}

            {this.props.categoryData.loader ? (
              <StackedBarChartLoader
                width={screenWidth * 0.4}
                height={window.screen.height * 0.45}
              />
            ) : this.props.categoryData.result &&
              this.props.categoryData.result.length > 0 ? (
              <div className="redemption-category">
                <StackedBarChartVertical
                  width={screenWidth * 0.4}
                  height={window.screen.height * 0.45}
                  xAxisNames={this.props.categorySwitch ? ["Number Of Redemptions"] : ["Redemption Value (points)"]}
                  data={this.props.categoryData.result}
                  margin={{ top: 40, right: 50, left: 70, bottom: 70 }}
                  colors={ this.props.categorySwitch ? [colors[0]]: [THEME_COLORS[1]] }
                  showRightYAxis={false}
                  isOnClickEvent={false}
                  xAxisNameId={"Category Name"}
                  skipLegend={false}
                  xAxisAngle={0}
                  textAnchor="middle"
                  yAxisLabel="Reward Category"
                  xAxisLabel={this.props.categorySwitch ? "Number Of Redemptions" : "Redemption Value (points)"}
                  showBrush={this.props.categoryData.result.length > 12}
                  brushWidth={screenWidth * 0.4}
                />

                <div
                  id="categoryPointBasedRedemptionsContainer"
                  className="d-none"
                >
                  <StackedBarChartVertical
                    width={screenWidth * 0.4}
                    height={window.screen.height * 0.45}
                    xAxisNames={this.props.categorySwitch ? ["Number Of Redemptions"] : ["Redemption Value (points)"]}
                    data={this.props.categoryData.result}
                    margin={{ top: 40, right: 50, left: 90, bottom: 70 }}
                    colors={ this.props.categorySwitch ? [colors[0]]: [THEME_COLORS[1]] }
                    showRightYAxis={false}
                    isOnClickEvent={false}
                    xAxisNameId={"Category Name"}
                    skipLegend={false}
                    xAxisAngle={0}
                    textAnchor="middle"
                    yAxisLabel="Reward Category"
                    xAxisLabel={this.props.categorySwitch ? "Number Of Redemptions" : "Redemption Value (points)"}
                    showBrush={false}
                    isFromImage={true}
                    brushWidth={screenWidth * 0.4}
                  />
                </div>
              </div>
            ) : (
              <div className="dataNotFound">No Data Found</div>
            )}
          </div>
        </div>
        {/* End  point based redemptions of Category  */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    merchantData: state.RedemptionDashboard.merchantData,
    merchantDataLoader: state.RedemptionDashboard.merchantData.loader,
    categoryData: state.RedemptionDashboard.categoryData,
    categoryDataLoader: state.RedemptionDashboard.categoryData.loader
  };
};

export default connect(mapStateToProps)(MerchantBarChartWrapper);
