import React, { PureComponent } from "react";
import * as d3 from "d3";
import { getMax, getMin } from "../../../Utilities/common-utill";
import { CHART, THEME_COLORS } from "../../../constants/constants";
import { thousandsWithCommas } from "../../../Utilities/common-utill";
export default class BubbleChart extends PureComponent {
  componentDidMount() {
    this.loadChart();
  }
  componentDidUpdate() {
    d3.select(this.node).select("svg").remove();
    // d3.select(this.bubbleInfoNode).select("svg").remove();

    this.loadChart();
  }

  // Add a scale for bubble color
  bubbleColor = d3.scaleOrdinal().range(THEME_COLORS);

  loadChart = () => {
    // set the dimensions and margins of the graph
    const margin = this.props.margin,
      width = this.props.width - margin.left - margin.right,
      height = this.props.height - margin.top - margin.bottom;
    // append the svg object to the body of the page
    const svg = d3
      .select(this.node)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    //Read the data
    const bubbleData = this.props.bubbleChartData;
    const data = bubbleData.sort((a, b) => (a.points < b.points ? 1 : -1));
    const minX = getMin(data, "transaction");
    const maxX = getMax(data, "transaction") + 100;

    const minY = getMin(data, "user_count");
    const maxY = getMax(data, "user_count") + 100;

    const minZ = getMin( [...data], "points");
    const maxZ = getMax([...data], "points");

    const bubbleSizeRange = [Math.max([(minZ / maxZ) * 28], 5), 28];
    const xWidth = width // - 100;

    // // ------------------- START Bubble size info -----------------

    // // const bubbleSvgLegends = d3
    // // .select(this.bubbleInfoNode)
    // // .append("svg")
    // // .attr("width", width + margin.left + margin.right)
    // // .attr("height", bubbleSizeRange[1]*2 +  50);
    // svg
    //   .append("circle")
    //   .attr("cx", width)
    //   .attr("cy", margin.top)
    //   .attr("r", function (d) {
    //     return bubbleSizeRange[1];
    //   })
    //   .style("fill", "none")
    //   .attr("stroke", "black");
    // svg
    //   .append("circle")
    //   .attr("cx", width)
    //   .attr("cy", margin.top+(bubbleSizeRange[1]*3))
    //   .attr("r", function (d) {
    //     return bubbleSizeRange[0];
    //   })
    //   .style("fill", "none")
    //   .attr("stroke", "black");
    // svg
    //   .append("text")
    //   // .attr("transform", "rotate(-90)")
    //   .attr("y", margin.top-bubbleSizeRange[1]+2)
    //   .attr("x", width)
    //   // .attr("dy", "1em")
    //   .style("text-anchor", "middle")
    //   .style("font-family", "Roboto")
    //   .style("font-weight", 500)
    //   .style("font-size", 14)
    //   .style("fill", "#758088")
    //   .text(maxZ);
    //   svg
    //   .append("text")
    //   // .attr("transform", "rotate(-90)")
    //   .attr("y", margin.top+(bubbleSizeRange[1]*3)-bubbleSizeRange[0]+2)
    //   .attr("x", width)
    //   // .attr("dy", "1em")
    //   .style("text-anchor", "middle")
    //   .style("font-family", "Roboto")
    //   .style("font-weight", 500)
    //   .style("font-size", 14)
    //   .style("fill", "#758088")
    //   .text(minZ);
    // // ------------------- END Bubble size info -----------------

    // Add X axis
    const x = d3.scaleLinear().domain([0, maxX]).range([0, xWidth]);
    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x))
      .selectAll("text")
      // .attr("y", 0)
      // .attr("x", 9)
      // .attr("dy", ".35em")
      .attr("transform", "rotate(-45)")
      .style("text-anchor", "end");

    // Add Y axis
    const y = d3.scaleLinear().domain([0, maxY]).range([height, 0]);
    svg.append("g").call(d3.axisLeft(y));

    // Add a scale for bubble size
    const z = d3.scaleLinear().domain([minZ, maxZ]).range(bubbleSizeRange);

    // ---- Tooltips
    let mousemove = (d) => {
      let div = d3
        .select(`#tooltip-bubble-chart-${this.props.graphName}`)
        .attr("class", "redemption-tooltip")
        .style("opacity", 0);
      const color = this.bubbleColor(d.category);
      let e = window.event,
        x = e.clientX + 100,
        y = e.clientY - 40;

      div.transition().duration(200).style("opacity", 1.0);

      div
        .html(
          `<span style="color:${color}">${d.category}</span><br/>
                <span style="color:#333">
                ${
                  this.props.chartDetails.axisLabel.xAxis
                }: ${thousandsWithCommas(d.transaction)}
                </br>${
                  this.props.chartDetails.axisLabel.yAxis
                }: ${thousandsWithCommas(d.user_count)}
                </br>${
                  this.props.chartDetails.axisLabel.zAxis
                }: ${thousandsWithCommas(d.points)}
            </span>`
        )
        .style("top", `${y - 50}px`)
        .style("left", `${x - 100}px`);
    };

    let mouseout = (d) => {
      let div = d3.select(`#tooltip-bubble-chart-${this.props.graphName}`);
      div.transition().duration(500).style("opacity", 0);
    };

    // Add dots
    svg
      .append("g")
      .selectAll("dot")
      .data(data)
      .enter()
      .append("circle")
      .attr("class", "bubbles")
      .attr("cx", function (d) {
        return x(d.transaction);
      })
      .attr("cy", function (d) {
        return y(d.user_count);
      })
      .attr("r", function (d) {
        return z(d.points);
      })
      .style("cursor", "pointer")
      .style("fill", (d) => {
        return this.bubbleColor(d.category);
      })
      .on("mouseover", mousemove)
      //   .on("mousemove", moveTooltip)
      .on("mouseleave", mouseout)
      .on("click", (d) => {
        const color = this.bubbleColor(d.category);
        this.props.bubbleClickedEvent(d, color);
      });

    // set x-axis labels
    svg
      .append("text")
      .attr(
        "transform",
        "translate(" + width / 2 + " ," + (height + margin.bottom - 5) + ")"
      )
      .style("text-anchor", "middle")
      .style("font-family", CHART.fontFamily)
      .style("font-weight", CHART.fontWeight)
      .style("font-size", 14)
      .style("fill", "#758088")
      .text(this.props.chartDetails.axisLabel.xAxis);

    // set y-axis labels
    svg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left)
      .attr("x", 0 - height / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .style("font-family", CHART.fontFamily)
      .style("font-weight", CHART.fontWeight)
      .style("font-size", 14)
      .style("fill", "#758088")
      .text(this.props.chartDetails.axisLabel.yAxis);

    // style to all axis ticks text
    svg
      .selectAll(".tick text")
      .style("fill", "#758088")
      .style("font-family", CHART.fontFamily)
      .style("font-size", 12)
      .style("font-weight", CHART.fontWeight);
  };

  render() {
    const graphName = "tooltip-bubble-chart-" + this.props.graphName;
    const legendsStyle = {
      margin: "0px 8px",
      width: "10px",
      height: "10px",
      display: "inline-block",
    };
    return (
      <div>
        <ul>
          {this.props.bubbleChartData.map((bbl) => (
            <li style={{ display: "inline", fontSize: "15px" }}>
              <span
                style={{
                  ...legendsStyle,
                  background: this.bubbleColor(bbl.category),
                }}
              ></span>
              {bbl.category}
            </li>
          ))}
        </ul>
        <div id={graphName}></div>
        {/* bellow is for rendering svg */}
        <div
          ref={(node) => {
            this.node = node;
          }}
          style={{ clear: "left" }}
        ></div>
      </div>
    );
  }
}
