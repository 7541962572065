import React from "react";
// import * as d3 from "d3";

class SankeyChart extends React.Component {
  constructor (props) {
    super(props);
  }

  componentDidMount () {
    this.loadChart();
  }

  componentDidUpdate () {
    // this.loadChart();
  }

    loadChart = () => {
      var units = "Appreciations";

      // set the dimensions and margins of the graph
      var margin = { top: 10, right: 10, bottom: 10, left: 10 };
      var width = this.props.width - margin.left - margin.right;
      var height = this.props.height - margin.top - margin.bottom;
      var graphName = this.props.graphName;  
      // format variables
      var formatNumber = d3.format(",.0f"); // zero decimal places
      var format = function (d) { return formatNumber(d) + " " + units; };
      // var color = d3.scaleOrdinal(d3.schemeCategory20);
        
      // append the svg object to the body of the page
      var svg = d3.select(this.node).append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", 
          "translate(" + margin.left + "," + margin.top + ")");

      // svg.remove();

      svg.selectAll("*").remove();
        
      // Set the sankey diagram properties
      var sankey = d3.sankey()
        .nodeWidth(7)
        .nodePadding(15)
        .size([width, height]);
        
      var path = sankey.link();
        
      // load the data
      //   d3.json("sankey.json", function (error, graph) {'
      var graph = { ...this.props.data };
      var nodeMap = {};
      graph.nodes.forEach(function (x) { nodeMap[x.name] = x; });
      graph.links = graph.links.map(function (x) {
        return {
          source: nodeMap[x.source],
          target: nodeMap[x.target],
          value: x.value
        };
      });
      sankey
        .nodes(graph.nodes)
        .links(graph.links)
        .layout(0);
        
      // add in the links
      var link = svg.append("g").selectAll(".link")
        .data(graph.links)
        .enter().append("path")
        .attr("class", "link")
        .attr("d", path)
        .attr("id", function (d, i) {
          d.id = i;
          return "link-" + graphName + i;
        })
        // .link {
        //   fill: none;
        //   stroke: #ccc;
        //   stroke-width: 2px;
        // }
        .style("fill", "none")
        .style("stroke", "#ccc")
        .style("stroke-width", function (d) { return Math.max(1, d.dy); })
        .style("stroke-opacity", 0.5)
        .sort(function (a, b) { return b.dy - a.dy; });
        
      // add the link titles
      link.append("title")
        .text(function (d) {
          return d.source.name + " → " + 
                        d.target.name + "\n" + format(d.value); 
        });
        
      // add in the nodes
      var node = svg.append("g").selectAll(".node")
        .data(graph.nodes)
        .enter().append("g")
        .attr("class", "node")
        .attr("data-clicked", 0)
        .attr("transform", function (d) { 
          return "translate(" + d.x + "," + d.y + ")"; 
        })
        .on("click", highlight_node_links)
                 ;
        
      // add the rectangles for the nodes
      node.append("rect")
        .attr("height", function (d) { return d.dy; })
        .attr("width", sankey.nodeWidth())
        .style("cursor", "pointer")
        .style("fill", (d) => d.color)
        .append("title")
        .text(function (d) { 
          return d.name + "\n" + format(d.value); 
        });
        
      // add in the title for the nodes
      node.append("text")
        .attr("x", -6)
        .attr("class", "text-sankey-node")
        .attr("y", function (d) { return d.dy / 2; })
        .attr("dy", ".35em")
        .style("cursor", "pointer")
        .style("font-family", "roboto")
        .style("font-weight", 500)
        .style("font-size", 14)
        .style("fill", "#595959")
        .attr("text-anchor", "end")
        .attr("transform", null)
        .text(function (d) { return d.name; })
        .filter(function (d) { return d.x < width / 2; })
        .attr("x", 6 + sankey.nodeWidth())
        .style("cursor", "pointer")
        .style("font-family", "roboto")
        .style("font-weight", 500)
        .style("font-size", 14)
        .style("fill", "#595959")
        .attr("text-anchor", "start");
        
      // the function for moving the nodes
      function dragmove (d) {
        d3.select(this)
          .attr("transform", 
            "translate(" + 
                       d.x + "," + 
                       (d.y = Math.max(
                         0, Math.min(height - d.dy, d3.event.y))
                       ) + ")");
        sankey.relayout();
        link.attr("d", path);
      }
      function highlight_node_links (node, i) {
        d3.selectAll(".link").style("stroke", "#ccc");
        d3.selectAll(".link").style("stroke-opacity", 0.5);
        var remainingNodes = [];
        var nextNodes = [];
        
        var stroke_opacity = 0;
        if(d3.select(this).attr("data-clicked") == "1") {
          // d3.selectAll(".node").attr("data-clicked", 0);
          d3.select(this).attr("data-clicked", "0");
          stroke_opacity = 0.5;
        } else {
          d3.selectAll(".node").attr("data-clicked", "0");
          d3.select(this).attr("data-clicked", "1");
          stroke_opacity = 0.7;
        }

        var traverse = [{
          linkType: "sourceLinks",
          nodeType: "target"
        }, {
          linkType: "targetLinks",
          nodeType: "source"
        }];
        
        traverse.forEach(function (step) {
          node[step.linkType].forEach(function (link) {
            remainingNodes.push(link[step.nodeType]);
            highlight_link(link.id, stroke_opacity, node.color);
          });
        
          while(remainingNodes.length) {
            nextNodes = [];
            remainingNodes.forEach(function (node) {
              node[step.linkType].forEach(function (link) {
                nextNodes.push(link[step.nodeType]);
                highlight_link(link.id, stroke_opacity, node.color);
              });
            });
            remainingNodes = nextNodes;
          }
        });
      }
        
      function highlight_link (id, opacity, color) {
        d3.select("#link-" + graphName + id).style("stroke-opacity", opacity);
        if(opacity == 0.7) {
          d3.select("#link-" + graphName + id).style("stroke", color);
        } else {
          d3.select("#link-" + graphName + id).style("stroke", "#ccc");
        }
      }
    //   });
    }    
    render () {
      return (
        <div ref={ (node) => this.node = node } >
          <div className="graphNodeTitle" style={{width:this.props.width}}>
              <div className="left">
                <p>From</p>
              </div>
              <div className="right">
                <p>To</p>
              </div>
            </div>
        </div>
      );
    }
}

export default SankeyChart;