import React, { PureComponent } from 'react';
import {
  PieChart, Pie, Legend, Tooltip, Cell
} from 'recharts';
import { THEME_COLORS } from "./../../constants/constants"

const RADIAN = Math.PI / 180;
const COLORS = [THEME_COLORS[1], THEME_COLORS[2], THEME_COLORS[4]];

class Example extends PureComponent {

    // constructor(props) {
    //     parent(props);
    // }

    renderCustomizedLabel = ({
        cx, cy, midAngle, innerRadius, outerRadius, percent, value, index,
      }) => {
         const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + (radius*2.2) * Math.cos(-midAngle * RADIAN);
        const y = cy + (radius*2.2) * Math.sin(-midAngle * RADIAN);
      
        return (
            value !== 0 ? 
          <text x={x} y={y} fill={COLORS[index % COLORS.length]} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${percent}%`}
             </text>
             : null
        );
    };

    render() {

        let circleRadius = Math.min(this.props.width, this.props.height) / 2.3;
        return (
            <div className="budgetUtilizationContainer" style={{width:"100%", height:"auto", marginTop: `${this.props.marginTop}px`}}>
                <div className="legendContainer pie-chart-legends">
                    {this.props.data
                        ? <div className="sunbustLegend">
                        {
                            this.props.data.map((entry, index) => {
                            return (<span className="legendItem" style={{ display: "inline-block", marginRight: "10px" }} key={`item-${index}`}
                            > <label style={{ backgroundColor: `${THEME_COLORS[index == 2 ? index + 2 : index + 1]}`, width: "15px", height: "12px", margin: "3px 3px 0px 3px", fontSize: "15px", paddingLeft: "0px", color: "#595959", fontFamily: "roboto, sans-serif", fontWeight: "500", display:"inline-block" }} ></label>
                            <label style={{ margin: "0px", fontSize: "12", fontWeight: "400",fontFamily: "roboto" }}>{entry.name}</label></span>);
                            })
                        }
                        </div>
                        : <div className="dataNotFound">
                        No Legends Found
                    </div>
                    }
                </div>
        
                {this.props.data ? 
                <div className="pie-chart-redemption">
                    <PieChart width={this.props.width} height={this.props.height*1.1}>
                        <Pie 
                        dataKey="value" 
                        cursor={false}
                        tick={false}
                        isAnimationActive={false} 
                        data={this.props.data}
                        cx={this.props.width/2.2}
                        cy={this.props.height/2.2} 
                        outerRadius={circleRadius} 
                        fill="#fff" 
                        label={this.renderCustomizedLabel}
                        >
                        {this.props.data.map((entry, index) => <Cell 
                            key={`cell-${index}`} 
                            cursor={false}
                            fill={entry.value != 0 ? COLORS[index % COLORS.length]: null} 
                            />)
                        }
                        </Pie> />

                        <Tooltip wrapperStyle={{ margin: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            whiteSpace: "nowrap",
                            fontStyle: "roboto",
                            fontSize: "14px",
                            fontWeight: "500px"
                            }}
                            // cursor={false}
                            formatter = { (value, name, props) =>  [ value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + ' (' + props.payload.percent + '%)', name ]  }
                        />
                        {/* <Legend
                            verticalAlign="top"
                            height={36}
                            layout="horizontal"
                            align={this.props.legendAlign ? this.props.legendAlign : "right"}
                            margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                            wrapperStyle={{ marginTop: this.props.isFromImage ? "-20px" : null }} 
                        /> */}
                </PieChart>
                </div>
                : null }
            </div>
        );
    }
}

export default Example;