/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const TAB_PREFIX = "tabsWithContent-";

class TabsWithContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: TAB_PREFIX+0,
    };
  }

  handleChangeSelectedTab = (name) => {
    this.setState({
      ...this.state,
      selectedTab: name,
    });
  };

  handleTabs = (tabId) => {
    this.setState({
      ...this.state,
      selectedTab: tabId,
    });
  };

  render() {
    return (
      <div className="tabContainer">
        <div className="tablinkouter">
          <ul className="nav nav-tabs" role="tablist">
            {this.props.tabsList.map((name, index) => (
              <li className="nav-item">
                <a
                  className={`nav-link ${this.state.selectedTab === TAB_PREFIX + index ? "active" : ""}`}
                  role="tab"
                  data-toggle="tab"
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleChangeSelectedTab(`${TAB_PREFIX + index}`);
                  }}
                >
                  {name}
                </a>
              </li>
            ))}
          </ul>
        </div>

        <div className="tab-content">
          {this.props.htmlContents.map((data, index) => (
            <div
              role="tabpanel"
              className={`tab-pane ${
                this.state.selectedTab === TAB_PREFIX + index ? "active" : ""
              }`}
              id={TAB_PREFIX + index}
            >
              {data}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default TabsWithContent;
