import React, { Component } from "react";
import { connect } from "react-redux";
import logo from "../../static/images/rewardzlogo.svg";
import { withRouter } from "react-router-dom";
import * as LoginAction from "./Actions/LoginActions";
import { showEventModalAction, hideEventModalAction } from "../../Common/CommonStore/CommonActions";
import crypto from "crypto";
import { Textbox } from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import config from "config";

class Login extends Component {
  constructor( props ) {
    super( props );
    this.state = { 
      userName: null,
      password: null,
      validate: false,
      showLoader: false
    };
    this.handleLoginSubmit = this.handleLoginSubmit.bind( this );
  }

  toggleValidating( validate ) {
    this.setState( { validate } );
  }

  componentWillReceiveProps = ( nextProps ) => {
    if( nextProps.loginError && nextProps.loginMessage && !this.props.showEventModalFlag ) {
   
      this.setState( {
        ...this.state,
        showLoader: false
      }, () => {
        this.props.showEventModal( {
          showEventModal: true,
          eventModalTitle: "Error",
          eventModalBody: nextProps.loginMessage,
          showEventModalButtonOne: true,
          showEventModalButtonTwo: false,
          buttonOneEventModalHandler: () => { this.props.hideEventModal(); this.props.loginErrorClear(); },
          buttonTwoEventModalHandler: null,
          buttonOneEventModalName: "OK",
          buttonTwoEventModalName: "",
          graphContent: null,
          graphTitle: "",
          showGraph: false,
          graphName: "",
          graphId: ""
        } ); 
      } )
    }
  }

  handleInputChange = ( e ) => {
    e.preventDefault();
    this.setState( {
      [e.target.name]: e.target.value
    } );
  }

  handleLoginSubmit = async( e ) => {
    e.preventDefault();
    this.toggleValidating( true );
    const { userName, password } = this.state;
    if( userName && password ) {
      let cipher = crypto.createCipher( "aes-128-cbc", config.SECRET_KEY );
      let myStr = cipher.update( this.state.password, "utf8", "hex" ) + cipher.final( "hex" );
       
      let apiPayload = {
        username: this.state.userName,
        password: myStr
      };
      this.setState( { 
        ...this.state,
        showLoader: true
       }, () => {
        this.props.loginRequest( apiPayload );
       } )  
    } 
  }

  render() { 
    return ( 
      <div className="login-wraper">
        <div className="contaienr-fluid">
          <div className="login-container d-flex">
            <div className="login-icon">
              <img src={ logo } title="logo"/>
            </div>
            <div className="loginform d-flex">
              <h4>Login</h4>
              <form onSubmit={ this.handleLoginSubmit }>
                <div className="form-group">                                    
                  <Textbox 
                    type="text"
                    name="userName"
                    classNameInput="form-control" 
                    id="exampleInputEmail1" 
                    validate={false}
                    placeholder="Username" 
                    onChange={ ( val, e ) => this.handleInputChange( e ) } 
                    onBlur={ ( e ) => e }
                    validationOption = { {
                      name: "userName",
                      check: true,
                      required: true,
                      msgOnError: "* Required",
                      showMsg: true
                    } }
                  />
                </div>
                <div className="form-group">                                   
                  <Textbox 
                    type="password" 
                    name="password" 
                    classNameInput="form-control" 
                    id="exampleInputPassword1" 
                    placeholder="Password" 
                    onChange={ ( val, e ) => this.handleInputChange( e ) }
                    onBlur={ ( e ) => e }
                    validate={false}
                    validationOption = { {
                      name: "userName",
                      check: true,
                      required: true,
                      msgOnError: "* Required",
                      showMsg: true
                    } }
                  />
                </div>      
                <div className={`logInbtn ${ this.state.showLoader ? "btn-loader": "" }`}>                         
                  <button type="submit" className="btn btn-primary" onClick={this.handleLoginSubmit} >Login</button>
                </div>  
              </form>
            </div>
          </div>
        </div>
      </div>

    );
  }
}
const mapStateToProps = ( state ) => {
  return {
    loginError: state.Login.loginError,
    loginMessage: state.Login.loginMessage,
    showEventModalFlag: state.Common.showEventModal
  };
};

const mapDispatchToProps = ( dispatch ) => {
  return {
    loginRequest: ( payload ) => dispatch( LoginAction.loginRequestAction( payload ) ),
    showEventModal: ( payload ) => dispatch( showEventModalAction( payload ) ),
    hideEventModal: ( ) => dispatch( hideEventModalAction() ),
    loginErrorClear: () => dispatch( LoginAction.loginErrorClearAction() )

  };
};

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( Login ) );