import React from "react";
import Expnadicon from "../../static/images/expandIcon.png";
import Csvicon from "../../static/images/exportcsv.png";
import info from "../../static/images/info.png";
import Exportimage from "../../static/images/exportimage.png";
import { handleDownload } from "../../Utilities/exportMethods";
import axios from "axios";
import config from "config";
import { GRAPH_IDS } from "../../constants/constants";
 
class Garaphactions extends React.Component {
  constructor( props ) {
    super( props );
    this.state = {
      showInfoMessage: false
    };
  }

  toggleMessage = ( e ) => {
    e.preventDefault();
    this.setState( {
      ...this.state,
      showInfoMessage: !this.state.showInfoMessage
    } );
  } 
  closeShowInfo = ( e ) => {
    e.preventDefault();
    this.setState( {
      ...this.state,
      showInfoMessage: false
    } );
  }

  getHistoGramData = ( e ) => {
   
    let data = { ...this.props.filterObjectForCSV(), parameter:this.props.parameter, ...this.props.postPayload, is_export : true }
    let csvFileName;

    if( this.props.from === 'appreciationflow' ) {
      
      data.export_parameter = this.props.export_parameter;

      csvFileName = 'appreciation_' + this.props.export_parameter + '.csv';

    } else if( this.props.from === 'appreciationExchange' ) {

       if( this.props.parameter === 'department' ) {
         data.department_id = this.props.specific
       } else  if( this.props.parameter === 'organization' ) {
        data.organization_id = this.props.specific
       }

       csvFileName = 'appreciation_' + this.props.parameter  + '.csv';

    } else if( this.props.from  === 'sunburst' ) {

        data.strength_name = this.props.payLoadData.strengthName;
        data.department_id = this.props.payLoadData.departmentId; 

        csvFileName = 'appreciation_' + data.strength_name + '_' + data.department_id  + '.csv';

    } else if( this.props.from === "heatMapExchange" || this.props.from === "heatMapExchangeMessage" ) {
        // data = {};

       if( this.props.export_parameter == 'department') { 
          data.from_dept_id  = this.props.payLoadData.from_dept_id;
          data.to_dept_id    = this.props.payLoadData.to_dept_id;

       } else {
          data.from_org_id  = this.props.payLoadData.from_org_id;
          data.to_org_id    = this.props.payLoadData.to_org_id;
       } 

        data.parameter = this.props.parameter
     
        if( this.props.from === "heatMapExchange" ) {
          data.date = this.props.selectedDate;
        } else {
          data.date = this.props.payLoadData.date;
        }  
        data.export_parameter = this.props.export_parameter;
        data.is_export = true;

        csvFileName = 'heatMapExchange_' + data.parameter + '_' + data.export_parameter + '.csv';
        
    }  else if( this.props.from === "spider" ) {


        // data = {}
    
        data.date = this.props.selectedDate;
        data.userId = this.props.individualUserId;
        data.is_export = true;

        csvFileName = 'spider_' + data.userId + '.csv';

    } else if ( this.props.from === "feed" ) {
      
        // data = {}
        data.date = this.props.selectedDate;
        data.userId = this.props.individualUserId;
        data.is_export = true;
        data.parameter = this.props.parameter;

        csvFileName = 'feed_' + data.userId + '_' + data.parameter + '.csv';

    } else if( this.props.from === "treemap" )  { 
        data.strengths = this.props.selectedStregth.map( strength => strength.name  );

        csvFileName = 'treemap' + '.csv';
    } else if( this.props.from === 'centerofInfluence' ) {
       
        if( this.props.graphId == GRAPH_IDS.SUNBURST_CHART ) {
 
          csvFileName = 'centerofInfluence_' + this.props.graphId + '.csv';
        } else{
          csvFileName = 'centerofInfluence_' + this.props.parameter + '.csv';
        }
    } else if( this.props.from === 'budgetUtilizationSnapshot' ) {
      csvFileName = 'budgetUtilizationSnapshot' + this.props.graphId + '.csv';
    } else if( this.props.from === 'budgetUtilizationTrend' ) {
      csvFileName = 'budgetUtilizationTrend' + this.props.graphId + '.csv';
    } else if (this.props.from === 'merchantPointBasedRedemptions'){
      csvFileName = 'merchantPointBasedRedemptions' + this.props.graphId + '.csv';
    } else {
      csvFileName = `${this.props.from}.csv`;
    }

    let apiUrl = this.props.apiUrl;
    this.callApi( e, apiUrl, data, csvFileName );
  }

  callApi = ( e, apiUrl, data, csvFileName ) => {

    e.preventDefault( );

    const headers = {
      "x-access-token" : sessionStorage.getItem( "Auth-Token" ),
    }

    let baseURL = config.API_URL + apiUrl;

    axios.post( baseURL, data, { headers } )
         .catch( error => {
             console.log( error );
          } )
         .then( res => {
           if(Array.isArray(res.data)) {
            const fileListLength = res.data.length;

            let loopIndex = 0;
            const fileListLoop = () => {
              setTimeout(() => {
                const name = res.data[loopIndex].name;
                const fileName = csvFileName.split(".");
                const newFileName = `${fileName[0]}[${name}].${fileName[1]}`;
                const downloadUrl = window.URL.createObjectURL(
                  new Blob([res.data[loopIndex].csv])
                );
                const link = document.createElement("a");
                link.href = downloadUrl;
                link.setAttribute("download", newFileName);
                link.click();
                link.remove();
                loopIndex++;
                if (loopIndex < fileListLength) {
                  fileListLoop();
                }
              }, 500);
            };

            fileListLoop();
            // THIS FUNCTION IS CREATED DUE TO SAFARI BROWSER ISSUE
            // SAFARI BROWSER IS NOT ALLOWED TO CLICK ANY THING WITHIN FEW SEC
            // IN SAFAR i think after 300ms its work
            //--------------------------------READ ABOVE NOTE ---------------

            // for (let i = 0; i < res.data.length; i++) {
            //   const name = res.data[i].name;
            //   const fileName = csvFileName.split(".");
            //   const newFileName = `${fileName[0]}[${name}].${fileName[1]}`;
            //   const downloadUrl = window.URL.createObjectURL(
            //     new Blob([res.data[i].csv])
            //   );
            //   const link = document.createElement("a");
            //   link.href = downloadUrl;
            //   link.setAttribute("download", newFileName);
            //   const id = `${i}-${newFileName}`;
            //   link.setAttribute("id", id);
            //   document.body.appendChild(link);
            //   document.getElementById(id).click();
            //   // link.click();
            //   link.remove();
            // }
           } else {
              const downloadUrl = window.URL.createObjectURL( new Blob([res.data] ) );
              const link = document.createElement( 'a' );
              link.href = downloadUrl;
              link.setAttribute( 'download', csvFileName );
              document.body.appendChild( link );
              link.click();
              link.remove();
           }
    } );
  }

  render() {
    
    return (
      <div className="actionbtnContainer ">     
        { this.props.showImageExport ? <button className="btn" onClick={ ( e ) => { e.preventDefault(); handleDownload( e, this.props.graphId, this.props.imageName, this.props.ExternalStyleSheet ); } } > 
          <img src={ Exportimage } title="Image"/>
        </button> : null }
        { this.props.showCSVExport ? <button className="btn" onClick={  ( e ) => { this.getHistoGramData( e ); }}> 
          <img src={ Csvicon } title="CSV"/>
        </button> : null}       
        { this.props.showInfo ? <button className={ this.state.showInfoMessage ? "btn showInfo showmessage" : "btn showInfo " } 
          onClick={this.toggleMessage}
        > 
          <img src={ info} title="Information" />
          <div className="infoMessage">
            <button className="btn closeBtn"> X</button>
            <p>{this.props.infoMessage}</p>
          </div>
        </button> : null }
        { this.props.showExpansion ? <button className="btn" onClick={ () => this.props.handleExpand( this.props.graphId ) } > 
          <img src={ Expnadicon } title="Expand"/>
        </button> : null }   
      </div>
    );
  }
};

export default Garaphactions;