import Api from "../Utilities/Api/ApiMethods";

// const getCompaniesDropdown = async payload => {
//   try {
//     const response = await Api.get("/auth/user/companies");
//     return { response: { ...response.data } };
//   } catch (error) {
//     return {
//       error: {
//         ...error
//       }
//     };
//   }
// };

// const getDepartmentDropdown = async payload => {
//   try {
//     let queryUrl = payload ? `/auth/user/departments?org_id=${JSON.stringify([...payload.org_id])}` : "/auth/user/departments";
//     const response = await Api.get( queryUrl );
//     return { response: { ...response.data } };
//   } catch ( error ) {
//     return {
//       error: {
//         ...error
//       }
//     };
//   }
// };

// const getEmployeesDropdown = async payload => {
//   try {
//     let queryString = `/auth/user/employees?search_term=${payload.search_term ? payload.search_term : ""}&org_id=${payload.org_id ? JSON.stringify(payload.org_id) : ""}&dept_id=${payload.dept_id ? JSON.stringify(payload.dept_id) : ""}`;
//     const response = await Api.get(queryString);
//     return { response: { ...response.data } };
//   } catch (error) {
//     return {
//       error: {
//         ...error
//       }
//     };
//   }
// };

// const getRoleDropdown = async payload => {
//   try {
//     const response = await Api.get("/auth/user/roles");
//     return { response: { ...response.data } };
//   } catch (error) {
//     return {
//       error: {
//         ...error
//       }
//     };
//   }
// };

const getRedCategoryDropdown = async payload => {
  try {
    const response = await Api.get("/auth/user/categories");
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};

const getRedemeptionTilesDetails = async payload => {
  try {
    const response = await Api.post("/auth/redemption/tiles", payload);
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};

const getRedemeptionSnapshotDetails = async payload => {
  try {
    const response = await Api.post("/auth/redemption/snapshot", payload);
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};

const getRedemeptionTrendDetails = async payload => {
  try {
    const response = await Api.post("/auth/redemption/trend", payload);
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};

const getRedemeptionMerchantDetails = async payload => {
  try {
    const response = await Api.post("/auth/redemption/graph/histogram", payload);
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};

const getRedemeptionCategoryDetails = async payload => {
  try {
    const response = await Api.post("/auth/redemption/graph/category", payload);
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};

const getRedemeptionPointsEarnedRedeemedDetails = async payload => {
  try {
    const response = await Api.post("/auth/redemption/rate", payload);
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};

const getRedemeptionPointsEarnedRedeemedByDriversDetails = async payload => {
  try {
    const response = await Api.post("/auth/redemption/driver", payload);
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};

const getRedemeptionTimeOfRedemptionByHourDetails = async payload => {
  try {
    const response = await Api.post("/auth/redemption/heatmap", payload);
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};

const getTimeOfRedemptionDetails = async payload => {
  try {
    const response = await Api.post("/auth/redemption/graph/getTimeOfRedemtion", payload);
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};

const getAgeOfRedemptionBySnapshotDetails = async payload => {
  try {
    const response = await Api.post("/auth/redemption/ageSnapshot", payload);
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};

const getAgeOfRedemptionByTrendDetails = async payload => {
  try {
    const response = await Api.post("/auth/redemption/ageTrend", payload);
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};

const getAgeOfRedemptionByFrequencyDetails = async payload => {
  try {
    const response = await Api.post("/auth/redemption/ageFrequency", payload);
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};

const getRedemptionUserAgeDataDetails = async payload => {
  try {
    const response = await Api.post("/auth/redemption/feed", payload);
    return { response: { ...response.data } };
  } catch (error) {
    return {
      error: {
        ...error
      }
    };
  }
};

export default {
  // getCompaniesDropdown,
  // getDepartmentDropdown,
  // getEmployeesDropdown,
  // getRoleDropdown,
  getRedCategoryDropdown,
  getRedemeptionTilesDetails,
  getRedemeptionSnapshotDetails,
  getRedemeptionTrendDetails,
  getRedemeptionMerchantDetails,
  getRedemeptionCategoryDetails,
  getRedemeptionPointsEarnedRedeemedDetails,
  getRedemeptionPointsEarnedRedeemedByDriversDetails,
  getRedemeptionTimeOfRedemptionByHourDetails,
  getTimeOfRedemptionDetails,
  getAgeOfRedemptionBySnapshotDetails,
  getAgeOfRedemptionByTrendDetails,
  getAgeOfRedemptionByFrequencyDetails,
  getRedemptionUserAgeDataDetails
};