import { stat } from "fs-extra";
import * as EngagementActionType from "../Actions/EngagementActionType";

const initialPointEarningCategoryTrend = {
  data: null,
  loader: false,
  showChart: false,
};
const initialState = {
  isEventsEnabled: false,

  tiles: {
    data: null,
    loader: false,
  },

  //-----------section 2-------------
  numOfUniqueUsers: {
    data: null,
    loader: false,
  },
  avgAppUsersByDayAndTime: {
    data: null,
    loader: false,
  },
  proportionsOfUniqueUser: {
    data: {
      data: [],
      compOrDepartList: [],
      avgData: [],
    },
    loader: false,
  },
  frequencyAppUses: {
    data: null,
    loader: false,
  },
  frequencyFeatureUses: {
    data: null,
    loader: false,
  },

  //-----------section 3-------------
  pointEarningCategoriesData: {
    data: null,
    loader: false,
  },
  pointEarningCategoriesTrendData: {
    ...initialPointEarningCategoryTrend,
  },
  topPointEarningData: {
    data: null,
    loader: false,
  },
  topPointToggleFlag_isP2P: false,
  topPointEarningDetailsData: {
    data: null,
    loader: false,
    show: false,
  },

  //-----------section 4-------------
  eventsResponses: {
    data: null,
    loader: false,
  },
  notifications: {
    data: null,
    loader: false,
  },
};

const EngagementDashboardReducer = (state = initialState, action) => {
  switch (action.type) {

    case EngagementActionType.IS_EVENTS_ENABLED_REQ:
      return {
        ...state,
        isEventsEnabled: false,
      };
    case EngagementActionType.IS_EVENTS_ENABLED_SUCCESS:
      return {
        ...state,
        isEventsEnabled: action.payload,
      };
    case EngagementActionType.IS_EVENTS_ENABLED_FAIL:
      return {
        ...state,
        isEventsEnabled: false,
      };

    case EngagementActionType.TILES_REQ:
      return {
        ...state,
        tiles: {
          ...state.tiles,
          loader: true,
        },
      };
    case EngagementActionType.TILES_SUCCESS:
      return {
        ...state,
        tiles: {
          ...state.tiles,
          data: action.payload,
          loader: false,
        },
      };
    case EngagementActionType.TILES_FAIL:
      return {
        ...state,
        tiles: {
          ...state.tiles,
          loader: false,
        },
      };

    //-----------section 2-------------
    case EngagementActionType.NUMBER_OF_UNIQUE_USERS_REQ:
      return {
        ...state,
        numOfUniqueUsers: {
          ...state.numOfUniqueUsers,
          loader: true,
        },
      };
    case EngagementActionType.NUMBER_OF_UNIQUE_USERS_SUCCESS:
      return {
        ...state,
        numOfUniqueUsers: {
          ...state.numOfUniqueUsers,
          data: action.payload,
          loader: false,
        },
      };
    case EngagementActionType.NUMBER_OF_UNIQUE_USERS_FAIL:
      return {
        ...state,
        numOfUniqueUsers: {
          ...state.numOfUniqueUsers,
          loader: false,
        },
      };

    case EngagementActionType.AVG_APP_USERS_BY_DAY_AND_TIME_REQ:
      return {
        ...state,
        avgAppUsersByDayAndTime: {
          ...state.avgAppUsersByDayAndTime,
          loader: true,
        },
      };
    case EngagementActionType.AVG_APP_USERS_BY_DAY_AND_TIME_SUCCESS:
      return {
        ...state,
        avgAppUsersByDayAndTime: {
          ...state.avgAppUsersByDayAndTime,
          data: action.payload,
          loader: false,
        },
      };
    case EngagementActionType.AVG_APP_USERS_BY_DAY_AND_TIME_FAIL:
      return {
        ...state,
        avgAppUsersByDayAndTime: {
          ...state.avgAppUsersByDayAndTime,
          loader: false,
        },
      };

    case EngagementActionType.PROPORTION_OF_UNIQUE_USER_REQ:
      return {
        ...state,
        proportionsOfUniqueUser: {
          ...state.proportionsOfUniqueUser,
          loader: true,
        },
      };
    case EngagementActionType.PROPORTION_OF_UNIQUE_USER_SUCCESS:
      return {
        ...state,
        proportionsOfUniqueUser: {
          ...state.proportionsOfUniqueUser,
          data: action.payload,
          loader: false,
        },
      };
    case EngagementActionType.PROPORTION_OF_UNIQUE_USER_FAIL:
      return {
        ...state,
        proportionsOfUniqueUser: {
          ...state.proportionsOfUniqueUser,
          loader: false,
        },
      };

    case EngagementActionType.FREQUENCY_OF_APP_USES_REQ:
      return {
        ...state,
        frequencyAppUses: {
          ...state.frequencyAppUses,
          loader: true,
        },
      };
    case EngagementActionType.FREQUENCY_OF_APP_USES_SUCCESS:
      return {
        ...state,
        frequencyAppUses: {
          ...state.frequencyAppUses,
          data: action.payload,
          loader: false,
        },
      };
    case EngagementActionType.FREQUENCY_OF_APP_USES_FAIL:
      return {
        ...state,
        frequencyAppUses: {
          ...state.frequencyAppUses,
          loader: false,
        },
      };

    case EngagementActionType.FREQUENCY_OF_FEATURE_USES_REQ:
      return {
        ...state,
        frequencyFeatureUses: {
          ...state.frequencyFeatureUses,
          loader: true,
        },
      };
    case EngagementActionType.FREQUENCY_OF_FEATURE_USES_SUCCESS:
      return {
        ...state,
        frequencyFeatureUses: {
          ...state.frequencyFeatureUses,
          data: action.payload,
          loader: false,
        },
      };
    case EngagementActionType.FREQUENCY_OF_FEATURE_USES_FAIL:
      return {
        ...state,
        frequencyFeatureUses: {
          ...state.frequencyFeatureUses,
          loader: false,
        },
      };

    //-----------section 3-------------
    case EngagementActionType.GET_POINT_EARNING_CATEGORIES_SUCCESS:
      return {
        ...state,
        pointEarningCategoriesData: {
          data: action.payload,
          loader: false,
        },
      };

    case EngagementActionType.GET_POINT_EARNING_CATEGORIES_REQ:
      return {
        ...state,
        ...state.pointEarningCategoriesData,
        pointEarningCategoriesData: {
          ...state.pointEarningCategoriesData,
          loader: true,
        },
        pointEarningCategoriesTrendData: {
          ...initialPointEarningCategoryTrend,
        },
      };

    case EngagementActionType.GET_POINT_EARNING_CATEGORIES_FAIL:
      return {
        ...state,
        pointEarningCategoriesData: {
          ...state.pointEarningCategoriesData,
          loader: false,
        },
        pointEarningCategoriesTrendData: {
          ...initialPointEarningCategoryTrend,
        },
      };

    case EngagementActionType.GET_POINT_EARNING_CATEGORIES_TREND_SUCCESS:
      return {
        ...state,
        pointEarningCategoriesTrendData: {
          ...state.pointEarningCategoriesTrendData,
          data: action.payload,
          loader: false,
        },
      };

    // PointEaringCategory Trends cases
    case EngagementActionType.GET_POINT_EARNING_CATEGORIES_TREND_REQ:
      return {
        ...state,
        pointEarningCategoriesTrendData: {
          ...state.pointEarningCategoriesTrendData,
          loader: true,
          showChart: true,
        },
      };

    case EngagementActionType.GET_POINT_EARNING_CATEGORIES_TREND_FAIL:
      return {
        ...state,
        pointEarningCategoriesTrendData: {
          ...state.pointEarningCategoriesTrendData,
          loader: false,
        },
      };

    case EngagementActionType.GET_TOP_POINT_EARNER_REQUEST:
      return {
        ...state,
        topPointEarningData: {
          ...state.topPointEarningData,
          loader: true,
        },
        topPointEarningDetailsData: {
          data: null,
          loader: false,
          show: false,
        },
      };

    case EngagementActionType.GET_TOP_POINT_EARNER_SUCCESS:
      return {
        ...state,
        topPointEarningData: {
          data: action.payload,
          loader: false,
        },
        topPointEarningDetailsData: {
          data: null,
          loader: false,
          show: false,
        },
      };

    case EngagementActionType.GET_TOP_POINT_EARNER_FAIL:
      return {
        ...state,
        topPointEarningData: {
          ...state.topPointEarningData,
          loader: false,
        },
        topPointEarningDetailsData: {
          data: null,
          loader: false,
          show: false,
        },
      };

    case EngagementActionType.SET_IS_P2P_FLAG:
      return {
        ...state,
        topPointToggleFlag_isP2P: action.payload,
      };

    case EngagementActionType.GET_TOP_POINT_EARNER_DETAILS_REQUEST:
      return {
        ...state,
        topPointEarningDetailsData: {
          ...state.topPointEarningDetailsData,
          loader: true,
          show: true,
        },
      };

    case EngagementActionType.GET_TOP_POINT_EARNER_DETAILS_SUCCESS:
      return {
        ...state,
        topPointEarningDetailsData: {
          data: action.payload,
          loader: false,
          show: true,
        },
      };

    case EngagementActionType.GET_TOP_POINT_EARNER_DETAILS_FAIL:
      return {
        ...state,
        topPointEarningDetailsData: {
          ...state.topPointEarningDetailsData,
          loader: false,
        },
      };

    //-----------section 4-------------
    case EngagementActionType.EVENTS_RESPONSES_REQ:
      return {
        ...state,
        eventsResponses: {
          ...state.eventsResponses,
          loader: true,
        },
      };
    case EngagementActionType.EVENTS_RESPONSES_SUCCESS:
      return {
        ...state,
        eventsResponses: {
          ...state.eventsResponses,
          data: action.payload,
          loader: false,
        },
      };
    case EngagementActionType.EVENTS_RESPONSES_FAIL:
      return {
        ...state,
        eventsResponses: {
          ...state.eventsResponses,
          loader: false,
        },
      };

    case EngagementActionType.NOTIFICATIONS_REQ:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          loader: true,
        },
      };
    case EngagementActionType.NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          data: action.payload,
          loader: false,
        },
      };
    case EngagementActionType.NOTIFICATIONS_FAIL:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          loader: false,
        },
      };

    default:
      return {
        ...state,
      };
  }
};

export default EngagementDashboardReducer;
