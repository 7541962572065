import * as ActionTypes from "./RedemptionActionTypes";

// export function redCategoryFilterDropdownRequestAction (data) {
//   return {
//     type: ActionTypes.GET_REDCATEGORY_FILTER_DROPDOWN_REQUEST,
//     payload: data
//   };
// };

// export function redCategoryFilterDropdownSuccessAction (data) {
//   return {
//     type: ActionTypes.GET_REDCATEGORY_FILTER_DROPDOWN_SUCCESS,
//     payload: data
//   };
// };

// export function redCategoryFilterDropdownFailAction (data) {
//   return {
//     type: ActionTypes.GET_REDCATEGORY_FILTER_DROPDOWN_FAIL,
//     payload: data
//   };
// };

// export function redModeFilterDropdownRequestAction (data) {
//   return {
//     type: ActionTypes.GET_REDCATEGORY_FILTER_DROPDOWN_REQUEST,
//     payload: data
//   };
// };

// export function redModeFilterDropdownSuccessAction (data) {
//   return {
//     type: ActionTypes.GET_REDCATEGORY_FILTER_DROPDOWN_SUCCESS,
//     payload: data
//   };
// };

// export function redModeFilterDropdownFailAction (data) {
//   return {
//     type: ActionTypes.GET_REDCATEGORY_FILTER_DROPDOWN_FAIL,
//     payload: data
//   };
// };


export function redemptionTilesRequestAction(data) {
  return {
    type: ActionTypes.GET_TILES_REQUEST,
    payload: data
  };
};

export function redemptionTilesSuccessAction(data) {
  return {
    type: ActionTypes.GET_TILES_SUCCESS,
    payload: data
  };
};

export function redemptionTilesFailAction(data) {
  return {
    type: ActionTypes.GET_TILES_FAIL,
    payload: data
  };
};

export function redemptionBudgetSnapshotRequestAction(data) {
  return {
    type: ActionTypes.GET_BUDGET_SNAPSHOT_REQUEST,
    payload: data
  };
};

export function redemptionBudgetSnapshotSuccessAction(data) {
  return {
    type: ActionTypes.GET_BUDGET_SNAPSHOT_SUCCESS,
    payload: data
  };
};

export function redemptionBudgetSnapshotFailAction(data) {
  return {
    type: ActionTypes.GET_BUDGET_SNAPSHOT_FAIL,
    payload: data
  };
};

export function redemptionBudgetTrendRequestAction(data) {
  return {
    type: ActionTypes.GET_BUDGET_TREND_REQUEST,
    payload: data
  };
};

export function redemptionBudgetTrendSuccessAction(data) {
  return {
    type: ActionTypes.GET_BUDGET_TREND_SUCCESS,
    payload: data
  };
};

export function redemptionBudgetTrendFailAction(data) {
  return {
    type: ActionTypes.GET_BUDGET_TREND_FAIL,
    payload: data
  };
};

export function redemptionMerchantRequestAction(data) {
  let updatePayLaod = { ...data, from: 0, offset: 10 };
  return {
    type: ActionTypes.GET_MERCHANT_DATA_REQUEST,
    payload: updatePayLaod
  };
}

export function redemptionMerchantSuccessAction(data) {
  return {
    type: ActionTypes.GET_MERCHANT_DATA_SUCCESS,
    payload: data
  };
}

export function redemptionMerchantFailAction() {
  return {
    type: ActionTypes.GET_MERCHANT_DATA_FAIL,
    payload: data
  };
}

export function redemptionCategoryRequestAction(data) {
  let updatePayLaod = { ...data, from: 0, offset: 10 };
  return {
    type: ActionTypes.GET_CATEGORY_DATA_REQUEST,
    payload: updatePayLaod
  };
}

export function redemptionCategorySuccessAction(data) {
  return {
    type: ActionTypes.GET_CATEGORY_DATA_SUCCESS,
    payload: data
  };
}

export function redemptionCategoryFailAction() {
  return {
    type: ActionTypes.GET_CATEGORY_DATA_FAIL,
    payload: data
  };
}

export function redemptionPointsEarnedRedeemedRequestAction(data) {
  return {
    type: ActionTypes.GET_POINTS_EARNED_REDEEMED_REQUEST,
    payload: data
  };
};

export function redemptionPointsEarnedRedeemedSuccessAction(data) {
  return {
    type: ActionTypes.GET_POINTS_EARNED_REDEEMED_SUCCESS,
    payload: data
  };
};

export function redemptionPointsEarnedRedeemedFailAction(data) {
  return {
    type: ActionTypes.GET_POINTS_EARNED_REDEEMED_FAIL,
    payload: data
  };
};


export function timeRedemptionRequestAction(data) {
  return {
    type: ActionTypes.GET_TIME_REDEEMED_REQUEST,
    payload: data
  };
};

export function timeRedemptionSuccessAction(data) {
  return {
    type: ActionTypes.GET_TIME_REDEEMED_SUCCESS,
    payload: data
  };
};

export function timeRedemptionFailAction(data) {
  return {
    type: ActionTypes.GET_TIME_REDEEMED_FAIL,
    payload: data
  };
};

export function redemptionPointsEarnedRedeemedByDriversRequestAction (data) {
  return {
    type: ActionTypes.GET_POINTS_EARNED_REDEEMED_BY_DRIVERS_REQUEST,
    payload: data
  };
};

export function redemptionPointsEarnedRedeemedByDriversSuccessAction (data) {
  return {
    type: ActionTypes.GET_POINTS_EARNED_REDEEMED_BY_DRIVERS_SUCCESS,
    payload: data
  };
};

export function redemptionPointsEarnedRedeemedByDriversFailAction (data) {
  return {
    type: ActionTypes.GET_POINTS_EARNED_REDEEMED_BY_DRIVERS_FAIL,
    payload: data
  };
};

export function redemptionTimeOfRedemptionByHourRequestAction (data) {
  return {
    type: ActionTypes.GET_TIME_OF_REDEMPTION_BY_HOUR_REQUEST,
    payload: data
  };
};

export function redemptionTimeOfRedemptionByHourSuccessAction (data) {
  return {
    type: ActionTypes.GET_TIME_OF_REDEMPTION_BY_HOUR_SUCCESS,
    payload: data
  };
};

export function redemptionTimeOfRedemptionByHourFailAction (data) {
  return {
    type: ActionTypes.GET_TIME_OF_REDEMPTION_BY_HOUR_FAIL,
    payload: data
  };
};

export function ageOfRedemptionSnapshotRequestAction (data) {
  return {
    type: ActionTypes.GET_AGE_OF_REDEMPTION_BY_SNAPSHOT_REQUEST,
    payload: data
  };
};

export function ageOfRedemptionSnapshotSuccessAction (data) {
  return {
    type: ActionTypes.GET_AGE_OF_REDEMPTION_BY_SNAPSHOT_SUCCESS,
    payload: data
  };
};

export function ageOfRedemptionSnapshotFailAction (data) {
  return {
    type: ActionTypes.GET_AGE_OF_REDEMPTION_BY_SNAPSHOT_FAIL,
    payload: data
  };
};

export function ageOfRedemptionTrendRequestAction (data) {
  return {
    type: ActionTypes.GET_AGE_OF_REDEMPTION_BY_TREND_REQUEST,
    payload: data
  };
};

export function ageOfRedemptionTrendSuccessAction (data) {
  return {
    type: ActionTypes.GET_AGE_OF_REDEMPTION_BY_TREND_SUCCESS,
    payload: data
  };
};

export function ageOfRedemptionTrendFailAction (data) {
  return {
    type: ActionTypes.GET_AGE_OF_REDEMPTION_BY_TREND_FAIL,
    payload: data
  };
};

export function ageOfRedemptionFrequencyRequestAction (data) {
  return {
    type: ActionTypes.GET_AGE_OF_REDEMPTION_BY_FREQUENCY_REQUEST,
    payload: data
  };
};

export function ageOfRedemptionFrequencySuccessAction (data) {
  return {
    type: ActionTypes.GET_AGE_OF_REDEMPTION_BY_FREQUENCY_SUCCESS,
    payload: data
  };
};

export function ageOfRedemptionFrequencyFailAction (data) {
  return {
    type: ActionTypes.GET_AGE_OF_REDEMPTION_BY_FREQUENCY_FAIL,
    payload: data
  };
};

export function redemptionUserAgeDataRequestAction (data) {
  return {
    type: ActionTypes.GET_REDEMPTION_USER_AGE_DATA_REQUEST,
    payload: data
  };
};

export function redemptionUserAgeDataSuccessAction (data) {
  return {
    type: ActionTypes.GET_REDEMPTION_USER_AGE_DATA_SUCCESS,
    payload: data
  };
};

export function redemptionUserAgeDataFailAction (data) {
  return {
    type: ActionTypes.GET_REDEMPTION_USER_AGE_DATA_FAIL,
    payload: data
  };
};