import React from "react";
import { connect } from "react-redux";
import { Calendar } from "react-date-range";
import moment from "moment";
import { MultiSelect } from "../";
import * as AppreciationActions from "../../Modules/Dashboard/Components/Appreciation/Actions/AppreciationActions";
import checkInvalidSelectedValue from "../../Utilities/checkInvalidSelectedValue";
import { EMPLOYEE_SEARCH_NAME } from "../../constants/constants";

// Dashboard Search Container
class FilterSearch extends React.Component {
  render() {
    return (
      <div className={`searchfilterform ${this.props.showFilterComponent ? "searchfilteropen" : ""}`} >
         
        <form className="filterForm">                            
          <div className="form-group col-12 col-md-12 col-lg-6">
            <div className={ this.props.showStartDatePicker ? "dateRange show" : "dateRange hide"} >
              <Calendar
                // onInit={this.handleSelect}
                onChange={this.props.handleStartDateSelect}
                calendars={1}
                date={ moment( this.props.timeFrame.startDate )}
                maxDate= {moment( this.props.timeFrame.endDate )}

              />
            </div>
            <div className={ this.props.showEndDatePicker ? "dateRange show" : "dateRange hide"} >
              { this.props.filters.endDate ? <Calendar
                // onInit={this.handleSelect}
                onChange={this.props.handleEndDateSelect}
                calendars={1}
                minDate={moment( this.props.timeFrame.startDate )}
                maxDate= {moment( this.props.filters.endDate )}
                date={ moment( this.props.timeFrame.endDate )}

              />: null}
            </div>
            <label>Period</label>
            <div className="dateInput d-flex">
              <label>From</label>
              <input className="form-control" type="text" onClick={this.props.toggleStartDatepicker} onChange={( ) => "" } value={this.props.timeFrame.startDate ? moment( this.props.timeFrame.startDate ).format( "DD MMM YYYY" ) : "" } ></input>
              <label className={ this.props.filters.endDate !== null ? "" : "disable" }>To</label>
              <input className={ this.props.filters.endDate !== null ? "form-control" : "form-control disable" } type="text" onClick={this.props.toggleEndDatepicker} onChange={( ) => "" } value={this.props.timeFrame.endDate ? moment( this.props.timeFrame.endDate ).format( "DD MMM YYYY" ) : ""} ></input>
            </div> 
          </div>
          <div className="form-group col-12 col-md-12 col-lg-3" >
            <label>Company</label>
            <MultiSelect           
              handleMultiSelect = { this.props.handleMultiSelect }
              name = "companyMultiSelect"
              data = { this.props.companyFilter } 
              handleDeleteOptions = { this.props.handleDeleteOptions }
              selectedValue = { this.props.dropDowns.companyMultiSelect }
              handleShowMore = { this.props.handleShowMoreMultiSelect }
              clearAllSelected = { this.props.clearAllSelected }
              showMore = { this.props.showMore.companyMultiSelect }
              showOptions = { this.props.dropDowns.companyMultiSelect.length > 0 && this.props.showOptions.companyMultiSelect }
              // inValidSelection = { checkInvalidSelectedValue( this.props.dropDowns.departmentMultiSelect, this.props.departmentFilter )}

            />           
          </div>
          <div className="form-group col-12 col-md-12 col-lg-3">
            <label>Department</label>
            <MultiSelect           
              handleMultiSelect = { this.props.handleMultiSelect }
              name = "departmentMultiSelect"
              data = { this.props.departmentFilter } 
              handleDeleteOptions = { this.props.handleDeleteOptions }
              selectedValue = { this.props.dropDowns.departmentMultiSelect }
              handleShowMore = { this.props.handleShowMoreMultiSelect }
              clearAllSelected = { this.props.clearAllSelected }
              showMore = { this.props.showMore.departmentMultiSelect }
              showOptions = { this.props.dropDowns.departmentMultiSelect.length > 0 && this.props.showOptions.departmentMultiSelect }
              inValidSelection = { checkInvalidSelectedValue( this.props.dropDowns.departmentMultiSelect, this.props.departmentFilter )}
            />           
          </div>
          {/* <div className="form-group col-12 col-md-12 col-lg-3">
            <label>Age Group</label>
            <MultiSelect           
              handleMultiSelect = { this.props.handleMultiSelect }
              name = "ageMultiSelect"
              data = { this.props.ageGroupFilter } 
              handleDeleteOptions = { this.props.handleDeleteOptions }
              selectedValue = { this.props.dropDowns.ageMultiSelect }
              handleShowMore = { this.props.handleShowMoreMultiSelect }
              showMore = { this.props.showMore.ageMultiSelect }
              showOptions = { this.props.dropDowns.ageMultiSelect.length > 0 && this.props.showOptions.ageMultiSelect }
            />           
          </div> */}
          <div className="form-group col-12 col-md-12 col-lg-3">
            <label>Role</label>
            <MultiSelect           
              handleMultiSelect = { this.props.handleMultiSelect }
              name = "roleMultiSelect"
              data = { this.props.rolesFilter } 
              handleDeleteOptions = { this.props.handleDeleteOptions }
              selectedValue = { this.props.dropDowns.roleMultiSelect }
              clearAllSelected = { this.props.clearAllSelected }
              handleShowMore = { this.props.handleShowMoreMultiSelect }
              showMore = { this.props.showMore.roleMultiSelect }
              showOptions = { this.props.dropDowns.roleMultiSelect.length > 0 && this.props.showOptions.roleMultiSelect }
            />           
          </div>
          <div className="form-group col-12 col-md-12 col-lg-3">
            <label>Employee Name</label>
            <MultiSelect           
              handleMultiSelect = { this.props.handleMultiSelect }
              name = { EMPLOYEE_SEARCH_NAME.EMPLOYEES }
              isSearchable={true}
              handleChange = { this.props.handleSearchableChange }
              handleDeleteOptions = { this.props.handleDeleteOptions }
              selectedValue = { this.props.dropDowns.employeeMultiSelect }
              handleShowMore = { this.props.handleShowMoreMultiSelect }
              clearAllSelected = { this.props.clearAllSelected }
              showMore = { this.props.showMore.employeeMultiSelect }
              showOptions = { this.props.dropDowns.employeeMultiSelect.length > 0 && this.props.showOptions.employeeMultiSelect }
              inValidSelection = { checkInvalidSelectedValue( this.props.dropDowns.employeeMultiSelect, this.props.employeesFilter )}
              placeholder = { "Enter at least 2 letters" }
            />           
          </div>

           <div className="form-group col-12 col-md-12 col-lg-3">
            <label>Employee ID</label>
            <MultiSelect
              handleMultiSelect = { this.props.handleMultiSelect }
              name = { EMPLOYEE_SEARCH_NAME.EMPLOYEE_IDS }
              isSearchable={true}
              handleChange = { this.props.handleSearchableChange }
              handleDeleteOptions = { this.props.handleDeleteOptions }
              selectedValue = { this.props.dropDowns.employeeIdsMultiSelect }
              handleShowMore = { this.props.handleShowMoreMultiSelect }
              clearAllSelected = { this.props.clearAllSelected }
              showMore = { this.props.showMore.employeeIdsMultiSelect }
              showOptions = { this.props.dropDowns.employeeIdsMultiSelect.length > 0 && this.props.showOptions.employeeIdsMultiSelect }
              inValidSelection = { checkInvalidSelectedValue( this.props.dropDowns.employeeIdsMultiSelect, this.props.employeesFilter )}
              placeholder = { "Enter at least 2 characters" }
            />
          </div>

          {/* {this.props.redemptionFilter ? 
            <div className="form-group col-12 col-md-12 col-lg-3">
              <label>Redemption Categories</label>
              <MultiSelect           
                handleMultiSelect = { this.props.handleMultiSelect }
                name = "redemptionCategoryMultiSelect"
                data = { this.props.redemptionCategoryFilter } 
                handleDeleteOptions = { this.props.handleDeleteOptions }
                selectedValue = { this.props.dropDowns.redemptionCategoryMultiSelect }
                handleShowMore = { this.props.handleShowMoreMultiSelect }
                clearAllSelected = { this.props.clearAllSelected }
                showMore = { this.props.showMore.redemptionCategoryMultiSelect }
                showOptions = { this.props.dropDowns.redemptionCategoryMultiSelect.length > 0 && this.props.showOptions.redemptionCategoryMultiSelect }
                inValidSelection = { checkInvalidSelectedValue( this.props.dropDowns.redemptionCategoryMultiSelect, this.props.redemptionCategoryFilter )}
              />           
            </div>
          : null}
          {this.props.redemptionFilter ? 
            <div className="form-group col-12 col-md-12 col-lg-3">
              <label>Redemption Mode</label>
              <MultiSelect           
                handleMultiSelect = { this.props.handleMultiSelect }
                name = "redemptionModeMultiSelect"
                data = { this.props.redemptionModeFilter } 
                handleDeleteOptions = { this.props.handleDeleteOptions }
                selectedValue = { this.props.dropDowns.redemptionModeMultiSelect }
                handleShowMore = { this.props.handleShowMoreMultiSelect }
                clearAllSelected = { this.props.clearAllSelected }
                showMore = { this.props.showMore.redemptionModeMultiSelect }
                showOptions = { this.props.dropDowns.redemptionModeMultiSelect.length > 0 && this.props.showOptions.redemptionModeMultiSelect }
                inValidSelection = { checkInvalidSelectedValue( this.props.dropDowns.redemptionModeMultiSelect, this.props.redemptionModeFilter )}
              />           
            </div>
          : null} */}
          <div className="form-group col-12 col-md-12 col-lg-2">
            <button type="submit" className="btn btn-secondary" onClick={ this.props.handleFilterClick } > Filter</button>
          </div>
        </form>

        { this.props.filterOverlay ? <div className={`filterOverlay ${!this.props.filterOverlay ? "hide" : ""}`} onClick={() => this.props.hideFilterOverlay()} ></div> : null }

      </div>

    );
  }
}

const mapStateToProps = state => {
  return {
    filters: state.AppreciationDashboard.filters,
    showFilterComponent: state.Common.showFilterComponent
  }
}

const mapDispatchToProps = dispatch => {
  return {
    departmentFilterDropdownRequest: ( data ) => dispatch( AppreciationActions.departmentFilterDropdownRequestAction( data ) ),
    
  };
};

export default connect( mapStateToProps, mapDispatchToProps )( FilterSearch );