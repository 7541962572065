import React from "react";

// Breadcrum component
class Breadcrum extends React.Component {
  render() {
    return (            
      <div class="breadcrumModule">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Bank Of Singapore</a></li>
            <li class="breadcrumb-item"><a href="#">Bank Of Singapore</a></li>
            <li class="breadcrumb-item active" aria-current="page"><a href="#">Delivery</a></li>
          </ol>
        </nav>
      </div>
    );
  }
}

export default Breadcrum;