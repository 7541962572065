export const SHOW_EVENT_MODAL = "SHOW_EVENT_MODAL";
export const HIDE_EVENT_MODAL = "HIDE_EVENT_MODAL"; 

export const SHOW_DASHBOARD_OVERLAY = "SHOW_DASHBOARD_OVERLAY";
export const HIDE_DASHBOARD_OVERLAY = "HIDE_DASHBOARD_OVERLAY";

export const EXPAND_SIDE_PANEL = "EXPAND_SIDE_PANEL";
export const SHRINK_SIDE_PANEL = "SHRINK_SIDE_PANEL";

export const SET_SCREEN_HEIGHT_WIDTH = "SET_SCREEN_HEIGHT_WIDTH";

export const START_PDF_EXPORT = "START_PDF_EXPORT";
export const END_PDF_EXPORT = "END_PDF_EXPORT";

export const SHOW_FILTER_COMPONENT = "SHOW_FILTER_COMPONENT";
export const HIDE_FILTER_COMPONENT = "HIDE_FILTER_COMPONENT";