import React from "react";

const PiechartLoader = props => {
    return(
        <div className="pichartLoaderOuter animated-background lg-width" style={{width:props.width, height:props.height}}>
            <div className="pichartInner animated-background lg-width">
                
            </div>
        </div>
    );
}

export default PiechartLoader;