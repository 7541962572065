import React from "react";
import spiderloderImg from "../../static/images/spiderloderImg.png";
import loaderbarchart from "../../static/images/loaderbarchart.png";
import TabSection from "../TabComponent/TabComponent";

class SpiderLoader extends React.Component {
  render() {
    return (
      <div className="spiderloader">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-6">
            <div className="spidercontainer">
              <img className="img-fluid" src={ spiderloderImg } title="Exportimage"/>
            </div>     
          </div>
          <div className="col-12 col-md-12  col-lg-6">
            <div className="col-12 nopad">
              <div className="barchartContainer">
                <img className="img-fluid" src={ loaderbarchart } title="Exportimage"/>    
              </div>
            </div>
            <div className="col-12 nopad">
                <TabSection />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SpiderLoader;
