import { SHOW_EVENT_MODAL, HIDE_EVENT_MODAL, SHOW_DASHBOARD_OVERLAY, HIDE_DASHBOARD_OVERLAY, EXPAND_SIDE_PANEL, SHRINK_SIDE_PANEL, SET_SCREEN_HEIGHT_WIDTH, START_PDF_EXPORT, END_PDF_EXPORT, SHOW_FILTER_COMPONENT, HIDE_FILTER_COMPONENT } from "./CommonActionTypes";
 
export function showEventModalAction (data) {
  return {
    type: SHOW_EVENT_MODAL,
    payload: data
  };
};

export function hideEventModalAction () {
  return {
    type: HIDE_EVENT_MODAL
  };
};

export function showDashboardOverlayAction () {
  return {
    type: SHOW_DASHBOARD_OVERLAY
  };
};

export function hideDashboardOverlayAction () {
  return {
    type: HIDE_DASHBOARD_OVERLAY
  };
};  

export function expandSideBarAction () {
  return {
    type: EXPAND_SIDE_PANEL
  };
};

export function shrinkSideBarAction () {
  return {
    type: SHRINK_SIDE_PANEL
  };
};  

export function setScreenHeightWidthAction (data) {
  return {
    type: SET_SCREEN_HEIGHT_WIDTH,
    payload: data
  };
};  

export function startPDFexport () {
  return {
    type: START_PDF_EXPORT
  };
}

export function endPDFexport () {
  return {
    type: END_PDF_EXPORT
  };
}

export function showFilterComponentAction  () {
  return {
    type: SHOW_FILTER_COMPONENT
  };
}

export function hideFilterComponentAction () {
  return {
    type: HIDE_FILTER_COMPONENT
  };
}