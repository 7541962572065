import React from "react";
import * as d3 from "d3";
import { THEME_COLORS } from "./../../constants/constants";

class RedemptionStackedBarChart extends React.Component {

    componentDidMount(){
        this.loadChart();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.data.length !== nextProps.data.length ) {
          this.loadChart();
        }
      }
    
    componentDidUpdate(){
        this.loadChart();
    }

    loadChart = () => {
        d3.select(this.node).selectAll("svg").remove();
        let margin = {top: 20, right: 80, bottom: 50, left: 60},
        width = this.props.width - margin.left - margin.right,
        height = this.props.height - margin.top - margin.bottom;
        let graphname = this.props.graphname, isExpand = this.props.expandscreen,
        lastMonthdata = this.props.lastmonthdata,
        maxPercentValue = this.props.maxPercentValue;
        
        let y = d3.scaleLinear()
        .rangeRound([height, 0])
            .nice();

        let x = d3.scaleBand()
        .rangeRound([0, width])
        .paddingInner(0.05)
        .align(0.1)

        let z = d3.scaleOrdinal()
        .range([THEME_COLORS[1], THEME_COLORS[2],THEME_COLORS[4] ]);

        let raw = this.props.data;
        let isSwitch = this.props.switch ? this.props.switch : false;
        let isexpandscreen = this.props.expandscreen;
        let pointsourcetypes = [], topupData = [], arrobjAllocated = [];

        // Data pre-processing
        raw.forEach(function(d, i) {
            if(pointsourcetypes.indexOf(d.pointsourcetype) < 0) {
                pointsourcetypes.push(d.pointsourcetype)
            }

            if( d.topup ) {
                topupData.push( d );
            }
        });
      
        let data_nest = d3.nest()
            .key(function(d) { return d.period })
            .key(function(d) { return d.pointsourcetype; })
            .rollup(function(v) { return d3.sum(v, function(d) { return d.percent; }); })
                .entries(raw);

        let years = data_nest.map(function(d) { return d.key; })

        let data_stack = []

        data_nest.forEach(function(d, i) {
            let t ={}

            d.percents = d.values.map(function(e) { return e.value; })
            pointsourcetypes.forEach(function(e, i) {
                t[e] = d.percents[i]
            })

            t.year = d.key;
            data_stack.push(t)
        })

        let layers = d3.stack().keys(pointsourcetypes)(data_stack);
        let max = d3.max(layers[layers.length-1], function(d) { return d[1]; });

        y.domain([0, maxPercentValue]);
        x.domain(years);
       
        let svg = d3.select(this.node).append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
            
        svg.append("g").selectAll("g")
            .data(layers)
            .enter().append("g")
            .style("fill", function(d) { return z(d.key); })
            .attr("class", function(d, i) { return `${z(d.key)}_${i}` })
            .selectAll("rect")
            .data(function(d) {  return d; })
            .enter().append("rect")
            .attr("class", function(d, i) { return isexpandscreen ? `rect_expand_${i}` : `rect_${i}` })
            .style("stroke", function(d) { 
                if( topupData && topupData.length > 0 ) {
                    let arrobjRemCredit = raw.filter(obj => {
                        let boolValid = false;
                        if( topupData.length > 0 ) {
                            topupData.forEach(element => {
                                if( !boolValid ) {
                                    boolValid = obj.period === d.data.year && obj.pointsourcetype === "Allocated" && obj.topup && element.period === obj.period;
                                }
                            });
                        }
                        return boolValid
                    });
                    if( arrobjRemCredit && arrobjRemCredit.length > 0 ) {
                        return THEME_COLORS[0]; 
                    }
                }
            })
            .attr("stroke-width", '2px')
            .attr("x", function(d, i) { return x(d.data.year); })
            .attr("y", function(d) { return y(d[1]); })
            .attr("height", function(d) { return y(d[0]) - y(d[1]); })
            .attr("width", x.bandwidth())
            .on("mousemove", function(d,index) {
                let div = d3.select(`#tooltip-${graphname}${isExpand}`)
                .attr("class", "redemption-tooltip")
                .style("opacity", 0);
        
                let e = window.event, x = e.clientX, y = e.clientY-40;
          
               let arrobjRedeemed = raw.filter(obj => {
                    return obj.period === d.data.year && obj.pointsourcetype === "Redeemed" 
                });

                let arrobjUnredeemed = raw.filter(obj => {
                    return obj.period === d.data.year && obj.pointsourcetype === "Awarded" 
                });

                let arrobjRemCredit = raw.filter(obj => {
                    return obj.period === d.data.year && obj.pointsourcetype === "Allocated" 
                });

                // let offsets = document.getElementById('budgetUtilizationTrendContainer').getBoundingClientRect();
                div.transition().duration(200).style("opacity", 1.0);

                let strTopupMonth = '';
                if( topupData && topupData.length > 0 ) {
                    let arrobjRemCredit = raw.filter(obj => {
                        let boolValid = false;
                        if( topupData.length > 0 ) {
                            topupData.forEach(element => {
                                if( !boolValid ) {
                                    boolValid = obj.period === d.data.year && obj.pointsourcetype === "Allocated" && obj.topup && element.period === obj.period;
                                }
                            });
                        }
                        return boolValid
                    });
                    if( arrobjRemCredit && arrobjRemCredit.length > 0 ) {
                        strTopupMonth = '( Top Up Month )';
                    }
                }

                isSwitch ?
                    div.html( `Period: ${d.data.year} <span style="color:${THEME_COLORS[0]}">${strTopupMonth ? strTopupMonth : '' } </span><br/>
                        <span style="color:${THEME_COLORS[4]}">Organisation Balance: ${d.data.Allocated}% (${arrobjRemCredit[0].value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}) </span><br/>
                        <span style="color:${THEME_COLORS[1]}">Redeemed: ${d.data.Redeemed}% (${arrobjRedeemed[0].value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")})</span>`)
                    .style("top", `${y-50}px`)
                    .style("left", `${x-100}px`)
                :
                    div.html( `Period: ${d.data.year} <span style="color:${THEME_COLORS[0]}">${strTopupMonth ? strTopupMonth : '' } </span><br/>
                        <span style="color:${THEME_COLORS[4]}">Organisation Balance: ${d.data.Allocated}% (${arrobjRemCredit[0].value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}) </span><br/>
                        <span style="color:${THEME_COLORS[2]}">Unredeemed: ${d.data.Awarded}% (${arrobjUnredeemed[0].value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}) </span><br/>
                        <span style="color:${THEME_COLORS[1]}">Redeemed: ${d.data.Redeemed}% (${arrobjRedeemed[0].value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")})</span>`)
                    .style("top", `${y-50}px`)
                    .style("left", `${x-100}px`)
                ;
            })
            .on("mouseout", function(d) {
                let div = d3.select(`#tooltip-${graphname}${isExpand}`)
                div.transition().duration(500).style("opacity", 0);
            });

        svg.append("g")
            .attr("class", "x axis")
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(x))

        svg.append("g")
            .attr("class", "y axis")
            .attr("transform", "translate(" + (0) + ", 0)")
            .call(d3.axisLeft().scale(y))
      
        let UDRData = this.props.UDRData;
        let averagePercent = 100/UDRData;

        for( let i = 1; i <= UDRData; i++ ) {
            arrobjAllocated.push( {x: years[i-1], y: (averagePercent*i).toFixed(1)} );
            if( lastMonthdata.period === years[i-1] ) {
                let diffPercent = ( 100 - (averagePercent*i).toFixed(1) ) - parseFloat(lastMonthdata.percent);
                if( diffPercent <= 0.5 && diffPercent >= -0.5 ) {
                    let div = d3.select(`#msg1`).style("opacity", 1);
                    div.html( `<span style="color:${THEME_COLORS[0]}"> Credit utilisation rate = UUR. Action: Continue to award & redeem points at the current rate.</span><br/>`)
                ;
                } else if( diffPercent > 0.5) {
                    let div = d3.select(`#msg1`).style("opacity", 1);                                                                      
                    div.html( `<span style="color:${THEME_COLORS[0]}">Credit utilisation rate > UUR. Action: Points may be utilised faster than planned. Encourage users to redeem points and consider a credit top up.</span><br/>`)
                ;
                } else {
                    let div = d3.select(`#msg1`).style("opacity", 1);
                    div.html( `<span style="color:${THEME_COLORS[0]}">Credit utilisation rate < UUR.  Action: Ramp up awarding of points and encourage users to redeem.</span><br/>`)
                ;
                }
            }
        };

        svg.selectAll('.x').selectAll(".tick").selectAll('text')
        // .attr("y", "30")
        .attr("x", x(years[0])-20)
        .attr("transform", "rotate(-45)")
        .style("font-size", 12)
        .style("font-weight", 700)
        .style("fill", "#758088")
        .style("font-family", "roboto");

        svg.selectAll('.y').selectAll(".tick").selectAll('text')
        .style("font-size", 12)
        .style("font-weight", 700)
        .style("fill", "#758088")
        .style("font-family", "roboto");

        // // Line Chart implementation ---------------------------------
        // let xScale = d3.scaleLinear()
        //     // .domain([0, arrobjAllocated.length+1]) // input
        //     .domain(years) // input
        //     .range([0, width+(arrobjAllocated.length*arrobjAllocated.length/2)-1])
        //     ; // output

        let xScale = d3.scaleLinear()
            .domain([0, UDRData]) // input
            .range([0, width]); // output
        // 6. Y scale will use the randomly generate number
        // let perDiff = maxPercentValue - 100;
        let yScale = d3.scaleLinear()
            .domain([0, maxPercentValue])
            .range([height, 0]);

        // 7. d3's line generator
        let line = d3.line()
            // .x(function(d, i) { return i === 0 ? d3.select(`.rect_${i}`).attr("x") : (abc*i) }) // set the x values for the line generator
            .x( function(d, i) {
                if( width > 1080 ) {
                    return xScale(i + 0.5 -( i * 0.015 ))
                } else if( width < 620 ) {
                    return xScale(i + 0.5 -( i * 0.015 ))
                } else {
                    return xScale(i + 0.5 -( i * 0.01 ))
                }
            })
        // .attr("cx", function(d, i) { return d3.select(`.rect_${i}`).attr("x")-1 })
        .y(function(d) { return yScale(d.y-0.5); })
        .curve(d3.curveMonotoneX)

        svg.append("path")
        .datum(arrobjAllocated)
        // .attr("class", "line")
        .attr("fill", "none")
        .attr("stroke", "#ffcccb")
        .attr("stroke-width", "3")
        // .attr("transform", "inherit")
        .attr("d", line);

        if( maxPercentValue > 100 ) {
            let thresholdline = d3.line()
            // .x(function(d, i) { return i === 0 ? d3.select(`.rect_${i}`).attr("x") : (abc*i) }) // set the x values for the line generator
            .x( function(d, i) {
                if( width > 1080 ) {
                    return xScale(i)
                } else if( width < 620 ) {
                    return xScale(i)
                } else {
                    return xScale(i)
                }
            })
            .y(function(d) { return yScale( isExpand ? 100 - 0.1 : 100 - 0.1 ); })
            .curve(d3.curveMonotoneX)
            
            svg.append("path")
                .datum(arrobjAllocated)
                .attr("fill", "none")
                .attr("stroke", "#000")
                // .attr("stroke-width", "1")
                .attr("d", thresholdline);

            let thresholdline2 = d3.line()
                .x( function(d, i) {
                    if( width > 1080 ) {
                        return xScale(i+1)
                    } else if( width < 620 ) {
                        return xScale(i+1)
                    } else {
                        return xScale(i+1)
                    }
                })
                .y(function(d) { return yScale( isExpand ? 100-0.2 : 100 - 0.2 ); })
                .curve(d3.curveMonotoneX)

                svg.append("path")
                .datum(arrobjAllocated)
                .attr("fill", "none")
                .attr("stroke", "#000")
                // .attr("stroke-width", "1")
                .attr("d", thresholdline2);
            }

        // 12. Appends a circle for each datapoint 
        svg.selectAll(".dot")
            .data(arrobjAllocated)
            .enter().append("circle")
            // .attr("class", "dot")
            .attr("fill", function(d, i ) { return i < UDRData ? "red" : 'none' })
            .attr("stroke", function(d, i ) { return i < UDRData ? "red" : "none" })
            // .attr("cx", function(d, i) { return xScale( x(d.x)+7) })
            .attr("cx", function(d, i) {
                if( width > 1080 ) {
                    return xScale(i + 0.5 -( i * 0.015 ))
                } else if( width < 620 ) {
                    return xScale(i + 0.5 -( i * 0.015 ))
                } else {
                    return xScale(i + 0.5 -( i * 0.01 ))
                }
            })
            .attr("cy", function(d, i ) { return yScale(d.y-0.5 )})
            .attr("r", 5)
            .on("mousemove", function(d,index) {
                let div = d3.select(`#tooltip-${graphname}${isExpand}`).attr("class", "redemption-tooltip").style("opacity", 0);

                let e = window.event, x = e.clientX, y = e.clientY-40;
                div.transition().duration(200).style("opacity", 1.0);

                div.html( `Period: ${d.x} <br/>
                    <span style="color:${THEME_COLORS[0]}">UUR: ${d.y}% </span><br/>`)
                .style("top", `${y-50}px`)
                .style("left", `${x-100}px`)
                ;
            })
            .on("mouseout", function(d) {
                let div = d3.select(`#tooltip-${graphname}${isExpand}`)
                div.transition().duration(500).style("opacity", 0);
            });

        svg.append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", -60)
        .attr("x", 0 - ((height) / 2))
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .style("font-family", "roboto")
        .style("font-weight", 500)
        .style("font-size", 14)
        .style("fill", "#758088")
        .text("Share of Organisation Credit (%)");
    }

    render(){
        let graphname = this.props.graphname, isExpand = this.props.expandscreen;
        return(
            <div /*id="budgetUtilizationTrendContainer" */ {...this.props} className={`budgetUtilizationTrendContainer-${this.props.expandscreen}`} ref = { (node) => this.node = node }>
                 <div className="legendContainer" style={{width: isExpand === true ? '' : "550px", minHeight:  isExpand === true ? '' : "72px"}}>
                {this.props.legendsname
                    ? <div className="sunbustLegend">
                    {
                        this.props.legendsname.map((entry, index) => {
                        return (<span className="legendItem" style={{ display: "inline-flex", marginRight: "10px", position: "relative"  }} key={`item-${index}`}
                        > {index != 3 && index != 4 && index != 5 ? <label style={{ backgroundColor: `${this.props.colorcodes[index]}`, width: "15px", height: "12px", margin: "3px 3px 0px 3px", fontSize: "15px", paddingLeft: "0px", color: "#595959", fontFamily: "roboto", fontWeight: "500", display:`${this.props.switch && index == 1 ? "none" : "inline-flex" }` }} ></label> 
                        :
                            index === 3 ? 
                            <label style={{ border: `2px solid ${THEME_COLORS[0]}`, width: "15px", height: "12px", margin: "3px 3px 0px 3px", fontSize: "15px", paddingLeft: "0px", color: "#595959", fontFamily: "roboto", fontWeight: "500", display:"inline-block" }} ></label> 
                            :
                            index === 4 ? 
                            <div class="lineLegends" style={{display: "inline-block", marginRight: "10px"}}>
                                <label style={{border: "0", backgroundColor: "#ffcccb", height: "3px", width: "20px", marginLeft: "-1px", marginTop: "9.8px", position: "absolute", cursor: "pointer"}} ></label>
                                <label style={{ position: "relative", borderRadius: "6px", color: "red", backgroundColor: `${THEME_COLORS[0]}`, width: "12px", height: "12px", margin: "3px 3px 0px 3px", fontSize: "15px", paddingLeft: "0px", color: "#595959", fontFamily: "roboto", fontWeight: "500", display:"inline-block" }} ></label>
                            </div>
                            :
                            <div class="lineLegends" style={{display: "inline-block", marginRight: "10px"}}>
                                <label style={{border: "0", backgroundColor: "#000", height: isExpand === true ? "2px" : "1px", width: "20px", marginLeft: "-1px", marginTop: "9.8px", position: "absolute", cursor: "pointer"}} ></label>
                                <label style={{ position: "relative", borderRadius: "6px", color: "#fff", backgroundColor: `#fff}`, width: "12px", height: "12px", margin: "3px 3px 0px 3px", fontSize: "15px", paddingLeft: "0px", color: "#595959", fontFamily: "roboto", fontWeight: "500", display:"inline-block" }} ></label>
                            </div>
                        }
                        {this.props.switch && index == 1 ? '' :
                        <label style={{ margin: "-2px 3px", fontSize: "12", fontWeight: "400",/* color: "#758088",*/fontFamily: "roboto" }}>{entry}</label>}</span>); 
                        })
                    }
                    </div>
                    : <div className="dataNotFound">
                    No Legends Found
                </div>
                }
                </div>
                <div>
                  <div id={`tooltip-${graphname}${isExpand}`} ></div>
                  <div id="msg1" style={{width: isExpand === true ? '' : "550px"}} ></div>
                </div>
            </div>
        )
    }
}

export default RedemptionStackedBarChart;