import React from "react";
import ReactWordcloud from "react-wordcloud";
import * as d3 from "d3";

class WordCloud extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      wordsData: []
    };
    this.myColor = d3.scaleLinear()
      .range([props.lowColor, props.highColor])
      .domain([1, props.maxValue]);
  }

  componentWillMount () {
    this.setState({
      wordsData: this.props.wordsData
    });
  }

  componentWillReceiveProps (nextProps) {
    if(this.props.wordsData !== nextProps.wordsData) {
      this.setState({
        wordsData: nextProps.wordsData
      });
    }
  }

  getClick = (wordClicked) => {
    if(this.props.clickEvent) {
      this.props.handleWordClicked(wordClicked);
    }
  }
  getColor = ( value ) => {
    let color = d3.scaleLinear()
      .range([this.props.lowColor, this.props.highColor])
      .domain([1, this.props.maxValue]);

      return( color(value) )
  }
  render () {
    const options = {
      // colors: ["#1f77b4"],
      enableTooltip: true,
      deterministic: true,
      fontFamily: "roboto",
      fontSizes: [this.props.minFontSize, this.props.maxFontSize],
      fontStyle: "normal",
      fontWeight: "500",
     
      padding: 1,
      rotations: 3,
      rotationAngles: [0, 0],
      scale: "sqrt",
      spiral: "archimedean",
      transitionDuration: 1000
    };
    const callbacks = {
      getWordColor: ({ value }) => {
        return this.getColor(value);
      },
      onWordClick: e => this.getClick(e),
      getWordTooltip: (props) => {
        return `${props.text}: ${props.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
      }
    };
    return (
      this.props.loader 
        ? <div className={ this.props.loader ? "cloudLoader animated-background lg-width" : "cloudLoader"}> </div> 
        : this.props.wordsData.length > 0
          ? <div className="cloudoutersupport">
            <ReactWordcloud options={options} callbacks={callbacks} words={this.props.wordsData} size={[this.props.width, this.props.height]} />
          </div> : <div className="dataNotFound">  
                    No Data Found
          </div>
    );
  }
} 

export default WordCloud;
