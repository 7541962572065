import React from "react";
import { GraphActions } from "../";

class EventModal extends React.Component {
  render () {
    return (
      this.props.showModal
        ? <div className={ this.props.showGraph ? "eventModalContainer graphModalContainer" : "eventModalContainer" } >
          <div className ="closebutton" onClick={this.props.buttonOneHandler}>	&times; </div>
          { !this.props.showGraph ? <div className="eventModalBody">
            <div className="eventModalTitle">
              {this.props.modalTitle}
            </div>
            {/* MODAL BODY */}
            <div className="eventModalBodyContent">
              { this.props.modalBody }
            </div>
            <div className={ `eventModalControl ${this.props.showButtonTwo ? "prompt" : null}`}>
              { this.props.showButtonOne ? <button className="btn btn-secondary" onClick={this.props.buttonOneHandler} >
                { this.props.buttonOneName }
              </button> : null }
              { this.props.showButtonTwo ? <button className="btn btn-secondary" onClick={this.props.buttonTwoHandler} >
                { this.props.buttonTwoName } 
              </button> : null}
            </div>
          </div>
            : <div className="graphInnerContainer">
              <div className="barcharModalTitlecontainer">
                <h2>
                  { this.props.graphTitle }
                </h2>                
              </div>
              <div className="barmodalSubTitle">
                <h3> { this.props.graphSubTitle } </h3>  
              </div>
              <div className="modalgraphWrapper">
                { [this.props.graphContent] }
              </div>  
            </div>
          }    
        </div>      
        : null
    );
  }
}

export default EventModal;