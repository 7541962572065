import React from "react";
import { SankeyChartD3, SankeyChartLoader } from "../../../../../../Widgets";
import { GraphActions } from "../../../../../../UI";
import { GRAPH_IDS } from "../../../../../../constants/constants";
import { connect } from "react-redux";
import HeatmapSection from "./Components/HeatmapSection";
import * as AppreciationActions from "../../../Appreciation/Actions/AppreciationActions";

class AppreciationFlow extends React.Component {
  
  constructor (props) {
    super(props);
    this.state = {
      selectedTab: "company"
    };
  }

  handleExpandChart = (graphProps) => {
    let graph = "";
    let departmentWiseSankeyHeight = this.props.sankey.sankeyData.department ? ((this.props.sankey.sankeyData.department.nodes.length + 10) / 100) : 0.5;
    if(departmentWiseSankeyHeight < 0.5 && departmentWiseSankeyHeight > 0.3) {
      departmentWiseSankeyHeight = 0.5;
    } else if(departmentWiseSankeyHeight <= 0.3) {
      departmentWiseSankeyHeight = 0.3;
    }

    if(graphProps.graphName === GRAPH_IDS.SANKEY_BY_COMPANY) {
      graph = <SankeyChartD3 
        width={ window.screen.width * 0.80 }
        height = { window.screen.height * 0.5 }
        data = { this.props.sankey.sankeyData.company }
        graphName = "sankeyCompanyExpanded"
      />;
    } else if(graphProps.graphName === GRAPH_IDS.SANKEY_BY_DEPARTMENT) {
      graph = 
      <div className="scroll-auto" style={{ height: window.screen.height * 0.55 }} >
        <SankeyChartD3 
          width={ window.screen.width * 0.80 }
          height = { window.screen.height * departmentWiseSankeyHeight }
          data = { this.props.sankey.sankeyData.department }
          graphName = "sankeyDepartmentExpanded"
        />
      </div>;
    } 
    
    this.props.showEventModal({
      showEventModal: true,
      eventModalTitle: "",
      eventModalBody: "",
      showEventModalButtonOne: false,
      showEventModalButtonTwo: false,
      buttonOneEventModalHandler: () => this.props.hideEventModal(),
      buttonTwoEventModalHandler: null,
      buttonOneEventModalName: "",
      buttonTwoEventModalName: "",
      graphContent: graph,
      graphTitle: graphProps.graphTitle,
      graphSubTitle: graphProps.graphSubTitle,
      showGraph: true,
      graphName: "",
      graphId: ""
    });
  };

  handleSankeyRadioSelect = ( value ) => {
    this.setState({
      ...this.state,
      sankey: {
        ...this.state.sankey,
        radioSelect: value
      }
    }, () => {
      this.props.appreciationFlowRequest({ ...this.props.getFilterObject(), parameter: value });
    });
  }

  setSelectedTab = (categoryName) => {
    this.setState({
      ...this.state,
      selectedTab: categoryName
    });
  }

  render () {
    
    let screenWidth = window.screen.width;
    
    if(document.getElementById("tilesSection")) {
      screenWidth = document.getElementById("tilesSection").offsetWidth; 
    }
    let companyGraphSubtitleSankey = "By Company";
    if(this.props.appreciationFlow.sankey.radioSelect === "all") {
      companyGraphSubtitleSankey = "By Company(All)";
    } else if(this.props.appreciationFlow.sankey.radioSelect === "across") {
      companyGraphSubtitleSankey = "By Company(Across Entities)";
    } else if(this.props.appreciationFlow.sankey.radioSelect === "within") {
      companyGraphSubtitleSankey = "By Company(Within Entities)";
    }

    let departmentGraphSubtitleSankey = "By department";
    if(this.props.appreciationFlow.sankey.radioSelect === "all") {
      departmentGraphSubtitleSankey = "By department(All)";
    } else if(this.props.appreciationFlow.sankey.radioSelect === "across") {
      departmentGraphSubtitleSankey = "By department(Across Entities)";
    } else if(this.props.appreciationFlow.sankey.radioSelect === "within") {
      departmentGraphSubtitleSankey = "By department(Within Entities)";
    }

    let departmentWiseSankeyHeight = this.props.sankey.sankeyData.department ? ((this.props.sankey.sankeyData.department.nodes.length + 10) / 100) : 0.4;
    if(departmentWiseSankeyHeight < 0.4) {
      departmentWiseSankeyHeight = 0.4;
    }

    return (
      <div className="appreciationFlow" >
        <div className="col-sm-12" >
          <div className="titleOuter">
            <h2>Appreciation Flow</h2>
          </div>
        </div>
        <div className="tabContainer">
          <div className="tablinkouter">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item">
                <a className={`nav-link tab-recive ${this.state.selectedTab === "company" ? "active" : ""}`} role="tab" data-toggle="tab" onClick={ () => this.setSelectedTab("company") } >Appreciations by Company </a>
              </li>
              <li className="nav-item">
                <a className={`nav-link tab-given ${this.state.selectedTab === "department" ? "active" : ""}`} role="tab" data-toggle="tab" onClick={ () => this.setSelectedTab("department") }>Appreciations by Department </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-sm-12 mt-3" >
          <div className="daywWiseFilter">
            <div className="custom-control custom-radio custom-control-inline">
              <input type="radio" id="sankeyAll" name="sankeyRadio" className="custom-control-input" checked = { this.props.appreciationFlow.sankey.radioSelect === "all" } onChange={ () => this.props.handleSankeyRadioSelect("all") } />
              <label className="custom-control-label" htmlFor="sankeyAll">All</label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input type="radio" id="sankeyAcross" name="sankeyRadio" className="custom-control-input" checked = { this.props.appreciationFlow.sankey.radioSelect === "across" } onChange={ () => this.props.handleSankeyRadioSelect("across") } />
              <label className="custom-control-label" htmlFor="sankeyAcross">Across Entities</label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input type="radio" id="sankeyWithin" name="sankeyRadio" className="custom-control-input" checked = { this.props.appreciationFlow.sankey.radioSelect === "within" } onChange={ () => this.props.handleSankeyRadioSelect("within") } />
              <label className="custom-control-label" htmlFor="sankeyWithin">Within Entities</label>
            </div>
          </div>
        </div>
        <div className={`col-sm-12 sankyByComapny tab-pane ${this.state.selectedTab === "company" ? "active" : "hide"}`}>
          <div className="graphsActionContainer">
            <h3>By Company </h3>
            <div className="exportContainer">
              <GraphActions
                showExpansion={this.props.sankey.sankeyData.company && this.props.sankey.sankeyData.company.nodes.length > 0}
                showCSVExport={ !!(this.props.sankey.sankeyData.company && this.props.sankey.sankeyData.company.nodes.length > 0) }
                showImageExport={this.props.sankey.sankeyData.company && this.props.sankey.sankeyData.company.nodes.length > 0}
                showInfo={true}
                from={"appreciationflow"}
                filterObjectForCSV={this.props.getFilterObject}
                export_parameter={"organization"}
                apiUrl={"auth/graph/appreciation_flow"}
                parameter={this.props.appreciationFlow.sankey.radioSelect}
                graphId={ GRAPH_IDS.SANKEY_BY_COMPANY }
                imageName={"Appreciation Flow By Company"}
                infoMessage={"The width of the flow is proportional to the # of appreciations from the giver (left) to the receiver (right). Use this to visualise the flow of appreciations within and across companies."}
                handleExpand={ (graphId) => this.handleExpandChart({ graphTitle: "Appreciation Flow", graphName: GRAPH_IDS.SANKEY_BY_COMPANY, graphSubTitle: companyGraphSubtitleSankey }) }
              />
            </div>
          </div> 
          <div className="graphInfoNote">
            <span>Note: </span> Please click on a company on the left (right) to highlight the appreciation flows from (to) the company.
          </div>
          { this.props.sankey.loader
            ? <SankeyChartLoader
              width={screenWidth * 0.92}
              height={window.screen.height * 0.4}
            />
            : this.props.sankey.sankeyData.company && this.props.sankey.sankeyData.company.nodes.length > 0
              ? <div id={GRAPH_IDS.SANKEY_BY_COMPANY} >
                <SankeyChartD3
                  width={screenWidth * 0.92}
                  height={window.screen.height * 0.4}
                  data = { this.props.sankey.sankeyData.company }
                  graphName = "sankeyCompany"
                /> 
              </div>
              : <div className="dataNotFound">
              No Data Found
              </div>
          }
        </div>
        <div className={`col-sm-12 sankyByDepartment tab-pane ${this.state.selectedTab === "department" ? "active" : "hide"}`}>
          <div className="graphsActionContainer">
            <h3>By Department </h3>
            <div className="exportContainer">
              <GraphActions
                showExpansion={this.props.selectedCompanies.length > 0 && this.props.sankey.sankeyData.department && this.props.sankey.sankeyData.department.nodes.length > 0}
                showCSVExport={ !!(this.props.selectedCompanies.length > 0 && this.props.sankey.sankeyData.department && this.props.sankey.sankeyData.department.nodes.length > 0) }
                showImageExport={this.props.selectedCompanies.length > 0 && this.props.sankey.sankeyData.department && this.props.sankey.sankeyData.department.nodes.length > 0}
                from={"appreciationflow"}
                export_parameter={"department"}
                filterObjectForCSV={this.props.getFilterObject}
                parameter={this.props.appreciationFlow.sankey.radioSelect}
                apiUrl={"auth/graph/appreciation_flow"}
                showInfo={true}
                graphId={ GRAPH_IDS.SANKEY_BY_DEPARTMENT }
                imageName={"Appreciation Flow By Department"}
                infoMessage={"The width of the flow is proportional to the # of appreciations from the giver (left) to the receiver (right). Use this to visualise the flow of appreciations within and across departments."}
                handleExpand={ (graphId) => this.handleExpandChart({ graphTitle: "Appreciation Flow", graphName: GRAPH_IDS.SANKEY_BY_DEPARTMENT, graphSubTitle: departmentGraphSubtitleSankey }) }
              />
            </div>
          </div>
          <div className="graphInfoNote">

            { this.props.selectedCompanies.length > 0
              ? <div> <span>Note: </span> Please click on a department on the left (right) to highlight the appreciation flows from (to) the department. </div>
              : <div> <span>Note: </span> Please filter a company or specific department(s) to view the flow of appreciations by department. Next, click on a department on the left (right) to highlight the appreciation flows from (to) the department. </div> }
          </div>
          {
            this.props.selectedCompanies.length === 0
              ? <div className="dataNotFound">
                Pending Selection
              </div>

              : this.props.sankey.loader
                ? <SankeyChartLoader
                  width={screenWidth * 0.92}
                  height={window.screen.height * departmentWiseSankeyHeight}
                />
                : this.props.sankey.sankeyData.department && this.props.sankey.sankeyData.department.nodes.length > 0
                  ? <div id={GRAPH_IDS.SANKEY_BY_DEPARTMENT} >
                    <SankeyChartD3
                      width={screenWidth * 0.92}
                      height={window.screen.height * departmentWiseSankeyHeight}
                      data = { this.props.sankey.sankeyData.department }
                      graphName = "sankeyDepartment"

                    />
                  </div> : <div className="dataNotFound">
    No Data Found
                  </div>
          }
        </div>
        <HeatmapSection
          apiUrl={"auth/graph/appreciation_flow"}
          heatmapState = { this.props.appreciationFlow.heatmap }
          handleRadioSelect = {this.props.handleRadioSelect}
          getFilterObject = { this.props.getFilterObject }
          selectedDate= { this.props.selectedDate}
          parameter={this.props.appreciationFlow.heatmap.radioSelect}
          selectedCompanies = { this.props.selectedCompanies }
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    sankey: state.AppreciationDashboard.appreciationFlow.sankey
  };
};

const mapDispatchToProps = dispatch => {
  return {
    appreciationFlowRequest: (data) => dispatch(AppreciationActions.appreciationFlowRequestAction(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppreciationFlow);