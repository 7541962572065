/* eslint-disable react/react-in-jsx-scope */

import React from "react";
import { connect } from "react-redux";
import {
  ENGAGEMENT_GRAPH_NAME,
  MESSAGES,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_TYPE_DROPDOWN,
  SLOTS_3HR,
  WEEKS_DAY,
} from "../../../../../constants/constants";
import { GraphActions, MultiSelect, TableLoader } from "../../../../../UI";
import API from "../../../../../Utilities/Api/Apis";
import {
  generateFileName,
  getMax,
  getNotificationTypeName,
  thousandsWithCommas,
} from "../../../../../Utilities/common-utill";
import { HeatmapLoader, StackedBarChartVertical } from "../../../../../Widgets";
import Heatmap from "../../../../../Widgets/HeatMap/Heatmap";
import StackedBarChartLoader from "../../../../../Widgets/StackedBarChart/StackedBarChartLoader";

const readColor = "#1fc85f";
const unreadColor = "#f49696";
class InteractionEffectiveness extends React.Component {
  // state = {
  //   selectedNotificationType: NOTIFICATIONS_TYPE_DROPDOWN[0],
  // };
  handleExpandChart = (props) => {
    props.showEventModal({
      showEventModal: true,
      eventModalTitle: "",
      eventModalBody: "",
      showEventModalButtonOne: false,
      showEventModalButtonTwo: false,
      buttonOneEventModalHandler: () => props.hideEventModal(),
      buttonTwoEventModalHandler: null,
      buttonOneEventModalName: "",
      buttonTwoEventModalName: "",
      graphContent: props.graph,
      graphTitle: props.graphTitle,
      graphSubTitle: props.graphSubTitle,
      showGraph: true,
      graphName: "Bar Chart",
      graphId: "",
    });
  };

  render() {
    let screenWidth = window.screen.width / 2;

    const eventsResponsesDataFlag =
      this.props.eventsResponsesData && this.props.eventsResponsesData.length;

    const getEventsResponsesChart = ({
      width = screenWidth * 0.75,
      height = window.screen.height * 0.45,
      breakTextLength = 30,
      marginLeft = 150,
      marginRight = 120,
    }) => {
      const xAxisNames = [
        "Checked in",
        "RSVP",
        "Visited page",
        "Did not visit page",
      ];
      const xAxisLabel = this.props.eventsUsersRatioSelections
        ? "% of Unique Users"
        : "# of Unique Users";
      return (
        <StackedBarChartVertical
          width={width}
          height={height}
          showDecimal={this.props.eventsUsersRatioSelections}
          decimal={2}
          breakTextLength={breakTextLength}
          xAxisNames={xAxisNames}
          data={this.props.eventsResponsesData}
          margin={{ top: 10, right: marginRight, left: marginLeft, bottom: 70 }}
          colors={["#00b3ff", "#1fc85f", "#f6cd61", "#ec4242"]}
          showRightYAxis={false}
          expanded={false}
          isOnClickEvent={false}
          // clickHandler={}
          // showActiveBar={false}
          // activeBarColor={"#2cc273"}
          xAxisNameId={"title"}
          // skipLegend={true}
          xAxisAngle={0}
          textAnchor="middle"
          // yAxisLabel=""
          xAxisLabel={xAxisLabel}
          showBrush={this.props.eventsResponsesData.length > 10}
          brushWidth={width}
        />
      );
    };

    const getMessage = (msg = "") => {
      const sliceEnd = 80;
      const newMessage =
        msg.length > sliceEnd ? msg.slice(0, sliceEnd) + "..." : msg;
      return newMessage;
    };

    const getNotificationsTable = ({
      width = "100%",
      height = window.screen.height * 0.45,
      showFullMessage = false,
      isOnClickEvent = true,
    }) => {
      const newLineDates = (string) => {
        let s = string.split(" ");
        return s.join("<br>");
      };

      return (
        <div>
          <div
            className="col-5 col-sm-4 d-flex align-items-center mb-3"
            style={{ padding: 0 }}
          >
            <div
              class="text-center"
              style={{
                zIndex: 1,
                background: readColor,
                display: "block",
                width: "50%",
              }}
            >
              Read
            </div>
            <div
              className="text-center"
              style={{
                zIndex: 1,
                background: unreadColor,
                display: "block",
                width: "50%",
              }}
            >
              Unread
            </div>
          </div>
          <div
            className="tableModule table-responsive"
            style={{ width, height }}
          >
            <table
              id="IdNotifications"
              className="table table-hover"
              style={{
                background: "white",
                textAlign: "left",
                cursor: "default",
              }}
            >
              <thead>
                <tr>
                  <th scope="col" key={"date"}>
                    Date
                  </th>
                  <th scope="col" key={"Type"}>
                    Type
                  </th>
                  <th scope="col" key={"title"}>
                    Title
                  </th>
                  <th scope="col" key={"Notification"}>
                    Notification
                  </th>
                  <th scope="col" key={"#sent"}>
                    #Sent
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.notificationsData.map((tableElement, index) => (
                  <tr style={{ cursor: "default" }}>
                    <td
                      style={{ width: "117px" }}
                      dangerouslySetInnerHTML={{
                        __html: newLineDates(tableElement.date),
                      }}
                    ></td>
                    <td style={{ textAlign: "left" }}>
                      {index === 0
                        ? this.props.selectedNotificationType.value
                        : getNotificationTypeName(tableElement.object_type)[0]}
                    </td>
                    <td>{tableElement.title || "No Title"}</td>
                    <td
                      style={{ textAlign: "left" }}
                      title={tableElement.message}
                    >
                      {showFullMessage
                        ? tableElement.message
                        : getMessage(tableElement.message)}
                      <div
                        className="d-flex align-items-center"
                        style={{ width: "100%" }}
                      >
                        <div
                          className="text-center"
                          style={{
                            zIndex: 1,
                            background: readColor,
                            // color: "white",
                            display: tableElement.read > 0 ? "block" : "none",
                            width: `${
                              (tableElement.read / tableElement.total_sent) *
                              100
                            }%`,
                          }}
                        >
                          {thousandsWithCommas(tableElement.read)}
                        </div>
                        <div
                          className="text-center"
                          style={{
                            background: unreadColor,
                            display: tableElement.unread > 0 ? "block" : "none",
                            // color: "white",
                            width: `${
                              (tableElement.unread / tableElement.total_sent) *
                              100
                            }%`,
                          }}
                        >
                          {thousandsWithCommas(tableElement.unread)}
                        </div>
                      </div>
                    </td>
                    {/* <td style={{ width: "30%" }}>
            </td> */}
                    <td>{thousandsWithCommas(tableElement.total_sent)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    };

    const notificationsFlag =
      this.props.notificationsData && this.props.notificationsData.length;

    return (
      <div className="budgetUtilization">
        <div className="row">
          <div className="col-sm-12">
            <div className="titleOuter">
              <h2>{ENGAGEMENT_GRAPH_NAME.INTERACTION_EFFECTIVENESS}</h2>
            </div>
          </div>
        </div>

        <div className="row">
          {this.props.isEventsEnabled ? (
            <div className="col-md-12 col-xl-12 col-lg1-12 mb-150">
              <div className="graphsActionContainer">
                <h3>{ENGAGEMENT_GRAPH_NAME.EVENTS_RESPONSES}</h3>
                <div className="exportContainer">
                  <GraphActions
                    showExpansion={eventsResponsesDataFlag}
                    showCSVExport={eventsResponsesDataFlag}
                    filterObjectForCSV={this.props.getFilterObject}
                    postPayload={{
                      isPercentageWise: this.props.eventsUsersRatioSelections,
                    }}
                    showImageExport={eventsResponsesDataFlag}
                    showInfo={true}
                    from={generateFileName(
                      ENGAGEMENT_GRAPH_NAME.EVENTS_RESPONSES,
                    )}
                    apiUrl={API.ENGAGEMENTS.EVENTS_RESPONSES}
                    graphId="IdEventsResponses"
                    imageName={generateFileName(
                      ENGAGEMENT_GRAPH_NAME.EVENTS_RESPONSES,
                    )}
                    infoMessage="Check-in: User checked in; RSVP: User RSVP=attending but did not check in; Visited Page: User visited the event page but did not RSVP/check in; Did not visit page: Remaining invited users."
                    handleExpand={(graphId) =>
                      this.handleExpandChart({
                        ...this.props,
                        graphTitle:
                          ENGAGEMENT_GRAPH_NAME.INTERACTION_EFFECTIVENESS,
                        graphSubTitle: ENGAGEMENT_GRAPH_NAME.EVENTS_RESPONSES,
                        screenWidth: screenWidth,
                        graph: getEventsResponsesChart({
                          width: window.innerWidth * 0.8,
                          height: window.screen.height * 0.58,
                          breakTextLength: 50,
                          marginLeft: 250,
                        }),
                      })
                    }
                  />
                </div>
              </div>
              <div className="graphInfoNote">
                <span>Note: </span>Use the radio button to compare event
                participant breakdown across events in absolute or percentage
                terms.
              </div>
              <div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="percentUsers"
                    name="eventsUserSelectionRatio"
                    className="custom-control-input"
                    checked={this.props.eventsUsersRatioSelections}
                    onChange={() =>
                      this.props.eventsUsersRatioSelectionsHandler(true)
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="percentUsers"
                  >
                    % of Unique Users
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="totalUsers"
                    name="eventsUserSelectionRatio"
                    className="custom-control-input"
                    checked={!this.props.eventsUsersRatioSelections}
                    onChange={() =>
                      this.props.eventsUsersRatioSelectionsHandler(false)
                    }
                  />
                  <label className="custom-control-label" htmlFor="totalUsers">
                    # of Unique Users
                  </label>
                </div>
              </div>
              <div id="IdEventsResponses">
                {this.props.eventsResponsesLoader ? (
                  <div className="histoloaderouter">
                    <StackedBarChartLoader
                      width={
                        document.getElementById("tilesSection").offsetWidth
                      }
                      height={window.screen.height * 0.48}
                    />
                  </div>
                ) : this.props.eventsResponsesData &&
                  this.props.eventsResponsesData.length >= 1 ? (
                  getEventsResponsesChart({
                    width: document.getElementById("tilesSection").offsetWidth,
                    height: window.screen.height * 0.45,
                  })
                ) : (
                  <div className="dataNotFound">{MESSAGES.NO_DATA_FOUND}</div>
                )}
              </div>
            </div>
          ) : null}

          <div className="col-md-12 col-xl-12 col-lg1-12 mb-150">
            <div className="graphsActionContainer">
              <h3>{ENGAGEMENT_GRAPH_NAME.NOTIFICATIONS}</h3>
              <div className="exportContainer">
                <GraphActions
                  showExpansion={notificationsFlag}
                  showCSVExport={notificationsFlag}
                  filterObjectForCSV={this.props.getFilterObject}
                  postPayload={{
                    type: this.props.selectedNotificationType.value,
                  }}
                  showImageExport={notificationsFlag}
                  showInfo={true}
                  from={generateFileName(ENGAGEMENT_GRAPH_NAME.NOTIFICATIONS)}
                  apiUrl={API.ENGAGEMENTS.NOTIFICATIONS}
                  graphId="IdNotifications"
                  imageName={generateFileName(
                    ENGAGEMENT_GRAPH_NAME.NOTIFICATIONS,
                  )}
                  infoMessage="This displays the read receipt status of notifications within the selected period."
                  handleExpand={(graphId) =>
                    this.handleExpandChart({
                      ...this.props,
                      graphTitle:
                        ENGAGEMENT_GRAPH_NAME.INTERACTION_EFFECTIVENESS,
                      graphSubTitle: ENGAGEMENT_GRAPH_NAME.NOTIFICATIONS,
                      screenWidth: screenWidth,
                      graph: getNotificationsTable({
                        width: "100%",
                        height: window.screen.height * 0.5,
                        showFullMessage: true,
                        isOnClickEvent: false,
                      }),
                    })
                  }
                />
              </div>
            </div>
            <div className="graphInfoNote">
              <span>Note: </span>Please use the dropdown list to filter by the
              type of initiative. Click on a column title to sort the data by
              that column.
            </div>
            <div
              className="col-5 col-sm-4 mb-2"
              style={{ marginLeft: "auto", padding: 0 }}
            >
              <MultiSelect
                name="notificationSelect"
                data={NOTIFICATIONS_TYPE_DROPDOWN}
                isMultiSelect={false}
                selectedValue={this.props.selectedNotificationType}
                handleMultiSelect={this.props.notificationSelectHandler}
              />
            </div>
            {this.props.notificationsLoader ? (
              <TableLoader
                width={screenWidth * 0.75}
                height={window.screen.height * 0.48}
              />
            ) : this.props.notificationsData &&
              this.props.notificationsData.length ? (
              getNotificationsTable({})
            ) : (
              <div className="dataNotFound">{MESSAGES.NO_DATA_FOUND}</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isEventsEnabled: state.EngagementDashboard.isEventsEnabled,

    eventsResponsesData: state.EngagementDashboard.eventsResponses.data,
    eventsResponsesLoader: state.EngagementDashboard.eventsResponses.loader,

    notificationsData: state.EngagementDashboard.notifications.data,
    notificationsLoader: state.EngagementDashboard.notifications.loader,
  };
};

export default connect(mapStateToProps)(InteractionEffectiveness);
