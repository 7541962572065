import React from "react";
import CountUp from "react-countup";

const formatingFunction = (value, props) => {
  if(!props.showDecimal) {
    let returnValue = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    if(props.suffix) {
      returnValue = `${returnValue}%`;
    }
    return returnValue;
  } else {
    let returnValue = props.value;
    if(props.ingoreRound && props.value /*&& props.value.toString().indexOf(".") > -1*/) {
      returnValue = Math.round((props.value) * 100) / 100;
      returnValue = (Math.round((returnValue) * 10) / 10);
      returnValue = returnValue.toFixed(1);
    }
    if(props.suffix) {
      returnValue = `${returnValue || 0}% ${props.absoluteValue? "("+props.absoluteValue+")" : ""}`;
    }

    if(props.currency) {
      returnValue = `$${returnValue || 0}`;
    }
    if(!isNaN(returnValue)) {
      returnValue = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    return  returnValue;
  }
};

const TilesComponent = props => {
  return (

    !props.showTopThree
      ? <div className={props.className ? `tileContainer  ${props.className}` : "tileContainer"}>
        <div className="percentContent">
          <div className="highlightNumber">

            <CountUp start={0} end={props.value} delay={0}
              suffix={props.suffix} duration={3} decimals={props.showDecimal ? props.decimalPoint : 0}
              formattingFn={(value) => formatingFunction(value, {...props, value})}
            >
              {({ countUpRef }) => (
                <div>
                  <span ref={countUpRef} />
                </div>
              )}
            </CountUp>

          </div>
          <div className="tileText">
            <p>
              {props.text}
            </p>
            {props.text1 ? 
            <p>
              {props.text1}
            </p> : null
            }
          </div>
        </div>
      </div>
      : <div className={props.className ? `tileContainer ${props.className}` : "tileContainer"}>
        <div className="listContent">
          <div className="title">
            {props.title}
          </div>
          <ul className="textlist">
            <li><div className="leftTitle">{props.pointOne}</div> <div className="rightValue">

              {props.valueOne
                ? <CountUp start={0}
                  end={props.valueOne}
                  delay={0}
                  duration={3}
                  decimals={props.showDecimal ? props.decimalPoint : 0}
                  formattingFn={(value) => formatingFunction(value, props)}

                >
                  {({ countUpRef }) => (
                    <div>
                      <span ref={countUpRef} />
                    </div>
                  )}
                </CountUp> : null}
            </div></li>
            <li><div className="leftTitle">{props.pointTwo}</div> <div className="rightValue">

              {props.valueTwo
                ? <CountUp
                  start={0}
                  end={props.valueTwo}
                  delay={0}
                  duration={3}
                  decimals={props.showDecimal ? props.decimalPoint : 0}
                  formattingFn={(value) => formatingFunction(value, props)}
                >
                  {({ countUpRef }) => (
                    <div>
                      <span style={{ color: props.valueTwo < 0 ? 'red' : ''}}  ref={countUpRef} />
                    </div>
                  )}
                </CountUp> : null}
            </div></li>
            <li><div className="leftTitle">{props.pointThree}</div> <div className="rightValue">

              {props.valueThree
                ? <CountUp
                  start={0}
                  end={props.valueThree}
                  delay={0} duration={3}
                  decimals={props.showDecimal ? props.decimalPoint : 0}
                  formattingFn={(value) => formatingFunction(value, props)}
                >
                  {({ countUpRef }) => (
                    <div>
                      <span style={{ color: props.valueThree < 0 ? 'red' : ''}} ref={countUpRef} />
                    </div>
                  )}
                </CountUp> : null}
            </div></li>
          </ul>
        </div>
      </div>

  );
};

export default TilesComponent;