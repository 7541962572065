/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import ComposedBarChart from "../../../../../../Widgets/Recharts/ComposedBarChart";
import BubbleChart from "../../../../../../Widgets/Recharts/d3/BubbleChart";
import HeatmapLoader from "../../../../../../Widgets/HeatMap/HeatmapLoader";
import * as EngagementActions from "../../Actions/EngagementActions";
import StackedBarChartLoader from "../../../../../../Widgets/StackedBarChart/StackedBarChartLoader";
import { GraphActions } from "../../../../../../UI";
import API from "../../../../../../Utilities/Api/Apis";
import { StackedBarChartVertical } from "../../../../../../Widgets";
import Switch from "react-switch";
import {
  ENGAGEMENTS,
  ENGAGEMENT_GRAPH_NAME,
  MESSAGES,
  THEME_COLORS,
} from "../../../../../../constants/constants";
import TabsWithContent from "../../../../../../UI/TabComponent/TabsWithContent";
import {
  generateFileName,
  toTitleCase,
} from "../../../../../../Utilities/common-utill";

// let API_ENDING_WITH= "";
const selectedBubbleDetails = {
  category_id: 0,
  category: "",
  point_source_ids: [],
};
let selectedColor = "#6fd8e5";

const selectedTopPointEarnerOrAwardersDetails = {
  name: "",
  user_id: "",
  department_id: "",
  category_id: "",
  unique_point_source: [],
};
class PointEarningBehaviourSection extends Component {
  bubbleClickedHandler = (selectedBubble, color) => {
    selectedColor = color ? color : "#6fd8e5";
    selectedBubbleDetails.category_id = selectedBubble.category_id;
    selectedBubbleDetails.category = selectedBubble.category;
    // const ids = selectedBubble.unique_point_source;
    selectedBubbleDetails.point_source_ids = selectedBubble.unique_point_source;
    // API_ENDING_WITH = selectedCategoryId + selectedCategory + selectedPointSourceIds;
    this.props.pointEarningCategoriesTrendReq({
      ...this.props.getFilterObject(),
      selectedBubbleDetails: { ...selectedBubbleDetails },
    });
  };

  clickTopPointsDetailsBar = (e) => {
    selectedTopPointEarnerOrAwardersDetails.name = e.name;
    selectedTopPointEarnerOrAwardersDetails.user_id = e.user_id;
    selectedTopPointEarnerOrAwardersDetails.department_id = e.department_id;
    selectedTopPointEarnerOrAwardersDetails.category_id = e.category_id;
    selectedTopPointEarnerOrAwardersDetails.unique_point_source =
      e.unique_point_source || [];
    let payload = {
      ...this.props.getFilterObject(),
      apiEndingWith: this.props.topPointEarnerOrAwarderDetails.selectedTabApi,
      selectedTopPointBarDetails: {
        ...selectedTopPointEarnerOrAwardersDetails,
      },
    };
    // CALL API only if single orgs or single depart is selected
    if (this.props.isSingleCmpOrDeprtSelected)
      this.props.topPointEarningDetailsRequest(payload);
  };

  handleExpandChart = (props) => {
    props.showEventModal({
      showEventModal: true,
      eventModalTitle: "",
      eventModalBody: "",
      showEventModalButtonOne: false,
      showEventModalButtonTwo: false,
      buttonOneEventModalHandler: () => props.hideEventModal(),
      buttonTwoEventModalHandler: null,
      buttonOneEventModalName: "",
      buttonTwoEventModalName: "",
      graphContent: props.graph,
      graphTitle: props.graphTitle,
      graphSubTitle: props.graphSubTitle,
      showGraph: true,
      graphName: "Bar Chart",
      graphId: "",
    });
  };
  render() {
    let screenWidth = window.screen.width / 2;
    const pointEarningTrendChartFlag =
      !this.props.pointEarningCategoriesTrendDataLoader &&
      this.props.pointEarningCategoriesTrendData &&
      this.props.pointEarningCategoriesTrendData.length;

    const selectPointEarnerTabName = Object.keys(
      this.props.topPointEarnerSwitch,
    ).filter((k) => this.props.topPointEarnerSwitch[k] === true);

    const fileNameForSelectedTabTopPoints = selectPointEarnerTabName.length
      ? ENGAGEMENTS.TOP_POINT_EARNING_AWARDING_TABS[selectPointEarnerTabName[0]]
          .tabName
      : ENGAGEMENTS.TOP_POINT_EARNING_AWARDING_TABS.byCategory.tabName;

    const pointEarnerFileName = generateFileName(
      `${ENGAGEMENT_GRAPH_NAME.TOP_POINT}-${
        this.props.isPointEarnerActive ? "earners" : "awarders"
      }-${fileNameForSelectedTabTopPoints}`,
    );

    const topPointEarnerExportFlag =
      !this.props.topPointEarningDataLoader &&
      this.props.topPointEarningData &&
      this.props.topPointEarningData.length &&
      ((this.props.topPointEarnerSwitch.byDepartment &&
        this.props.isSingleCmpOrDeprtSelected) ||
        !this.props.topPointEarnerSwitch.byDepartment);

    const topPointEarnerDetailsFlag =
      this.props.isSingleCmpOrDeprtSelected &&
      this.props.topPointEarningDetailsData &&
      this.props.topPointEarningDetailsData.length &&
      !this.props.topPointEarningDetailsDataLoader;

    const graphBubbleChart = (
      graphName,
      width = screenWidth * 0.75,
      height = window.screen.height * 0.49,
    ) => (
      <BubbleChart
        bubbleChartData={this.props.pointEarningCategoriesData}
        width={width}
        height={height}
        margin={{ top: 40, right: 60, left: 60, bottom: 65 }}
        graphName={graphName}
        chartDetails={{
          axisLabel: {
            xAxis: "# of Point Earning Transactions",
            yAxis: "# of Unique Users",
            zAxis: "Total # of Points Earned",
          },
        }}
        bubbleClickedEvent={this.bubbleClickedHandler}
      ></BubbleChart>
    );

    const getPontEarningCategoryTrendTitle = () => {
      let title = ENGAGEMENT_GRAPH_NAME.POINT_EARNING_CATEGORY_TREND;
      if (
        this.props.showPointEarningTrendChart &&
        selectedBubbleDetails.category &&
        selectedBubbleDetails.category !== ""
      ) {
        const name = toTitleCase(
          selectedBubbleDetails.category.replace(/\./g, ""),
        );

        title = `${name} Points Trend`;
      }
      return title;
    };

    const graphPtEarningCatTrend = (
      width = screenWidth * 0.75,
      height = window.screen.height * 0.45,
    ) => (
      <ComposedBarChart
        width={width}
        height={height}
        margin={{ top: 40, right: 50, left: 15, bottom: 40 }}
        composedBarChartData={this.props.pointEarningCategoriesTrendData}
        chartDetails={{
          xKey: "month",
          leftYKey: "points",
          rightYKey: "user_count",
          barKey: "points",
          lineKey: "user_count",
          dataLength: this.props.pointEarningCategoriesTrendData.length,
          axisLabel: {
            yLeft: "Total # of Points Earned",
            yRight: "# of Unique Users",
            line: "# of Unique Users",
            bar: "Total # of Points Earned",
          },
          lineStroke: "#185f7d",
          barStroke: selectedColor,
        }}
      ></ComposedBarChart>
    );

    const topPointEarner = ({
      width = screenWidth * 0.75,
      height = window.screen.height * 0.45,
      breakTextLength = 20,
      barColor = this.props.selectedColorForTopPoints,
      marginLeft = 120,
    }) => (
      <StackedBarChartVertical
        width={width}
        height={height}
        breakTextLength={breakTextLength}
        xAxisNames={["points"]}
        data={this.props.topPointEarningData}
        margin={{ top: 40, right: 50, left: marginLeft, bottom: 70 }}
        colors={[barColor]}
        showRightYAxis={false}
        expanded={false}
        isOnClickEvent={true}
        clickHandler={this.clickTopPointsDetailsBar}
        showActiveBar={true}
        activeBarColor={"#c4c096"}
        xAxisNameId={"name"}
        skipLegend={true}
        xAxisAngle={0}
        textAnchor="middle"
        // yAxisLabel=""
        xAxisLabel={`# of Points ${
          this.props.isPointEarnerActive ? "Earning" : "Awarding"
        }`}
        showBrush={this.props.topPointEarningData.length > 10}
        brushWidth={width}
      />
    );

    const topPointEarnerDetailsCharts = ({
      width = screenWidth * 0.75,
      height = window.screen.height * 0.45,
      breakTextLength = 20,
      barColor = this.props.selectedColorForTopPoints,
      marginLeft = 120,
    }) => {
      const colorsMapping = {
        "By Category":
          ENGAGEMENTS.TOP_POINT_EARNING_AWARDING_TABS.byCategory.chartColor,
        "By Department":
          ENGAGEMENTS.TOP_POINT_EARNING_AWARDING_TABS.byDepartment.chartColor,
        "By User":
          ENGAGEMENTS.TOP_POINT_EARNING_AWARDING_TABS.byUser.chartColor,
      };
      const firstTab = this.props.topPointEarningDetailsData[0].name;
      const firstTabChartColor = "#c4c096"; // colorsMapping[firstTab];

      const secondTab = this.props.topPointEarningDetailsData[1].name;
      const secondTabChartColor = "#c4c096"; // colorsMapping[secondTab];

      const firstBreakDown = (
        <StackedBarChartVertical
          width={width}
          height={height}
          breakTextLength={breakTextLength}
          xAxisNames={["points"]}
          data={this.props.topPointEarningDetailsData[0].data}
          margin={{ top: 40, right: 50, left: marginLeft, bottom: 70 }}
          colors={[firstTabChartColor]}
          showRightYAxis={false}
          expanded={false}
          xAxisNameId={"name"}
          skipLegend={true}
          xAxisAngle={0}
          textAnchor="middle"
          // yAxisLabel=""
          xAxisLabel={`# of Points ${
            this.props.isPointEarnerActive ? "Earning" : "Awarding"
          }`}
          showBrush={this.props.topPointEarningDetailsData[0].data.length > 12}
          brushWidth={width}
        />
      );

      const secondBreakDown = (
        <StackedBarChartVertical
          width={width}
          height={height}
          breakTextLength={breakTextLength}
          xAxisNames={["points"]}
          data={this.props.topPointEarningDetailsData[1].data}
          margin={{ top: 40, right: 50, left: marginLeft, bottom: 70 }}
          colors={[secondTabChartColor]}
          showRightYAxis={false}
          expanded={false}
          xAxisNameId={"name"}
          skipLegend={true}
          xAxisAngle={0}
          textAnchor="middle"
          // yAxisLabel=""
          xAxisLabel={`# of Points ${
            this.props.isPointEarnerActive ? "Earning" : "Awarding"
          }`}
          showBrush={this.props.topPointEarningDetailsData[1].data.length > 12}
          brushWidth={width}
        />
      );
      return (
        <TabsWithContent
          tabsList={[firstTab, secondTab]}
          htmlContents={[firstBreakDown, secondBreakDown]}
        />
      );
    };

    const getPointEarningAwardingBreakdownTitle = () => {
      const selectedBar = { ...selectedTopPointEarnerOrAwardersDetails };
      let title = `Point ${
        this.props.isPointEarnerActive ? "Earning" : "Awarding"
      } Breakdown`;
      if (
        selectedBar.name &&
        selectedBar !== "" &&
        this.props.showTopPointEarnerDetails
      ) {
        const name = toTitleCase(selectedBar.name.replace(/\./g, ""));
        console.log("name", name);
        title = `${name} - Points ${
          this.props.isPointEarnerActive ? "Earning" : "Awarding"
        } Breakdown`;
      }
      return title;
    };

    return (
      <div className="budgetUtilization">
        <div className="row">
          <div className="col-sm-12">
            <div className="titleOuter">
              <h2>{ENGAGEMENT_GRAPH_NAME.POINT_EARNING_BEHAVIOUR}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-xl-6 mb-150">
            <div className="graphsActionContainer">
              <h3>{ENGAGEMENT_GRAPH_NAME.POINT_EARNING_CATEGORIES}</h3>
              <div className="exportContainer">
                <GraphActions
                  showExpansion={
                    this.props.pointEarningCategoriesData &&
                    this.props.pointEarningCategoriesData.length
                  }
                  showCSVExport={
                    this.props.pointEarningCategoriesData &&
                    this.props.pointEarningCategoriesData.length
                  }
                  filterObjectForCSV={this.props.getFilterObject}
                  showImageExport={
                    this.props.pointEarningCategoriesData &&
                    this.props.pointEarningCategoriesData.length
                  }
                  showInfo={true}
                  from={generateFileName(
                    ENGAGEMENT_GRAPH_NAME.POINT_EARNING_CATEGORIES,
                  )}
                  apiUrl={API.ENGAGEMENTS.POINT_EARNING_CATEGORIES}
                  // parameter={this.props.appreciationExchangeFrequency}
                  graphId="pointEarningBubbleChart"
                  imageName={generateFileName(
                    ENGAGEMENT_GRAPH_NAME.POINT_EARNING_CATEGORIES,
                  )}
                  infoMessage="Use this chart to compare the point allocation across categories in your company. If users have not earned any points for a certain category during the filtered period, that category will not be displayed."
                  handleExpand={(graphId) =>
                    this.handleExpandChart({
                      ...this.props,
                      graphTitle: ENGAGEMENT_GRAPH_NAME.POINT_EARNING_BEHAVIOUR,
                      graphSubTitle:
                        ENGAGEMENT_GRAPH_NAME.POINT_EARNING_CATEGORIES,
                      screenWidth: screenWidth,
                      graph: graphBubbleChart(
                        "point_earning_categories_expand",
                        screenWidth * 0.9,
                        window.screen.height * 0.5,
                      ),
                    })
                  }
                />
              </div>
            </div>
            <div className="graphInfoNote">
              <span>Note: </span> The size of the bubble corresponds to the
              number of points earned for that category for the current
              selection.
            </div>
            <div id="pointEarningBubbleChart">
              {!this.props.pointEarningCategoriesDataLoader ? (
                this.props.pointEarningCategoriesData &&
                this.props.pointEarningCategoriesData.length ? (
                  graphBubbleChart("point_earning_categories")
                ) : (
                  <div className="dataNotFound">{MESSAGES.NO_DATA_FOUND}</div>
                )
              ) : (
                <HeatmapLoader
                  width={screenWidth * 0.75}
                  height={window.screen.height * 0.4}
                ></HeatmapLoader>
              )}
            </div>
          </div>
          <div className="col-md-12 col-xl-6 col-lg1-12 mb-150">
            <div className="graphsActionContainer">
              <h3>{getPontEarningCategoryTrendTitle()}</h3>
              <GraphActions
                showExpansion={pointEarningTrendChartFlag}
                showCSVExport={pointEarningTrendChartFlag}
                filterObjectForCSV={this.props.getFilterObject}
                postPayload={{
                  selectedBubbleDetails: { ...selectedBubbleDetails },
                }}
                showImageExport={pointEarningTrendChartFlag}
                showInfo={true}
                from={getPontEarningCategoryTrendTitle()}
                apiUrl={`${API.ENGAGEMENTS.POINT_EARNING_CATEGORIES_TREND}`}
                // parameter={this.props.appreciationExchangeFrequency}
                graphId="PointEarningTrendBarChart"
                imageName={getPontEarningCategoryTrendTitle()}
                infoMessage="Use this chart to drill down into the trend of the characteristics of your selected point-earning category."
                handleExpand={(graphId) =>
                  this.handleExpandChart({
                    ...this.props,
                    graphTitle: ENGAGEMENT_GRAPH_NAME.POINT_EARNING_BEHAVIOUR,
                    graphSubTitle: getPontEarningCategoryTrendTitle(),
                    screenWidth: screenWidth,
                    graph: graphPtEarningCatTrend(
                      window.innerWidth * 0.8,
                      window.screen.height * 0.5,
                    ),
                  })
                }
              />
            </div>
            <div className="graphInfoNote">
              <span>Note: </span> Please select a particular category/bubble
              from the chart on the left to view a trend of of its performance.
            </div>
            <div id="PointEarningTrendBarChart">
              {this.props.showPointEarningTrendChart ? (
                this.props.pointEarningCategoriesTrendDataLoader ? (
                  <StackedBarChartLoader
                    width={screenWidth * 0.75}
                    height={window.screen.height * 0.46}
                  />
                ) : this.props.pointEarningCategoriesTrendData &&
                  this.props.pointEarningCategoriesTrendData.length ? (
                  graphPtEarningCatTrend(
                    screenWidth * 0.8,
                    window.screen.height * 0.52,
                  )
                ) : (
                  <div className="dataNotFound">{MESSAGES.NO_DATA_FOUND}</div>
                )
              ) : (
                <div className="dataNotFound">{MESSAGES.PENDING_SELECTION}</div>
              )}
            </div>
          </div>

          <div className="col-md-12 col-xl-6 col-lg1-12 mb-120">
            <div className="graphsActionContainer">
              <div className=" d-flex align-items-center">
                <h3 className="mr-2">
                  {ENGAGEMENT_GRAPH_NAME.TOP_POINT + " "}
                  {this.props.isPointEarnerActive ? "Earning" : "Awarding"}
                </h3>
                {this.props.topPointHasIsP2P ? (
                  <div className="switchBtnouter">
                    <label className="m-0">
                      <Switch
                        onChange={() =>
                          this.props.topPointEarnerOrAwarderSwitchHandler()
                        }
                        checked={!this.props.isPointEarnerActive}
                        uncheckedIcon={null}
                        checkedIcon={null}
                        onColor={"#888888"}
                      />
                    </label>
                  </div>
                ) : null}
              </div>
              <GraphActions
                showExpansion={topPointEarnerExportFlag}
                showCSVExport={topPointEarnerExportFlag}
                filterObjectForCSV={this.props.getFilterObject}
                showImageExport={topPointEarnerExportFlag}
                showInfo={true}
                from={pointEarnerFileName}
                apiUrl={`${API.ENGAGEMENTS.TOP_POINT_EARNING}${this.props.topPointEarnerApiEndingWith}`}
                // parameter={this.props.appreciationExchangeFrequency}
                graphId="topPointEarning"
                imageName={pointEarnerFileName}
                infoMessage={`If user(s) have not ${
                  this.props.isPointEarnerActive ? "earned" : "awarded"
                } points for a certain user/department/category, it will not be displayed. Export to csv for a full list of points awarded by individual users/departments/categories.`}
                handleExpand={(graphId) =>
                  this.handleExpandChart({
                    ...this.props,
                    graphTitle: ENGAGEMENT_GRAPH_NAME.POINT_EARNING_BEHAVIOUR,
                    graphSubTitle: `${ENGAGEMENT_GRAPH_NAME.TOP_POINT} ${
                      this.props.isPointEarnerActive ? "Earning" : "Awarding"
                    }`,
                    screenWidth: screenWidth,
                    graph: topPointEarner({
                      width: window.innerWidth * 0.8,
                      height: window.screen.height * 0.58,
                      breakTextLength: 40,
                      marginLeft: 200,
                    }),
                  })
                }
              />
            </div>
            <div className="graphInfoNote">
              <span>Note: </span> Please select a particular
              category/department/user for a breakdown of its usage patterns.
            </div>
            <div className="tabContainer">
              <div className="tablinkouter">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        this.props.topPointEarnerSwitch.byCategory
                          ? "active"
                          : ""
                      }`}
                      role="tab"
                      data-toggle="tab"
                      onClick={() =>
                        this.props.topPointEarnerHandler(
                          ENGAGEMENTS.TOP_POINT_EARNING_AWARDING_TABS.byCategory
                            .name,
                        )
                      }
                    >
                      Categories
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        this.props.topPointEarnerSwitch.byDepartment
                          ? "active"
                          : ""
                      }`}
                      role="tab"
                      data-toggle="tab"
                      onClick={() =>
                        this.props.topPointEarnerHandler(
                          ENGAGEMENTS.TOP_POINT_EARNING_AWARDING_TABS
                            .byDepartment.name,
                        )
                      }
                    >
                      Departments
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        this.props.topPointEarnerSwitch.byUser ? "active" : ""
                      }`}
                      role="tab"
                      data-toggle="tab"
                      onClick={() =>
                        this.props.topPointEarnerHandler(
                          ENGAGEMENTS.TOP_POINT_EARNING_AWARDING_TABS.byUser
                            .name,
                        )
                      }
                    >
                      Users
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div id="">{topPointEarner()}</div> */}
            <div id="topPointEarning">
              {this.props.topPointEarnerSwitch.byDepartment &&
              !this.props.isSingleCmpOrDeprtSelected ? (
                <div className="dataNotFound">{MESSAGES.PENDING_SELECTION}</div>
              ) : !this.props.topPointEarningDataLoader ? (
                this.props.topPointEarningData &&
                this.props.topPointEarningData.length ? (
                  topPointEarner({})
                ) : (
                  <div className="dataNotFound">{MESSAGES.NO_DATA_FOUND}</div>
                )
              ) : (
                <StackedBarChartLoader
                  width={screenWidth * 0.75}
                  height={window.screen.height * 0.4}
                />
              )}
            </div>
          </div>
          <div className="col-md-12 col-xl-6 col-lg1-12 mb-120">
            <div className="graphsActionContainer">
              <h3>{getPointEarningAwardingBreakdownTitle()}</h3>
              <GraphActions
                showExpansion={topPointEarnerDetailsFlag}
                showCSVExport={topPointEarnerDetailsFlag}
                filterObjectForCSV={this.props.getFilterObject}
                postPayload={{
                  selectedTopPointBarDetails: {
                    ...selectedTopPointEarnerOrAwardersDetails,
                  },
                }}
                showImageExport={topPointEarnerDetailsFlag}
                showInfo={true}
                from={getPointEarningAwardingBreakdownTitle()}
                apiUrl={`${API.ENGAGEMENTS.TOP_POINT_EARNING}${this.props.topPointEarnerApiEndingWith}&is_break_down=true`}
                // parameter={this.props.appreciationExchangeFrequency}
                graphId="TopPointEarnerDetails"
                imageName={getPointEarningAwardingBreakdownTitle()}
                infoMessage={`This displays the detailed breakdown of point-${
                  this.props.isPointEarnerActive ? "earning" : "awarded"
                } behaviour for the chosen category, department, or user.`}
                handleExpand={(graphId) =>
                  this.handleExpandChart({
                    ...this.props,
                    graphTitle: ENGAGEMENT_GRAPH_NAME.POINT_EARNING_BEHAVIOUR,
                    graphSubTitle: getPointEarningAwardingBreakdownTitle(),
                    screenWidth: screenWidth,
                    graph: topPointEarnerDetailsCharts({
                      width: window.innerWidth * 0.8,
                      height: window.screen.height * 0.52,
                      breakTextLength: 40,
                      marginLeft: 200,
                    }),
                  })
                }
              />
            </div>
            <div className="graphInfoNote">
              <span>Note: </span>
              {this.props.isSingleCmpOrDeprtSelected
                ? `Please select a bar from the chart on the left for further breakdowns 
                of the point ${
                  this.props.isPointEarnerActive ? "earning" : "awarded"
                } behavior.`
                : "Please select a particular company or department in the top filter."}
            </div>
            <div id="TopPointEarnerDetails">
              {this.props.isSingleCmpOrDeprtSelected &&
              this.props.showTopPointEarnerDetails ? (
                this.props.topPointEarningDetailsDataLoader ? (
                  <StackedBarChartLoader
                    width={screenWidth * 0.75}
                    height={window.screen.height * 0.46}
                  />
                ) : this.props.topPointEarningDetailsData &&
                  this.props.topPointEarningDetailsData[0].data.length ? (
                  topPointEarnerDetailsCharts({})
                ) : (
                  <div className="dataNotFound">{MESSAGES.NO_DATA_FOUND}</div>
                )
              ) : (
                <div className="dataNotFound">{MESSAGES.PENDING_SELECTION}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pointEarningCategoriesData:
      state.EngagementDashboard.pointEarningCategoriesData.data,
    pointEarningCategoriesDataLoader:
      state.EngagementDashboard.pointEarningCategoriesData.loader,

    showPointEarningTrendChart:
      state.EngagementDashboard.pointEarningCategoriesTrendData.showChart,
    pointEarningCategoriesTrendData:
      state.EngagementDashboard.pointEarningCategoriesTrendData.data,
    pointEarningCategoriesTrendDataLoader:
      state.EngagementDashboard.pointEarningCategoriesTrendData.loader,

    topPointEarningData: state.EngagementDashboard.topPointEarningData.data,
    topPointEarningDataLoader:
      state.EngagementDashboard.topPointEarningData.loader,

    topPointEarningDetailsData:
      state.EngagementDashboard.topPointEarningDetailsData.data,
    topPointEarningDetailsDataLoader:
      state.EngagementDashboard.topPointEarningDetailsData.loader,
    showTopPointEarnerDetails:
      state.EngagementDashboard.topPointEarningDetailsData.show,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    pointEarningCategoriesTrendReq: (data) =>
      dispatch(
        EngagementActions.engagementPointEarningCategoriesTrendReq(data),
      ),

    topPointEarningDetailsRequest: (data) =>
      dispatch(EngagementActions.engagementTopPointEarningDetailsReq(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PointEarningBehaviourSection);
