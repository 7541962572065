import React from "react";
import * as d3 from "d3";
import { THEME_COLORS } from "../../constants/constants";

class timeofRedemptionGroupBarchartD3 extends React.Component {
    componentDidMount() {
        this.loadChart();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.data.length !== nextProps.data.length) {
            this.loadChart();
        }
    }

    componentDidUpdate() {
        this.loadChart();
    }

    loadChart = () => {
        d3.select(this.node).selectAll("svg").remove();

        let data = this.props.data, maxValue = 0;
        maxValue = this.props.XmaxValue

        let isExpandScreen = this.props.expandScreen;
        //chart setup
        let svg = d3.select(this.node).append("svg")
            .attr("width", this.props.width)
            .attr("height", this.props.height),
            margin = { top: 40, right: 50, bottom: 80, left: 60 },
            width = this.props.width - margin.left - margin.right,
            height = this.props.height - margin.top - margin.bottom,
            g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        let div = d3.select(this.node).append("div")
            .attr("class", "tooltip")
            .style("opacity", 0);

        //y position calculation function
        let y = d3.scaleLinear()
            .domain([0, maxValue + 1])
            .range([height, 0]);

        let x0 = d3.scaleBand()  // domain defined below
            .rangeRound([0, width])
            .paddingInner(0)
            .paddingOuter(0);

        let x1 = d3.scaleBand()  // domain and range defined below
            .paddingOuter(0.25)
            .paddingInner(0.15);

        let z = d3.scaleOrdinal().range([THEME_COLORS[3], THEME_COLORS[23], THEME_COLORS[12]]);

        const yAxis = d3.axisLeft(y).ticks(5);

        let subCategories = Object.keys(data[0])

        x0.domain(["Time Of Redemptions"]);

        x1.domain(subCategories).rangeRound([0, x0.bandwidth()])

        // // Add bar chart
        let selection = g.selectAll("g")
            .data(data)
            .enter().append("g")
            .attr("transform", d => "translate(" + 10 + ",0)");

        selection.selectAll("rect")
            .data(function (d) {
                return subCategories.map(
                    function (key) {
                        return {

                            key: key, value: d[key], ...d
                        };
                    });
            })
            .enter().append("rect")
            .attr("x", d => x1(d.key))
            .attr("y", d => (d.value < 0 ? y(0) : y(d.value)))
            .attr("width", x1.bandwidth())
            .attr("height", d => Math.abs(y(d.value) - y(0)))
            .attr("fill", d => z(d.key))
            .on("mousemove", onMouseOver)
            .on("mouseout", onMouseOut);

        function onMouseOver(d, i) {
            let e = 0, x = 0, y = 0;
            if (isExpandScreen) {
                e = window.event, x = e.clientX - margin.left, y = e.clientY - 90;
            } else {
                e = window.event, x = e.clientX, y = e.clientY - 40;

            }

            let offsets = document.getElementById('timeOfRedemption').getBoundingClientRect();
            div.transition().duration(200).style("opacity", 1.0);

            div.html(`<span style="color:${THEME_COLORS[3]}">Avg # of Weekday Redemptions per Week: ${d.weekday ? d.weekday.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : 0} </span><br/>
                        <span style="color:${THEME_COLORS[23]}">Avg # of Weekend Redemptions per Week: ${d.weekend ? d.weekend.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : 0}</span>`)
                .style("top", `${isExpandScreen ? y - 50 : y - offsets.top - 50}px`)
                .style("left", `${isExpandScreen ? x - 100 : x - offsets.left - 50}px`);
        }

        function onMouseOut(d, i) {
            div.transition().duration(500).style("opacity", 0);
        }

        //add the x-axis
        g.append("g")
            .attr("class", "axis")
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(x0))
            .selectAll(".tick text")
            .call(wrap, x0.bandwidth());

        g.append('g')
            .call(yAxis);

        function wrap(text, width) {
            text.each(function () {
                let text = d3.select(this),
                    words = text.text().split(/\s+/).reverse(),
                    word,
                    line = [],
                    lineNumber = 0,
                    lineHeight = 1.1, // ems
                    y = text.attr("y"),
                    dy = parseFloat(text.attr("dy")),
                    tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");
                while (word = words.pop()) {
                    line.push(word);
                    tspan.text(line.join(" "));
                    if (tspan.node().getComputedTextLength() > width) {
                        line.pop();
                        tspan.text(line.join(" "));
                        line = [word];
                        tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
                    }
                }
            });
        }

        svg.selectAll(".tick").selectAll('text')
            .style("fill", "#758088")
            .style("font-size", 12)
            .style("font-weight", 700)
            .style("font-family", "roboto");
    }

    render() {
        let subCategories = this.props.data && this.props.data.length > 0 ? Object.keys(this.props.data[0]) : null;
        let colorCodes = [THEME_COLORS[3], THEME_COLORS[23], THEME_COLORS[12]];      
        return (
            <div id="timeOfRedemption" class="pointsEarnedAndRedeemed" style={{ border: "1px", borderColor: "black" }} ref={(node) => this.node = node}>
                <div className="legendContainer">
                    {subCategories
                        ? <div className="sunbustLegend">
                            {
                                subCategories.map((entry, index) => {
                                    return (<span className="legendItem" style={{ display: "inline-block", marginRight: "10px" }} key={`item-${index}`}
                                    > <label style={{ backgroundColor: `${colorCodes[index]}`, width: "15px", height: "12px", margin: "3px 3px 0px 3px", fontSize: "15px", paddingLeft: "0px", color: "#595959", fontFamily: "roboto", fontWeight: "500" }} ></label>
                                        <label style={{ margin: "0px", fontSize: "12", fontWeight: "400",/* color: "#758088",*/fontFamily: "roboto" }}>Avg # of {entry === 'weekday' ? "Weekday" : "Weekend"} Redemptions per Week</label></span>);
                                })
                            }
                        </div>
                        : <div className="dataNotFound">
                            No Legends Found
                </div>
                    }
                </div>
            </div>
        )
    }
}

export default timeofRedemptionGroupBarchartD3;