import React, { Fragment } from "react";
import * as d3 from "d3";
import { CHART, THEME_COLORS } from ".././../../constants/constants";

const COLOR_CODE = [
  ...THEME_COLORS,
  ...THEME_COLORS,
  ...THEME_COLORS,
  ...THEME_COLORS,
];

// Heatmap Chart
class CustomizedLineChart extends React.Component {
  componentDidMount() {
    this.loadChart();
  }

  componentDidUpdate() {
    d3.select(this.node).select("svg").remove();
    this.loadChart();
  }

  loadChart = () => {
    const xKey = this.props.xDataKey;
    const yKey = this.props.yDataKey;

    const avgLineName = this.props.avgLineName;
    const avgLineColor = this.props.avgLineColor;
    const averageLineLabel = this.props.averageLineLabel;

    // custom line stroke only for avg lines
    const avgLineStroke = () => {
      d3.selectAll(`#${this.props.avgLineUniqueId}`).style(
        "stroke-width",
        this.props.avgLineStroke,
      );
    };

    let data = this.props.data;

    let avgLineData = [];
    if (this.props.data[0].name === avgLineName) {
      avgLineData = this.props.data[0].values;
    }

    let width = this.props.width;
    let height = this.props.height;
    let graphName = this.props.graphName;

    let isExpand = this.props.isExpand;

    let margin = 50,
      duration = 250;

    let defaultStrokeWidth = 2;
    let lineOpacity = "1";
    let lineOpacityHover = "0.85";
    let otherLinesOpacityHover = "0";
    let lineStroke = "3px";
    let lineStrokeHover = "3.5px";
    let circleOpacity = "0.85";
    let circleOpacityOnLineHover = "0.25";
    let circleRadius = 5;
    let circleRadiusHover = 6;

    let x = d3.scaleBand().rangeRound([0, width - margin]);

    let years = data[0].values.map((d) => {
      return d[xKey];
    });
    let names = data.map((d) => {
      return d.name;
    });

    x.domain(years);

    // Normalising the data
    data.forEach((d) => {
      const values = {};
      d.values.forEach((v) => {
        values[v.period] = v.value;
      });

      const dValues = [];
      for (let i = 0; i < years.length; i++) {
        const year = years[i];
        dValues.push({
          name: d.name,
          period: year,
          value: values[year] || 0,
        });
      }
      d.values = dValues;
    });

    var xScale = d3
      .scaleLinear()
      .domain([0, data[0].values.length]) // input
      .range([0, width - margin]); // output

    let yScale = d3
      .scaleLinear()
      .domain([0, this.props.yAxisMaxValue])
      .range([height - margin, 0]);

    // let color = d3.scaleOrdinal(THEME_COLORS);

    /* Add SVG */
    let svg = d3
      .select(this.node)
      .append("svg")
      .attr("width", width + margin + "px")
      .attr("height", height + margin + "px")
      .append("g")
      .attr("transform", `translate(${margin}, ${margin})`);

    if (avgLineData) {
      svg
        .append("path")
        .datum(avgLineData)
        .attr("fill", "#D3D3D3")
        .attr("fill-opacity", 0.6)
        .attr("stroke", "none")
        .attr("class", "yogesh")
        .attr(
          "d",
          d3
            .area()
            .x(function (d, i) {
              return xScale(i + 0.5 - i * 0.005);
            })
            .y0(height - margin)
            .y1(function (d) {
              return yScale(d.value);
            }),
        );
    }

    /* Add line into SVG */
    let line = d3
      .line()
      .defined(function (d) {
        return d.value;
      })
      .x(function (d, i) {
        return xScale(i + 0.5 - i * 0.005);
      })
      .y((d) => yScale(d[yKey]));

    let lines = svg.append("g").attr("class", "lines");

    lines
      .selectAll(".line-group")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "line-group")
      .attr("transform", `none`)
      .on("mouseover", function (d, i) {
        svg
          .append("text")
          .attr("class", "title-text")
          .style("fill", () => {
            let colorIndex = names.findIndex((name) => name === d.name);

            return d.name === avgLineName
              ? avgLineColor
              : COLOR_CODE[colorIndex];
          })
          .text(d.name === avgLineName ? averageLineLabel : d.name)
          .attr("text-anchor", "middle")
          .attr("x", (width - margin) / 2)
          .attr("y", -10);
      })
      .on("mouseout", function (d) {
        svg.select(".title-text").remove();
      })
      .append("path")
      .attr("class", "d3-line")
      .attr("d", (d) => line(d.values))
      .attr("id", (d) =>
        d.name === avgLineName ? this.props.avgLineUniqueId : "",
      )
      .style("stroke", (d, i) =>
        d.name === avgLineName ? avgLineColor : COLOR_CODE[i],
      )
      .style("stroke-width", defaultStrokeWidth)
      .style("opacity", lineOpacity)
      .style("fill", "none")
      .on("mouseover", function (d) {
        d3.selectAll(".d3-line").style("opacity", otherLinesOpacityHover);
        d3.selectAll(".circle").style("opacity", circleOpacityOnLineHover);
        d3.select(this)
          .style("opacity", lineOpacityHover)
          .style("stroke-width", lineStrokeHover)
          .style("cursor", "pointer");
        avgLineStroke();
      })
      .on("mouseout", function (d) {
        d3.selectAll(".d3-line").style("opacity", lineOpacity);
        d3.selectAll(".circle").style("opacity", circleOpacity);
        d3.select(this)
          .style("stroke-width", defaultStrokeWidth)
          .style("cursor", "none");
        avgLineStroke();
      });

    /* Add circles in the line */

    lines
      .selectAll("circle-group")
      .data(data)
      .enter()
      .append("g")
      .style("fill", (d, i) =>
        d.name === avgLineName ? avgLineColor : COLOR_CODE[i],
      )
      .selectAll("circle")
      .data((d) => d.values)
      .enter()
      .append("g")
      .attr("class", "circle")
      .on("mouseover", function (d, index) {
        if (d[yKey]) {
          let div = d3
            .select(`#tooltip-${graphName}${isExpand}`)
            .attr("class", "redemption-tooltip")
            .style("opacity", 0);

          let e = 0,
            x = 0,
            y = 0;
          (e = window.event), (x = e.clientX), (y = e.clientY - 40);

          let colorIndex = names.findIndex((name) => name === d.name);

          const color =
            d.name === avgLineName ? avgLineColor : COLOR_CODE[colorIndex];

          div.transition().duration(200).style("opacity", 1.0);
          div
            .html(
              `Period: ${d[xKey]} <br/>
            <span style="color:${color}"> Name: ${
                d.name === avgLineName ? averageLineLabel : d.name
              } </span><br/>
            <span style="color:${color}"> % of Unique Users : ${d[yKey].toFixed(
                1,
              )} </span><br/>`,
            )
            .style("top", `${y - 50}px`)
            .style("left", `${x - 100}px`);
        }
      })
      .on("mouseout", function (d) {
        let div = d3.select(`#tooltip-${graphName}${isExpand}`);
        div.transition().duration(500).style("opacity", 0);
      })
      .append("circle")
      // .attr("cx", d => xScale(d[xKey]))
      .attr("cx", function (d, i) {
        return xScale(i + 0.5 - i * 0.005);
      })
      .attr("cy", (d) => yScale(d[yKey]))
      .attr("r", (d) => (d[yKey] ? circleRadius : 0))
      .style("opacity", (d) => (d[yKey] ? circleOpacity : 0))
      .on("mouseover", function (d) {
        d3.select(this)
          .transition()
          .duration(duration)
          .attr("r", circleRadiusHover);
      })
      .on("mouseout", function (d) {
        d3.select(this).transition().duration(duration).attr("r", circleRadius);
      });

    /* Add Axis into SVG */
    let yAxis = d3.axisLeft(yScale).ticks(10);

    let XAxisElement = svg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0, ${height - margin})`)
      .call(d3.axisBottom(x));

    let YAxisElement = svg
      .append("g")
      .attr("class", "y-axis")
      .call(yAxis)
      .append("text")
      .attr("y", 15)
      .attr("transform", "rotate(-90)")
      .attr("fill", "#000");
    // .text("Total values")
    XAxisElement.selectAll(".tick")
      .selectAll("text")
      .attr("transform", "rotate(-45)");
    svg
      .selectAll(".x-axis")
      .selectAll(".tick")
      .selectAll("text")
      .attr("x", "-15")
      .attr("y", "15")
      .style("font-size", 12)
      .style("font-weight", CHART.fontWeight)
      .style("fill", "#758088")
      .style("font-family", CHART.fontFamily);
    svg
      .selectAll(".y-axis")
      .selectAll(".tick")
      .selectAll("text")
      .attr("x", "-10")
      .attr("y", "-0")
      .style("font-size", 12)
      .style("font-weight", CHART.fontWeight)
      .style("fill", "#758088")
      .style("font-family", CHART.fontFamily);

    svg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -margin)
      .attr("x", 0 - height / 2.4)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .style("font-family", CHART.fontFamily)
      .style("font-weight", CHART.fontWeight)
      .style("font-size", 14)
      .style("fill", "#758088")
      .text(this.props.yAxisLabel);

    avgLineStroke();
  };

  render() {
    let graphName = this.props.graphName,
      isExpand = this.props.isExpand;
    let counter = 0;
    return (
      <div
        /*id="ageOfRedemptionTrend"*/ className="d-flex"
        style={{ fontFamily: CHART.fontFamily, fontWeight: CHART.fontWeight }}
      >
        <div
          ref={(node) => {
            this.node = node;
          }}
          style1={{ float: "left" }}
        >
          <div>
            <div id={`tooltip-${graphName}${isExpand}`}></div>
          </div>
        </div>

        <div className="custom-legends" style1={{ float: "left" }}>
          {this.props.data ? (
            <ul
              className="strengthName scroll-auto"
              style={{
                flexWrap: "wrap",
                height: this.props.height * 0.75,
                alignContent: "left",
                float: "left",
                textAlign: "left",
              }}
            >
              {this.props.data.map((entry, index) => {
                const labelValue =
                  entry.name === this.props.avgLineName
                    ? this.props.averageLineLabel
                    : entry.name;

                const boxColor =
                  entry.name === this.props.avgLineName
                    ? this.props.avgLineColor
                    : COLOR_CODE[index];

                return (
                  <li key={`item-${index}`}>
                    <label
                      style={{
                        backgroundColor: `${boxColor}`,
                        width: "15px",
                        height: "12px",
                        margin: "3px 3px 0px 3px",
                        fontSize: "15px",
                        paddingLeft: "0px",
                        color: "#595959",
                        fontFamily: "Roboto",
                        fontWeight: "500",
                        display: "inline-flex",
                      }}
                    ></label>

                    <label
                      style={{
                        margin: "0px",
                        fontSize: "12",
                        fontWeight: "400",
                        fontFamily: "Roboto",
                        display: "inline-flex",
                      }}
                    >
                      {labelValue}
                    </label>
                  </li>
                );
              })}
            </ul>
          ) : (
            <div className="dataNotFound">No Legends Found</div>
          )}
        </div>
      </div>
    );
  }
}

export default CustomizedLineChart;
