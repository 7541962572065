const convertDataForDropdown = ( inputData, forWhichDropdown ) => {
  return new Promise( ( resolve, reject ) => {
    let exportData = [];
    if( forWhichDropdown === "employeeName" ) {
      inputData.forEach( data => {
        exportData.push( { ...data, value: `${data.first_name} ${data.last_name}`, label: `${data.first_name} ${data.last_name}` } );
      } );
    } else if( forWhichDropdown === "ageGroupDropdown" ) {
      inputData.forEach( data => {
        exportData.push( { ...data, value: `${data.from_value}-${data.to_value}`, label: `${data.from_value}-${data.to_value}` } );
      } );
    } else if(forWhichDropdown === "role") {
      inputData.forEach( data => {
        exportData.push( { ...data, value: data.name, label: data.display_name } );
      } );
    } else if( forWhichDropdown === "employeeId" ) {
      inputData.forEach( data => {
        exportData.push( { ...data, value: `${data.first_name} ${data.last_name}`, label: `${data.employee_id}` } );
      } );
    }  else {
      inputData.forEach( data => {
        exportData.push( { ...data, value: data.name, label: data.name } );
      } );
    } 
    resolve( exportData );
  } ); 
};

export default convertDataForDropdown;