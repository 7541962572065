import React from "react";
import * as d3 from "d3";
import { THEME_COLORS } from "./../../constants/constants"

class NegativeBarChartD3 extends React.Component {
    // constructor(props){
    //     super(props);
    // }

    componentDidMount(){
        this.loadChart();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.data[0].value !== nextProps.data[0].value) {
          this.loadChart();
        }
      }
    
    componentDidUpdate(){
        this.loadChart();
    }

    loadChart = () => {
        d3.select(this.node).selectAll("svg").remove();
        const COLORS = [THEME_COLORS[1], THEME_COLORS[2], THEME_COLORS[4]];
        let margin = {top: this.props.expandScreen ? 50 : 50, right: 50, bottom: 20, left: 50},
		width = this.props.width - margin.left - margin.right,
		height = (this.props.height - margin.top - margin.bottom);
    
        // scales
        let xScale = d3.scaleLinear()
            .range([0, width]);

        let yScale = d3.scaleBand()
            .rangeRound([0, height])
            .paddingInner(0.1);

        let data = this.props.data;
        let expandScreen = this.props.expandScreen;
        let graphName = this.props.graphName;
        let hideMargin = this.props.hideMargin;

		// domains
		xScale.domain(d3.extent(data, function(d) { return d.percent/100 })).nice();
		yScale.domain(data.map(function(d) { return d.name; }));

		// define x-axis
		var formatAsPercentage = d3.format("1.0%");

		let xAxis = d3.axisBottom()
                    .scale(xScale)
                    .tickFormat(formatAsPercentage);
		// create svg
		let svg = d3.select(this.node)
            .append("svg")
            .attr("class", function(d) { return !expandScreen && !hideMargin ?  "mt-100" : ""})
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("font-family", "roboto")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        // format tooltip       
        let thsndFormat = d3.format(",");

        // create  bars
        svg.selectAll(".bar")
        .data(data)
        .enter()
        .append("rect")
        .attr("class", function(d) { return "bar bar--" + (d.percent < 0 ? "negative" : "positive"); })
        .attr("x", function(d) { return xScale(Math.min(0, d.percent/100)); })
        .attr("y", function(d) { return yScale(d.name); })
        .attr("width", function(d) { return Math.abs(xScale(d.percent/100) - xScale(0)); })
        .attr("height", yScale.bandwidth())
        .attr("font-family", "Roboto")
        .attr("fill", function(d, index) { return COLORS[index] })
        // tooltip
        .on("mousemove", function(d) {
            let div = d3.select(`#tooltip-${graphName}${expandScreen}`)
            .attr("class", "redemption-tooltip")
            .style("opacity", 0);

            let e = window.event, x = e.clientX, y = e.clientY-40;
            div.transition().duration(200).style("opacity", 1.0);

            div.html( d.name + " : " + thsndFormat(d.value) + " (" +thsndFormat(d.percent) + "%)")
            .style("top", `${y}px`)
            .style("left", `${x}px`)
            ;
        })
        .on("mouseout", function(d) {
            let div = d3.select(`#tooltip-${graphName}${expandScreen}`)
            div.transition().duration(500).style("opacity", 0);
        });
			 
        svg.append("g")
			.attr("class", "x-axis")
            .attr("transform", "translate(0," + height + ")")
            .call(xAxis);					 
			
		// add tickNegative
		let tickNeg = svg.append("g")
                    .attr("class", "y-axis")
                    .attr("transform", "translate(" + xScale(0) + ",0)")
                    .call(d3.axisLeft(yScale))
                    .selectAll(".tick")
                    .filter(function(d, i) { return data[i].percent < 0; });

        tickNeg.select("line").attr("x2", 6);
        tickNeg.select("text").attr("x", 9).style("text-anchor", "start");
    }

    render(){
        let expandScreen = this.props.expandScreen;
        let graphName = this.props.graphName;

        return(
            <div {...this.props} className={`budgetUtilizationContainer-${this.props.expandScreen}`} /*id="budgetUtilizationContainer"*/ ref = { (node) => this.node = node }>
                <div id={`tooltip-${graphName}${expandScreen}`} ></div>
            </div>
        )
    }
}

export default NegativeBarChartD3;