export const STACKED_BAR_CHART_DATA = [
  {
    x: "Company 1",
    "Appreciation Recieved": 4000,
    "Appreciation Given": 2400,
    Benchmark: 400,
  },
  {
    x: "Company 2",
    "Appreciation Recieved": 3000,
    "Appreciation Given": 1398,
    Benchmark: 2400,
  },
  {
    x: "Company 3",
    "Appreciation Recieved": 2000,
    "Appreciation Given": 9800,
    Benchmark: 700,
  },
  {
    x: "Company 4",
    "Appreciation Recieved": 2780,
    "Appreciation Given": 3908,
    Benchmark: 2100,
  },
  {
    x: "Company 5",
    "Appreciation Recieved": 1890,
    "Appreciation Given": 4800,
    Benchmark: 2300,
  },
  {
    x: "Company 6",
    "Appreciation Recieved": 4000,
    "Appreciation Given": 2400,
    Benchmark: 600,
  },
  {
    x: "Company 7",
    "Appreciation Recieved": 3000,
    "Appreciation Given": 1398,
    Benchmark: 900,
  },
  {
    x: "Company 8",
    "Appreciation Recieved": 2000,
    "Appreciation Given": 9800,
    Benchmark: 1100,
  },
  {
    x: "Company 9",
    "Appreciation Recieved": 2780,
    "Appreciation Given": 3908,
    Benchmark: 1500,
  },
  {
    x: "Company 10",
    "Appreciation Recieved": 1890,
    "Appreciation Given": 4800,
    Benchmark: 1600,
  },
  {
    x: "Company 11",
    "Appreciation Recieved": 4000,
    "Appreciation Given": 2400,
    Benchmark: 850,
  },
  {
    x: "Company 12",
    "Appreciation Recieved": 3000,
    "Appreciation Given": 1398,
    Benchmark: 200,
  },
  {
    x: "Company 13",
    "Appreciation Recieved": 2000,
    "Appreciation Given": 9800,
    Benchmark: 240,
  },
  {
    x: "Company 14",
    "Appreciation Recieved": 2780,
    "Appreciation Given": 3908,
    Benchmark: 350,
  },
  {
    x: "Company 15",
    "Appreciation Recieved": 1890,
    "Appreciation Given": 4800,
    Benchmark: 200,
  },
];

export const BAR_CHART_DATA = [
  { x: "Company 1", "Appreciation Recieved": 20, "Appreciation Given": 30 },
  { x: "Company 2", "Appreciation Recieved": 15, "Appreciation Given": 25 },
];

export const WORD_CLOUD_CHART_DATA = [
  { value: "Lester", count: 10 },
  { value: "Sheldon", count: 20 },
  { value: "Marsha", count: 30 },
  { value: "Rodney", count: 40 },
  { value: "Gilberto", count: 50 },
  { value: "Lois", count: 60 },
  { value: "Paulette", count: 70 },
  { value: "Neil", count: 20 },
  { value: "Geneva", count: 30 },
  { value: "Brendan", count: 40 },
  { value: "Lindsey", count: 50 },
  { value: "Leon", count: 60 },
  { value: "Craig", count: 70 },
  { value: "Hector", count: 40 },
  { value: "Stuart", count: 80 },
  { value: "Enrique", count: 90 },
  { value: "Theodore", count: 110 },
];

export const SINGLE_BAR_CHART_D3 = [
  { name: "company1", value: 45 },
  { name: "company2", value: 47 },
  { name: "company3", value: 52 },
  { name: "company4", value: 70 },
  { name: "company5", value: 75 },
  { name: "company6", value: 78 },
];

export const DOUBLE_BAR_CHART_D3 = [
  { name: "company1", valueOne: 45, valueTwo: 78 },
  { name: "company2", valueOne: 47, valueTwo: 75 },
  { name: "company3", valueOne: 52, valueTwo: 70 },
  { name: "company4", valueOne: 70, valueTwo: 52 },
  { name: "company5", valueOne: 75, valueTwo: 47 },
  { name: "company6", valueOne: 78, valueTwo: 45 },
];

export const LOGIN_FAIL_MESSAGE =
  "Authentication failed. Please verify the credentials!";

export const TILES_DECIMAL_POINT = 1;

export const GRAPH_IDS = {
  RADAR_CHART: "RADAR_CHART",
  WORDCLOUD_RECIPENT: "WORDCLOUD_RECIPENT",
  WORDCLOUD_GIVER: "WORDCLOUD_GIVER",
  SUNBURST_CHART: "SUNBURST_CHART",
  SANKEY_BY_COMPANY: "SANKEY_BY_COMPANY",
  SANKEY_BY_DEPARTMENT: "SANKEY_BY_DEPARTMENT",
  HEATMAP_BY_COMPANY: "HEATMAP_BY_COMPANY",
  HEATMAP_BY_DEPARTMENT: "HEATMAP_BY_DEPARTMENT",
};

export const THEME_COLORS = [
  "#ec4242",
  "#f6cd61",
  "#c4c096",
  "#9b49e4",
  "#2cc273",
  "#b2881b",
  "#af3856",
  "#00b3ff",
  "#0d2d46",
  "#63c9d5",
  "#f49696",
  "#1ba95e",
  "#30b1df",
  "#d26b74",
  "#b773f3",
  "#199353",
  "#d49b03",
  "#e87504",
  "#185f7d",
  "#c6940f",
  "#530a94",
  "#1fc85f",
  "#906d0f",
  "#2dc173",
];

export const REDEMPTION_GRAPH_NAMES = {
  SNAPSHOT_BAR_CHART: "Budget Utilization Snapshot ( Bar Chart )",
  SNAPSHOT_PIE_CHART: "Budget Utilization Snapshot ( Pie Chart )",
  SNAPSHOT_CHART: "Snapshot",
  TREND_BAR_CHART: "Trend",
  POINTS_ER_GROUPED_BAR_CHART: "Points Awarded & Redeemed (by Month) ",
  POINTS_ER_HISTOGRAM_CHART: "Redemption Drivers",
  TIME_OF_REDEEMPTION_HEATMAP_CHART: "Average Redemptions by Day and Time",
  TIME_OF_REDEMPTION: "Average Redemptions by Day",
  AGE_OF_REDEMPTION_SNAPSOT: "Snapshot",
  AGE_OF_REDEMPTION_FREQUENCY: "Points Distribution by Age",
  AGE_OF_REDEMPTION_TREND: "Monthly Trend",
};

export const EMPLOYEE_SEARCH_NAME = {
  EMPLOYEES: "employeeMultiSelect",
  EMPLOYEE_IDS: "employeeIdsMultiSelect",
};

export const ENGAGEMENT_GRAPH_NAME = {
  OVERALL_APP_USAGE: "Overall App Usage",
  POINT_EARNING_BEHAVIOUR: "Point Earning Behaviour",
  INTERACTION_EFFECTIVENESS: "Interaction Effectiveness",
  NUMBER_OF_UNIQUE_USERS: "Number of Unique Users",
  AVERAGE_APP_USERS_BY_DAY_AND_TIME: "Average App Usage by Day and Time",
  PROPORTION_OF_UNIQUE_USERS: "Proportion of Engaged Users",
  AVG_FREQUENCY_OF_APP_USES: "Avg Frequency of App Usage",
  AVG_FREQUENCY_OF_FEATURE_USE_PER_MONTH:
    "Avg Frequency of Feature Use per Month",
  POINT_EARNING_CATEGORIES: "Point Earning Categories",
  POINT_EARNING_CATEGORY_TREND: "Point Earning Category Trend",
  TOP_POINT: "Top Point",
  EVENTS_RESPONSES: "Events Responses",
  NOTIFICATIONS: "Notifications",
};

export const ENGAGEMENTS = {
  TOP_POINT_EARNING_AWARDING_TABS: {
    byCategory: {
      name: "byCategory",
      tabName: "Categories",
      chartColor: "#af3856",
    },
    byDepartment: {
      name: "byDepartment",
      tabName: "Departments",
      chartColor: "#185f7d",
    },
    byUser: {
      name: "byUser",
      tabName: "Users",
      chartColor: "#63c9d5",
    },
  },
  TOP_POINT_EARNING_TAB_API_NAME: {
    byUser: "by-user",
    byDepartment: "by-department",
    byCategory: "by-category",
  },
};

export const CHART = {
  fontFamily: "'Roboto', sans-serif",
  fontWeight: 700,
};

export const MESSAGES = {
  NO_DATA_FOUND: "No Data Found",
  PENDING_SELECTION: "Pending Selection",
};

export const WEEKS_DAY = [
  "Sunday",
  "Saturday",
  "Friday",
  "Thursday",
  "Wednesday",
  "Tuesday",
  "Monday",
];

export const SLOTS_3HR = [
  "12AM-3AM",
  "3AM-6AM",
  "6AM-9AM",
  "9AM-12PM",
  "12PM-3PM",
  "3PM-6PM",
  "6PM-9PM",
  "9PM-12AM",
];

export const NOTIFICATIONS_TYPE = {
  EVENT: {
    id: 1,
    name: "Event",
    display_name: "Event",
    value: "Event",
    label: "Event",
  },
  ANNOUNCEMENT: {
    id: 2,
    name: "Announcement",
    display_name: "Announcement",
    value: "Announcement",
    label: "Announcement",
  },
  REWARD: {
    id: 3,
    name: "Reward",
    display_name: "Reward",
    value: "Reward",
    label: "Reward",
  },
  // SURVEY: { id: 6, name: "Survey", display_name: "Survey", value: "Survey", label: "Survey" },
};

export const NOTIFICATIONS_TYPE_DROPDOWN = [
  { id: "", name: "All", display_name: "All", value: "All", label: "All" },
  ...Object.keys(NOTIFICATIONS_TYPE).map((type) => NOTIFICATIONS_TYPE[type]),
];
