import React, { PureComponent } from "react";
import {
  PieChart, Pie, Sector, Cell, Label, Tooltip, Legend
} from "recharts";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ( {
  cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload
} ) => {
  const radius = innerRadius + ( outerRadius - innerRadius ) * 0.1;
  const x = cx + radius * Math.cos( -midAngle * RADIAN );
  const y = cy + radius * Math.sin( -midAngle * RADIAN );
  return (
    <text className="pieChartLabel" x={x} y={y} fill="black" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central" style={{ "fontSize": "10px" }}>
      {/* <textPath> */}
      {`${payload.payload.name}`}        
      {/* </textPath> */}
    </text>
  );
};

const renderCustomizedOuterLabel = ( {
  cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload
} ) => {
  const radius = innerRadius + ( outerRadius - innerRadius ) * 0.5;
  const x = cx + radius * Math.cos( -midAngle * RADIAN );
  const y = cy + radius * Math.sin( -midAngle * RADIAN );
  return (
    <text className="pieChartLabel" x={x} y={y} fill="black" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central" style={{ "fontSize": "10px" }}>
      {/* <textPath> */}
      {`${payload.payload.name}`}        
      {/* </textPath> */}
    </text>
  );
};

const renderActiveShape = ( props ) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, percent, value } = props;
  const sin = Math.sin( -RADIAN * midAngle );
  const cos = Math.cos( -RADIAN * midAngle );
  const sx = cx + ( outerRadius + 70 ) * cos;
  const sy = cy + ( outerRadius + 70 ) * sin;
  const mx = cx + ( outerRadius + 100 ) * cos;
  const my = cy + ( outerRadius + 100 ) * sin;
  const ex = mx + ( cos >= 0 ? 1 : -1 ) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";
  return (
    <g>
      {/* <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text> */}
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
      <text x={ex + ( cos >= 0 ? 1 : -1 ) * 12} y={ey} style={ { fontSize: 14, fontFamily: "Montserrat", fontWeight: 500, fill: "#758088" } } textAnchor={textAnchor} fill="#333">{`${props.name}`}</text>
      <text x={ex + ( cos >= 0 ? 1 : -1 ) * 12} y={ey} dy={18} style={ { fontSize: 14, fontFamily: "Montserrat", fontWeight: 500, fill: "#758088" } } textAnchor={textAnchor} fill="#999">
        {`${( props.percent * 100 ).toFixed( 2 )}%`}
      </text>
    </g>
  );
};

export default class Piechart extends PureComponent {
  state = {
    activeIndex: -1,
    title: "",
    titleStrength: "",
    activeIndexStrength: -1
  }
  renderActiveShape = ( props ) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
      fill, payload, percent, value } = props;
    const sin = Math.sin( -RADIAN * midAngle );
    const cos = Math.cos( -RADIAN * midAngle );
    const sx = cx + ( outerRadius + 10 ) * cos;
    const sy = cy + ( outerRadius + 10 ) * sin;
    const mx = cx + ( outerRadius + 30 ) * cos;
    const my = cy + ( outerRadius + 30 ) * sin;
    const ex = mx + ( cos >= 0 ? 1 : -1 ) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    return (
      <g>
        {/* <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text> */}
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
        <text x={ex + ( cos >= 0 ? 1 : -1 ) * 12} y={ey} textAnchor={textAnchor} style={ { fontSize: 14, fontFamily: "Montserrat", fontWeight: 500, fill: "#758088" } } fill="#333">{`${props.name}`}</text>
        <text x={ex + ( cos >= 0 ? 1 : -1 ) * 12} y={ey} dy={18} textAnchor={textAnchor} style={ { fontSize: 14, fontFamily: "Montserrat", fontWeight: 500, fill: "#758088" } } fill="#999">
          { `${props.parentStrength}`}
          {/* {`${(props.percent * 100).toFixed(2)}%`} */}
        </text>
      </g>
    );
  };
   renderActiveShapeExample = ( props ) => {
     const {
       cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, value
     } = props;
  
     return (
       <g>
         <text x={cx} y={cy + 20} dy={13} textAnchor="middle" className="datavalue">{null}</text>
         <Sector
           cx={cx}
           cy={cy}
           innerRadius={innerRadius}
           outerRadius={outerRadius}
           startAngle={startAngle}
           endAngle={endAngle}
           fill={fill}
         />
         <Sector
           cx={cx}
           cy={cy}
           startAngle={startAngle}
           endAngle={endAngle}
           innerRadius={outerRadius - 1}
           outerRadius={outerRadius + 15}
           fill={fill}
         />
       </g>
     );
   };
  onPieLeave = () => this.setState( { activeIndex: -1, title: "" } );

  onPieEnter = ( data, index ) => {
    this.setState( {
      ...this.state,
      activeIndex: index,
      title: data.name,
      activeIndexStrength: -1,
      titleStrength: ""
    } );
  }

  onPieLeaveStrength = () => this.setState( { activeIndexStrength: -1, titleStrength: "" } );

  onPieEnterStrength = ( data, index ) => {
    this.setState( {
      ...this.state,
      activeIndexStrength: index,
      titleStrength: data.name,
      activeIndex: -1,
      title: ""
    } );
  }

  render() {
    return (
      <PieChart width={this.props.width} height={this.props.height}>
        {/* <Tooltip /> */}
        <Pie
          innerRadius={ this.props.innerPie.innerRadius }
          outerRadius={ this.props.innerPie.outerRadius }
          fill="#8884d8"
          dataKey="value"
          id="innerPie"
        >

        </Pie>
        <Pie
          data={this.props.data.parentStrengths}
          labelLine={false}
          activeIndex={this.state.activeIndexStrength}
          activeShape={renderActiveShape}
          // label={renderCustomizedLabel}
          innerRadius={ this.props.middlePie.innerRadius }
          outerRadius={ this.props.middlePie.outerRadius }
          fill="#8884d8"
          dataKey="count"
          onMouseEnter={this.onPieEnterStrength}
          onMouseLeave={this.onPieLeaveStrength}
          onClick={ this.props.clickEvent ? this.props.handleClicked : null }
        >
          {
            this.props.data.parentStrengths.map( ( entry, index ) => <Cell key={`cell-${index}`} fill={entry.color} /> ) 
          }
          <Label value={"Strengths"} offset={0} style={ { fontSize: 14, fontFamily: "Montserrat", fontWeight: 500, fill: "#758088" } } position="center" />
        </Pie>
        <Pie
          data={this.props.data.childDepartments}
          activeIndex={this.state.activeIndex}
          activeShape={this.renderActiveShape}
          labelLine={false}
          // label={renderCustomizedOuterLabel}
          innerRadius={ this.props.outerPie.innerRadius }
          outerRadius={ this.props.outerPie.outerRadius }
          fill="#8884d8"
          dataKey="count"
          onMouseEnter={this.onPieEnter}
          onMouseLeave={this.onPieLeave}
          onClick={ this.props.clickEvent ? this.props.handleClicked : null }
        >
          {
            this.props.data.childDepartments.map( ( entry, index ) => <Cell key={`cell-${index}`} fill={entry.color} /> )
          }
        </Pie>
      </PieChart>
    );
  }
}
