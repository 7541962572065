import { takeEvery, put, call } from "redux-saga/effects";
import * as AppreciationActionTypes from "../Actions/AppreciationActionTypes";
import AppreciationDashboardApi from "../../../../../Api/AppreciationDashboardApi";
import * as AppreciationActions from "../Actions/AppreciationActions";
import * as EngagementActions from "../../Engagement/Actions/EngagementActions";

import {
  convertDataForStackedBarChart,
  convertDataCompanyDept
} from "../../../../../Utilities/convertDataForStackedBarChart";
import moment from "moment";
import {
  getMaxAppreciationCountForSpider,
  removeDoubleQuotes,
  dataManipulationForSunburstChart,
  treeMap,
  appreciationFlowManipulation,
  appreciationFlowHeatmapManipulation,
  appreciationFlowHeatmapMessageManipulation,
  wordCloud
} from "../../../../../Utilities/graphManipulations";
import convertDataToDropDown from "../../../../../Utilities/convertDataForDropdown";
export function* getCompanyFilterAsync(action) {
  const { response, error } = yield call(
    AppreciationDashboardApi.getCompaniesDropdown,
    action.payload
  );

  if (response) {
    if (response.status_code === 200) {
      let isfrom = action.payload.isfrom;
      delete action.payload.isfrom;
      let updatedPayload = {
        ...action.payload,
        date: {
          ...action.payload.date,
          to: moment(response.data.date).format("YYYY-MM-DD")
        }
      };
      yield put(
        EngagementActions.setP2P(response.data.isP2P)
      );
      // if only single company is there, then consider it by default
      if (
        response.data &&
        response.data.result &&
        response.data.result.length === 1
      ) {
        updatedPayload.org_id = [response.data.result[0].id];
        updatedPayload.org = [response.data.result[0].name];
      }
      let data = yield convertDataToDropDown(response.data.result);
      yield put(
        AppreciationActions.setEndDateRangeFilterAction(response.data.date)
      );

      if( isfrom === "appreciation" ) {
        yield put(
          AppreciationActions.histogramDataRequestAction({
            ...updatedPayload,
            parameter: "month"
          })
        );
        yield put(
          AppreciationActions.departmentHistogramDataRequestAction({
            ...updatedPayload,
            parameter: "department"
          })
        );
        yield put(
          AppreciationActions.companyHistogramDataRequestAction({
            ...updatedPayload,
            parameter: "organization"
          })
        );
        yield put(
          AppreciationActions.tilesDropdownRequestAction({ ...updatedPayload })
        );
        yield put(
          AppreciationActions.wordCloudDataRequestAction({ ...updatedPayload })
        );
        yield put(
          AppreciationActions.sunburstDataRequestAction({ ...updatedPayload })
        );
        yield put(
          AppreciationActions.treeMapDataRequestAction({ ...updatedPayload })
        );
        yield put(
          AppreciationActions.appreciationFlowRequestAction({ ...updatedPayload })
        );
        yield put(
          AppreciationActions.appreciationFlowHeatmapRequestAction({
            ...updatedPayload,
            chart: "heatmap"
          })
        );
      }

      yield put(AppreciationActions.companyFilterDropdownSuccessAction(data));
    } else if (response.status_code === 400) {
      yield put(AppreciationActions.companyFilterDropdownFailAction());
    }
  } else {
    yield put(AppreciationActions.companyFilterDropdownFailAction());
  }
}

export function* getDepartmentFilterAsync(action) {
  const { response, error } = yield call(
    AppreciationDashboardApi.getDepartmentDropdown,
    action.payload
  );
  if (response) {
    if (response.status_code === 200) {
      let data = yield convertDataToDropDown(response.data.result);
      yield put(
        AppreciationActions.departmentFilterDropdownSuccessAction(data)
      );
    } else if (response.status_code === 500) {
      yield put(AppreciationActions.departmentFilterDropdownFailAction());
    }
  } else {
    yield put(AppreciationActions.departmentFilterDropdownFailAction());
  }
}

export function* getEmployeeFilterAsync(action) {
  const { response, error } = yield call(
    AppreciationDashboardApi.getEmployeesDropdown,
    action.payload
  );
  if (response) {
    if (response.status_code === 200) {
      let updatedPayload = yield convertDataToDropDown(
        response.data.result,
        "employeeName"
      );
      yield put(
        AppreciationActions.employeeFilterDropdownSuccessAction(updatedPayload)
      );
    } else if (response.status_code === 400) {
      yield put(AppreciationActions.employeeFilterDropdownFailAction());
    }
  } else {
    yield put(AppreciationActions.employeeFilterDropdownFailAction());
  }
}

export function* getAgeGroupFilterAsync(action) {
  const { response, error } = yield call(
    AppreciationDashboardApi.getAgeGroupDropdown,
    action.payload
  );

  if (response) {
    if (response.status_code === 200) {
      let data = yield convertDataToDropDown(
        response.data.result,
        "ageGroupDropdown"
      );
      yield put(AppreciationActions.ageGroupFilterDropdownSuccessAction(data));
    } else if (response.status_code === 400) {
      yield put(AppreciationActions.ageGroupFilterDropdownFailAction());
    }
  } else {
    yield put(AppreciationActions.ageGroupFilterDropdownFailAction());
  }
}

export function* getRoleFilterAsync(action) {
  const { response, error } = yield call(
    AppreciationDashboardApi.getRoleDropdown,
    action.payload
  );

  if (response) {
    if (response.status_code === 200) {
      let data = yield convertDataToDropDown(response.data.result, "role");

      yield put(AppreciationActions.roleFilterDropdownSuccessAction(data));
    } else if (response.status_code === 400) {
      yield put(AppreciationActions.roleFilterDropdownFailAction());
    }
  } else {
    yield put(AppreciationActions.roleFilterDropdownFailAction());
  }
}

export function* getTilesDetails(action) {
  const { response, error } = yield call(
    AppreciationDashboardApi.getTilesData,
    action.payload
  );

  if (response) {
    if (response.status_code === 200) {
      yield put(AppreciationActions.tilesDropdownSuccessAction(response.data));
    } else if (response.status_code === 400) {
      yield put(AppreciationActions.tilesDropdownFailAction());
    }
  } else {
    yield put(AppreciationActions.tilesDropdownFailAction());
  }
}

export function* getHistogramAsync(action) {
  const { response, error } = yield call(
    AppreciationDashboardApi.getHistogramData,
    action.payload
  );

  if (response) {
    let benchMarkResponses = {
      // SKELETON RESPONSE FOR BENCHMARK
      response: {
        data: []
      }
    };

    // Hide benchmark if dept/role/user is selected
    // if (
    //   action.payload.parameter === "month" &&
    //   !action.payload.dept_id.length &&
    //   !action.payload.role.length &&
    //   !action.payload.user_id.length
    // ) {
    //   // CALL BENCHMAR API FOR MONTHLY
    //   benchMarkResponses = yield call(
    //     AppreciationDashboardApi.getHistogramBenchMarkData,
    //     action.payload
    //   );
    // }

    if (response.status_code === 200) {
      let payload = yield convertDataForStackedBarChart(
        response.data.result,
        action.payload.date,
        action.payload.parameter,
        benchMarkResponses.response
      );
      yield put(AppreciationActions.histogramDataSuccessAction(payload));
    } else if (response.status_code === 400) {
      yield put(AppreciationActions.histogramDataFailAction());
    }
  } else {
    yield put(AppreciationActions.histogramDataFailAction());
  }
}

export function* getDepartmentHistogramAsync(action) {
  const { response, error } = yield call(
    AppreciationDashboardApi.getHistogramData,
    action.payload
  );
  if (response) {
    if (response.status_code === 200) {
      let updatedPayload = yield convertDataCompanyDept(response.data.result);
      yield put(
        AppreciationActions.departmentHistogramDataSuccessAction(updatedPayload)
      );
    } else if (response.status_code === 400) {
      yield put(AppreciationActions.departmentHistogramDataFailAction());
    }
  } else {
    yield put(AppreciationActions.departmentHistogramDataFailAction());
  }
}

export function* getCompanyHistogramAsync(action) {
  const { response, error } = yield call(
    AppreciationDashboardApi.getHistogramData,
    action.payload
  );

  if (response) {
    if (response.status_code === 200) {
      let updatedPayload = yield convertDataCompanyDept(response.data.result);
      yield put(
        AppreciationActions.companyHistogramDataSuccessAction(updatedPayload)
      );
    } else if (response.status_code === 400) {
      yield put(AppreciationActions.companyHistogramDataFailAction());
    }
  } else {
    yield put(AppreciationActions.companyHistogramDataFailAction());
  }
}

export function* getCompanyDrilldownHistogramAsync(action) {
  const { response, error } = yield call(
    AppreciationDashboardApi.getCompanyDrilldown,
    action.payload
  );

  if (response) {
    if (response.status_code === 200) {
      let keys = Object.keys(response.data);

      let updatedData = keys.map(key => {
        return {
          x: key,
          "Across Entity": response.data[key].across_percent.toFixed(1),
          "Within Entity": response.data[key].within_percent.toFixed(1)
        };
      });

      yield put(
        AppreciationActions.companyDrilldownHistogramDataSuccessAction(
          updatedData
        )
      );
    } else if (response.status_code === 400) {
      yield put(AppreciationActions.companyDrilldownHistogramDataFailAction());
    }
  } else {
    yield put(AppreciationActions.companyDrilldownHistogramDataFailAction());
  }
}

export function* getDepartmentDrilldownHistogramAsync(action) {
  const { response, error } = yield call(
    AppreciationDashboardApi.getCompanyDrilldown,
    action.payload
  );

  if (response) {
    if (response.status_code === 200) {
      let keys = Object.keys(response.data);

      let updatedData = keys.map(key => {
        return {
          x: key,
          "Across Entity": response.data[key].across_percent.toFixed(1),
          "Within Entity": response.data[key].within_percent.toFixed(1)
        };
      });

      yield put(
        AppreciationActions.departmentDrilldownHistogramDataSuccessAction(
          updatedData
        )
      );
    } else if (response.status_code === 400) {
      yield put(
        AppreciationActions.departmentDrilldownHistogramDataFailAction()
      );
    }
  } else {
    yield put(AppreciationActions.departmentDrilldownHistogramDataFailAction());
  }
}

export function* getWordCloudAsync(action) {
  const { response, error } = yield call(
    AppreciationDashboardApi.getWordCloudData,
    action.payload
  );

  if (response) {
    if (response.status_code === 200) {
      let updatedData = yield wordCloud(response.data);
      yield put(AppreciationActions.wordCloudDataSuccessAction(updatedData));
    } else if (response.status_code === 400) {
      yield put(AppreciationActions.wordCloudDataFailAction());
    }
  } else {
    yield put(AppreciationActions.wordCloudDataFailAction());
  }
}

export function* getSpiderAsync(action) {
  const { response, error } = yield call(
    AppreciationDashboardApi.getSpiderData,
    action.payload
  );

  if (response) {
    if (response.status_code === 200) {
      let updatedResponse = yield getMaxAppreciationCountForSpider(
        response.data
      );
      yield put(AppreciationActions.spiderDataSuccessAction(updatedResponse));
    } else if (response.status_code === 400) {
      yield put(AppreciationActions.spiderDataFailAction());
    }
  } else {
    yield put(AppreciationActions.spiderDataFailAction());
  }
}

export function* getAppreciationFeedAsync(action) {
  const { response, error } = yield call(
    AppreciationDashboardApi.getAppreciationFeed,
    action.payload
  );
  if (response) {
    if (response.status_code === 200) {
      let updatedPayload = yield removeDoubleQuotes(response.data);
      yield put(
        AppreciationActions.appreciationFeedSuccessAction(updatedPayload)
      );
    } else if (response.status_code === 400) {
      yield put(AppreciationActions.appreciationFeedFailAction());
    }
  } else {
    yield put(AppreciationActions.appreciationFeedFailAction());
  }
}

export function* getSunburstAsync(action) {
  const { response, error } = yield call(
    AppreciationDashboardApi.getSunburstdata,
    action.payload
  );

  if (response) {
    if (response.status_code === 200) {
      let updatedPayload = yield dataManipulationForSunburstChart(
        response.data.result
      );
      yield put(AppreciationActions.sunburstDataSuccessAction(updatedPayload));
    } else if (response.status_code === 400) {
      yield put(AppreciationActions.sunburstDataFailAction());
    }
  } else {
    yield put(AppreciationActions.sunburstDataFailAction());
  }
}

export function* getStrengthEmployeeAsync(action) {
  const { response, error } = yield call(
    AppreciationDashboardApi.getStrengthEmployeeListdata,
    action.payload
  );

  if (response) {
    if (response.status_code === 200) {
      // let updatedPayload = yield dataManipulationForSunburstChart( response.data.result );
      yield put(
        AppreciationActions.strengthEmployeeListSuccessAction(
          response.data.result
        )
      );
    } else if (response.status_code === 400) {
      yield put(AppreciationActions.strengthEmployeeListFailAction());
    }
  } else {
    yield put(AppreciationActions.strengthEmployeeListFailAction());
  }
}
export function* getTreeMapAsync(action) {
  const { response, error } = yield call(
    AppreciationDashboardApi.getTreeMapData,
    action.payload
  );
  if (response) {
    if (response.status_code === 200) {
      let updatedPayload = yield treeMap(
        response.data.result,
        response.data.stregths,
        response.data.total_strength_for_org
      );
      yield put(AppreciationActions.treeMapDataSuccessAction(updatedPayload));
    } else if (response.status_code === 400) {
      yield put(AppreciationActions.treeMapDataFailAction());
    }
  } else {
    yield put(AppreciationActions.treeMapDataFailAction());
  }
}

export function* getAppreciationFlowAsync(action) {
  const { response, error } = yield call(
    AppreciationDashboardApi.getAppreciationFlowData,
    action.payload
  );
  if (response) {
    if (response.status_code === 200) {
      let updatedPayload = yield appreciationFlowManipulation(response.data);
      yield put(
        AppreciationActions.appreciationFlowSuccessAction(updatedPayload)
      );
    } else if (response.status_code === 400) {
      yield put(AppreciationActions.appreciationFlowFailAction());
    }
  } else {
    yield put(AppreciationActions.appreciationFlowFailAction());
  }
}

export function* getAppreciationFlowHeatmapAsync(action) {
  const { response, error } = yield call(
    AppreciationDashboardApi.getAppreciationFlowData,
    action.payload
  );
  if (response) {
    if (response.status_code === 200) {
      let updatedPayload = yield appreciationFlowHeatmapManipulation(
        response.data
      );
      yield put(
        AppreciationActions.appreciationFlowHeatmapSuccessAction(updatedPayload)
      );
    } else if (response.status_code === 400) {
      yield put(AppreciationActions.appreciationFlowHeatmapFailAction());
    }
  } else {
    yield put(AppreciationActions.appreciationFlowHeatmapFailAction());
  }
}

export function* getAppreciationFlowMessageExchangeAsync(action) {
  const { response, error } = yield call(
    AppreciationDashboardApi.getAppreciationFeed,
    action.payload
  );
  if (response) {
    if (response.status_code === 200) {
      let updatedPayload = yield appreciationFlowHeatmapMessageManipulation(
        response.data.result
      );
      yield put(
        AppreciationActions.appreciationFlowHeatmapMessageExchangeSuccessAction(
          updatedPayload
        )
      );
    } else if (response.status_code === 400) {
      yield put(
        AppreciationActions.appreciationFlowHeatmapMessageExchangeFailAction()
      );
    }
  } else {
    yield put(
      AppreciationActions.appreciationFlowHeatmapMessageExchangeFailAction()
    );
  }
}
export default function* rootAppreciationSaga() {
  yield takeEvery(
    AppreciationActionTypes.GET_COMPANY_FILTER_DROPDOWN_REQUEST,
    getCompanyFilterAsync
  );
  yield takeEvery(
    AppreciationActionTypes.GET_DEPARTMENT_FILTER_DROPDOWN_REQUEST,
    getDepartmentFilterAsync
  );
  yield takeEvery(
    AppreciationActionTypes.GET_EMPLOYEES_FILTER_DROPDOWN_REQUEST,
    getEmployeeFilterAsync
  );
  yield takeEvery(
    AppreciationActionTypes.GET_AGE_GROUP_FILTER_DROPDOWN_REQUEST,
    getAgeGroupFilterAsync
  );
  yield takeEvery(
    AppreciationActionTypes.GET_ROLE_FILTER_DROPDOWN_REQUEST,
    getRoleFilterAsync
  );
  yield takeEvery(
    AppreciationActionTypes.GET_HISTOGRAM_DATA_REQUEST,
    getHistogramAsync
  );
  yield takeEvery(
    AppreciationActionTypes.GET_TILES_DATA_REQUEST,
    getTilesDetails
  );
  yield takeEvery(
    AppreciationActionTypes.GET_COMPANY_HISTOGRAM_DATA_REQUEST,
    getCompanyHistogramAsync
  );
  yield takeEvery(
    AppreciationActionTypes.GET_DEPARTMENT_HISTOGRAM_DATA_REQUEST,
    getDepartmentHistogramAsync
  );
  yield takeEvery(
    AppreciationActionTypes.GET_COMPANY_DRILLDOWN_DATA_REQUEST,
    getCompanyDrilldownHistogramAsync
  );
  yield takeEvery(
    AppreciationActionTypes.GET_DEPARTMENT_DRILLDOWN_DATA_REQUEST,
    getDepartmentDrilldownHistogramAsync
  );
  yield takeEvery(
    AppreciationActionTypes.GET_WORD_CLOUD_DATA_REQUEST,
    getWordCloudAsync
  );
  yield takeEvery(
    AppreciationActionTypes.GET_SPIDER_DATA_REQUEST,
    getSpiderAsync
  );
  yield takeEvery(
    AppreciationActionTypes.GET_APPRECIATION_FEEDS_REQUEST,
    getAppreciationFeedAsync
  );
  yield takeEvery(
    AppreciationActionTypes.GET_SUNBURST_DATA_REQUEST,
    getSunburstAsync
  );
  yield takeEvery(
    AppreciationActionTypes.GET_STRENGTH_EMPLOYEE_LIST_REQUEST,
    getStrengthEmployeeAsync
  );
  yield takeEvery(
    AppreciationActionTypes.GET_TREE_MAP_DATA_REQUEST,
    getTreeMapAsync
  );
  yield takeEvery(
    AppreciationActionTypes.GET_APPRECIATION_FLOW_REQUEST,
    getAppreciationFlowAsync
  );
  yield takeEvery(
    AppreciationActionTypes.GET_APPRECIATION_FLOW_HEATMAP_REQUEST,
    getAppreciationFlowHeatmapAsync
  );
  yield takeEvery(
    AppreciationActionTypes.GET_APPRECIATION_FLOW_HEATMAP_MESSAGE_EXCHANGE_REQUEST,
    getAppreciationFlowMessageExchangeAsync
  );
}
