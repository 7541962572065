
const initializeStore = ( props ) => {
  let authToken = sessionStorage.getItem( "Auth-Token" );
  let username = sessionStorage.getItem( "username" );
  let isParentOrgLogin = sessionStorage.getItem( "isParentOrgLogin" );
  const isAppreciationsEnabled = JSON.parse(sessionStorage.getItem( "isAppreciationsEnabled" ));

  if( authToken === "null" || authToken === "undefined" && username === "null" || username === "undefined" ) {
    props.loginFail( { statusCode: 401, message: "Unauthorized" } );
  } else {
    props.loginSucceed( { authToken, username, isParentOrgLogin, isAppreciationsEnabled } );
  }
};

export default initializeStore;