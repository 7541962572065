import React from "react";
import { connect } from "react-redux";
import { EventModal } from "./UI";
import Home from "./Home";
import "./static/css/main.scss";
import "babel-polyfill";
import { withRouter } from "react-router-dom";
import { hideEventModalAction, setScreenHeightWidthAction } from "./Common/CommonStore/CommonActions";
import * as LoginAction from "./Modules/Login/Actions/LoginActions";

class App extends React.Component {
  constructor( props ) {
    super( props );
    props.setScreenHeightWidth({ height: window.screen.height, width: window.screen.width } )

  }

  clearLoginErrorMessage = () => {
    this.props.hideEventModal();
    this.props.loginErrorClear();
  }

  render() {
    return (
      <React.Fragment>
        <div className="wrapper">
          <Home {...this.props} />
          <EventModal
            showModal={this.props.showEventModal}
            modalTitle={this.props.eventModalTitle}
            modalBody={this.props.eventModalBody}
            showButtonOne={this.props.showEventModalButtonOne}
            showButtonTwo={this.props.showEventModalButtonTwo}
            buttonOneHandler={this.props.buttonOneEventModalHandler}
            buttonTwoHandler={this.props.buttonTwoEventModalHandler}
            buttonOneName={this.props.buttonOneEventModalName}
            buttonTwoName={this.props.buttonTwoEventModalName}
            graphContent= {this.props.graphContent}
            graphTitle= {this.props.graphTitle}
            showGraph= {this.props.showGraph}
            graphName= {this.props.graphName}
            graphId= {this.props.graphId}
            graphSubTitle= {this.props.graphSubTitle}
          />
        </div>
        <div className={ this.props.showEventModal ? "modalOverlay" : "modalOverlay hide"} onClick={ this.clearLoginErrorMessage } ></div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    showEventModal: state.Common.showEventModal,
    eventModalTitle: state.Common.eventModalTitle,
    eventModalBody: state.Common.eventModalBody,
    showEventModalButtonOne: state.Common.showEventModalButtonOne,
    showEventModalButtonTwo: state.Common.showEventModalButtonTwo,
    buttonOneEventModalHandler: state.Common.buttonOneEventModalHandler,
    buttonTwoEventModalHandler: state.Common.buttonTwoEventModalHandler,
    buttonOneEventModalName: state.Common.buttonOneEventModalName,
    buttonTwoEventModalName: state.Common.buttonTwoEventModalName,
    graphContent: state.Common.graphContent,
    graphTitle: state.Common.graphTitle,
    showGraph: state.Common.showGraph,
    graphName: state.Common.graphName,
    graphId: state.Common.graphId,
    graphSubTitle: state.Common.graphSubTitle
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideEventModal: () => dispatch( hideEventModalAction() ),
    loginErrorClear: () => dispatch( LoginAction.loginErrorClearAction() ),
    setScreenHeightWidth: ( data ) => dispatch( setScreenHeightWidthAction( data ) )
  }
}
export default withRouter( connect( mapStateToProps, mapDispatchToProps )( App ) );