import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import TilesSection from "./Components/TilesSection";
import CenterInfluence from "./Components/CenterofInfluenceSection";
import AppreciationExchangeSection from "./Components/AppreciationExchangeSection";
import AppreciationFlow from "./Components/AppreciationFlow/AppreciationFlowSection";  
import { Filter, Footer } from "../../../../UI";
import * as AppreciationActions from "../Appreciation/Actions/AppreciationActions";
import IndividualAppreciationSection from "./Components/IndividualAppreciationSection";
import pdficon from "../../../../static/images/pdficon.png";
import gotop from "../../../../static/images/top.png";
import AppreciationDashboardApi from "../../../../Api/AppreciationDashboardApi";
import convertDataForDropdown from "../../../../Utilities/convertDataForDropdown";
import { handlePdfGeneration } from "../../../../Utilities/exportMethods";
import * as CommonActions from "../../../../Common/CommonStore/CommonActions";
import { EMPLOYEE_SEARCH_NAME } from "../../../../constants/constants";

// Appreciation Dashboard
class AppreciationContainer extends React.Component {
  constructor (props) {
    super(props);
    let date = this.props.filters.endDate
    let startDate = moment().startOf("year").format("DD MMM YYYY");
    let endDate = moment().subtract(1, 'days')
    if(date) {
      startDate = moment(date).startOf("year").format("DD MMM YYYY")
      endDate = date
    }
    this.state = {
      timeFrame: {
        startDate,
        endDate,
        // endDate: this.props.filters.endDate
      },
      showStartDatePicker: false,
      showEndDatePicker: false,
      closeExpandedView: false,
      filterOverlay: false,
      dropDowns: {
        companyMultiSelect: [],
        departmentMultiSelect: [],
        ageMultiSelect: [],
        roleMultiSelect: [],
        employeeMultiSelect: [],
        employeeIdsMultiSelect: [],
      },
      selectedCompanies: [],
      showMore: {
        companyMultiSelect: false,
        departmentMultiSelect: false,
        ageMultiSelect: false,
        roleMultiSelect: false,
        employeeMultiSelect: false,
        employeeIdsMultiSelect: false,
      },
      showOptions: {
        companyMultiSelect: true,
        departmentMultiSelect: true,
        ageMultiSelect: true,
        roleMultiSelect: true,
        employeeMultiSelect: true,
        employeeIdsMultiSelect: true,
      },

      appreciationExchange: {
        appreciationExchangeFrequency: "month",
        showCompanyDrillDown: false,
        showDepartmentDrillDown: false,
        companyDrillDownName: "",
        departmentDrillDownName: ""
      },
      individualAppreciation: {
        showIndividualAppreciation: false,
        individualEmail: "",
        individualUserId: null
      },
      centerofInfluence: {
        employeeList: {
        },
        treemap: {
          rangeSliderValue: 1,
          switch: false,
          strengthMultiSelect: []
        }
      },
      appreciationFlow: {
        heatmap: {
          radioSelect: "all"
        },
        sankey: {
          radioSelect: "all"
        }
      }
      
    };

    if (!this.props.isAppreciationsEnabled) {
      this.props.history.push("/redemption");
      return;
    }

    props.companyFilterDropdownRequest({...this.getFilterObject(), isfrom : 'appreciation'});
    // props.departmentFilterDropdownRequest();
    // props.employeeFilterDropdownRequest({ org_id: [], dept_id: [], role: [] });
    // props.ageGroupFilterDropdownRequest();
    // props.roleFilterDropdownRequest();
  }
  
  componentDidMount () {
    window.scrollTo(0, 0);
  }
  componentWillReceiveProps = (nextProps) => {
    if(this.props.isAppreciationsEnabled && this.props.dashboardOverlay && !nextProps.dashboardOverlay) {
      this.setState({
        ...this.state,
        showEndDatePicker: false,
        showStartDatePicker: false
      });
    }
    if( this.props.filters.endDate !== nextProps.filters.endDate ) {
      this.setState({
        ...this.state,
        timeFrame: {
          ...this.state.timeFrame,
          startDate: moment(nextProps.filters.endDate).startOf("year").format("DD MMM YYYY"),
          endDate: nextProps.filters.endDate
        }
      }, () => {
        // this.props.companyFilterDropdownRequest({...this.getFilterObject(), isfrom : 'appreciation'});
        this.props.departmentFilterDropdownRequest();
        this.props.employeeFilterDropdownRequest({ org_id: [], dept_id: [], role: [] });
        this.props.roleFilterDropdownRequest();
      });
    } 

    if(nextProps.companyFilter && nextProps.companyFilter.length === 1) {

      this.setState({
        ...this.state,
        dropDowns: {
          ...this.state.dropDowns,
          companyMultiSelect: [nextProps.companyFilter[0]]
        }
      }, () => {
        let selectedCompanies = this.state.dropDowns.companyMultiSelect.map(company => company.id);
        let selectedDepartment = this.state.dropDowns.departmentMultiSelect.map(department => department.id);
        this.setState({
          ...this.state,
          selectedCompanies: [...selectedCompanies, ...selectedDepartment]
        })
      });
      
    }
  }

  getFilterObject = () => {
    let selectedDeptIds = this.state.dropDowns.departmentMultiSelect.map(department => department.id);
    let allDeptIds = this.props.departmentFilter.map(department => department.id);
    let validDeptIds = [];  
    selectedDeptIds.forEach(deptId => {
      if(allDeptIds.includes(deptId)) {
        validDeptIds.push(deptId);
      }
    });

    let selectedEmpIds = this.state.dropDowns.employeeMultiSelect.map(employee => employee.id);
    let allEmpIds = this.props.employeesFilter.map(employee => employee.id);
    let validEmpIds = []; 
    selectedEmpIds.forEach(empId => {
      if(allEmpIds.includes(empId)) {
        validEmpIds.push(empId);
      }
    });
    let filters = {
      date: {
        from: moment(this.state.timeFrame.startDate).format("YYYY-MM-DD"),
        to: this.state.timeFrame.endDate ? moment(this.state.timeFrame.endDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")
      },
      org_id: this.state.dropDowns.companyMultiSelect.map(company => company.id),
      dept_id: validDeptIds,
      // age: this.state.dropDowns.ageMultiSelect.map(ageGroup => { return ({ from: ageGroup.from_value, to: ageGroup.to_value }); }),
      user_id: validEmpIds,
      role: this.state.dropDowns.roleMultiSelect.map(role => role.name),
      org: this.state.dropDowns.companyMultiSelect.map(company => company.value),
      dept: this.state.dropDowns.departmentMultiSelect.map(department => department.value),
      users: this.state.dropDowns.employeeMultiSelect.map(employee => employee.value)
      // department_id  : this.state.appreciationExchange.department_id, 
      // organization_id : this.state.appreciationExchange.organization_id 
      
    };
    const EmpIds = this.state.dropDowns.employeeIdsMultiSelect;
    for (let i =0; i< EmpIds.length; i++) {
      if(filters.user_id.indexOf(EmpIds[i].id)<0) {
        filters.user_id.push(EmpIds[i].id)
        filters.users.push(EmpIds[i].value)
      }
    }

    return filters;
  }

  // START OF SEARCH FILTERS EVENTS
  handleStartDateSelect = (date) => {
    this.setState({ 
      ...this.state,
      timeFrame: {
        ...this.state.timeFrame,
        startDate: date
      },
      showStartDatePicker: false,
      filterOverlay: false
    }, () => {
      // this.props.hideDashboardOverlay();
    });
  }

  handleEndDateSelect = (date) => {
    this.setState({ 
      ...this.state,
      timeFrame: {
        ...this.state.timeFrame,
        endDate: date
      },
      showEndDatePicker: false,
      filterOverlay: false

    }, () => {
      // this.props.hideDashboardOverlay();
    });
  }
  toggleStartDatepicker = () => {
    // if( this.props.dashboardOverlay ) {
    //   this.props.hideDashboardOverlay();
    // } else {
    //   this.props.showDashboardOverlay();
    // }
    this.setState({
      ...this.state,
      showStartDatePicker: !this.state.showStartDatePicker,
      filterOverlay: !this.state.filterOverlay

    });
  }
  toggleEndDatepicker = () => {
    // if( this.props.dashboardOverlay ) {
    //   this.props.hideDashboardOverlay();
    // } else {
    //   this.props.showDashboardOverlay();
    // }
    this.setState({
      ...this.state,
      showEndDatePicker: !this.state.showEndDatePicker,
      filterOverlay: !this.state.filterOverlay

    });
  }
  handleCloseExpaneded = () => {
    this.setState({ 
      ...this.state,
      closeExpandedView: true
    }  
    );
  }

  resetExpandedView = () => {
    this.setState({ 
      ...this.state,
      closeExpandedView: false
    }  
    );
  }

  handleMultiSelect = (name, data) => {
    this.setState({ 
      ...this.state,
      dropDowns: {
        ...this.state.dropDowns,
        [name]: data || []   
      },
      showOptions: {
        ...this.state.showOptions,
        [name]: true
      },
      showMore: {
        ...this.state.showMore,
        [name]: false
      }
    }, () => {
      if(name === "companyMultiSelect") {
        let companyIds = data.map(companies => companies.id);
        this.props.departmentFilterDropdownRequest({ org_id: companyIds });
        let deptId = this.state.dropDowns.departmentMultiSelect.map(department => department.id);
        let roleName = this.state.dropDowns.roleMultiSelect.map(role => role.name);
        this.props.employeeFilterDropdownRequest({ org_id: companyIds, dept_id: deptId, role: roleName });
      } else if(name === "departmentMultiSelect") {
        let orgId = []; let deptId = [], roleName = [];
        orgId = this.state.dropDowns.companyMultiSelect.map(company => company.id);
        deptId = this.state.dropDowns.departmentMultiSelect.map(department => department.id);
        roleName = this.state.dropDowns.roleMultiSelect.map(role => role.name);
        this.props.employeeFilterDropdownRequest({ org_id: orgId, dept_id: deptId, role: roleName });
      } else if(name === "roleMultiSelect") {
        let orgId = []; let deptId = [], roleName = [];
        orgId = this.state.dropDowns.companyMultiSelect.map(company => company.id);
        deptId = this.state.dropDowns.departmentMultiSelect.map(department => department.id);
        roleName = this.state.dropDowns.roleMultiSelect.map(role => role.name);
        this.props.employeeFilterDropdownRequest({ org_id: orgId, dept_id: deptId, role: roleName });
      }
    });
  }

  handleDeleteOptions = (name, index) => {
    let updatedChips = [ ...this.state.dropDowns[name] ];
    updatedChips.splice(index, 1);
    this.setState({ 
      ...this.state,
      dropDowns: {
        ...this.state.dropDowns,
        [name]: updatedChips
      },
      showOptions: {
        ...this.state.showOptions,
        [name]: true
      }
      
    }, () => {
      if(name === "companyMultiSelect") {
        let companyIds = this.state.dropDowns.companyMultiSelect.map(companies => companies.id);
        this.props.departmentFilterDropdownRequest({ org_id: companyIds });
        let deptId = this.state.dropDowns.departmentMultiSelect.map(department => department.id);
        let roleName = this.state.dropDowns.roleMultiSelect.map(role => role.name);
        this.props.employeeFilterDropdownRequest({ org_id: companyIds, dept_id: deptId, role: roleName });
      } else if(name === "departmentMultiSelect") {
        let orgId = []; let deptId = [];
        orgId = this.state.dropDowns.companyMultiSelect.map(company => company.id);
        deptId = this.state.dropDowns.departmentMultiSelect.map(department => department.id);
        let roleName = this.state.dropDowns.roleMultiSelect.map(role => role.name);
        this.props.employeeFilterDropdownRequest({ org_id: orgId, dept_id: deptId, role: roleName });
      } else if(name === "roleMultiSelect") {
        let orgId = []; let deptId = [], roleName = [];
        orgId = this.state.dropDowns.companyMultiSelect.map(company => company.id);
        deptId = this.state.dropDowns.departmentMultiSelect.map(department => department.id);
        roleName = this.state.dropDowns.roleMultiSelect.map(role => role.name);
        this.props.employeeFilterDropdownRequest({ org_id: orgId, dept_id: deptId, role: roleName });
      }
      if(this.state.showMore[name] && this.state.dropDowns[name].length > 2) {
        this.setState({ 
          ...this.state,
          showMore: {
            ...this.state.showMore,
            [name]: true
          }
        });
      }
    });
  }

  handleShowMoreMultiSelect = (name) => {
    this.setState({ 
      ...this.state,
      showMore: {
        ...this.state.showMore,
        [name]: true   
      }
    });
  }

  handleCloseMultiSelectOption = () => {
    if(this.state.showOptions.companyMultiSelect) {
      this.setState({ 
        ...this.state,
        showOptions: {
          companyMultiSelect: false
        }, 
        showMore: {
          companyMultiSelect: false
        }
      });
    }
  }

  closeAll = () => {
    if(this.state.showMore.companyMultiSelect || this.state.showMore.departmentMultiSelect || this.state.showMore.roleMultiSelect || this.state.showMore.employeeMultiSelect) {
      this.setState({ 
        ...this.state,
        showMore: {
          companyMultiSelect: false,
          departmentMultiSelect: false,
          roleMultiSelect: false,
          employeeMultiSelect: false
        }
      });
    }
  }

  handleSearchableChange = async (inputValue, searchName) => {
    let trimmedInputValue = inputValue.trim();
    if(trimmedInputValue.length > 1) {
      let orgId = []; let deptId = [];
      orgId = this.state.dropDowns.companyMultiSelect.map(company => company.id);
      deptId = this.state.dropDowns.departmentMultiSelect.map(department => department.id);
      let roleName = this.state.dropDowns.roleMultiSelect.map(role => role.name);
      if(searchName === EMPLOYEE_SEARCH_NAME.EMPLOYEES) {
        return await this.getEmployees(orgId, deptId, roleName, trimmedInputValue)
      } else if(searchName === EMPLOYEE_SEARCH_NAME.EMPLOYEE_IDS) {
        return await this.getEmployeesById(orgId, deptId, roleName, trimmedInputValue)
      }
    }
  }

  getEmployees= async(orgId, deptId, roleName, trimmedInputValue)=>{
    const data = await AppreciationDashboardApi.getEmployeesDropdown({ org_id: orgId, dept_id: deptId, role: roleName, search_term: trimmedInputValue });
    const filtered = await convertDataForDropdown(data.response.data.result, "employeeName");
    return filtered;
  }

  getEmployeesById= async(orgId, deptId, roleName, trimmedInputValue)=>{
    const filterByUserId = true;
    const data = await AppreciationDashboardApi.getEmployeesDropdown({ org_id: orgId, dept_id: deptId, role: roleName, search_term: trimmedInputValue, filterByUserId });
    const filtered = await convertDataForDropdown(data.response.data.result, "employeeId");
    return filtered;
  }

  clearAllFilterSelected = (name) => {
    this.setState({ 
      ...this.state,
      dropDowns: {
        ...this.state.dropDowns,
        [name]: []   
      },
      showOptions: {
        ...this.state.showOptions,
        [name]: false
      },
      showMore: {
        ...this.state.showMore,
        [name]: false
      } }, () => {
        let companyIds = this.state.dropDowns.companyMultiSelect.map(company => company.id);
        let deptIds = this.state.dropDowns.departmentMultiSelect.map(department => department.id);
        let roleName = this.state.dropDowns.roleMultiSelect.map(role => role.name);
        this.props.departmentFilterDropdownRequest({ org_id: companyIds });
        this.props.employeeFilterDropdownRequest({ org_id: companyIds, dept_id: deptIds, role: roleName });
    });
  }
  
  // END OF SEARCH FILTERS EVENTS

  // START OF APPRECIATION EXCHANGE EVENTS
  handleAppreciationExchangeFrequency = (frequency) => {
    this.setState({ 
      ...this.state,
      appreciationExchange: {
        ...this.state.appreciationExchange,
        appreciationExchangeFrequency: frequency
      }
    }, () => {
      this.props.histogramDataRequest({ ...this.getFilterObject(), parameter: this.state.appreciationExchange.appreciationExchangeFrequency });
    });
  }

  handleApperciationExchangeBarClick = (selectedBar) => {
    if(selectedBar.dept_id) {
      this.setState({
        ...this.state,
        appreciationExchange: {
          ...this.state.appreciationExchange,
          showDepartmentDrillDown: true,
          departmentDrillDownName: selectedBar.dept_name,
          department_id: selectedBar.dept_id
        }
      }, () => {
        this.props.departmentDrilldownHistogramDataRequest({ ...this.getFilterObject(), department_id: selectedBar.dept_id });
      });
    } else {
      this.setState({
        ...this.state,
        appreciationExchange: {
          ...this.state.appreciationExchange,
          showCompanyDrillDown: true,
          companyDrillDownName: selectedBar.org_name,
          organization_id: selectedBar.org_id 
        }
      }, () => {
        this.props.companyDrilldownHistogramDataRequest({ ...this.getFilterObject(), organization_id: selectedBar.org_id });
      });
    }
  }

  hideCompanyDrillDown = () => {
    this.setState({
      ...this.state,
      appreciationExchange: {
        ...this.state.appreciationExchange,
        showCompanyDrillDown: false
      }
    });
  }

  hideDepartmentDrillDown = () => {
    this.setState({
      ...this.state,
      appreciationExchange: {
        ...this.state.appreciationExchange,
        showDepartmentDrillDown: false
      }
    });
  }

  hideFilterOverlay = () => {
    this.setState({ 
      ...this.state,
      filterOverlay: false,
      showEndDatePicker: false,
      showStartDatePicker: false
    });
  }
  // END OF APPRECIATION EXCHANGE EVENTS
   
  // START OF CENTER OF INFLUENCE EVENTS
   handleEmployeeClicked = (employee) => {
     //  let newlink = document.createElement("a");
     //  newlink.setAttribute("href", "#individualAppreciationSection");
     //  newlink.click();
     this.setState({ 
       ...this.state,
       individualAppreciation: {
         showIndividualAppreciation: true,
         individualName: employee.name,
         individualEmail: employee.receiver_email || employee.giver_email || employee.email,
         individualUserId: employee.user_id,
         date: {
           from: this.state.timeFrame.startDate ? moment(this.state.timeFrame.startDate).format("YYYY-MM-DD") : moment().startOf("year").format("YYYY-MM-DD"),
           to: this.state.timeFrame.endDate ? moment(this.state.timeFrame.endDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")
         }
       } 
     }, () => {
       let apiPayload = {
         date: {
           from: this.state.timeFrame.startDate ? moment(this.state.timeFrame.startDate).format("YYYY-MM-DD") : moment().startOf("year").format("YYYY-MM-DD"),
           to: this.state.timeFrame.endDate ? moment(this.state.timeFrame.endDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")
         },
         userId: employee.user_id,

       };
       this.props.spiderDataRequest( { ...this.getFilterObject(), ...apiPayload } );
       window.scrollTo(0, document.body.scrollHeight);
     });
   }

   handleStrengthClicked = (clickedPayload) => {
     let { dept_id, name, parentStrength } = clickedPayload;
     let apiPayload = {
       strength_name: clickedPayload.parentStrength ? clickedPayload.parentStrength : name,
       department_id: dept_id || null
     };
     this.setState({ 
       ...this.state,
       centerofInfluence: {
         ...this.state.centerofInfluence,
         employeeList: {
           ...this.state.centerofInfluence.employeeList,
           strengthName: parentStrength || name,
           departmentName: parentStrength ? name : null
         } }
     }, () => {
       this.props.strengthEmployeeListRequest({ ...this.getFilterObject(), ...apiPayload });
     });
   }

   handleRangeSlider = value => {
     this.setState({ 
       ...this.state,
       centerofInfluence: {
         ...this.state.centerofInfluence,
         treemap: {
           ...this.state.centerofInfluence.treemap,
           rangeSliderValue: value
         }
       }
     });
     this.props.treeMapDataRequest({ ...this.getFilterObject(), no_of_strengths: value });
   }

  handleFilterTreeMap = value => {
    this.props.treeMapDataRequest({ ...this.getFilterObject(), ...value });
  }
  switchHandler = (checked) => {
    this.setState({ 
      ...this.state,
      centerofInfluence: {
        ...this.state.centerofInfluence,
        treemap: {
          ...this.state.centerofInfluence.treemap,
          switch: checked,
          strengthMultiSelect: [],
          rangeSliderValue: 1
        }
      }         
    }, () => {
      let payloadForTreemap = {
        ...this.getFilterObject(),
        no_of_strengths: this.state.centerofInfluence.treemap.checked ? 0 : this.state.centerofInfluence.treemap.rangeSliderValue,
        strengths: this.state.centerofInfluence.treemap.strengthMultiSelect.map(strength => strength.strength_id)
      };
      this.props.treeMapDataRequest(payloadForTreemap);
    });
  }

  handleDeleteStrengthOptionsTreemap = (name, index) => {
    let updatedStrength = [ ...this.state.centerofInfluence.treemap[name] ];
    updatedStrength.splice(index, 1);
    this.setState({ 
      ...this.state,
      centerofInfluence: {
        ...this.state.centerofInfluence,
        treemap: {
          ...this.state.centerofInfluence.treemap,
          [name]: updatedStrength
        }
      }
    });
  }
  handleShowMoreMultiSelectTreemap = () => {
    this.setState({
      ...this.state,
      showMoreStrength: true
    });
  }
  handleMultiSelectTreemap = (name, data) => {
    this.setState({
      ...this.state,
      centerofInfluence: {
        ...this.state.centerofInfluence,
        treemap: {
          ...this.state.centerofInfluence.treemap,
          [name]: data
        }
      }
    }, () => {
      // let payload = {
      //   strengths: this.state.centerofInfluence.treemap.strengthMultiSelect.map(strength => strength.strength_id)
      // };
      // this.handleFilterTreeMap(payload);
    });
  
    // this.setState({ 
    //   ...this.state,
    //   [name]: data,
    //   isFilterApplied: true
    // }, () => {
    //   let payload = {
    //     strengths: this.state.strengthMultiSelect.map(strength => strength.strength_id)
    //   };
    //   this.props.handleTreeMap(payload);
    // }
    // );
  }
  clearAllSelectedStrengthsTreemap = () => {
    this.setState({
      ...this.state,
      centerofInfluence: {
        ...this.state.centerofInfluence,
        treemap: {
          ...this.state.centerofInfluence.treemap,
          strengthMultiSelect: []
        }
      }
      
    });
  }
  // END OF CENTER OF INFLUENCE EVENTS
  
  // START OF APPRECIATION FLOW EVENTS
  handleRadioSelectHeatmap = (name, radioName) => {
    this.setState({
      ...this.state,
      appreciationFlow: {
        ...this.state.appreciationFlow,
        heatmap: {
          ...this.state.appreciationFlow.heatmap,
          radioSelect: radioName
        }
      }
      
    }, () => {
      this.props.appreciationFlowHeatmapRequest({ ...this.getFilterObject(), chart: "heatmap", parameter: radioName });
    });
  }

  handleSankeyRadioSelect = (value) => {
    this.setState({
      ...this.state,
      appreciationFlow: {
        ...this.state.appreciationFlow,
        sankey: {
          ...this.state.appreciationFlow.sankey,
          radioSelect: value
        }
      }
    }, () => {
      this.props.appreciationFlowRequest({ ...this.getFilterObject(), parameter: value });
    });
  }
  // END OF APPRECIATION FLOW ECENTS
  handleFilterClick = (e) => {
    e.preventDefault();
    let selectedCompanies = this.state.dropDowns.companyMultiSelect.map(company => company.id);
    let selectedDepartment = this.state.dropDowns.departmentMultiSelect.map(department => department.id);
    this.setState({ // State changes before filter click
      ...this.state,
      selectedCompanies: [...selectedCompanies, ...selectedDepartment],
      appreciationExchange: {
        ...this.state.appreciationExchange,
        appreciationExchangeFrequency: "month",
        showDepartmentDrillDown: false,
        departmentDrillDownName: "",
        showCompanyDrillDown: false,
        companyDrillDownName: ""
      },
      individualAppreciation: {
        ...this.state.individualAppreciation,
        showIndividualAppreciation: false
      },
      centerofInfluence: {
        ...this.state.centerofInfluence,
        employeeList: {
          strengthName: "",
          departmentName: ""
        }        
      },
      appreciationFlow: {
        heatmap: {
          radioSelect: "all"
        },
        sankey: {
          radioSelect: "all"
        }
      }
    }, () => {
      // let payloadForTreemap = {
      //   ...this.getFilterObject(),
      //   no_of_strengths: this.state.centerofInfluence.treemap.checked ? 0 : this.state.centerofInfluence.treemap.rangeSliderValue,
      //   strengths: this.state.centerofInfluence.treemap.strengthMultiSelect.map(strength => strength.strength_id)
      // }
      this.props.tilesDropdownRequest({ ...this.getFilterObject() });
      this.props.histogramDataRequest({ ...this.getFilterObject(), parameter: this.state.appreciationExchange.appreciationExchangeFrequency });
      this.props.departmentHistogramDataRequest({ ...this.getFilterObject(), parameter: "department" });
      this.props.companyHistogramDataRequest({ ...this.getFilterObject(), parameter: "organization" });
      this.props.wordCloudDataRequest({ ...this.getFilterObject() });
      this.props.sunburstDataRequest({ ...this.getFilterObject() });
      // this.props.treeMapDataRequest(payloadForTreemap);
      this.props.resetEmployeeList();
      this.props.appreciationFlowRequest({ ...this.getFilterObject() });
      this.switchHandler(false);
      this.props.appreciationFlowHeatmapRequest({ ...this.getFilterObject(), chart: "heatmap" });
      this.props.appreciationFlowHeatmapMessageExchangeReset();
    });
  }
  onTopClicked = (e) => {
    window.scrollTo(0, 0); 
  } 

  handlePdfGeneration = (e, id) => {
    this.props.startPDFexport();
    e.preventDefault();
    handlePdfGeneration(id, this.props.endPDFexport);
  }

  render () {
    document.body.addEventListener("click", this.closeAll, true);
    return (
      <div className="appreciationContainer" id="appreciationContainer" >
        <div className="row">
                            
          <Filter 
            dashboardOverlay={ this.props.dashboardOverlay }
            companyFilter={ this.props.companyFilter }
            departmentFilter={ this.props.departmentFilter }
            employeesFilter={ this.props.employeesFilter }
            ageGroupFilter={ this.props.ageGroupFilter }
            rolesFilter={ this.props.rolesFilter }
            handleStartDateSelect ={this.handleStartDateSelect}  
            showStartDatePicker= {this.state.showStartDatePicker}
            showEndDatePicker ={this.state.showEndDatePicker}
            handleEndDateSelect ={ this.handleEndDateSelect }
            timeFrame={this.state.timeFrame}
            filterOverlay = { this.state.filterOverlay }
            hideFilterOverlay = { this.hideFilterOverlay }
            toggleStartDatepicker={this.toggleStartDatepicker}
            toggleEndDatepicker={this.toggleEndDatepicker}
            handleMultiSelect={this.handleMultiSelect}
            handleDeleteOptions={this.handleDeleteOptions}
            dropDowns={this.state.dropDowns}
            handleShowMoreMultiSelect={this.handleShowMoreMultiSelect}
            showMore={this.state.showMore}
            showOptions={this.state.showOptions}
            handleSearchableChange={this.handleSearchableChange}
            handleFilterClick={ this.handleFilterClick }
            clearAllSelected = { this.clearAllFilterSelected }
          />
          
        {this.props.isChromeBasedBrowser ? 
          <div className="actionbtnContainer topExport">
            <div className={`exportPdfbtn ${this.props.pdfExport ? "btn-loader" : ""}`}>
              <button className="btn" onClick={ (e) => this.handlePdfGeneration(e, "outer-container") } >
                Export Dashboard 
                <img src={ pdficon } title="pdficon"/>
              </button>
            </div>
          </div>
        : null }

          <TilesSection  />
          
          <AppreciationExchangeSection 
            timeFrame={this.state.timeFrame}
            handleAppreciationExchangeFrequency={ this.handleAppreciationExchangeFrequency }
            appreciationExchangeFrequency = { this.state.appreciationExchange.appreciationExchangeFrequency }
            appreciationExchangeStateData = { this.state.appreciationExchange }
            handleExpandChart = { this.handleExpandChart }
            showEventModal = { this.props.showEventModal }
            hideEventModal = { this.props.hideEventModal }
            handleBarClick = { this.handleApperciationExchangeBarClick }
            hideCompanyDrillDown = { this.hideCompanyDrillDown }
            hideDepartmentDrillDown = { this.hideDepartmentDrillDown }
            filterForCSV = { this.getFilterObject }
          />

          <AppreciationFlow 
            showEventModal = { this.props.showEventModal }
            hideEventModal = { this.props.hideEventModal }
            getFilterObject = { this.getFilterObject }
            appreciationFlow = { this.state.appreciationFlow }
            handleSankeyRadioSelect = { this.handleSankeyRadioSelect }
            handleRadioSelect = { this.handleRadioSelectHeatmap }
            selectedCompanies = { this.state.selectedCompanies }
            selectedDate = {
              { from: this.state.timeFrame.startDate ? moment(this.state.timeFrame.startDate).format("YYYY-MM-DD") : moment().startOf("year").format("YYYY-MM-DD"),
                to: this.state.timeFrame.endDate ? moment(this.state.timeFrame.endDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD") }
            }
          />
         
          <CenterInfluence 
            handleWordClicked = { this.handleEmployeeClicked }
            showEventModal = { this.props.showEventModal }
            hideEventModal = { this.props.hideEventModal }
            handleRangeSlider = { this.handleRangeSlider }
            handleTreeMap = {this.handleFilterTreeMap }
            getFilterObject = { this.getFilterObject }
            from={"centerofInfluence"}
            handleStrengthClicked = { this.handleStrengthClicked }
            employeeList = { this.state.centerofInfluence.employeeList }
            rangeSliderValue = { this.state.centerofInfluence.treemap.rangeSliderValue }
            switch={ this.state.centerofInfluence.treemap.switch }
            switchHandler = { this.switchHandler }
            handleMultiSelect = { this.handleMultiSelectTreemap }
            handleDeleteStrengthOptions = { this.handleDeleteStrengthOptionsTreemap }
            strengthMultiSelect = { this.state.centerofInfluence.treemap.strengthMultiSelect }
            clearAllSelectedStrengths = { this.clearAllSelectedStrengthsTreemap }
          />

          <div id="individualAppreciationSection">
            { this.state.individualAppreciation.showIndividualAppreciation 
              ? <IndividualAppreciationSection 
                individualName = { this.state.individualAppreciation.individualName }
                individualEmail = { this.state.individualAppreciation.individualEmail }
                individualUserId = { this.state.individualAppreciation.individualUserId }
                showEventModal = { this.props.showEventModal }
                hideEventModal = { this.props.hideEventModal }
                getFilterObject = { this.getFilterObject }
                selectedDate = {
                  { from: this.state.timeFrame.startDate ? moment(this.state.timeFrame.startDate).format("YYYY-MM-DD") : moment().startOf("year").format("YYYY-MM-DD"),
                    to: this.state.timeFrame.endDate ? moment(this.state.timeFrame.endDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD") }
                }
              /> : null }
          </div>
          {/* <TabSection /> */}
          {/* <SpiderLoader /> */}
          <Footer />
          <div className="gotopbutton" id="gototopbutton" onClick={this.onTopClicked}>
            <img src={gotop} title="Go to Top" /> 
          </div>
        </div>
        
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isChromeBasedBrowser: state.Common.isChromeBasedBrowser,
    dashboardOverlay: state.Common.dashboardOverlay,
    loginReducer: state.Login,
    companyFilter: state.AppreciationDashboard.filterDropDown.company,
    departmentFilter: state.AppreciationDashboard.filterDropDown.department,
    employeesFilter: state.AppreciationDashboard.filterDropDown.employees,
    ageGroupFilter: state.AppreciationDashboard.filterDropDown.ageGroup,
    rolesFilter: state.AppreciationDashboard.filterDropDown.roles,
    individualAppreciationData: state.AppreciationDashboard.individualAppreciation,
    pdfExport: state.Common.pdfExport,
    filters: state.AppreciationDashboard.filters,
    isAppreciationsEnabled: state.Login.isAppreciationsEnabled
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showDashboardOverlay: () => dispatch(CommonActions.showDashboardOverlayAction()),
    hideDashboardOverlay: () => dispatch(CommonActions.hideDashboardOverlayAction()),
    startPDFexport: () => dispatch(CommonActions.startPDFexport()),
    endPDFexport: () => dispatch(CommonActions.endPDFexport()),
    companyFilterDropdownRequest: (data) => dispatch(AppreciationActions.companyFilterDropdownRequestAction(data)),
    departmentFilterDropdownRequest: (data) => dispatch(AppreciationActions.departmentFilterDropdownRequestAction(data)),
    employeeFilterDropdownRequest: (data) => dispatch(AppreciationActions.employeeFilterDropdownRequestAction(data)),
    ageGroupFilterDropdownRequest: () => dispatch(AppreciationActions.ageGroupFilterDropdownRequestAction()),
    roleFilterDropdownRequest: () => dispatch(AppreciationActions.roleFilterDropdownRequestAction()),
    histogramDataRequest: (data) => dispatch(AppreciationActions.histogramDataRequestAction(data)),
    tilesDropdownRequest: (data) => dispatch(AppreciationActions.tilesDropdownRequestAction(data)),
    employeeFilterDropdownSuccess: (data) => dispatch(AppreciationActions.employeeFilterDropdownSuccessAction(data)),
    showEventModal: (data) => dispatch(CommonActions.showEventModalAction(data)),
    hideEventModal: () => dispatch(CommonActions.hideEventModalAction()),
    departmentHistogramDataRequest: (data) => dispatch(AppreciationActions.departmentHistogramDataRequestAction(data)),
    companyHistogramDataRequest: (data) => dispatch(AppreciationActions.companyHistogramDataRequestAction(data)),
    departmentDrilldownHistogramDataRequest: (data) => dispatch(AppreciationActions.departmentDrilldownHistogramDataRequestAction(data)),
    companyDrilldownHistogramDataRequest: (data) => dispatch(AppreciationActions.companyDrilldownHistogramDataRequestAction(data)),
    wordCloudDataRequest: (data) => dispatch(AppreciationActions.wordCloudDataRequestAction(data)),
    spiderDataRequest: (data) => dispatch(AppreciationActions.spiderDataRequestAction(data)),
    sunburstDataRequest: (data) => dispatch(AppreciationActions.sunburstDataRequestAction(data)),
    strengthEmployeeListRequest: (data) => dispatch(AppreciationActions.strengthEmployeeListRequestAction(data)),
    treeMapDataRequest: (data) => dispatch(AppreciationActions.treeMapDataRequestAction(data)),
    resetEmployeeList: () => dispatch(AppreciationActions.resetEmployeeListAction()),
    appreciationFlowRequest: (data) => dispatch(AppreciationActions.appreciationFlowRequestAction(data)),
    appreciationFlowHeatmapRequest: (data) => dispatch(AppreciationActions.appreciationFlowHeatmapRequestAction(data)),
    appreciationFlowHeatmapMessageExchangeReset: () => dispatch(AppreciationActions.appreciationFlowHeatmapMessageExchangeResetAction())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppreciationContainer);
