import { THEME_COLORS } from "../constants/constants";
export const getMaxAppreciationCountForSpider = (inputPayload) => {
  return new Promise((resolve, reject) => {
    let outputPayload = { ...inputPayload };      
    let max = 0;
    outputPayload.result.forEach(element => {
      if(parseInt(element.user_appreciation_count) > max) {
        max = parseInt(element.user_appreciation_count);
      } 
      if(parseInt(element.expected) > max) {
        max = parseInt(element.expected);
      }
    });
    outputPayload.maxAppreciation = max;
    resolve(outputPayload);
  });
};

export const removeDoubleQuotes = (inputPayload) => {
  return new Promise((resolve, reject) => {
    let outputPayload = { ...inputPayload };
    let givenFeeds = outputPayload.given.map((feed) => {
      let transactionMessage = feed.transaction_message;
      if(feed.transaction_message.charAt(0) === "\"" && feed.transaction_message.charAt(feed.transaction_message.length - 1) === "\"") {
        transactionMessage = feed.transaction_message.substr(1, feed.transaction_message.length - 2);
      }
      return ({ ...feed, transaction_message: transactionMessage });
    });
    let receivedFeeds = outputPayload.received.map((feed) => {
      let transactionMessage = feed.transaction_message;
      if(feed.transaction_message.charAt(0) === "\"" && feed.transaction_message.charAt(feed.transaction_message.length - 1) === "\"") {
        transactionMessage = feed.transaction_message.substr(1, feed.transaction_message.length - 2);
      }
      return ({ ...feed, transaction_message: transactionMessage });
    });
    outputPayload.given = givenFeeds;
    outputPayload.received = receivedFeeds;
    resolve(outputPayload);
  });
};

export const dataManipulationForSunburstChart = (inputPayload) => {
  return new Promise((resolve, reject) => {
    let parentStrengths = inputPayload.map((strength, index) => {
      let count = 0;
      let childDepartments = [];
      strength.dept.forEach(department => {
        // childDepartments = [];
        count += parseInt(department.count);
        childDepartments.push({ ...department, parentStrength: strength.strength_name, size: parseInt(department.count), color: THEME_COLORS[index], strength_id: strength.strength_id });
      });

      return { strength_id: strength.strength_id, strength_name: strength.strength_name, count: count, name: strength.strength_name, color: THEME_COLORS[index], children: childDepartments };
    });
    
    let totalStrengthCount = 0;
    parentStrengths.forEach(element => {
      totalStrengthCount += element.count;
    });
    let outputPayload = {
      parentStrengths: { name: "Strengths", children: parentStrengths, totalStrengthCount: totalStrengthCount } 
    };
    resolve(outputPayload);
  });
};
export const treeMap = (inputPayload, inputStrengths, maxStrengthCount) => {
  return new Promise((resolve, reject) => {
    let outputPayload = [];
    let treeMapPayload = [];
    let strengths = [];
    inputPayload.map((employee, index) => {
      let children = employee.stregths.map((child) => {
        return ({ ...child, name: child.strength_name, size: parseInt(child.count) });
      });

      treeMapPayload.push({ ...employee, name: employee.first_name ? `${employee.first_name.toUpperCase()} ${employee.last_name.toUpperCase()}` : "Anonymous", children: children, color: THEME_COLORS[index] });
    });

    inputStrengths
      ? strengths = inputStrengths.map(strength => {
        return ({ ...strength, strength_id: strength.id, label: strength.name, value: strength.name });
      }) : strengths = [];
    outputPayload.treeMapData = treeMapPayload;
    outputPayload.strengths = strengths;
    outputPayload.maxStrengthCount = maxStrengthCount;
    resolve(outputPayload);
  });
};

export const appreciationFlowManipulation = (inputPayload) => {
  return new Promise((resolve, reject) => {
    let sankeyNodeNameDepartment = [];
    let departmentColor = [];
    let deptColorIndex = 0;
    inputPayload.department.forEach((dept, index) => {
      if(!sankeyNodeNameDepartment.includes(dept.giver_dept_name)) {
        sankeyNodeNameDepartment.push(dept.giver_dept_name);
        if(sankeyNodeNameDepartment.includes(`${dept.giver_dept_name} `)) {
          let color = departmentColor[ sankeyNodeNameDepartment.indexOf(`${dept.giver_dept_name} `) ];
          departmentColor.push(color);
          deptColorIndex++;
        } else {
          departmentColor.push(THEME_COLORS[deptColorIndex % THEME_COLORS.length]);
          deptColorIndex++;
        }
      } else {
        // do nothing
      }  

      if(!sankeyNodeNameDepartment.includes(`${dept.receiver_dept_name} `)) {
        sankeyNodeNameDepartment.push(`${dept.receiver_dept_name} `);
        if(sankeyNodeNameDepartment.includes(`${dept.receiver_dept_name}`)) {
          let color = departmentColor[ sankeyNodeNameDepartment.indexOf(dept.receiver_dept_name) ];
          departmentColor.push(color);
          deptColorIndex++;
        } else {
          departmentColor.push(THEME_COLORS[deptColorIndex % THEME_COLORS.length]);
          deptColorIndex++;
        }
      }
    });

    let sankeyNodesDepartment = [];
    sankeyNodesDepartment = sankeyNodeNameDepartment.map((name, index) => {
      return { name: name, color: departmentColor[index] };
    });
    let sankeyLinksDepartment = inputPayload.department.map(dept => {
      return { ...dept, source: dept.giver_dept_name, target: `${dept.receiver_dept_name} `, value: parseInt(dept.count) };
    });

    let sankeyNodeNameCompany = [];
    let companyColor = [];
    let companyColorIndex = 0;
    inputPayload.organization.forEach((company, index) => {
      if(!sankeyNodeNameCompany.includes(company.giver_org_name)) {
        sankeyNodeNameCompany.push(company.giver_org_name);
        if(sankeyNodeNameCompany.includes(`${company.giver_org_name} `)) {
          let color = companyColor[ sankeyNodeNameCompany.indexOf(`${company.giver_org_name} `) ];
          companyColor.push(color);
          companyColorIndex++;
        } else {
          companyColor.push(THEME_COLORS[companyColorIndex % THEME_COLORS.length]);
          companyColorIndex++;
        }
      } else {
        // do nothing
      }  

      if(!sankeyNodeNameCompany.includes(`${company.receiver_org_name} `)) {
        sankeyNodeNameCompany.push(`${company.receiver_org_name} `);
        if(sankeyNodeNameCompany.includes(`${company.receiver_org_name}`)) {
          let color = companyColor[ sankeyNodeNameCompany.indexOf(company.receiver_org_name) ];
          companyColor.push(color);
          companyColorIndex++;
        } else {
          companyColor.push(THEME_COLORS[companyColorIndex % THEME_COLORS.length]);
          companyColorIndex++;
        }
      }
    });

    let sankeyNodesCompany = [];
    sankeyNodesCompany = sankeyNodeNameCompany.map((name, index) => {
      return { name: name, color: companyColor[index] };
    });
    let sankeyLinksCompany = inputPayload.organization.map(company => {
      return { ...company, source: company.giver_org_name, target: `${company.receiver_org_name} `, value: parseInt(company.count) };
    });

    let sankey = {
      department: {
        links: sankeyLinksDepartment,
        nodes: sankeyNodesDepartment
      },
      company: {
        links: sankeyLinksCompany,
        nodes: sankeyNodesCompany
      }
    };
    let outPutPayload = {
      sankeyData: sankey
    };
    resolve(outPutPayload);
  });
};

export const appreciationFlowHeatmapManipulation = (inputPayload) => {
  return new Promise((resolve, reject) => {
    let departmentNames = [];
    let companiesName = [];
    let maxDeptAppreciationCount = 0;
    let maxCompanyAppreciationCount = 0;
    let departments = inputPayload.department.map((dept) => {
      if(!departmentNames.includes(dept.giver_dept_name)) {
        departmentNames.push(dept.giver_dept_name);
      } 
      if(!departmentNames.includes(dept.receiver_dept_name)) {
        departmentNames.push(dept.receiver_dept_name);
      }
      maxDeptAppreciationCount = maxDeptAppreciationCount < parseInt(dept.count) ? parseInt(dept.count) : maxDeptAppreciationCount;
      return ({ ...dept, giver: dept.giver_dept_name, receiver: dept.receiver_dept_name, value: parseInt(dept.count) });
    });

    let companies = inputPayload.organization.map(company => {
      if(!companiesName.includes(company.giver_org_name)) {
        companiesName.push(company.giver_org_name);
      }
      if(!companiesName.includes(company.receiver_org_name)) {
        companiesName.push(company.receiver_org_name);
      }
      maxCompanyAppreciationCount = maxCompanyAppreciationCount < parseInt(company.count) ? parseInt(company.count) : maxCompanyAppreciationCount;

      return ({ ...company, giver: company.giver_org_name, receiver: company.receiver_org_name, value: parseInt(company.count) });
    });
    let outputPayload = {
      department: departments,
      departmentName: departmentNames,
      maxDeptAppreciationCount: maxDeptAppreciationCount,
      organization: companies,
      companiesName: companiesName,
      maxCompanyAppreciationCount: maxCompanyAppreciationCount
    };
    resolve(outputPayload);
  });
};

export const appreciationFlowHeatmapMessageManipulation = (inputPayload) => {
  return new Promise((resolve, reject) => {
    let outputPayload = inputPayload.map(feed => {
      let transactionMessage = feed.transaction_message;
      if(feed.transaction_message.charAt(0) === "\"" && feed.transaction_message.charAt(feed.transaction_message.length - 1) === "\"") {
        transactionMessage = feed.transaction_message.substr(1, feed.transaction_message.length - 2);
      }
      return ({ ...feed, transaction_message: transactionMessage });
    });
    resolve(outputPayload);
  });
};

export const wordCloud = (inputPayload) => {
  return new Promise((resolve, reject) => {
    let giverMax = 0;
    let giver = inputPayload.giver.result.map(giver => {
      if(giverMax < parseInt(giver.count)) {
        giverMax = parseInt(giver.count);
      } 
      return ({
        ...giver, text: giver.giver_first_name ? `${giver.giver_first_name.toUpperCase()} ${giver.giver_last_name.charAt(0).toUpperCase()}` : "Anonymous", value: parseInt(giver.count), name: giver.giver_first_name ? `${giver.giver_first_name.toUpperCase()} ${giver.giver_last_name.charAt(0).toUpperCase()}` : "Anonymous"
      });
    });
    let receipentMax = 0;
    
    let receipents = inputPayload.receipents.result.map(receipent => {
      if(receipentMax < parseInt(receipent.count)) {
        receipentMax = parseInt(receipent.count);
      }
      return ({
        ...receipent, text: receipent.receiver_first_name ? `${receipent.receiver_first_name.toUpperCase()} ${receipent.receiver_last_name.charAt(0).toUpperCase()}` : "Anonymous", value: parseInt(receipent.count), name: receipent.receiver_first_name ? `${receipent.receiver_first_name.toUpperCase()} ${receipent.receiver_last_name.charAt(0).toUpperCase()}` : "Anonymous"
      });
    });
    
    let updatedPayload = {
      ...inputPayload,
      giver: {
        ...inputPayload.giver,
        result: giver,
        max: giverMax
      },
      receipents: {
        ...inputPayload.receipents,
        result: receipents,
        max: receipentMax
      }
    };
    resolve(updatedPayload);
  });
};