import React from "react";
import * as d3 from "d3";
import { CHART } from "../../constants/constants";
// Heatmap Chart
class HeatMap extends React.Component {
  componentDidMount() {
    this.loadChart();
  }

  componentDidUpdate() {
    this.loadChart();
  }

  handleClick = (clickedContent, props) => {
    // HANDLE CLICKED CONTENT
    this.props.handleClicked ? this.props.handleClicked(clickedContent) : null;
  }

  loadChart = () => {
    const chartDataKeys= this.props.chartDataKeys;
    const xAxis = chartDataKeys && chartDataKeys.xAxis ? chartDataKeys.xAxis : "receiver";
    const yAxis = chartDataKeys && chartDataKeys.yAxis ? chartDataKeys.yAxis : "giver";

    var marginLeft = 0;
    if(!this.props.ignoreLeftTextLength){
      this.props.dataNames.forEach(data => {
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        ctx.font = "12px Roboto";
        let width = ctx.measureText(data).width;
        marginLeft = width > marginLeft ? width : marginLeft;
      });
    }

    var width = this.props.width- this.props.margin.left - this.props.margin.right;
    var height = this.props.height  - this.props.margin.top - this.props.margin.bottom;
    var graphName = this.props.graphName;

    // create a tooltip
    var tooltip = d3.select(this.node)
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "2px")
      .style("border-radius", "5px")
      .style("padding", "5px");

    // Three function that change the tooltip when user hover / move / leave a cell
    var mouseover = function (d) {
      d3.select(this)
        .style("stroke", "#333")
        .style("stroke-width", 1);
    };
    var mousemove = function (d) {
      tooltip
        .html("The exact value of<br>this cell is: " + d.value)
        .style("left", (d3.mouse(this)[0] + 70) + "px")
        .style("top", (d3.mouse(this)[1]) + "px");
    };
    var mouseleave = function (d) {
      d3.select(this)
        .style("stroke", "#333")
        .style("stroke-width", 0.2);
    };

    // append the svg object to the body of the page
    d3.select(this.node).select("svg").remove();
    var svg = d3.select(this.node).append("svg")

      .attr("width", this.props.width+marginLeft+10)
      .attr("height", this.props.height+marginLeft+10)
      .append("g")
      .attr("id", "chartContainer")
      .attr("transform",
        `translate(${this.props.margin.left+marginLeft},${this.props.margin.top})`);

    // Labels of row and columns
    var myGroups = this.props.dataNames;

    // Build X scales and axis:

    var x = d3.scaleBand();
    x.range([0, width])
      .domain(this.props.dataXAxisNames ? this.props.dataXAxisNames : myGroups)
      .padding(0.01);
    svg.append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(x).tickSize(0))
      .selectAll("text")
      .style("text-anchor", "end")
      .style("font-size", 12)
      .style("font-weight", 700)
      .style("font-family", "roboto")
      .style("fill", "#595959")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-45)");

    // .call(wrap, 50)

    function wrap(text, width) {
      text.each(function () {
        var text = d3.select(this);
        var words = text.text().split(/\s+/).reverse();
        var word;
        var line = [];
        var lineNumber = 0;
        var lineHeight = 1.1; // ems
        var y = text.attr("y");
        var dy = parseFloat(text.attr("dy"));
        var tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");
        while (word = words.pop()) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
          }
        }
      });
    }

    // Build X scales and axis:
    var y = d3.scaleBand();
    y.range([height, 0])
      .domain(myGroups)
      .padding(0.01);
    svg.append("g")
      .call(d3.axisLeft(y).tickSize(0))
      .selectAll("text")
      .style("font-size", 12)
      .style("font-family", CHART.fontFamily)
      .style("font-weight", CHART.fontWeight)
      .style("fill", "#595959");
    // Build color scale
    var myColor = d3.scaleLinear()
      .range([this.props.lightColor, this.props.darkColor])
      .domain([0, this.props.maxCount]);

    // Read the data

    let data = this.props.data;
    let labelName = this.props.labelName ? this.props.labelName + ": " : "Appreciation: ";
    var g = svg.selectAll()
      .data(data, function (d) { return d[xAxis] + ":" + d[yAxis]; })
      .enter()
      .append("g");

    d3.selectAll(".tick")
      .append("title")
      .text(d => d);

    g.append("rect")
      .attr("x", function (d) { return x(d[xAxis]); })
      .attr("y", function (d) { return y(d[yAxis]); })
      .attr("width", x.bandwidth())
      .attr("height", y.bandwidth())
      .on("click", (d) => this.handleClick(d))
      .style("cursor", "pointer")
      .style("stroke", "#333")
      .style("stroke-width", 0.2)
      .on("mouseover", mouseover)
      // .on("mousemove", mousemove)
      .on("mouseleave", mouseleave)
      .style("fill", function (d) { return d.value == 0 ? "#fff" : myColor(d.value); });
    g.append("title")
      .text(d =>
        d[yAxis] + " → " +
        d[xAxis] + "\n" + labelName + d.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));
    // add the link titles
    // link.append("title")
    //   .text(function (d) {
    //     return d.source.name + " → " + 
    //                   d.target.name + "\n" + format(d.value); 
    //   });
    // g.append("text")
    //   .attr("x", function (d) { return x(d[yAxis])  })
    //   .attr("y", function (d) { return y(d[xAxis]) })
    //   .attr("width", x.bandwidth())
    //   .attr("height", y.bandwidth())
    //   .attr("stroke", "#000")
    //   .style("font-size",12)
    //   .text((d) => d.value);

    // text label for the x axis
    svg.append("text")
      .attr("transform",
        `translate(${width/2},${marginLeft+this.props.height-20})`)
      .style("text-anchor", "middle")
      .style("font-family", CHART.fontFamily)
      .style("font-weight", CHART.fontWeight)
      .style("font-size", 14)
      .style("fill", "#758088")
      .text( this.props.xAxisName ? this.props.xAxisName : "Appreciation Receiver" );

    // text label for the y axis
    svg.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -(this.props.margin.left+marginLeft))
      .attr("x", 0 - (height / 2))
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .style("font-family", CHART.fontFamily)
      .style("font-weight", CHART.fontWeight)
      .style("font-size", 14)
      .style("fill", "#758088")
      .text( this.props.yAxisName ? this.props.yAxisName : "Appreciation Giver");
  }

  render() {
    return (
      <div ref={(node) => { this.node = node; }} >
        <svg>
          <defs>
            <filter id={`${this.props.graphName}Shadow`} x="-40%" y="-40%" width="180%" height="180%" filterUnits="userSpaceOnUse">
              <feOffset result="offOut" in="SourceAlpha" dx="20" dy="20" />
              <feGaussianBlur result="blurOut" in="offOut" stdDeviation="10" />
              <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
            </filter>
          </defs>

        </svg>
      </div>
    );
  }
}

export default HeatMap;