import React from "react";
import { Heatmap, HeatmapLoader, HeatmapExpanded } from "../../../../../../../Widgets";
import { GraphActions } from "../../../../../../../UI";
import HeatMapMessageExchange from "./HeatMapMessageExchange";
import { GRAPH_IDS } from "../../../../../../../constants/constants";

const handleExpandChart = (props, graphId) => {
  let graph = "";
  let marginLeft = 0;
  props.axisNames.forEach(data => {
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    ctx.font = "12px Roboto";        
    let width = ctx.measureText(data).width;
    marginLeft = width > marginLeft ? width : marginLeft;
  });

  graph = 
  <HeatmapExpanded 
    height = { props.axisNames.length * 20 > window.screen.height * 0.5 ? props.axisNames.length * 20 : props.axisNames.length * 20 > window.screen.height * 0.6 ? window.screen.height * 0.5 : window.screen.height * 0.6 }
    width = { props.axisNames.length * 40 > window.screen.width * 0.75 ? (props.axisNames.length * 50) : props.axisNames.length * 40 > window.screen.width * 0.75 ? window.screen.width * 0.5 : window.screen.width * 0.6 }
    data = { props.heatmapData }
    graphName={props.graphName}
    dataNames = { props.axisNames }
    maxCount = { props.maxAppreciationCount }
    darkColor={props.darkColor}
    lightColor = {props.lightColor }
    margin={{top: 20, right: 20, bottom: 80, left: 150}}
    isExpand={ true}
    scrollWidth={window.screen.width * 0.82}
    scrollHeight = { window.screen.height * 0.5 }
    axisNameWidth = { marginLeft }
  />
  ; 
  
  props.showEventModal({
    showEventModal: true,
    eventModalTitle: "",
    eventModalBody: "",
    showEventModalButtonOne: false,
    showEventModalButtonTwo: false,
    buttonOneEventModalHandler: () => props.hideEventModal(),
    buttonTwoEventModalHandler: null,
    buttonOneEventModalName: "",
    buttonTwoEventModalName: "",
    graphContent: graph,
    graphTitle: props.graphTitle,
    graphSubTitle: props.graphSubTitle,
    showGraph: true,
    graphName: "",
    graphId: ""
  });
};

const HeatmapTabContent = props => {
  let subtitle = "";
  if(props.radioSelect === "all") {
    subtitle = "All";
  } else if(props.radioSelect === "across") {
    subtitle = "Across Entities";
  } else if(props.radioSelect === "within") {
    subtitle = "Within Entities";
  }

  let showGraphAction = false;

  if( props.heatMapTitle !== "Appreciations by Company" && props.selectedCompanies && props.selectedCompanies.length > 0 && props.heatmapData && props.heatmapData.length > 0 ) {
    showGraphAction = true;
  } 

  if( props.heatMapTitle === "Appreciations by Company" && props.heatmapData && props.heatmapData.length > 0 )  {
    showGraphAction = true;
  }

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="graphsActionContainer">
          <div className="daywWiseFilter">
            <div className="custom-control custom-radio custom-control-inline">
              <input type="radio" id= {`${props.graphName}All`} name={`radioSelect${props.graphName}`} className="custom-control-input" checked = { props.radioSelect === "all" } onChange={ () => props.handleRadioSelect("all") } />
              <label className="custom-control-label" htmlFor={`${props.graphName}All`}>All</label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input type="radio" id={`${props.graphName}Across`} name={`radioSelect${props.graphName}`} className="custom-control-input" checked = {props.radioSelect === "across" } onChange={ () => props.handleRadioSelect("across") } />
              <label className="custom-control-label" htmlFor={`${props.graphName}Across`}> Across Entities</label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input type="radio" id={`${props.graphName}Within`} name={`radioSelect${props.graphName}`} className="custom-control-input" checked = { props.radioSelect === "within" } onChange={ () => props.handleRadioSelect("within") } />
              <label className="custom-control-label" htmlFor={`${props.graphName}Within`}> Within Entities</label>
            </div>
          </div>
          <div className="exportContainer">
            <GraphActions 
              showExpansion={showGraphAction}
              showCSVExport={ showGraphAction }
              showImageExport={showGraphAction}
              showInfo={true}
              payLoadData={props.payLoadData}
              filterObjectForCSV={props.filterObjectForCSV}
              export_parameter={props.export_parameter}
              parameter={props.parameter}
              selectedDate={props.selectedDate}
              from={props.from}
              apiUrl={props.apiUrl}
              parameter={props.parameter}
              graphId={ props.heatMapTitle === "Appreciations by Company" ? GRAPH_IDS.HEATMAP_BY_COMPANY : GRAPH_IDS.HEATMAP_BY_DEPARTMENT }
              imageName={`Appreciation Flow ${props.heatMapTitle}`}
              infoMessage = "The color intensity is proportional to the number of appreciations given by the entity on the Y-axis to the entity on the X-axis. Hover on a cell to see the exact number of appreciations. "
              handleExpand={ (graphId) => handleExpandChart({ ...props, graphTitle: "Appreciation Flow", graphSubTitle: `${props.heatMapTitle}(${subtitle})` }, props.heatMapTitle === "Appreciations by Company" ? GRAPH_IDS.HEATMAP_BY_COMPANY : GRAPH_IDS.HEATMAP_BY_DEPARTMENT) } /> 
          
          </div>
        </div> 
        <div className="graphInfoNote">
          <span>Note:</span> {props.noteMessage}
        </div>
        { 
          props.heatmapLoader 
            ? <HeatmapLoader height = { window.screen.height * 0.5 }
              width = { window.screen.width * 0.8 } />
            : props.heatmapData.length > 0 
              ? <div id={ props.heatMapTitle === "Appreciations by Company" ? GRAPH_IDS.HEATMAP_BY_COMPANY : GRAPH_IDS.HEATMAP_BY_DEPARTMENT } >
                { props.heatMapTitle === "Appreciations by Company" 
                  ? <div className="scroll-auto">
                    <Heatmap                                //HEATMAP FOR COMPANY
                      height = { props.axisNames.length * 20 > window.screen.height * 0.5 ? props.axisNames.length * 20 : props.axisNames.length * 20 > window.screen.height * 0.6 ? window.screen.height * 0.5 : window.screen.height * 0.6 }
                      width = { props.axisNames.length * 40 > window.screen.width * 0.75 ? (props.axisNames.length * 50) : props.axisNames.length * 40 > window.screen.width * 0.75 ? window.screen.width * 0.5 : window.screen.width * 0.6 }
                      data = { props.heatmapData }
                      graphName={props.graphName}
                      dataNames = { props.axisNames }
                      maxCount = { props.maxAppreciationCount }
                      handleClicked = { props.handleClicked }
                      darkColor={props.darkColor}
                      lightColor = {props.lightColor }
                      margin={{top: 20, right: 20, bottom: 80, left: 50}}
                    /></div>
                  : props.selectedCompanies.length > 0
                    ? <div className="scroll-auto">
                      <Heatmap                                //HEATMAP FOR DEPARTMENT
                        height = { props.axisNames.length * 20 > window.screen.height * 0.5 ? props.axisNames.length * 20 : props.axisNames.length * 20 > window.screen.height * 0.3 ? window.screen.height * 0.5 : window.screen.height * 0.3 }
                        width = { props.axisNames.length * 40 > window.screen.width * 0.75 ? (props.axisNames.length * 50) : props.axisNames.length * 40 > window.screen.width * 0.75 ? window.screen.width * 0.5 : window.screen.width * 0.6 }
                        data = { props.heatmapData }
                        graphName={props.graphName}
                        dataNames = { props.axisNames }
                        maxCount = { props.maxAppreciationCount }
                        handleClicked = { props.handleClicked }
                        darkColor={props.darkColor}
                        lightColor = {props.lightColor }
                        margin={{top: 20, right: 20, bottom: 80, left: 50}}
                        /></div> : <div className="dataNotFound">
                  Pending Selection
                    </div> 
                }
                 
              </div> 
              : <div className="dataNotFound">  
              No Data Found
              </div> }
                
      </div>
      <div className="col-sm-12">
        
        <HeatMapMessageExchange 
          payLoadData={props.payLoadData}
          filterObjectForCSV={props.filterObjectForCSV}
          export_parameter={props.export_parameter}
          from={"heatMapExchangeMessage"}
          parameter={props.parameter}
          messageExchangeData = { props.messageExchangeData }
          messageExchangeLoader = { props.messageExchangeLoader }
          messageExchangeState = { props.messageExchangeState }
          selectedTab={props.selectedTab}
          graphId = { props.heatMapTitle === "Appreciations by Company" ? GRAPH_IDS.HEATMAP_BY_COMPANY : GRAPH_IDS.HEATMAP_BY_DEPARTMENT }
        />

      </div>
    </div> 
  );
};

export default HeatmapTabContent;
