export const GET_COMPANY_FILTER_DROPDOWN_REQUEST = "GET_COMPANY_FILTER_DROPDOWN_REQUEST";
export const GET_COMPANY_FILTER_DROPDOWN_SUCCESS = "GET_COMPANY_FILTER_DROPDOWN_SUCCESS";
export const GET_COMPANY_FILTER_DROPDOWN_FAIL = "GET_COMPANY_FILTER_DROPDOWN_FAIL";

export const GET_DEPARTMENT_FILTER_DROPDOWN_REQUEST = "GET_DEPARTMENT_FILTER_DROPDOWN_REQUEST";
export const GET_DEPARTMENT_FILTER_DROPDOWN_SUCCESS = "GET_DEPARTMENT_FILTER_DROPDOWN_SUCCESS";
export const GET_DEPARTMENT_FILTER_DROPDOWN_FAIL = "GET_DEPARTMENT_FILTER_DROPDOWN_FAIL";

export const GET_EMPLOYEES_FILTER_DROPDOWN_REQUEST = "GET_EMPLOYEES_FILTER_DROPDOWN_REQUEST";
export const GET_EMPLOYEES_FILTER_DROPDOWN_SUCCESS = "GET_EMPLOYEES_FILTER_DROPDOWN_SUCCESS";
export const GET_EMPLOYEES_FILTER_DROPDOWN_FAIL = "GET_EMPLOYEES_FILTER_DROPDOWN_FAIL";

export const GET_AGE_GROUP_FILTER_DROPDOWN_REQUEST = "GET_AGE_GROUP_FILTER_DROPDOWN_REQUEST"; 
export const GET_AGE_GROUP_FILTER_DROPDOWN_SUCCESS = "GET_AGE_GROUP_FILTER_DROPDOWN_SUCCESS"; 
export const GET_AGE_GROUP_FILTER_DROPDOWN_FAIL = "GET_AGE_GROUP_FILTER_DROPDOWN_FAIL";

export const GET_ROLE_FILTER_DROPDOWN_REQUEST = "GET_ROLE_FILTER_DROPDOWN_REQUEST";
export const GET_ROLE_FILTER_DROPDOWN_SUCCESS = "GET_ROLE_FILTER_DROPDOWN_SUCCESS";
export const GET_ROLE_FILTER_DROPDOWN_FAIL = "GET_ROLE_FILTER_DROPDOWN_FAIL"; 

export const GET_HISTOGRAM_DATA_REQUEST = "GET_HISTOGRAM_DATA_REQUEST";
export const GET_HISTOGRAM_DATA_SUCCESS = "GET_HISTOGRAM_DATA_SUCCESS";
export const GET_HISTOGRAM_DATA_FAIL = "GET_HISTOGRAM_DATA_FAIL";

export const GET_TILES_DATA_REQUEST = "GET_TILES_DATA_REQUEST";
export const GET_TILES_DATA_SUCCESS = "GET_TILES_DATA_SUCCESS";
export const GET_TILES_DATA_FAIL = "GET_TILES_DATA_FAIL"; 

export const GET_COMPANY_HISTOGRAM_DATA_REQUEST = "GET_COMPANY_HISTOGRAM_DATA_REQUEST";
export const GET_COMPANY_HISTOGRAM_DATA_SUCCESS = "GET_COMPANY_HISTOGRAM_DATA_SUCCESS";
export const GET_COMPANY_HISTOGRAM_DATA_FAIL = "GET_COMPANY_HISTOGRAM_DATA_FAIL";

export const GET_DEPARTMENT_HISTOGRAM_DATA_REQUEST = "GET_DEPARTMENT_HISTOGRAM_DATA_REQUEST";
export const GET_DEPARTMENT_HISTOGRAM_DATA_SUCCESS = "GET_DEPARTMENT_HISTOGRAM_DATA_SUCCESS";
export const GET_DEPARTMENT_HISTOGRAM_DATA_FAIL = "GET_DEPARTMENT_HISTOGRAM_DATA_FAIL";

export const GET_COMPANY_DRILLDOWN_DATA_REQUEST = "GET_COMPANY_DRILLDOWN_DATA_REQUEST";
export const GET_COMPANY_DRILLDOWN_DATA_SUCCESS = "GET_COMPANY_DRILLDOWN_DATA_SUCCESS";
export const GET_COMPANY_DRILLDOWN_DATA_FAIL = "GET_COMPANY_DRILLDOWN_DATA_FAIL";

export const GET_DEPARTMENT_DRILLDOWN_DATA_REQUEST = "GET_DEPARTMENT_DRILLDOWN_DATA_REQUEST";
export const GET_DEPARTMENT_DRILLDOWN_DATA_SUCCESS = "GET_DEPARTMENT_DRILLDOWN_DATA_SUCCESS";
export const GET_DEPARTMENT_DRILLDOWN_DATA_FAIL = "GET_DEPARTMENT_DRILLDOWN_DATA_FAIL";

export const GET_WORD_CLOUD_DATA_REQUEST = "GET_WORD_CLOUD_DATA_REQUEST";
export const GET_WORD_CLOUD_DATA_SUCCESS = "GET_WORD_CLOUD_DATA_SUCCESS";
export const GET_WORD_CLOUD_DATA_FAIL = "GET_WORD_CLOUD_DATA_FAIL";

export const GET_SPIDER_DATA_REQUEST = "GET_SPIDER_DATA_REQUEST";
export const GET_SPIDER_DATA_SUCCESS = "GET_SPIDER_DATA_SUCCESS";
export const GET_SPIDER_DATA_FAIL = "GET_SPIDER_DATA_FAIL";

export const GET_APPRECIATION_FEEDS_REQUEST = "GET_APPRECIATION_FEEDS_REQUEST";
export const GET_APPRECIATION_FEEDS_SUCCESS = "GET_APPRECIATION_FEEDS_SUCCESS";
export const GET_APPRECIATION_FEEDS_FAIL = "GET_APPRECIATION_FEEDS_FAIL";

export const GET_SUNBURST_DATA_REQUEST = "GET_SUNBURST_DATA_REQUEST";
export const GET_SUNBURST_DATA_SUCCESS = "GET_SUNBURST_DATA_SUCCESS";
export const GET_SUNBURST_DATA_FAIL = "GET_SUNBURST_DATA_FAIL";

export const GET_STRENGTH_EMPLOYEE_LIST_REQUEST = "GET_STRENGTH_EMPLOYEE_LIST_REQUEST";
export const GET_STRENGTH_EMPLOYEE_LIST_SUCCESS = "GET_STRENGTH_EMPLOYEE_LIST_SUCCESS";
export const GET_STRENGTH_EMPLOYEE_LIST_FAIL = "GET_STRENGTH_EMPLOYEE_LIST_FAIL";
export const RESET_STRENGTH_EMPLOYEE_LIST = "RESET_STRENGTH_EMPLOYEE_LIST";

export const GET_TREE_MAP_DATA_REQUEST = "GET_TREE_MAP_DATA_REQUEST";
export const GET_TREE_MAP_DATA_SUCCESS = "GET_TREE_MAP_DATA_SUCCESS";
export const GET_TREE_MAP_DATA_FAIL = "GET_TREE_MAP_DATA_FAIL";

export const GET_APPRECIATION_FLOW_REQUEST = "GET_APPRECIATION_FLOW_REQUEST";
export const GET_APPRECIATION_FLOW_SUCCESS = "GET_APPRECIATION_FLOW_SUCCESS";
export const GET_APPRECIATION_FLOW_FAIL = "GET_APPRECIATION_FLOW_FAIL";

export const GET_APPRECIATION_FLOW_HEATMAP_REQUEST = "GET_APPRECIATION_FLOW_HEATMAP_REQUEST";
export const GET_APPRECIATION_FLOW_HEATMAP_SUCCESS = "GET_APPRECIATION_FLOW_HEATMAP_SUCCESS";
export const GET_APPRECIATION_FLOW_HEATMAP_FAIL = "GET_APPRECIATION_FLOW_HEATMAP_FAIL";

export const GET_APPRECIATION_FLOW_HEATMAP_MESSAGE_EXCHANGE_REQUEST = "GET_APPRECIATION_FLOW_HEATMAP_MESSAGE_EXCHANGE_REQUEST";
export const GET_APPRECIATION_FLOW_HEATMAP_MESSAGE_EXCHANGE_SUCCESS = "GET_APPRECIATION_FLOW_HEATMAP_MESSAGE_EXCHANGE_SUCCESS";
export const GET_APPRECIATION_FLOW_HEATMAP_MESSAGE_EXCHANGE_FAIL = "GET_APPRECIATION_FLOW_HEATMAP_MESSAGE_EXCHANGE_FAIL";
export const GET_APPRECIATION_FLOW_HEATMAP_MESSAGE_EXCHANGE_RESET = "GET_APPRECIATION_FLOW_HEATMAP_MESSAGE_EXCHANGE_RESET";

export const SET_FILTER_END_DATE = "SET_FILTER_END_DATE";

export const RESET_APPRECIATION_DASHBOARD_REDUCER = "RESET_APPRECIATION_DASHBOARD_REDUCER"; 