import * as LoginActionTypes from "../Actions/LoginActionTypes";

const initialState = {
  authToken: null,
  username: null,
  isParentOrgLogin: false,
  isAppreciationsEnabled: false,
  loginMessage: "",
  loginError: false
};

const LoginReducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case LoginActionTypes.LOGIN_SUCCESS:
      sessionStorage.setItem( "Auth-Token", action.payload.authToken );
      sessionStorage.setItem( "username", action.payload.username );
      sessionStorage.setItem( "isParentOrgLogin", action.payload.isParentOrgLogin );
      sessionStorage.setItem( "isAppreciationsEnabled", action.payload.isAppreciationsEnabled );
      return {
        ...state,
        authToken: action.payload.authToken,
        username: action.payload.username,
        isParentOrgLogin: action.payload.isParentOrgLogin,
        isAppreciationsEnabled: action.payload.isAppreciationsEnabled,
        loginError: false
      };
    case LoginActionTypes.LOGIN_FAIL:
      
      return {
        ...state,
        authToken: null,
        username: null,
        loginError: true,
        loginMessage: action.payload.message
      };
    case LoginActionTypes.LOGOUT_REQUEST:
      sessionStorage.clear(); 
      return {
        ...initialState
      };  
    case LoginActionTypes.LOGIN_ERROR_CLEAR:
      return {
        ...state,
        loginError: false,
        loginMessage: ""
      };
       
    default: 
      return {
        ...state
      };
  }   
};

export default LoginReducer;