import { call, all } from "redux-saga/effects";
import rootLoginSaga from "../Modules/Login/Sagas/LoginSaga";
import rootAppreciationDashboardSaga from "../Modules/Dashboard/Components/Appreciation/Sagas/AppreciationSaga";
import rootRedemptionDashboardSaga from "../Modules/Dashboard/Components/RedemptionPattern/Sagas/RedemptionSaga";
import engagementDashboardSaga from "../Modules/Dashboard/Components/Engagement/Sagas/EngagementSaga";

export default function * rootSaga() {
  yield all( [
    call( rootLoginSaga ),
    call( rootAppreciationDashboardSaga ),
    call( rootRedemptionDashboardSaga ),
    call( engagementDashboardSaga )
  ] );
}
