export const IS_EVENTS_ENABLED_REQ = "IS_EVENTS_ENABLED_REQ";
export const IS_EVENTS_ENABLED_SUCCESS = "IS_EVENTS_ENABLED_SUCCESS";
export const IS_EVENTS_ENABLED_FAIL = "IS_EVENTS_ENABLED_FAIL";

export const TILES_REQ = "TILES_REQ";
export const TILES_SUCCESS = "TILES_SUCCESS";
export const TILES_FAIL = "TILES_FAIL";

export const NUMBER_OF_UNIQUE_USERS_REQ = "NUMBER_OF_UNIQUE_USERS_REQ";
export const NUMBER_OF_UNIQUE_USERS_SUCCESS = "NUMBER_OF_UNIQUE_USERS_SUCCESS";
export const NUMBER_OF_UNIQUE_USERS_FAIL = "NUMBER_OF_UNIQUE_USERS_FAIL";

export const AVG_APP_USERS_BY_DAY_AND_TIME_REQ= "AVG_APP_USERS_BY_DAY_AND_TIME_REQ";
export const AVG_APP_USERS_BY_DAY_AND_TIME_SUCCESS = "AVG_APP_USERS_BY_DAY_AND_TIME_SUCCESS";
export const AVG_APP_USERS_BY_DAY_AND_TIME_FAIL = "AVG_APP_USERS_BY_DAY_AND_TIME_FAIL";

export const PROPORTION_OF_UNIQUE_USER_REQ = "PROPORTION_OF_UNIQUE_USER_REQ";
export const PROPORTION_OF_UNIQUE_USER_SUCCESS = "PROPORTION_OF_UNIQUE_USER_SUCCESS";
export const PROPORTION_OF_UNIQUE_USER_FAIL = "PROPORTION_OF_UNIQUE_USER_FAIL";

export const FREQUENCY_OF_APP_USES_REQ = "FREQUENCY_OF_APP_USES_REQ";
export const FREQUENCY_OF_APP_USES_SUCCESS = "FREQUENCY_OF_APP_USES_SUCCESS";
export const FREQUENCY_OF_APP_USES_FAIL = "FREQUENCY_OF_APP_USES_FAIL";


export const FREQUENCY_OF_FEATURE_USES_REQ = "FREQUENCY_OF_FEATURE_USES_REQ";
export const FREQUENCY_OF_FEATURE_USES_SUCCESS = "FREQUENCY_OF_FEATURE_USES_SUCCESS";
export const FREQUENCY_OF_FEATURE_USES_FAIL = "FREQUENCY_OF_FEATURE_USES_FAIL";

export const GET_POINT_EARNING_CATEGORIES_REQ = "GET_POINT_EARNING_CATEGORIES_REQ";
export const GET_POINT_EARNING_CATEGORIES_SUCCESS = "GET_POINT_EARNING_CATEGORIES_SUCCESS";
export const GET_POINT_EARNING_CATEGORIES_FAIL = "GET_POINT_EARNING_CATEGORIES_FAIL";


export const GET_POINT_EARNING_CATEGORIES_TREND_REQ = "GET_POINT_EARNING_CATEGORIES_TREND_REQ";
export const GET_POINT_EARNING_CATEGORIES_TREND_SUCCESS = "GET_POINT_EARNING_CATEGORIES_TREND_SUCCESS";
export const GET_POINT_EARNING_CATEGORIES_TREND_FAIL = "GET_POINT_EARNING_CATEGORIES_TREND_FAIL";


export const GET_TOP_POINT_EARNER_REQUEST = "GET_TOP_POINT_EARNER_REQUEST";
export const GET_TOP_POINT_EARNER_SUCCESS = "GET_TOP_POINT_EARNER_SUCCESS";
export const GET_TOP_POINT_EARNER_FAIL = "GET_TOP_POINT_EARNER_FAIL";

export const SET_IS_P2P_FLAG = "SET_IS_P2P_FLAG";

export const GET_TOP_POINT_EARNER_DETAILS_REQUEST = "GET_TOP_POINT_EARNER_DETAILS_REQUEST";
export const GET_TOP_POINT_EARNER_DETAILS_SUCCESS = "GET_TOP_POINT_EARNER_DETAILS_SUCCESS";
export const GET_TOP_POINT_EARNER_DETAILS_FAIL = "GET_TOP_POINT_EARNER_DETAILS_FAIL";

export const EVENTS_RESPONSES_REQ= "EVENTS_RESPONSES_REQ";
export const EVENTS_RESPONSES_SUCCESS = "EVENTS_RESPONSES_SUCCESS";
export const EVENTS_RESPONSES_FAIL = "EVENTS_RESPONSES_FAIL";

export const NOTIFICATIONS_REQ= "NOTIFICATIONS_REQ";
export const NOTIFICATIONS_SUCCESS = "NOTIFICATIONS_SUCCESS";
export const NOTIFICATIONS_FAIL = "NOTIFICATIONS_FAIL";
