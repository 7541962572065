import * as RedemptionActionTypes from "../Actions/RedemptionActionTypes";

const initialState = {
  // filterDropDown: {
  //   redcategory: [],
  // },
  tiles: {
    // topRedemptionCategory: { fetched_count: 0, result: [] },
    loader: false,
  },
  snapshotData: {
    loader: false
  },
  trendData: {
    loader: false
  },
  merchantData: {
    loader: false
  },
  categoryData: {
    loader: false
  },
  pointsEarnedRedeemedData : {
    loader:false
  },
  pointsEarnedRedeemedByDriversData : {
    loader:false
  },
  timeOfRedemptionByHourData : {
    loader:false
  },
  timeRedeemedData: {
    loader: false
  },
  ageOfRedemptionBySnapshotData: {
    loader: false
  },
  ageOfRedemptionByTrendData: {
    loader: false
  },
  ageOfRedemptionByFrequencyData: {
    loader: false
  },
  redemptionUserData: {
    loader: false
  }
};

const RedemptionDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    // case RedemptionActionTypes.GET_REDCATEGORY_FILTER_DROPDOWN_SUCCESS:
    //   return {
    //     ...state,
    //     filterDropDown: {
    //       ...state.filterDropDown,
    //       redcategory: action.payload
    //     }
    //   };

    // case RedemptionActionTypes.GET_REDCATEGORY_FILTER_DROPDOWN_FAIL:
    //     return {
    //         ...state,
    //         filterDropDown: {
    //             ...state.filterDropDown,
    //             redcategory: []
    //         }
    //     };

    // case RedemptionActionTypes.GET_FILTER_REDMODE_DROPDOWN_SUCCESS:
    //   return {
    //     ...state,
    //     filterDropDown: {
    //       ...state.filterDropDown,
    //       redMode: action.payload
    //     }
    //   };

    // case RedemptionActionTypes.GET_FILTER_REDMODE_DROPDOWN_FAIL:
    //     return {
    //         ...state,
    //         filterDropDown: {
    //             ...state.filterDropDown,
    //             redMode: []
    //         }
    //     };

    case RedemptionActionTypes.GET_TILES_SUCCESS:
      return {
        ...state,
        tiles: {
          ...action.payload,
          loader: false
        }
      };

    case RedemptionActionTypes.GET_TILES_REQUEST:
      return {
        ...state,
        tiles: {
          ...state.tiles,
          loader: true
        }
      };

    case RedemptionActionTypes.GET_TILES_FAIL:
      return {
        ...state,
        tiles: {
          ...state.tiles,
          loader: false
        }
      };

    case RedemptionActionTypes.GET_BUDGET_SNAPSHOT_SUCCESS:
      return {
        ...state,
        snapshotData: {
          ...action.payload,
          loader: false
        }
      };

    case RedemptionActionTypes.GET_BUDGET_SNAPSHOT_REQUEST:
      return {
        ...state,
        snapshotData: {
          ...state.snapshotData,
          loader: true
        }
      };

    case RedemptionActionTypes.GET_BUDGET_SNAPSHOT_FAIL:
      return {
        ...state,
        snapshotData: {
          ...state.snapshotData,
          loader: false
        }
      };

    case RedemptionActionTypes.GET_BUDGET_TREND_SUCCESS:
      return {
        ...state,
        trendData: {
          ...action.payload,
          loader: false
        }
      };

    case RedemptionActionTypes.GET_BUDGET_TREND_REQUEST:
      return {
        ...state,
        trendData: {
          ...state.trendData,
          loader: true
        }
      };

    case RedemptionActionTypes.GET_BUDGET_TREND_FAIL:
      return {
        ...state,
        trendData: {
          ...state.trendData,
          loader: false
        }
      };

    case RedemptionActionTypes.GET_MERCHANT_DATA_REQUEST:
      return {
        ...state,
        merchantData: {
          ...state.merchantData,
          loader: true
        }
      };

    case RedemptionActionTypes.GET_MERCHANT_DATA_SUCCESS:
      return {
        ...state,
        merchantData: {
          ...action.payload,
          loader: false
        }
      };

    case RedemptionActionTypes.GET_MERCHANT_DATA_FAIL:
      return {
        ...state,
        merchantData: {
          ...state.merchantData,
          loader: false
        }
      };

    case RedemptionActionTypes.GET_CATEGORY_DATA_REQUEST:
      return {
        ...state,
        categoryData: {
          ...state.categoryData,
          loader: true
        }
      };

    case RedemptionActionTypes.GET_CATEGORY_DATA_SUCCESS:
      return {
        ...state,
        categoryData: {
          ...action.payload,
          loader: false
        }
      };

    case RedemptionActionTypes.GET_CATEGORY_DATA_FAIL:
      return {
        ...state,
        categoryData: {
          ...state.categoryData,
          loader: false
        }
      };

    case RedemptionActionTypes.GET_POINTS_EARNED_REDEEMED_SUCCESS:
      return {
        ...state,
        pointsEarnedRedeemedData: {
          ...action.payload,
          loader: false
        }
      };

    case RedemptionActionTypes.GET_POINTS_EARNED_REDEEMED_REQUEST:
      return {
        ...state,
        pointsEarnedRedeemedData: {
          ...state.pointsEarnedRedeemedData,
          loader: true
        }
      };

    case RedemptionActionTypes.GET_POINTS_EARNED_REDEEMED_FAIL:
      return {
        ...state,
        pointsEarnedRedeemedData: {
          ...state.pointsEarnedRedeemedData,
          loader: false
        }
      };

    case RedemptionActionTypes.GET_POINTS_EARNED_REDEEMED_BY_DRIVERS_SUCCESS:
      return {
          ...state,
          pointsEarnedRedeemedByDriversData: {
              ...action.payload,
              loader: false
          }
      };

    case RedemptionActionTypes.GET_POINTS_EARNED_REDEEMED_BY_DRIVERS_REQUEST:
      return {
        ...state,
        pointsEarnedRedeemedByDriversData: {
          ...state.pointsEarnedRedeemedByDriversData,
          loader: true
        }
    };

    case RedemptionActionTypes.GET_POINTS_EARNED_REDEEMED_BY_DRIVERS_FAIL:
      return {
          ...state,
          pointsEarnedRedeemedByDriversData: {
              ...state.pointsEarnedRedeemedByDriversData,
              loader: false
            }
    };

    case RedemptionActionTypes.GET_TIME_OF_REDEMPTION_BY_HOUR_SUCCESS:
      return {
          ...state,
          timeOfRedemptionByHourData: {
              ...action.payload,
              loader: false
          }
      };

    case RedemptionActionTypes.GET_TIME_OF_REDEMPTION_BY_HOUR_REQUEST:
      return {
        ...state,
        timeOfRedemptionByHourData: {
          ...state.timeOfRedemptionByHourData,
          loader: true
        }
    };

    case RedemptionActionTypes.GET_TIME_OF_REDEMPTION_BY_HOUR_FAIL:
      return {
          ...state,
          timeOfRedemptionByHourData: {
              ...state.timeOfRedemptionByHourData,
              loader: false
            }
    };

    case RedemptionActionTypes.GET_AGE_OF_REDEMPTION_BY_SNAPSHOT_SUCCESS:
      return {
          ...state,
          ageOfRedemptionBySnapshotData: {
              ...action.payload,
              loader: false
          }
      };

    case RedemptionActionTypes.GET_AGE_OF_REDEMPTION_BY_SNAPSHOT_REQUEST:
      return {
        ...state,
        ageOfRedemptionBySnapshotData: {
          ...state.ageOfRedemptionBySnapshotData,
          loader: true
        }
    };

    case RedemptionActionTypes.GET_AGE_OF_REDEMPTION_BY_SNAPSHOT_FAIL:
      return {
          ...state,
          ageOfRedemptionBySnapshotData: {
              ...state.ageOfRedemptionBySnapshotData,
              loader: false
            }
    };

    case RedemptionActionTypes.GET_AGE_OF_REDEMPTION_BY_FREQUENCY_SUCCESS:
      return {
          ...state,
          ageOfRedemptionByFrequencyData: {
              ...action.payload,
              loader: false
          }
      };

    case RedemptionActionTypes.GET_AGE_OF_REDEMPTION_BY_FREQUENCY_REQUEST:
      return {
        ...state,
        ageOfRedemptionByFrequencyData: {
          ...state.ageOfRedemptionByFrequencyData,
          loader: true
        }
    };

    case RedemptionActionTypes.GET_AGE_OF_REDEMPTION_BY_FREQUENCY_FAIL:
      return {
          ...state,
          ageOfRedemptionByFrequencyData: {
              ...state.ageOfRedemptionByFrequencyData,
              loader: false
            }
    };

    case RedemptionActionTypes.GET_AGE_OF_REDEMPTION_BY_TREND_SUCCESS:
      return {
          ...state,
          ageOfRedemptionByTrendData: {
              ...action.payload,
              loader: false
          }
      };

    case RedemptionActionTypes.GET_AGE_OF_REDEMPTION_BY_TREND_REQUEST:
      return {
        ...state,
        ageOfRedemptionByTrendData: {
          ...state.ageOfRedemptionByTrendData,
          loader: true
        }
    };

    case RedemptionActionTypes.GET_AGE_OF_REDEMPTION_BY_TREND_FAIL:
      return {
          ...state,
          ageOfRedemptionByTrendData: {
              ...state.ageOfRedemptionByTrendData,
              loader: false
            }
    };

    case RedemptionActionTypes.GET_REDEMPTION_USER_AGE_DATA_SUCCESS:
      return {
          ...state,
          redemptionUserData: {
              ...action.payload,
              loader: false
          }
      };

    case RedemptionActionTypes.GET_REDEMPTION_USER_AGE_DATA_REQUEST:
      return {
        ...state,
        redemptionUserData: {
          ...state.redemptionUserData,
          loader: true
        }
    };

    case RedemptionActionTypes.GET_REDEMPTION_USER_AGE_DATA_FAIL:
      return {
          ...state,
          redemptionUserData: {
              ...state.redemptionUserData,
              loader: false
            }
    };

    case RedemptionActionTypes.GET_TIME_REDEEMED_REQUEST:
      return {
        ...state,
        timeRedeemedData: {
          ...state.timeRedeemedData,
          loader: true
        }
      };

    case RedemptionActionTypes.GET_TIME_REDEEMED_SUCCESS:
      return {
        ...state,
        timeRedeemedData: {
          ...action.payload,
          loader: false
        }
      };


    case RedemptionActionTypes.GET_TIME_REDEEMED_FAIL:
      return {
        ...state,
        timeRedeemedData: {
          ...state.timeRedeemedData,
          loader: false
        }
      };

    default:
      return {
        ...state
      };
  }
};

export default RedemptionDashboardReducer;