import getBetweenDates from "./getBetweenDates";
import moment from "moment";
import _ from "lodash";

export const convertDataForStackedBarChart = (
  apiData,
  dates,
  frequency,
  benchMarkResponse
) => {
  return new Promise(async (resolve, reject) => {
    const monthArray = {
      1: "Jan",
      2: "Feb",
      3: "Mar",
      4: "Apr",
      5: "May",
      6: "Jun",
      7: "July",
      8: "Aug",
      9: "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec"
    };

    for (let i = 0; i < apiData.length; i++) {
      apiData[i]["Appreciations Received"] = apiData[
        i
      ].received_count.toLocaleString();
      apiData[i]["Appreciations Given"] = apiData[
        i
      ].given_count.toLocaleString();

      if (frequency === "month") {
        apiData[i]["xAxis"] = `${monthArray[apiData[i].p2]} ${apiData[i].p1}`;
      } else if (frequency === "week") {
        apiData[i]["xAxis"] = `${moment(apiData[i].date).format(
          "DD MMM YYYY"
        )}`;
      } else if (frequency === "day") {
        apiData[i]["xAxis"] = `${moment(apiData[i].date).format(
          "DD MMM YYYY"
        )}`;
        apiData[i]["year"] = `${moment(apiData[i].date).format("YYYY")}`;
      }
    }

    let matchedRecord, 
      benchMarkData = benchMarkResponse ? benchMarkResponse.data : null;
    if (frequency === "month") {
      apiData.forEach(histogramData => {
        matchedRecord = _.find(benchMarkData, {
          p1: histogramData.p1,
          p2: histogramData.p2
        });
        if (matchedRecord && matchedRecord.benchmark) {
          histogramData.Benchmark = matchedRecord.benchmark;
        }
      });
    }

    resolve(apiData);
  });
};

export const convertDataCompanyDept = apiData => {
  return new Promise((resolve, reject) => {
    let updatedApiData = apiData.map(data => {
      // return ( { ...data, "Appreciation Given": formatNumber(data.given_count), "Appreciation Received": formatNumber(data.received_count) } );
      return {
        ...data,
        "Appreciations Given": data.given_count,
        "Appreciations Received": data.received_count
      };
    });
    resolve(updatedApiData);
  });
};

function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
