import React from "react";
import { RadarChart } from "../../../../../Widgets";
import { Tab, MultiSelect, GraphActions, TabLoader } from "../../../../../UI";
import { connect } from "react-redux";
import * as AppreciationDashboardActions from "../Actions/AppreciationActions";
import { GRAPH_IDS } from "../../../../../constants/constants";
import { dispatch } from "d3";

const handleExpandChart = (props, graphName) => {
  let graph = "";
  if(graphName === GRAPH_IDS.RADAR_CHART) {
    graph = <RadarChart 
      data={props.individualAppreciation.strenghtsData}
      individualName = { props.individualName }
      maxDomain = { props.individualAppreciation.maxAppreciationCount }
      individualEmail = { props.individualEmail }
      height={ window.screen.height * 0.7 }
      width = { window.screen.width * 0.6 }
      legendAlign="right"
    />;
  } 
  
  props.showEventModal({
    showEventModal: true,
    eventModalTitle: "",
    eventModalBody: "",
    showEventModalButtonOne: false,
    showEventModalButtonTwo: false,
    buttonOneEventModalHandler: () => props.hideEventModal(),
    buttonTwoEventModalHandler: null,
    buttonOneEventModalName: "",
    buttonTwoEventModalName: "",
    graphContent: graph,
    graphTitle: `Strength Assessment: ${props.individualName}`,
    graphSubTitle: props.graphSubTitle,
    showGraph: true,
    graphName: "",
    graphId: ""
  });
};

class IndividualAppreciationSection extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      strengthMultiSelect: [],
      strengths: [],
      showMoreStrength: false, 
      strengthMultiSelectOption: []
    };
  }
  
  componentWillReceiveProps (nextProps) {
    if(this.props.individualAppreciation.strenghtsData != nextProps.individualAppreciation.strenghtsData) {
      let strengths = nextProps.individualAppreciation.strenghtsData.map((strength) => {
        return { ...strength, label: strength.name, value: strength.name };
      });
      let strengthDropDown = nextProps.individualAppreciation.strengthDropDown.map(strength => {
        return { ...strength, label: strength.name, value: strength.name, strength_id: strength.id };
      });
      this.setState({ 
        ...this.state,
        strengths: strengths,
        showMoreStrength: false,
        strengthMultiSelect: [],
        strengthMultiSelectOption: strengthDropDown
  
      }, () => {
        let payload = {
          userId: this.props.individualUserId,
          strength_id: this.state.strengthMultiSelect.map(strength => strength.strength_id)
        };
        this.props.appreciationFeedRequest({ date: nextProps.selectedDate, ...payload });
      });
    } if(this.props.individualAppreciation.strengthDropDown != nextProps.individualAppreciation.strengthDropDown) {
      let strengths = nextProps.individualAppreciation.strenghtsData.map((strength) => {
        return { ...strength, label: strength.name, value: strength.name };
      });
      let strengthDropDown = nextProps.individualAppreciation.strengthDropDown.map(strength => {
        return { ...strength, label: strength.name, value: strength.name, strength_id: strength.id };
      });
      this.setState({ 
        ...this.state,
        strengths: strengths,
        showMoreStrength: false,
        strengthMultiSelectOption: strengthDropDown
  
      }, () => {
        let payload = {
          userId: this.props.individualUserId,
          strength_id: this.state.strengthMultiSelect.map(strength => strength.strength_id)
        };
        // this.props.appreciationFeedRequest( { date: nextProps.selectedDate, ...payload} );
      });
    }
  }

  componentDidMount () {
    let payload = {
      userId: this.props.individualUserId,
      strength_id: this.state.strengthMultiSelect.map(strength => strength.strength_id)
    };
    this.props.appreciationFeedRequest({ date: this.props.selectedDate, ...payload });
  }

  handleMultiSelect = (name, data) => {
    this.setState({ 
      ...this.state,
      [name]: data,
      user_Id: this.props.individualUserId
    }, () => {
      let payload = {
        userId: this.props.individualUserId,
        strengths: this.state.strengthMultiSelect.map(strength => strength.name)
      };
      this.props.appreciationFeedRequest({ date: this.props.selectedDate, ...payload });
    }
    
    );
  }

  handleShowMoreMultiSelect = () => {
    this.setState({
      ...this.state,
      showMoreStrength: true
    });
  }
  handleDeleteStrengthOptions = (name, index) => {
    let updatedStrength = [ ...this.state[name] ];
    
    updatedStrength.splice(index, 1);
    
    
    this.setState({ 
      ...this.state,
      [name]: updatedStrength
    }, () => {

      let payload = {
        userId: this.props.individualUserId,
        strengths: this.state.strengthMultiSelect.map(strength => strength.name)
      };
      this.props.appreciationFeedRequest({ date: this.props.selectedDate, ...payload });
    });
  }

  clearAllSelectedStrengths = () => {
    this.setState({
      ...this.state,
      strengthMultiSelect: []
    }, () => {
      let payload = {
        userId: this.props.individualUserId,
        strengths: []
      };
      this.props.appreciationFeedRequest({ date: this.props.selectedDate, ...payload });
    });
  }
  closeShowMore = () => {
    this.setState({
      ...this.state,
      showMoreStrength: false
    });
  }
  render () {


    let screenWidth = window.screen.width;
    if(document.getElementById("tilesSection")) {
      screenWidth = document.getElementById("tilesSection").offsetWidth; 
    }
    if(this.state.showMoreStrength) {
      document.body.addEventListener("click", this.closeShowMore, true); 
    }

    return (

      <div className="individualAppSection">
        <div className="row">
          <div className="col-sm-6">
            <div className="graphsActionContainer">
                
              <h3> Strength Assessment: <span> {this.props.individualName} </span> <span className="mailId">({this.props.individualEmail})</span>  </h3>
              <div className="exportContainer">
                <GraphActions 
                  showExpansion={true}
                  showCSVExport={true}
                  showImageExport={true}
                  showInfo={true}
                  selectedDate={this.props.selectedDate}
                  individualUserId={this.props.individualUserId}
                  filterObjectForCSV={this.props.getFilterObject}
                  from={"spider"}
                  apiUrl={"auth/graph/spider"}
                  graphId={GRAPH_IDS.RADAR_CHART}
                  imageName={`Appreciationof${this.props.individualName}(${this.props.individualEmail})`}
                  infoMessage="This compares the individual's appreciations received versus the company's benchmark average among appreciated employees."
                  handleExpand={ () => handleExpandChart({ ...this.props, graphTitle: "", graphSubTitle: "" }, GRAPH_IDS.RADAR_CHART) } 

                /> 
              </div>
            </div> 
            <div className="cloud-outer">
              <div id={GRAPH_IDS.RADAR_CHART} >
                <RadarChart 
                  data={this.props.individualAppreciation.strenghtsData}
                  individualName = { this.props.individualName }
                  maxDomain = { this.props.individualAppreciation.maxAppreciationCount }
                  individualEmail = { this.props.individualEmail }
                  height={ window.screen.height * 0.5 }
                  width = { screenWidth * 0.5 }
                  legendAlign="right"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="smallBarChart">
              <div className="graphInfoNote">
                <span>Note: </span> Please select one or more strength(s) to view the individual's appreciations received and given for the selected strength(s).
              </div>
              <div className="multiSelectwithlabel">
                <label>Strength</label>
                <MultiSelect           
                  handleMultiSelect = { this.handleMultiSelect }
                  name = "strengthMultiSelect"
                  data = { this.state.strengthMultiSelectOption } 
                  handleDeleteOptions = { this.handleDeleteStrengthOptions }
                  selectedValue = { this.state.strengthMultiSelect }
                  handleShowMore = { this.handleShowMoreMultiSelect }
                  showMore = { this.state.strengthMultiSelect.length > 0 && this.state.showMoreStrength }
                  showOptions = { this.state.strengthMultiSelect.length > 0 && this.state.strengthMultiSelect }
                  clearAllSelected = { this.clearAllSelectedStrengths }
                />    
              </div>
            </div>  
            <div className="tabouter">
              { this.props.individualAppreciation.loader
                ? <TabLoader />
                : <Tab 
                  feeds = { this.props.individualAppreciation.feeds }
                  filterObjectForCSV={this.props.getFilterObject}
                  apiUrl={"auth/graph/feed"}
                  individualUserId={this.props.individualUserId}
                  from={'feed'}
                  selectedDate={this.props.selectedDate}
                />  
              }
                    
            </div>
          </div>
        </div>
      </div>
    );
  }
};
const mapStateToProps = state => {
  return {
    individualAppreciation: state.AppreciationDashboard.individualAppreciation
  };
};

const mapDispatchToProps = dispatch => {
  return {
    appreciationFeedRequest: (data) => dispatch(AppreciationDashboardActions.appreciationFeedRequestAction(data))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(IndividualAppreciationSection);