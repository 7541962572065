import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';

// Export PDF Method
export const handlePdfGeneration = ( id, endPdfExport, name = null ) => {
  const input = document.getElementById( id );
  var w = document.getElementById( id ).offsetWidth;
  var h = document.getElementById( id ).offsetHeight;
  
  var ratio = h / w;
  d3.select(`#gototopbutton`).style("display", "none");
  html2canvas( input, {quality:4, scale:5} )
    .then( ( canvas ) => {
      const imgData = canvas.toDataURL( "image/jpeg", 1 );
      const pdf = new jsPDF( "p", "px", [ w, h ] );
      var width = pdf.internal.pageSize.getWidth();    
      var height = pdf.internal.pageSize.getHeight();
      height = ratio * width;
      pdf.addImage( imgData, "JPEG", 0, 0, width - 20, height - 10 );
      endPdfExport();
      pdf.save( name ? name : "Appreciations.pdf" );
      d3.select(`#gototopbutton`).style("display", "block");
    } )
    .catch( err => {
      endPdfExport();
    } )
};

// Export Image Method
export const handleDownload = ( event, id, imageName, ExternalStyleSheet ) => {
  let svgEl = document.getElementById(id);
  event.preventDefault();
  let isExist = svgEl.classList.contains("d-none"); 
  if(isExist) {
    svgEl.classList.remove("d-none");
  }

  domtoimage.toBlob(svgEl).then(function (blob) {
    saveAs(blob, `${imageName}.png`);
    if(isExist) {
      svgEl.classList.add("d-none");
    }
  });
};