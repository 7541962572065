import Api from "./ApiBase";
var CryptoJS = require( "crypto-js" );

// Common Api Methods
class ApiMethods {
    mapObjectToQueryString = obj => {
      if( obj === undefined ) {
        return "";
      }
      const keyValuePairs = [];

      Object.keys( obj ).forEach( key => {
        // Add the parsed and encoded values to the array
        if( obj[key] ) {
          keyValuePairs.push(
            `${encodeURIComponent( key )}=${encodeURIComponent(
              obj[key].toString()
            )}`
          );
        }
      } );

      // Seperate every object by &
      return keyValuePairs.join( "&" );
    };

    get = async( url ) => {
      try {
        return await Api.get( url );
      } catch ( error ) {
        console.log( "Utilities: ApiMethods: get Method: error::", error );
        throw(error)
      }
    }

    post = async( url, body ) => {
      try {
        return await Api.post( url, body );
      } catch ( error ) {
        console.log( "Utilities: ApiMethods: post Method: error::", error );
        throw error;
      }
    }

    put = async( url, body ) => {
      try {
        return await Api.put( url, body );
      } catch ( error ) {
        console.log( "Utilities: ApiMethods: put Method: error::", error );
      }
    }

    delete = async( url ) => {
      try {
        return await Api.delete( url );
      } catch ( error ) {
        console.log( "Utilities: ApiMethods: delete Method: error::", error );
      }
    }
}

export default new ApiMethods();
