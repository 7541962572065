import React from "react";
import * as d3 from "d3";
import { THEME_COLORS } from "./../../constants/constants";

// Heatmap Chart
class WeeklyBarChart extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      currentDrillDown: "Strengths",
      totalStrengthCount: 0,
      counter: 0
    };
  }

  componentDidMount() {
      this.loadChart();
  }

  componentDidUpdate() {
      this.loadChart();
  }

  loadChart = () => {
    let margin = {top: 30, right: 20, bottom: 116, left: 40},
    width = this.props.width - margin.left - margin.right,
    height = this.props.height - margin.top - margin.bottom;
    
    let legendNames = this.props.legendNames;
    let graphName = this.props.graphName;
    let isExapand = this.props.expandScreen;
    let colorCodes = this.props.colorCodes;

    // scales
    d3.select(this.node).select("svg").remove();
  
    let svg = d3.select(this.node).append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    
    const sample = this.props.data;
    const margin1 = 20;
    const chart = svg.append('g').attr('transform', `translate(${margin1}, ${margin1})`);

    const xScale = d3.scaleBand()
      .range([0, width])
      .domain(sample.map((s) => s.range))
      .padding(0.4)
  
    const yScale = d3.scaleLinear()
      .range([height, 0])
      .domain([0, parseInt((this.props.xMaxValue).toFixed(0))]);

    let XAxisElement = chart.append('g')
      .attr('transform', `translate(0, ${height})`)
      .call(d3.axisBottom(xScale));

    let YAxisElement = chart.append('g')
      .call(d3.axisLeft(yScale).ticks(5));

    let mousemove = function (d) {
      let div = d3.select(`#tooltip-${graphName}-${isExapand}`)
      .attr("class", "redemption-tooltip")
      .style("opacity", 0);

      let e = 0, x = 0, y = 0;
      e = window.event, x = e.clientX, y = e.clientY-40;

      div.transition().duration(200).style("opacity", 1.0);

      div.html( `<span style="color:${colorCodes[1]}"> # of redemptions on ${d.range}: ${d.value} </span>`)
        .style("top", `${y}px`)
        .style("left", `${x-100}px`);
    }

    let mouseout = function(d) {
      let div = d3.select(`#tooltip-${graphName}-${isExapand}`)
      div.transition().duration(500).style("opacity", 0);
    }

    const barGroups = chart.selectAll()
      .data(sample)
      .enter()
      .append('g')

    barGroups
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (g) => xScale(g.range))
      .attr('y', (g) => yScale(g.value))
      .attr('height', (g) => height - yScale(g.value))
      .attr('width', xScale.bandwidth())
      .style('fill', (g) => g.range === 'Weekday' ? colorCodes[0]  : colorCodes[1]  )
      .on("mousemove", mousemove)
      .on("mouseout", mouseout);

      svg.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -40)
      .attr("x", 0 - ((height) / 1.75))
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .style("font-family", "roboto")
      .style("font-weight", 500)
      .style("font-size", 14)
      .style("fill", "#758088")
      .text(this.props.yAxisLabel);

      svg.append("text")
      .attr("transform",
        "translate(" + ((width) / 2) + " ," +
        (height + (margin.bottom - 14 ) ) + ")")
      .style("text-anchor", "middle")
      .style("font-family", "roboto")
      .style("font-weight", 500)
      .style("font-size", 14)
      .style("fill", "#758088")
      .text( this.props.xAxisLabel );

      XAxisElement.selectAll('.tick').selectAll('text').attr("transform", "rotate(-45)").style("font-size", 12)
      .style("font-weight", 700)
      .style("fill", "#758088")
      .style("font-family", "roboto");

      YAxisElement.selectAll('.tick').selectAll('text').style("font-size", 12)
      .style("font-weight", 700)
      .style("fill", "#758088")
      .style("font-family", "roboto");

      XAxisElement.selectAll('.tick').selectAll('text').attr("x", "-15").attr("y", "20");
      YAxisElement.selectAll('.tick').selectAll('text').attr("x", "-10").attr("y", "0");
    }
    
    render() {
      let graphName = this.props.graphName, legendNames = this.props.legendNames, colorCodes = this.props.colorCodes, isExapand = this.props.expandScreen;
        return (
            <div id="timeOfRedemption" class="pointsEarnedAndRedeemed" style={{ border: "1px", borderColor: "black" }} ref={(node) => this.node = node}>
                {/* <div className="legendContainer scroll-auto" style={{}}>
                {this.props.data ? <div className="sunbustLegend">
                {
                    this.props.data.map((entry, index) => {
                    return (<span className="legendItem" style={{ display: "inline-block", marginRight: "10px" }} key={`item-${index}`}>
                        <label style={{ backgroundColor: `${colorCodes[index]}`, width: "15px", height: "12px", margin: "3px 3px 0px 3px", fontSize: "15px", paddingLeft: "0px", color: "#595959", fontFamily: "roboto", fontWeight: "500", display:"inline-block" }} ></label>
                        <label style={{ margin: "0px", fontSize: "12", fontWeight: "400",fontFamily: "roboto" }}>{legendNames[index]}</label>
                    </span>);                    
                    })
                }
                </div>
                : <div className="dataNotFound">
                    No Legends Found
                </div>
                }
                </div> */}

                <div>
                <div id={`tooltip-${graphName}-${isExapand}`} ></div>
                </div>
            </div>
        );
      }
    }
    
    export default WeeklyBarChart;