import React from "react";
import { GraphActions, Table, TableLoader } from "../../../../../UI";
import { connect } from "react-redux";
import "rc-slider/assets/index.css";
import { GRAPH_IDS, REDEMPTION_GRAPH_NAMES, THEME_COLORS } from "../../../../../constants/constants";
// import RedemptionStackedBarChart from "../../../../../Widgets/StackedBarChart/RedemptionStackedBarChart";
import RedemptionStackedBarChartLoader from "../../../../../Widgets/StackedBarChart/StackedBarChartLoader";
import HorizontalBarChart from "../../../../../Widgets/BarChart/HorizontalBarChart";
import SimpleBarChartD3 from "../../../../../Widgets/BarChart/SimpleBarChartD3";
import {LineChart, ExtendedLineChart} from "../../../../../Widgets";
import Switch from "react-switch";

const handleExpandChart = (props, graphName ) => {
  let graph = "";
  let onSwitchColorCode = "#888888";
  if( props.graphTitle === REDEMPTION_GRAPH_NAMES.AGE_OF_REDEMPTION_SNAPSOT ) {
    graph = <div className="scroll-auto text-center" style={{ height: window.screen.height * 0.55 }} >
                <div className="tabContainer">
                  <div className="tablinkouter">
                    <ul className="nav nav-tabs" role="tablist">
                      <li className="nav-item">
                        <a className={`nav-link tab-recive ${!props.ageSnapshotSwitch ? "active" : ""}`} role="tab" data-toggle="tab" disableClick> By Company </a>
                      </li>
                      <li className="nav-item">
                        <a className={`nav-link tab-given ${props.ageSnapshotSwitch ? "active" : ""}`} role="tab" data-toggle="tab" disableClick> By Department </a>
                      </li>
                    </ul>
                  </div>
              </div>
              <HorizontalBarChart
                  // height={window.screen.height * 0.5}
                  height={props.ageOfRedemptionBySnapshotData && props.ageOfRedemptionBySnapshotData.length > 10 ?  window.innerHeight * 0.5 * ( props.ageOfRedemptionBySnapshotData.length * 0.08) : window.innerHeight * 0.5 }
                  width={window.screen.width * 0.7}
                  yAxisLabel={props.ageSnapshotSwitch ? "Department" : "Company"}
                  xAxisLabel={"Number of days"}
                  data={props.ageOfRedemptionBySnapshotData}
                  xMaxValue={props.ageMaxValue}
                  maxLength={props.maxLength}
                  switch = {props.switch}
                  expandScreen={true}
                  legendNames={["Avg Age of Redeemed Points","Avg Age of Unredeemed Points", props.ageSnapshotSwitch ? "Department Name" : "Company Name" ]}
                  colorCodes={[THEME_COLORS[9], THEME_COLORS[8]]}
                />
            </div>;
  } else if( props.graphTitle === REDEMPTION_GRAPH_NAMES.AGE_OF_REDEMPTION_FREQUENCY ) {
    graph = <div className="scroll-auto text-center" style={{ height: window.screen.height * 0.50 }} >
              <div className="tabContainer">
                <div className="tablinkouter">
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a className={`nav-link tab-recive ${!props.ageFrequencySwitch ? "active" : ""}`} role="tab" data-toggle="tab"> By Redeemed Points </a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link tab-given ${props.ageFrequencySwitch ? "active" : ""}`} role="tab" data-toggle="tab"> By Unredeemed Points </a>
                    </li>
                  </ul>
                </div>
              </div>


              <SimpleBarChartD3
                height={window.screen.height * 0.42}
                width={window.screen.width * 0.7}
                yAxisLabel={"Number of Users"}
                xAxisLabel={"Average Age Group (in days)"}
                data={props.ageOfRedemptionByFrequencyData}
                xMaxValue={props.maxNumberUser}
                legendNames={["Number of Users","Age Range"]}
                graphName={'age_of_redemption_frequency_expand'}
                isExapand={true}
                color={props.ageFrequencySwitch ? '#ff4d4d' : 'green' }
              />
            </div>;
  } else if( props.graphTitle === REDEMPTION_GRAPH_NAMES.AGE_OF_REDEMPTION_TREND ) {
    graph = <div className="scroll-auto text-center" style={{ height: window.screen.height * 0.55 }} >
              <div className="tabContainer" style={{width: window.innerWidth * 0.75}}>
                <div className="tablinkouter">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a className={`nav-link tab-recive ${!props.ageTrendDepartmentSwitch ? "active" : ""}`} role="tab" data-toggle="tab"> By Company </a>
                  </li>
                  <li className="nav-item">
                    <a className={`nav-link tab-given ${props.ageTrendDepartmentSwitch ? "active" : ""}`} role="tab" data-toggle="tab"> By Department </a>
                  </li>
                </ul>

                <div className="titlewithSlider d-flex align-items-center right-panel">
                <span className="d-inline-block mr-2"><strong> {props.ageTrendSwitch ? "By Unredeemed Points" : "By Redeemed Points" }: </strong></span>
                  <div className="switchBtnouter">
                    <label className="m-0">
                      <Switch
                        checked={props.ageTrendSwitch}
                        uncheckedIcon={null}
                        checkedIcon={null}
                        onColor={onSwitchColorCode}
                      />
                    </label>
                  </div>
                </div>
              </div>
              </div>

              <div className="budget-utilization-trend-container col-sm-12 text-center" /* style={{ maxHeight: "500px", minWidth: window.innerWidth * 0.8, overflowY: "auto"}} */ >
                <ExtendedLineChart
                  height={window.screen.height * 0.45}
                  width={window.screen.width * 0.47}
                  data={props.ageOfRedemptionByTrendData}
                  yAxisMaxValue = {props.trendYAxisMaxValue}
                  isDepartment={props.ageTrendDepartmentSwitch}
                  isUnRedeemed={props.ageTrendSwitch}
                  graphName={'age-trend'}
                  isExpand={true}
                  yAxisLabel={`Avg Age of ${props.ageTrendSwitch ? "Unredeemed" : "Redeemed"} Points`}
                />
              </div>
            </div>;
  }
  
  props.showEventModal({
    showEventModal: true,
    eventModalTitle: "",
    eventModalBody: "",
    showEventModalButtonOne: false,
    showEventModalButtonTwo: false,
    buttonOneEventModalHandler: () => props.hideEventModal(),
    buttonTwoEventModalHandler: null,
    buttonOneEventModalName: "",
    buttonTwoEventModalName: "",
    graphContent: graph,
    graphTitle: props.graphTitle,
    graphSubTitle: props.graphSubTitle,
    showGraph: true,
    graphName: graphName,
    graphId: ""
  });
};

class AgeOfRedemption extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      ageRange: ""
    };
  }

  getAgeOfRedemptionFilterObjectData = () => {
    let filterObjectData = {
      ...this.props.getFilterObject(),
      bydepartment: this.props.ageSnapshotSwitch,
      byUnredeemed: this.props.ageFrequencySwitch,
      ...this.state,
      ageRange: this.state.ageRange
    }
    return filterObjectData;
  }

  getAgeOfRedemptionTrendFilterObjectData = () => {
    let filterObjectData = {
      ...this.props.getFilterObject(),
      isByDepartment: this.props.ageTrendDepartmentSwitch,
      byUnredeemed:this.props.ageTrendSwitch
    }
    return filterObjectData;
  }

  handleAgeRangeClicked = (clickedPayload) => {
    if(clickedPayload) {
      this.setState({
        ...this.state,
        ageRange : clickedPayload.range
      }, () => {
        this.props.handleAgeRangeClicked(clickedPayload);
      });
    } else {
      this.setState({
        ...this.state,
        sunburstTitle: {
          ...this.state.sunburstTitle,
          ageRange : ""
        }
      }, () => {
        this.props.handleAgeRangeClicked();
      })
    }
  }

  render() {
    let screenWidth = window.screen.width;
    const onSwitchColorCode = "#888888";
    return (
      <div className="budgetUtilization borderTopContainer">
        <div className="row" >
          <div className="col-sm-12">
            <div className="titleOuter">
              <h2>Age of Redeemed vs Unredeemed Points</h2>
            </div>
          </div>
        </div>

        <div className="budgetUtilizationSec1 row pd-10 pt-10">
          <div className="col-lg-12 col-xl-12 left-panel" >
            <div className="graphsActionContainer">
              <h3> Snapshot </h3>
              <div className="exportContainer">
                <GraphActions
                  showExpansion={!this.props.ageOfRedemptionBySnapshotLoader && this.props.ageOfRedemptionBySnapshotData && this.props.ageOfRedemptionBySnapshotData.length > 0 && ( this.props.ageSnapshotSwitch && this.props.selectedCompanies.length > 0 || !this.props.ageSnapshotSwitch)}
                  showCSVExport={!this.props.ageOfRedemptionBySnapshotLoader && this.props.ageOfRedemptionBySnapshotData && this.props.ageOfRedemptionBySnapshotData.length > 0 && ( this.props.ageSnapshotSwitch && this.props.selectedCompanies.length > 0 || !this.props.ageSnapshotSwitch)}
                  showImageExport={!this.props.ageOfRedemptionBySnapshotLoader && this.props.ageOfRedemptionBySnapshotData && this.props.ageOfRedemptionBySnapshotData.length > 0 && ( this.props.ageSnapshotSwitch && this.props.selectedCompanies.length > 0 || !this.props.ageSnapshotSwitch)}
                  showInfo={true}
                  from={!this.props.ageSnapshotSwitch ? 'Age of Redeemed vs Unredeemed Points Snapshot (by company)' : "Age of Redeemed vs Unredeemed Points Snapshot (by department)" }
                  apiUrl={"auth/redemption/ageSnapshot"}
                  filterObjectForCSV={this.getAgeOfRedemptionFilterObjectData}
                  graphId="ageOfRedemptionSnapshot"
                  imageName={`Age Of Redemption Snapshot`}
                  infoMessage={`Age of redeemed points refers to how long (days) the points have been held by the user before they are redeemed. Age of unredeemed points refers to how long (days) the unredeemed points have been held by the user as of the "To" date in the filter above. Ideally, the gap between the two should be minimised. A gap (unredeemed - redeemed) exceeding 2x the age of redeemed points would be critical to address.`}
                  handleExpand={() => handleExpandChart({ ...this.props, graphTitle: REDEMPTION_GRAPH_NAMES.AGE_OF_REDEMPTION_SNAPSOT })}
                />
              </div>
            </div>
            <div className="graphInfoNote">
              
            {this.props.ageSnapshotSwitch && this.props.selectedCompanies.length === 0 
              ? <div> <span>Note: </span> Please filter a company or specific department(s) to view the age of redemption snapshot by department. Next, when avg age of unredeemed points exceeds that of redeemed points, consider reminding your users to redeem their points. </div>
               : <div> <span>Note: </span> When avg age of unredeemed points exceeds that of redeemed points, consider reminding your users to redeem their points. </div>
              }              
            </div>

            <div className="tabContainer">
                <div className="tablinkouter">
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a className={`nav-link tab-recive ${!this.props.ageSnapshotSwitch ? "active" : ""}`} role="tab" data-toggle="tab" onClick={ () => this.props.ageOfRedemptionSnapshotHandler(false, 'Snapshot' ) }> By Company </a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link tab-given ${this.props.ageSnapshotSwitch ? "active" : ""}`} role="tab" data-toggle="tab" onClick={ () => this.props.ageOfRedemptionSnapshotHandler(true, 'Snapshot' ) }> By Department </a>
                    </li>
                  </ul>
                </div>
            </div>

            <div className="budget-utilization-trend-container col-sm-12 text-center" style={{ maxHeight: "500px", minWidth: window.innerWidth * 0.75, overflowY: "auto"}} >
              {this.props.ageSnapshotSwitch && this.props.selectedCompanies.length === 0  ? 
                <div className="dataNotFound">
                  Pending Selection
                </div>
                : !this.props.ageOfRedemptionBySnapshotLoader && this.props.ageOfRedemptionBySnapshotData ?
                  this.props.ageOfRedemptionBySnapshotData.length > 0 ? 
                    <HorizontalBarChart
                      height={this.props.ageOfRedemptionBySnapshotData && this.props.ageOfRedemptionBySnapshotData.length > 10 ?  window.innerHeight * 0.45 * ( this.props.ageOfRedemptionBySnapshotData.length * 0.08) : window.innerHeight * 0.45 }
                      width={window.innerWidth * 0.7}
                      yAxisLabel={this.props.ageSnapshotSwitch ? "Department" : "Company"}
                      xAxisLabel={"Number of days"}
                      data={this.props.ageOfRedemptionBySnapshotData}
                      xMaxValue={this.props.ageMaxValue+25}
                      maxLength={this.props.maxLength}
                      switch = {this.props.switch}
                      expandScreen={false}
                      legendNames={["Avg Age of Redeemed Points","Avg Age of Unredeemed Points", this.props.ageSnapshotSwitch ? "Department Name" : "Company Name" ]}
                      colorCodes={[THEME_COLORS[9], THEME_COLORS[8]]}
                      graphName={'age-snapshot'}
                    />
                  :  <div className="dataNotFound">
                  No Data Found
                  </div>
                : <RedemptionStackedBarChartLoader
                  height={window.screen.height * 0.4}
                  width={screenWidth * 0.7}
                />
                }
            </div>

            <div className="budget-utilization-trend-container col-sm-12 text-center d-none" id="ageOfRedemptionSnapshot"  style={{ minWidth: window.innerWidth * 0.75, minHeight: (this.props.ageOfRedemptionBySnapshotData && this.props.ageOfRedemptionBySnapshotData.length > 10 ?  window.innerHeight * 0.45 * ( this.props.ageOfRedemptionBySnapshotData.length * 0.08) : window.innerHeight * 0.45)+100}} >
              <div className="tabContainer">
                  <div className="tablinkouter">
                    <ul className="nav nav-tabs" role="tablist">
                      <li className="nav-item">
                        <a className={`nav-link tab-recive ${!this.props.ageSnapshotSwitch ? "active" : ""}`} role="tab" data-toggle="tab"> By Company </a>
                      </li>
                      <li className="nav-item">
                        <a className={`nav-link tab-given ${this.props.ageSnapshotSwitch ? "active" : ""}`} role="tab" data-toggle="tab"> By Department </a>
                      </li>
                    </ul>
                  </div>
              </div>

              {this.props.ageSnapshotSwitch && this.props.selectedCompanies.length === 0  ? 
                <div className="dataNotFound">
                  Pending Selection
                </div>
                : !this.props.ageOfRedemptionBySnapshotLoader && this.props.ageOfRedemptionBySnapshotData ?
                  this.props.ageOfRedemptionBySnapshotData.length > 0 ? 
                    <HorizontalBarChart
                      height={this.props.ageOfRedemptionBySnapshotData && this.props.ageOfRedemptionBySnapshotData.length > 10 ?  window.innerHeight * 0.45 * ( this.props.ageOfRedemptionBySnapshotData.length * 0.08) : window.innerHeight * 0.45 }
                      width={window.innerWidth * 0.7}
                      yAxisLabel={this.props.ageSnapshotSwitch ? "Department" : "Company"}
                      xAxisLabel={"Number of days"}
                      data={this.props.ageOfRedemptionBySnapshotData}
                      xMaxValue={this.props.ageMaxValue+25}
                      maxLength={this.props.maxLength}
                      switch = {this.props.switch}
                      expandScreen={false}
                      legendNames={["Avg Age of Redeemed Points","Avg Age of Unredeemed Points", this.props.ageSnapshotSwitch ? "Department Name" : "Company Name" ]}
                      colorCodes={[THEME_COLORS[9], THEME_COLORS[8]]}
                      graphName={'age-snapshot'}
                    />
                  :  <div className="dataNotFound">
                  No Data Found
                  </div>
                : <RedemptionStackedBarChartLoader
                  height={window.screen.height * 0.4}
                  width={screenWidth * 0.7}
                />
                }
            </div>
          </div>

          <div className="col-lg-12 col-xl-12 mt-10" >
            <div className="graphsActionContainer">
              <h3> Monthly Trend </h3>
              <div className="exportContainer">
                <GraphActions
                  showExpansion={!this.props.ageOfRedemptionByTrendDataLoader && this.props.ageOfRedemptionByTrendData && this.props.ageOfRedemptionByTrendData.length > 0 && ( this.props.ageTrendDepartmentSwitch && this.props.selectedCompanies.length > 0 || !this.props.ageTrendDepartmentSwitch)}
                  showCSVExport={!this.props.ageOfRedemptionByTrendDataLoader && this.props.ageOfRedemptionByTrendData && this.props.ageOfRedemptionByTrendData.length > 0 && ( this.props.ageTrendDepartmentSwitch && this.props.selectedCompanies.length > 0 || !this.props.ageTrendDepartmentSwitch)}
                  showImageExport={!this.props.ageOfRedemptionByTrendDataLoader && this.props.ageOfRedemptionByTrendData && this.props.ageOfRedemptionByTrendData.length > 0 && ( this.props.ageTrendDepartmentSwitch && this.props.selectedCompanies.length > 0 || !this.props.ageTrendDepartmentSwitch)}
                  showInfo={true}
                  from={!this.props.ageTrendDepartmentSwitch ? 
                    !this.props.ageTrendSwitch ? "Redeemed Points Age Trend (by company and month)" : "Unredeemed Points Age Trend (by company and month)"
                  : !this.props.ageTrendSwitch ? "Redeemed Points Age Trend (by department and month)" : "Unredeemed Points Age Trend (by department and month)"}
                  apiUrl={"auth/redemption/ageTrend"}
                  filterObjectForCSV={this.getAgeOfRedemptionTrendFilterObjectData}
                  graphId="ageOfRedemptionTrend"
                  imageName={`Age Of Redemption Trend`}
                  infoMessage="(1) A flat line indicates consistency in user behaviour. (2) A declining line indicates users are redeeming faster (for redeemed points) and/or getting awarded fewer points over time (unredeemed points). (3) An increasing line indicates that users are holding points longer before redeeming."
                  handleExpand={() => handleExpandChart({ ...this.props, graphTitle: REDEMPTION_GRAPH_NAMES.AGE_OF_REDEMPTION_TREND })}
                />
              </div>
            </div>
            <div className="graphInfoNote">
              {this.props.ageTrendDepartmentSwitch && this.props.selectedCompanies.length === 0 
                ? <div> <span>Note: </span> Please filter a company or specific department(s) to view the age of redemption trend by department. Next, hover to identify the name of the entity and corresponding avg points age. </div>
                : <div> <span>Note: </span> Please hover to identify the name of the entity and corresponding avg points age. </div>
              }              
            </div>
            <div id="ageOfRedemptionTrend" >
              <div className="tabContainer" style={{width: window.innerWidth * 0.7}}>
                <div className="tablinkouter">
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a className={`nav-link tab-recive ${!this.props.ageTrendDepartmentSwitch ? "active" : ""}`} role="tab" data-toggle="tab" onClick={ () => this.props.ageOfRedemptionTrendHandler(false, 'trend' ) }> By Company </a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link tab-given ${this.props.ageTrendDepartmentSwitch ? "active" : ""}`} role="tab" data-toggle="tab" onClick={ () => this.props.ageOfRedemptionTrendHandler(true, 'trend' ) }> By Department </a>
                    </li>
                  </ul>

                  <div className="titlewithSlider d-flex align-items-center right-panel">
                  <span className="d-inline-block mr-2"><strong> {this.props.ageTrendSwitch ? "By Unredeemed Points" : "By Redeemed Points" }: </strong></span>
                    <div className="switchBtnouter">
                      <label className="m-0">
                        <Switch onChange={() => this.props.ageOfRedemptionSnapshotHandler(!this.props.ageTrendSwitch, 'trend' )}
                          checked={this.props.ageTrendSwitch}
                          uncheckedIcon={null}
                          checkedIcon={null}
                          onColor={onSwitchColorCode}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="budget-utilization-trend-container col-sm-12 text-center" style={{ maxHeight: "500px", minWidth: window.innerWidth * 0.8, overflowY: "auto"}} >
            {this.props.ageTrendDepartmentSwitch && this.props.selectedCompanies.length === 0  ? 
                <div className="dataNotFound">
                  Pending Selection
                </div>
                : !this.props.ageOfRedemptionByTrendDataLoader && this.props.ageOfRedemptionByTrendData ?
                  this.props.ageOfRedemptionByTrendData.length > 0 ? 
                  <ExtendedLineChart
                    height={window.innerHeight * 0.45}
                    width={window.innerWidth * 0.4}
                    data={this.props.ageOfRedemptionByTrendData}
                    yAxisMaxValue = {this.props.trendYAxisMaxValue}
                    isDepartment={this.props.ageTrendDepartmentSwitch}
                    isUnRedeemed={this.props.ageTrendSwitch}
                    graphName={'age-trend'}
                    yAxisLabel={`Avg Age of ${this.props.ageTrendSwitch ? "Unredeemed" : "Redeemed"} Points`}
                  />
                  :  <div className="dataNotFound">
                  No Data Found
                  </div>
                : <RedemptionStackedBarChartLoader
                  height={window.screen.height * 0.4}
                  width={screenWidth * 0.7}
                />
                }
            </div>
            </div>
          </div>
        </div>
        <div className="budgetUtilizationSec1 row pd-10 pt-10">
          <div className="col-lg-12 col-xl-6 left-panel" >
            <div className="graphsActionContainer">
              <h3> Points Distribution by Age  </h3>
              <div className="exportContainer">
                <GraphActions
                  showExpansion={!this.props.ageOfRedemptionByFrequencyLoader && this.props.ageOfRedemptionByFrequencyData && this.props.ageOfRedemptionByFrequencyData.length > 0}
                  showCSVExport={!this.props.ageOfRedemptionByFrequencyLoader && this.props.ageOfRedemptionByFrequencyData && this.props.ageOfRedemptionByFrequencyData.length > 0}
                  showImageExport={!this.props.ageOfRedemptionByFrequencyLoader && this.props.ageOfRedemptionByFrequencyData && this.props.ageOfRedemptionByFrequencyData.length > 0}
                  showInfo={true}
                  from={!this.props.ageFrequencySwitch ? "Points Distribution by Age - Redeemed" : "Points Distribution by Age - Unredeemed"}
                  apiUrl={"auth/redemption/ageFrequency"}
                  filterObjectForCSV={this.getAgeOfRedemptionFilterObjectData}
                  graphId="ageOfRedemptionFrequency"
                  imageName={`Age Of Redemption Frequency`}
                  infoMessage="This chart groups users by different age categories. A long-tail to the right (left) indicates that there are a few users who are holding on to points for a long period (who redeem points quickly upon award)."
                  handleExpand={() => handleExpandChart({ ...this.props, graphTitle: REDEMPTION_GRAPH_NAMES.AGE_OF_REDEMPTION_FREQUENCY })}
                />
              </div>
            </div>
            <div className="graphInfoNote"><span>Note: </span> Please click on a bar to retrieve the list of users with {!this.props.ageFrequencySwitch ? "redeemed" : "unredeemed" } points in that specific age group.</div>

            <div id="ageOfRedemptionFrequency" >
              <div className="tabContainer">
                <div className="tablinkouter">
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a className={`nav-link tab-recive ${!this.props.ageFrequencySwitch ? "active" : ""}`} role="tab" data-toggle="tab" onClick={ () => this.props.ageOfRedemptionSnapshotHandler(false, 'frequency' ) }> By Redeemed Points </a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link tab-given ${this.props.ageFrequencySwitch ? "active" : ""}`} role="tab" data-toggle="tab" onClick={ () => this.props.ageOfRedemptionSnapshotHandler(true, 'frequency' ) }> By Unredeemed Points </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="budget-utilization-trend-container col-sm-12 text-center" >
                  { !this.props.ageOfRedemptionByFrequencyLoader && this.props.ageOfRedemptionByFrequencyData ?
                    this.props.ageOfRedemptionByFrequencyData.length > 0 ? 
                    <SimpleBarChartD3
                      height={window.innerHeight > 680 ? window.innerHeight * 0.5 : window.innerHeight * 0.6 }
                      width={window.innerWidth * 0.35}
                      yAxisLabel={"Number of Users"}
                      xAxisLabel={"Average Age Group (in days)"}
                      data={this.props.ageOfRedemptionByFrequencyData}
                      xMaxValue={this.props.maxNumberUser}
                      legendNames={["Number of Users","Average Age Group (in days)"]}
                      handleClicked={this.handleAgeRangeClicked}
                      graphName={'age_of_redemption_frequency'}
                      color={this.props.ageFrequencySwitch ? '#ff4d4d' : 'green' }
                    />
                    :  <div className="dataNotFound">
                    No Data Found
                    </div>
                  : <RedemptionStackedBarChartLoader
                    height={window.innerHeight * 0.5}
                    width={window.innerWidth * 0.35}
                  />
                  }
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-xl-6 right-panel" >
            <div className="graphsActionContainer">
                <h3> Users with {this.props.ageFrequencySwitch ? 'Unredeemed': 'Redeemed' } Points in Selected Age Group </h3>
                <div className="exportContainer">
                  <GraphActions
                    showCSVExport={this.props.feedData && this.props.feedData.length > 0}
                    showInfo={true}
                    from={this.props.ageFrequencySwitch ? "Users with Unredeeemed Points in Selected Age Group" : "Users with Redeemed Points in Selected Age Group" }
                    apiUrl={"auth/redemption/feed"}
                    filterObjectForCSV={this.getAgeOfRedemptionFilterObjectData}
                    graphId="ageOfRedemptionFeed"
                    infoMessage="This list helps you identify specific users who need to be reminded to redeem their points."
                  />
                </div>
              </div>

              <div className="graphInfoNote" id={"ageOfRedemptionFeed"}><span>Note: </span> Please click on a bar in the chart to the left to populate this list.</div>
                {this.props.feedDataLoader ? <TableLoader
                  height={window.innerHeight * 0.5}
                  width={window.innerWidth * 0.35} /> 
                  : 
                  this.props.feedData ? <Table
                    tableHeading={["Name","Points", `Avg Age of ${this.props.ageFrequencySwitch ? 'Unredeemed': 'Redeemed' } Points`, "Email"]}
                    data={this.props.feedData}
                    disableClick={true}
                    fromredemption={true}
                    // loader={this.props.centerofInfluenceData.employeeStrength.loader}
                  />
                  : <div className="dataNotFound">
                  No Data Found
                  </div>

                }
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = state => {
  return {
    ageOfRedemptionBySnapshotData: state.RedemptionDashboard.ageOfRedemptionBySnapshotData.snapshotData,
    ageMaxValue: state.RedemptionDashboard.ageOfRedemptionBySnapshotData.ageMaxValue,
    maxLength: state.RedemptionDashboard.ageOfRedemptionBySnapshotData.maxLength,
    ageOfRedemptionBySnapshotLoader: state.RedemptionDashboard.ageOfRedemptionBySnapshotData.loader,
    ageOfRedemptionByFrequencyData: state.RedemptionDashboard.ageOfRedemptionByFrequencyData.frequencyData,
    ageOfRedemptionByFrequencyLoader: state.RedemptionDashboard.ageOfRedemptionByFrequencyData.loader,
    maxNumberUser: state.RedemptionDashboard.ageOfRedemptionByFrequencyData.maxNumberUser,
    feedData: state.RedemptionDashboard.redemptionUserData.feedData,
    feedDataLoader: state.RedemptionDashboard.redemptionUserData.loader,
    ageOfRedemptionByTrendData: state.RedemptionDashboard.ageOfRedemptionByTrendData.feedData,
    ageOfRedemptionByTrendDataLoader: state.RedemptionDashboard.ageOfRedemptionByTrendData.loader,
    trendYAxisMaxValue : state.RedemptionDashboard.ageOfRedemptionByTrendData.maxValue
  };
};

export default connect(mapStateToProps)(AgeOfRedemption);