import React from "react";
import * as d3 from "d3";
import { THEME_COLORS } from "./../../constants/constants";

// Heatmap Chart
class SingleBarChartD3 extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      currentDrillDown: "Strengths",
      totalStrengthCount: 0,
      counter: 0
    };
  }

  componentDidMount() {
      this.loadChart();
  }

  componentDidUpdate() {
      this.loadChart();
  }

  loadChart = () => {
    let margin = {top: 30, right: 20, bottom: 80, left: 40},
    width = this.props.width - margin.left - margin.right,
    height = this.props.height - margin.top - margin.bottom;
    
    let legendNames = this.props.legendNames;
    let graphName = this.props.graphName;
    let isExapand = this.props.isExapand;
    let color = this.props.color;

    // scales
    d3.select(this.node).select("svg").remove();
  
    let svg = d3.select(this.node).append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    
    const sample = this.props.data;
    const margin1 = 20;

    const chart = svg.append('g')
      .attr('transform', `translate(${margin1}, ${margin1})`);

    const xScale = d3.scaleBand()
      .range([0, width])
      .domain(sample.map((s) => s.range))
      .padding(0.4)
  
    const yScale = d3.scaleLinear()
      .range([height, 0])
      .domain([0, parseInt(this.props.xMaxValue)]);

    let XAxisElement = chart.append('g')
      .attr('transform', `translate(0, ${height})`)
      .call(d3.axisBottom(xScale));

    let YAxisElement = chart.append('g')
      .call(d3.axisLeft(yScale));

    let mousemove = function (d) {
      let div = d3.select(`#tooltip-${graphName}`)
      .attr("class", "redemption-tooltip")
      .style("opacity", 0);

      let e = 0, x = 0, y = 0;
      e = window.event, x = e.clientX, y = e.clientY-40;

      div.transition().duration(200).style("opacity", 1.0);

      div.html( `<span style="color:${THEME_COLORS[8]}">${legendNames[0]}: ${d.value} </span><br/>
          <span style="color:${color}">${legendNames[1]}: ${d.range} </span><br/>`)
        .style("top", `${y-50}px`)
        .style("left", `${x-100}px`);
    }

    let mouseout = function(d) {
      let div = d3.select(`#tooltip-${graphName}`)
      div.transition().duration(500).style("opacity", 0);
    }

    let handleClicked = function(d, props) {
      let div = d3.select(`#tooltip-${graphName}`)
      div.transition().duration(0).style("opacity", 0);    
      {!isExapand ? props.handleClicked(d) : null }
      div.transition().duration(0).style("opacity", 0);
    }

    const barGroups = chart.selectAll()
      .data(sample)
      .enter()
      .append('g')

    barGroups
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (g) => xScale(g.range))
      .attr('y', (g) => yScale(g.value))
      .attr('height', (g) => height - yScale(g.value))
      .attr('width', xScale.bandwidth())
      .style('fill', color)
      .on("mousemove", mousemove)
      .on("mouseout", mouseout)
      .on("click", (d) => {
        this.setState(() => {
          handleClicked(d, { ...this.props });
        }, () => this.loadChart());
      })

      svg.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -30)
      .attr("x", 0 - ((height) / 2))
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .style("font-family", "roboto")
      .style("font-weight", 500)
      .style("font-size", 14)
      .style("fill", "#758088")
      .text(this.props.yAxisLabel);

      svg.append("text")
      .attr("transform",
        "translate(" + ((width) / 2) + " ," +
        (height + (margin.bottom - 5 ) ) + ")")
      .style("text-anchor", "middle")
      .style("font-family", "roboto")
      .style("font-weight", 500)
      .style("font-size", 14)
      .style("fill", "#758088")
      .text( this.props.xAxisLabel );

      XAxisElement.selectAll('.tick').selectAll('text').attr("transform", "rotate(-45)").attr("x", "-15").attr("y", "10").style("font-size", 12)
      .style("font-weight", 700)
      .style("fill", "#758088")
      .style("font-family", "roboto");
      YAxisElement.selectAll('.tick').selectAll('text').attr("x", "-10").attr("y", "0").style("font-size", 12)
      .style("font-weight", 700)
      .style("fill", "#758088")
      .style("font-family", "roboto");
    }
    
    render() {
      let graphName = this.props.graphName;
        return (
          <div ref={(node) => { this.node = node; }}>
            <div>
              <div id={`tooltip-${graphName}`} ></div>
            </div>
          </div>
        );
      }
    }
    
    export default SingleBarChartD3;