import React from "react";
import moment from "moment";
import { MessegeExchangeLoader } from "../../../../../../../Widgets";
import GraphActions from "../../../../../../../UI/GraphActionsComponent/Graphnactions";
const HeatMapMessageExchange = props => {
  return (
    <div className="heatMapdata"> 
      <div className= { props.messageExchangeState.giverName && props.messageExchangeData.length > 0 ? "dataheader" : "dataheader nodatafoundstate" }>
        {props.messageExchangeState.giverName && props.messageExchangeData.length > 0
          ? <div className="heatmapDatatitleContainer d-flex justify-content-between">
            <div className=""> <span className="number">{props.messageExchangeData.length.toLocaleString()}</span> Appreciations from - <span>{props.messageExchangeState.giverName}</span> to <span> {props.messageExchangeState.receiverName}</span> </div>
            <div className="exportcsvimg">
              <GraphActions 
                showExpansion={false}
                showCSVExport={ props.messageExchangeData.length > 0 }
                showImageExport={false}
                showInfo={false}
                payLoadData={props.payLoadData}
                from={props.from}
                filterObjectForCSV={props.filterObjectForCSV}
                export_parameter={props.export_parameter}
                apiUrl={"auth/graph/feed"}
                parameter={props.parameter}
              />  
            </div>
          </div>
          : <div className="graphInfoNote">
               Note: Please click on a cell to retreive the relevant appreciation messages.
          </div>}
      </div>

      { 
        props.messageExchangeState.giverName && props.messageExchangeState.receiverName
        
          ? props.messageExchangeLoader ? <MessegeExchangeLoader /> 
            : props.messageExchangeData.length > 0
              ? <div className="datalistContainer">                 
                { props.messageExchangeData.map((messageExchange, index) => {
                  let message = messageExchange.transaction_message.replace(/\\u[\dA-F]{4}/gi, function (match) {
                    return String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16)); 
                  });
                  return (
                    <div className="appreciationList" key={`heatmap-messageexchange-${index}`} >
                      <div className="appreciation">
                        <p>{message}</p>
                      </div>
                      <div className="appreciationInfo">
                        <div className="pointsCount">
                              Points <span>{messageExchange.points}</span> 
                        </div>
                        <div className="Info">
                          <div className="personName"> 
                          from <span>{ messageExchange.giver_first_name ? `${messageExchange.giver_first_name} ${messageExchange.giver_last_name.charAt(0)}` : "Anonymous" }</span> to <span>{ messageExchange.receiver_first_name ? `${messageExchange.receiver_first_name} ${messageExchange.receiver_last_name.charAt(0)}` : "Anonymous" } </span>
                          </div>
                          <div className="date">
                                Date : <span>{moment(messageExchange.created).format("DD MMM YYYY")}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div> 
              : <div className="dataNotFound" > No data Found </div> 
          : <div className="dataNotFound" > Pending Selection </div> 
      }
                    
    </div>
  );
};

export default HeatMapMessageExchange;