import React from "react";

const HeatmapLoader = props => {
  return (
    <div className="animated-background lg-width" style={{ height: props.height, width: props.width }} >

    </div>

  );
};

export default HeatmapLoader;