import React from "react";
import { StackedBarChart, StackedBarChartVertical, BarChartLoader, Histogram } from "../../../../../Widgets";
import { GraphActions } from "../../../../../UI";
import BackImg from "../../../../../static/images/back.png";
import { connect } from "react-redux";
import StackedBarChartLoader from "../../../../../Widgets/StackedBarChart/StackedBarChartLoader";
const handleExpandChart = (props, graphName) => {
  let colors = [ "#6fd8e5", "#ff7b85"];
  let xAxisNames = ["Appreciations Received", "Appreciations Given"];
  let xAxisNameDrillDown = [ "Across Entity", "Within Entity" ];
  let drillDownColors = ["#f6cd61", "#0c2d44"];

  let graph = "";
  if(graphName === "histogram") {
    graph = <Histogram
      width={window.screen.width * 0.84} 
      height={window.screen.height * 0.55}
      xAxisNames= {xAxisNames}
      data={props.appreciationExchangeData.histogram}
      margin={{ top: 40, right: 70, left: 20, bottom: 70 }}
      colors={colors}
      showRightYAxis = {false}
      isOnClickEvent = { false }
      xAxisNameId={"xAxis"}
      xAxisAngle={-45}
      textAnchor="end"
      showBrush={props.appreciationExchangeData.histogram.length > 15 }
      brushWidth={props.screenWidth}
      brushHeight={20}
      barSize={50}
      yAxisLabel = "# of Appreciations"
      xAxisLabel={props.appreciationExchangeFrequency === "week" ? "Week Commencing" : "" }

    />;
  } else if(graphName === "ByCompany") {
    let marginLeft = 0;

    props.appreciationExchangeData.companyHistogram.forEach(company => {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      ctx.font = "12px Roboto";        
      let width = ctx.measureText(company.org_name).width;
      marginLeft = width > marginLeft ? width : marginLeft;
    });
    graph = <StackedBarChartVertical
      width={window.screen.width * 0.8 } 
      height={window.screen.height * 0.6} 
      xAxisNames= {xAxisNames}
      data={props.appreciationExchangeData.companyHistogram}
      margin={{ top: 40, right: 15, left: marginLeft, bottom: 70 }}
      colors={colors}
      showRightYAxis ={ false }
      expanded={true}
      isOnClickEvent = { false }
      xAxisNameId={"org_name"}
      clickHandler = { (e) => props.handleBarClick(e) }
      xAxisAngle={0}
      textAnchor="end"
      yAxisLabel = "Companies"
      xAxisLabel = "Appreciations"
      showBrush={ (window.screen.height * 0.6) < (props.appreciationExchangeData.companyHistogram.length * 30) }
      brushWidth = {window.screen.width * 0.8 }
    />;
  } else if(graphName === "ByCompanyDrillDown") {
    graph = <StackedBarChart
      width={window.screen.width * 0.8} 
      height={window.screen.height * 0.6} 
      xAxisNames= {xAxisNameDrillDown}
      // data={drilledDown}
      data={props.appreciationExchangeData.companyDrilldown} // drill down api
      margin={{ top: 40, right: 30, left: 20, bottom: 50 }}
      colors={drillDownColors}
      showRightYAxis ={ false }
      isOnClickEvent = { false }
      xAxisNameId={"x"}
      clickHandler = { (e) => props.handleBarClick(e) }
      xAxisAngle={0}
      textAnchor="middle"
      xAxisLabel = "Appreciation Status"
      yAxisLabel = "Appreciation %"
      showBrush={false}

    />; 
  } else if(graphName === "ByDepartment") {
    let marginLeft = 0;
    props.appreciationExchangeData.departmentHistogram.forEach(dept => {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      ctx.font = "12px Roboto";        
      let width = ctx.measureText(dept.dept_name).width;
      marginLeft = width > marginLeft ? width : marginLeft;
    }); 

    graph = <StackedBarChartVertical
      width={window.screen.width * 0.8 } 
      height={window.screen.height * 0.6} 
      xAxisNames= {xAxisNames}
      data={props.appreciationExchangeData.departmentHistogram}
      margin={{ top: 40, right: 15, left: marginLeft, bottom: 70 }}
      colors={colors}
      showRightYAxis ={ false }
      expanded={true}
      isOnClickEvent = { false }
      xAxisNameId={"dept_name"}
      clickHandler = { (e) => props.handleBarClick(e) }
      xAxisAngle={0}
      onClick={false}
      textAnchor="start"
      yAxisLabel = "Departments"
      xAxisLabel = "Appreciations"
      showBrush={ (window.screen.height * 0.6) < (props.appreciationExchangeData.departmentHistogram.length * 30) }
      brushWidth = {window.screen.width * 0.8 }
    />;
  } else if(graphName === "ByDepartmentDrillDown") {
    graph = <StackedBarChart
      width={window.screen.width * 0.8} 
      height={window.screen.height * 0.6} 
      xAxisNames= {xAxisNameDrillDown}
      // data={drilledDown}
      data={props.appreciationExchangeData.departmentDrilldown} // drill down api
      margin={{ top: 40, right: 30, left: 20, bottom: 50 }}
      colors={drillDownColors}
      showRightYAxis ={ false }
      isOnClickEvent = { false }
      xAxisNameId={"x"}
      clickHandler = { (e) => props.handleBarClick(e) }
      xAxisAngle={0}
      textAnchor="middle"
      xAxisLabel = "Appreciation Status"
      yAxisLabel = "Appreciation %"
      showBrush={false}

    />;
  }
  
  props.showEventModal({
    showEventModal: true,
    eventModalTitle: "",
    eventModalBody: "",
    showEventModalButtonOne: false,
    showEventModalButtonTwo: false,
    buttonOneEventModalHandler: () => props.hideEventModal(),
    buttonTwoEventModalHandler: null,
    buttonOneEventModalName: "",
    buttonTwoEventModalName: "",
    graphContent: graph,
    graphTitle: props.graphTitle,
    graphSubTitle: props.graphSubTitle,
    showGraph: true,
    graphName: "",
    graphId: ""
  });
};

class AppreciationExchange extends React.Component {
  render () {
    let screenWidth = window.screen.width; 
    if(document.getElementById("tilesSection")) {
      screenWidth = document.getElementById("tilesSection").offsetWidth; 
    }   
    let colors = [ "#6fd8e5", "#ff7b85"];
    let xAxisNames = ["Appreciations Received", "Appreciations Given"];
    let frequencyTitle = "By Day"; 
    if(this.props.appreciationExchangeFrequency === "day") {
      frequencyTitle = "By Day";
    } else if(this.props.appreciationExchangeFrequency === "week") {
      frequencyTitle = "By Week";
    } else if(this.props.appreciationExchangeFrequency === "month") {
      frequencyTitle = "By Month";
    } else {
      frequencyTitle = "By Day";
    }
    let xAxisNameDrillDown = [ "Across Entity", "Within Entity" ];
    let drillDownColors = ["#f6cd61", "#0c2d44"];

    let ByCompanyGraphName = this.props.appreciationExchangeStateData.showCompanyDrillDown ? `Appreciation within and across ${this.props.appreciationExchangeStateData.companyDrillDownName}` : "By Company";
    let ByDepartmentGraphName = this.props.appreciationExchangeStateData.showDepartmentDrillDown ? `Appreciation within and across ${this.props.appreciationExchangeStateData.departmentDrillDownName}` : "By Department";
    return (
      <div className="histogramSection">        
        <div className="titleOuter">
          <h2>Number <span>of</span> Appreciations</h2>
        </div>         
          
        <div className="graphOuter">
          <div>
            <div className="graphsActionContainer">
              <div className="daywWiseFilter">
                <div className="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="daily" name="daywisefilter" className="custom-control-input" checked = { this.props.appreciationExchangeFrequency === "day" } onChange={ () => this.props.handleAppreciationExchangeFrequency("day") } />
                  <label className="custom-control-label" htmlFor="daily">Day</label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="weekly" name="daywisefilter" className="custom-control-input" checked = { this.props.appreciationExchangeFrequency === "week" } onChange={ () => this.props.handleAppreciationExchangeFrequency("week") } />
                  <label className="custom-control-label" htmlFor="weekly">Week</label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="monthly" name="daywisefilter" className="custom-control-input" checked = { this.props.appreciationExchangeFrequency === "month" } onChange={ () => this.props.handleAppreciationExchangeFrequency("month") } />
                  <label className="custom-control-label" htmlFor="monthly">Month</label>
                </div>
              </div>
              <div className="exportContainer">
                <GraphActions 
                  showExpansion={true}
                  showCSVExport={ this.props.appreciationExchangeData.histogram.length > 0 }
                  filterObjectForCSV={this.props.filterForCSV}
                  showImageExport={true}
                  showInfo={true}
                  from={"appreciationExchange"}
                  apiUrl={"auth/graph/histogram"}
                  parameter={this.props.appreciationExchangeFrequency}
                  graphId="mainHistogram"
                  imageName={`Numbers of Appreciations ${frequencyTitle}`}
                  infoMessage = "Please select Day, Week or Month for the desired data reporting frequency."
                  handleExpand={ (graphId) => handleExpandChart({ ...this.props, graphTitle: "Number of Appreciations", graphSubTitle: frequencyTitle, screenWidth: screenWidth }, "histogram") } /> 
              </div>
            </div> 
            <div className="titlesAndLegend d-flex justify-content-between">
              <div className="titles">
                <h3>{frequencyTitle}</h3>
              </div>
              <div className="histrogramlegends d-flex justify-content-start ">
                <div className="legendtitel">
                  <span></span> 
                </div>
              </div>
            </div>              
            <div>              
              { 
                !this.props.appreciationExchangeData.histogramLoader 
                  ? <Histogram
                    width={screenWidth} 
                    height={ (window.screen.height) * 0.4 } 
                    xAxisNames= {xAxisNames}
                    data={this.props.appreciationExchangeData.histogram}
                    margin={{ top: 40, right: 70, left: 20, bottom: 70 }}
                    colors={colors}
                    frequency={this.props.appreciationExchangeFrequency}
                    showRightYAxis = { false }
                    isOnClickEvent = { false }
                    xAxisNameId={"xAxis"}
                    xAxisAngle={-45}
                    textAnchor="end"
                    // showRightYAxis={ this.props.appreciationExchangeFrequency !== "day" }
                    showBrush={this.props.appreciationExchangeData.histogram.length > 15 }
                    brushWidth={screenWidth}
                    brushHeight={20}
                    barSize={50}
                    yAxisLabel = "# of Appreciations"
                    xAxisLabel={this.props.appreciationExchangeFrequency === "week" ? "Week Commencing" : "" }
                  /> 

                  : <div className="histoloaderouter"> <StackedBarChartLoader height={(window.screen.height) * (1 / 3)} width={screenWidth} /> </div>
              }
            </div>
            <div id="mainHistogram" className="d-none" >
              <Histogram
 width={this.props.appreciationExchangeData.histogram.length <= 10 
  ? screenWidth
  : ((this.props.appreciationExchangeData.histogram.length * 50) < screenWidth) ? screenWidth : this.props.appreciationExchangeData.histogram.length * 50} 
 height={window.screen.height * 0.55}
                xAxisNames= {xAxisNames}
                data={this.props.appreciationExchangeData.histogram}
                margin={{ top: 40, right: 70, left: 20, bottom: 70 }}
                colors={colors}
                isOnClickEvent = { false }
                xAxisNameId={"xAxis"}
                xAxisAngle={-45}
                textAnchor="end"
                showRightYAxis={false} 
                showBrush={false}
                brushWidth={this.props.screenWidth}
                brushHeight={20}
                barSize={50}
                yAxisLabel = "# of Appreciations"
                xAxisLabel={this.props.appreciationExchangeFrequency === "week" ? "Week Commencing" : "" }

              />
            </div>
            
          </div>
          <div className="row appreciationsWithin" >
            <div className="col-lg-12 col-xl-6">         
              <div className="graphsActionContainer">
              
                <h3>{ByCompanyGraphName}</h3>
                <div className="exportContainer">               
                  <GraphActions 
                    showExpansion={true}
                    showCSVExport={this.props.appreciationExchangeData.companyHistogram.length > 0 }
                    showImageExport={true}
                    filterObjectForCSV={this.props.filterForCSV}
                    apiUrl={this.props.appreciationExchangeStateData.showCompanyDrillDown ? "auth/graph/histogram/specific" : "auth/graph/histogram" }
                    from={"appreciationExchange"}
                    specific={this.props.appreciationExchangeStateData.organization_id}
                    parameter={"organization"}
                    showInfo={true}
                    graphId={ this.props.appreciationExchangeStateData.showCompanyDrillDown ? "byCompanyHistogramDrillDown" : "byCompanyHistogram" }  
                    imageName={`Numbers of Appreciations ${ByCompanyGraphName}`}
                    infoMessage={this.props.appreciationExchangeStateData.showCompanyDrillDown ? "Click Back to return to the breakdown of appreciations received & given." : "Please click on the desired bar for more details on the selected company."}
                    handleExpand={ (graphId) => handleExpandChart({ ...this.props, graphTitle: "Numbers of Appreciations", graphSubTitle: ByCompanyGraphName }, 
                      this.props.appreciationExchangeStateData.showCompanyDrillDown ? "ByCompanyDrillDown" : "ByCompany"
                    ) } /> 
                </div>
              </div> 
              <div className="graphInfoNote">
                { !this.props.appreciationExchangeStateData.showCompanyDrillDown
                  ? <div><span>Note: </span>  Please click on an individual company for the share of appreciations within and across companies.</div>
                  : null }
                
              </div>

              { this.props.appreciationExchangeStateData.showCompanyDrillDown ? <div className="backbtnContainer">
                <button className="btn" onClick={ this.props.hideCompanyDrillDown } > 
                  <img src={BackImg} title="BackImg"/> Back
                </button>
              </div> : <div className="backbtnContainer" ></div> }
              { this.props.appreciationExchangeStateData.showCompanyDrillDown 
                ? <div>
                  {this.props.appreciationExchangeData.companyDrilldownLoader
                    ? <StackedBarChartLoader width={(screenWidth * 0.37) } 
                      height={ window.screen.height * (0.25) } />
                    : <StackedBarChart
                      width={(screenWidth * 0.37) } 
                      height={ window.screen.height * (0.37) }  
                      xAxisNames= {xAxisNameDrillDown}
                      // data={drilledDown}
                      data={this.props.appreciationExchangeData.companyDrilldown} // drill down data
                      margin={{ top: 40, right: 15, left: 70, bottom: 70 }}
                      colors={drillDownColors}
                      showRightYAxis ={ false }
                      isOnClickEvent = { true }
                      xAxisNameId={"x"}
                      isOnClickEvent = { false }
                      xAxisAngle={0}
                      barSize={50}
                      textAnchor="middle"
                      xAxisLabel = "Appreciation Status"
                      yAxisLabel = "Appreciation %"
                      showBrush={false}
                    />
                  }
                  <div id="byCompanyHistogramDrillDown" className="d-none" >
                    <StackedBarChart
                      width={(screenWidth * 0.37) } 
                      height={ window.screen.height * (0.37) }  
                      xAxisNames= {xAxisNameDrillDown}
                      // data={drilledDown}
                      data={this.props.appreciationExchangeData.companyDrilldown} // drill down data
                      margin={{ top: 40, right: 15, left: 70, bottom: 70 }}
                      colors={drillDownColors}
                      showRightYAxis ={ false }
                      isOnClickEvent = { true }
                      xAxisNameId={"x"}
                      clickHandler = { (e) => this.props.handleBarClick(e) }
                      xAxisAngle={0}
                      barSize={50}
                      textAnchor="middle"
                      xAxisLabel = "Appreciation Status"
                      yAxisLabel = "Appreciation %"
                      showBrush={false}
                    /> 

                  </div>
                
                </div>
             
                : <div >
                  { this.props.appreciationExchangeData.companyHistogramLoader ? <StackedBarChartLoader width={(screenWidth * 0.4) } 
                    height={ window.screen.height * (0.4) } />
                    : this.props.appreciationExchangeData.companyHistogram.length > 0 
                      ? <div> 
                     
                        <StackedBarChartVertical
                          width={(screenWidth * 0.4) } 
                          height={ window.screen.height * (0.4) } 
                          xAxisNames= {xAxisNames}
                          data={this.props.appreciationExchangeData.companyHistogram}
                          margin={{ top: 40, right: 15, left: 70, bottom: this.props.appreciationExchangeData.departmentHistogram.length > 10 ? 90 : 70 }}
                          colors={colors}
                          showRightYAxis ={ false }
                          isOnClickEvent = { true }
                          xAxisNameId={"org_name"}
                          clickHandler = { (e) => this.props.handleBarClick(e) }
                          xAxisAngle={0}
                          textAnchor="end"
                          yAxisLabel = "Company"
                          xAxisLabel = "# of Appreciations"
                          showBrush={this.props.appreciationExchangeData.companyHistogram.length > 10}
                          brushWidth = {screenWidth / 2 }
                        />
                      
                        <div id="byCompanyHistogram" className="d-none" >
                          <StackedBarChartVertical
                            width={(screenWidth * 0.4) } 
                            height={ window.screen.height * (0.4) } 
                            xAxisNames= {xAxisNames}
                            data={this.props.appreciationExchangeData.companyHistogram}
                            margin={{ top: 40, right: 15, left: 70, bottom: 70 }}
                            colors={colors}
                            showRightYAxis ={ false }
                            isOnClickEvent = { true }
                            xAxisNameId={"org_name"}
                            clickHandler = { (e) => this.props.handleBarClick(e) }
                            xAxisAngle={0}
                            textAnchor="middle"
                            yAxisLabel = "Company"
                            xAxisLabel = "# of Appreciations"
                            showBrush={this.props.appreciationExchangeData.companyHistogram.length > 10}
                            brushWidth = {screenWidth / 2 }
                          />
                        </div>
                      </div>
                      : <div className="dataNotFound">  
                      No Data Found
                      </div>
                
                  }
              
                </div>
              }
            </div>
            <div className="col-lg-12 col-xl-6">
              <div className="graphsActionContainer">
                <h3>{ByDepartmentGraphName}</h3>
                <div className="exportContainer">
                  <GraphActions 
                    showExpansion={true}
                    showCSVExport={this.props.appreciationExchangeData.departmentHistogram.length > 0 }
                    showImageExport={true}
                    from={"appreciationExchange"}
                    apiUrl={ this.props.appreciationExchangeStateData.showDepartmentDrillDown ? "auth/graph/histogram/specific" : "auth/graph/histogram"}
                    parameter={"department"}
                    specific={this.props.appreciationExchangeStateData.department_id}
                    filterObjectForCSV={this.props.filterForCSV}
                    showInfo={true}
                    graphId= { this.props.appreciationExchangeStateData.showDepartmentDrillDown ? "byDepartmentDrillDown" : "byDepartment" } 
                    imageName={`Numbers of Appreciations ${ByDepartmentGraphName}`}
                    infoMessage={this.props.appreciationExchangeStateData.showDepartmentDrillDown ? " Click Back to return to the breakdown of appreciations received & given." : "Please click on the desired bar for more details on the selected department." }
                    handleExpand={ (graphId) => handleExpandChart({ ...this.props, graphTitle: "Numbers of Appreciations", graphSubTitle: ByDepartmentGraphName }, 
                      this.props.appreciationExchangeStateData.showDepartmentDrillDown ? "ByDepartmentDrillDown" : "ByDepartment"
                    ) } /> 
                </div>
              </div> 
              <div className="graphInfoNote">
                {!this.props.appreciationExchangeStateData.showDepartmentDrillDown
                  ? <div>
                    <span>Note: </span>  Please click on an individual department for the share of appreciations within and across departments.
                  </div>
                  : null} 
              </div>
              { this.props.appreciationExchangeStateData.showDepartmentDrillDown ? <div className="backbtnContainer">
                <button className="btn" onClick={ this.props.hideDepartmentDrillDown } > 
                  <img src={BackImg} title="BackImg"/> Back
                </button>
              </div> : <div className="backbtnContainer" ></div> }
              
              { this.props.appreciationExchangeStateData.showDepartmentDrillDown
                ? <div>
                  { this.props.appreciationExchangeData.departmentDrilldownLoader ? <StackedBarChartLoader width={(screenWidth * 0.37) } 
                    height={ window.screen.height * (0.25) } /> 
                    : <StackedBarChart
                      width={(screenWidth * 0.37) } 
                      height={ window.screen.height * (0.37) }  
                      xAxisNames= {xAxisNameDrillDown}
                      data={this.props.appreciationExchangeData.departmentDrilldown} 
                      margin={{ top: 40, right: 15, left: 70, bottom: 70 }}
                      colors={drillDownColors}
                      showRightYAxis ={ false }
                      isOnClickEvent = { true }
                      xAxisNameId={"x"}
                      isOnClickEvent = { false }
                      xAxisAngle={0}
                      barSize={50}
                      textAnchor="middle"
                      xAxisLabel = "Appreciation Status"
                      yAxisLabel = "Appreciation %"
                      showBrush={false}
                    />
                  }
                   
                  <div id="byDepartmentDrillDown" className="d-none">
                    <StackedBarChart
                      width={(screenWidth * 0.37) } 
                      height={ window.screen.height * (0.25) }  
                      xAxisNames= {xAxisNameDrillDown}
                      data={this.props.appreciationExchangeData.departmentDrilldown} 
                      margin={{ top: 40, right: 15, left: 70, bottom: 70 }}
                      colors={drillDownColors}
                      showRightYAxis ={ false }
                      isOnClickEvent = { true }
                      xAxisNameId={"x"}
                      clickHandler = { (e) => this.props.handleBarClick(e) }
                      xAxisAngle={0}
                      barSize={50}
                      textAnchor="middle"
                      xAxisLabel = "Appreciation Status"
                      yAxisLabel = "Appreciation %"
                      showBrush={false}
                    />
                  </div>
                </div>
                : <div>
                  
                  { this.props.appreciationExchangeData.departmentHistogramLoader ? <StackedBarChartLoader width={(screenWidth * 0.4) } 
                    height={ window.screen.height * (0.4) } /> 
                    : this.props.appreciationExchangeData.departmentHistogram.length > 0 
                      ? <div>
                        <div id="byDepartment" className="d-none">  
                          <StackedBarChartVertical
                            width={ window.screen.width * 0.4 } 
                            height={ window.screen.height * 0.4 } 
                            xAxisNames= {xAxisNames}
                            data={this.props.appreciationExchangeData.departmentHistogram}
                            margin={{ top: 40, right: 15, left: 70, bottom: 70 }}
                            colors={colors}
                            showRightYAxis ={ false }
                            isOnClickEvent = { false }
                            xAxisNameId={"dept_name"}
                            // clickHandler = { ( e ) => this.props.handleBarClick( e ) }
                            xAxisAngle={0}
                            textAnchor="end"
                            yAxisLabel = "Department"
                            xAxisLabel = "# of Appreciations"
                            showBrush={false}
                            // paddingTop = {/}
                          /> 
                        </div>
                        <div>
                
                          <StackedBarChartVertical
                            width={(screenWidth * 0.4) } 
                            height={ window.screen.height * (0.4) } 
                            xAxisNames= {xAxisNames}
                            data={this.props.appreciationExchangeData.departmentHistogram}
                            margin={{ top: 40, right: 15, left: 70, bottom: this.props.appreciationExchangeData.companyHistogram.length > 10 ? 90 : 70 }}
                            colors={colors}
                            showRightYAxis ={ false }
                            isOnClickEvent = { true }
                            xAxisNameId={"dept_name"}
                            clickHandler = { (e) => this.props.handleBarClick(e) }
                            xAxisAngle={0}
                            textAnchor="middle"
                            yAxisLabel = "Department"
                            xAxisLabel = "# of Appreciations"
                            showBrush={this.props.appreciationExchangeData.departmentHistogram.length > 10}
                            brushWidth = {screenWidth * 0.4 }

                          />
                  
                        </div>
                
                      </div> 
                      : <div className="dataNotFound">  
                    No Data Found
                      </div>
                  }
                </div>
              }  
              
            </div>
          </div>
        </div>
  
      </div>
    );
  }
};

const mapStateToprops = state => {
  return {
    appreciationExchangeData: state.AppreciationDashboard.appreciationExchange

  };
};

export default connect(mapStateToprops)(AppreciationExchange);