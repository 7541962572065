import React from "react";

// Common Loader Container
const StackedBarChartLoader = (props) => {
  return (
    <div className="main-loader-wrapper d-flex" style={{height: props.height, width: props.width}}>
        <div className="main-loader">
            <div className="loader__bar animated-background "></div>
            <div className="loader__bar animated-background "></div>
            <div className="loader__bar animated-background "></div>
            <div className="loader__bar animated-background "></div>
            <div className="loader__bar animated-background "></div>
            <div className="loader__bar animated-background "></div>
            <div className="loader__bar animated-background "></div>
            <div className="loader__bar animated-background "></div>
            <div className="loader__bar animated-background "></div>     
            <div className="loader__bar animated-background "></div>
            <div className="loader__bar animated-background "></div>
            <div className="loader__bar animated-background "></div>
            <div className="loader__bar animated-background "></div>
            <div className="loader__bar animated-background "></div>
            <div className="loader__bar animated-background "></div>
            <div className="loader__bar animated-background "></div>
            <div className="loader__bar animated-background "></div>
            <div className="loader__bar animated-background "></div>    
            <div className="loader__bar animated-background "></div>
            <div className="loader__bar animated-background "></div>
            <div className="loader__bar animated-background "></div>
            <div className="loader__bar animated-background "></div>
            <div className="loader__bar animated-background "></div>    
            <div className="loader__bar animated-background "></div>
            <div className="loader__bar animated-background "></div>    
            <div className="loader__bar animated-background "></div>
            <div className="loader__bar animated-background "></div>
            <div className="loader__bar animated-background "></div>
            <div className="loader__bar animated-background "></div>
            <div className="loader__bar animated-background "></div> 
            <div className="loader__bar animated-background "></div>
            <div className="loader__bar animated-background "></div>
            <div className="loader__bar animated-background "></div>
            <div className="loader__bar animated-background "></div>
        </div>    
    </div>
     
  );
};

export default StackedBarChartLoader;