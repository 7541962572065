import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logoutAction } from "../../Modules/Login/Actions/LoginActions";
import * as AppreciationActions from "../../Modules/Dashboard/Components/Appreciation/Actions/AppreciationActions";
import * as CommonActions from "../../Common/CommonStore/CommonActions";

import logo from "../../static/images/logo.png";
import user from "../../static/images/download.png";
import Hamburger from "../../static/images/hamburger.png";
import Hamburgerwhite from "../../static/images/hamburger-white.png";
import filterFunel from "../../static/images/filterFunel.png";
import filterFunelhover from "../../static/images/filterFunelhover.png";





// Header Cointainer
class HeaderContainer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      showDropDown: false,
      onoverfilterbutton:false
    }
  }
  handleLogout = ( e ) => {
    e.preventDefault();
    this.props.resetAppreciationReducer();
    this.props.logout();
  }

  handleSettingClick = ( e ) => {
    e.preventDefault();
  }
  sideBarToggler = () => {
    if( this.props.expandedSideBar ) {
      this.props.shrinkSideBar();
    } else {
      this.props.expandSideBar();
    }
  }
  toggleDropdown = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      showDropDown: !this.state.showDropDown
    })
  }

  toggleSearchComponent = (e) => {
    e.preventDefault();
    if(this.props.showFilter){
      this.props.hideFilterComponent();
    } else {
      this.props.showFilterComponent();
    }
  }
  toggleFilterIcon = () =>{
    this.setState({
      ...this.state,
      onoverfilterbutton: this.state.onoverfilterbutton ? false : true
            
    })
  }
  render() {
    const { location } = this.props; 
    let headerTitle = "";
    if( location.pathname.includes( "appreciation" ) ) {
      headerTitle = "Appreciation";
    } else if( location.pathname.includes( "redeption" ) ) {
      headerTitle = "Redeption";
    } else if( location.pathname.includes( "engagement" ) ) {
      headerTitle = "Engagement";
    } else {
      headerTitle = "Appreciation";
    }
    return (
      <div id="header-nav" className ={!this.props.expandedSideBar ? "active" : ""} >
        <nav className="navbar navbar-expand-lg navbar-light main-nav">
          <div className="container-fluid">  
            <div className="left-nav">
              <button type="button" id="sidebarCollapse" className="btn" 
                onClick={this.sideBarToggler}
              >
                <span className="navbar-toggler-icon">
                  <img src={Hamburger} alt="Hamburger" className="redhambeurger"></img>
                  <img src={Hamburgerwhite} alt="Hamburger-white" className="whitehambeurger"></img>
                </span>
                                
              </button>
              <div className="navbar-brand">
                <img src = { logo } alt="Logo" />
              </div>
              {/* <div className="title-name d-none d-md-block d-lg-block">
                <p>{headerTitle}</p>
              </div> */}
            </div>      
                        
            <div className="navbar navbar-right">
              <ul className="right-nav">
                {/* <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle disabled" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Bank Of Singapore
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <a className="dropdown-item" href="#">Action</a>
                    <a className="dropdown-item" href="#">Another action</a>
                    <a className="dropdown-item" href="#">Something else here</a>
                  </div>
                </li> */}
                <li className="nav-item filter-btn" title={this.props.showFilter ? "Hide filters" : "Show filters" } onMouseOver={this.toggleFilterIcon } onMouseOut ={this.toggleFilterIcon } onClick={ this.toggleSearchComponent } ><img className="img-fluid" src ={ this.state.onoverfilterbutton ? filterFunelhover : filterFunel } alt = "filter button" ></img></li>

                <li className={`nav-item dropdown profile-tab ${ this.state.showDropDown ? "show" : "" }`}>
                  <a className="nav-link dropdown-toggle" href="#" onClick={this.toggleDropdown} id="profileLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img className="round-img" src={ user } />
                  </a>
                  <div className={`dropdown-menu dropdown-menu-right ${ this.state.showDropDown ? "show" : "" }`} aria-labelledby="profileLink">
                    <div className="dropdown-item profileSeting"> 
                      <div className="profile-img">
                        <img src={ user } />
                      </div>
                      <div className="personal-info">
                        <div className="email">
                          <p>{this.props.username}</p>
                        </div>
                      </div>
                    </div>  
                    <div className="dropdown-item profileSeting-btns">
                      <a className="btn btn-secondary" onClick={ this.handleLogout }>Logout</a>
                      <a className="btn btn-secondary" onClick={ this.handleSettingClick } >Setting</a>
                    </div>                                              
                                       
                  </div>
                </li> 
                               
              </ul>
            </div>
          </div>
        </nav>
      </div>

    );
  }
}
const mapStateToProps = state => {
  return {
    username: state.Login.username,
    expandedSideBar: state.Common.expandedSideBar,
    showFilter: state.Common.showFilterComponent

  };
};

const mapDispatchToProps = dispatch => {
  return {  
    logout: () => dispatch( logoutAction() ),
    expandSideBar: () => dispatch( CommonActions.expandSideBarAction() ),
    shrinkSideBar: () => dispatch( CommonActions.shrinkSideBarAction() ),
    resetAppreciationReducer: () => dispatch( AppreciationActions.resetAppreciationReducerAction() ),
    showFilterComponent: () => dispatch( CommonActions.showFilterComponentAction() ),
    hideFilterComponent: () => dispatch( CommonActions.hideFilterComponentAction() )
  };
};
export default withRouter( connect( mapStateToProps, mapDispatchToProps )( HeaderContainer ) );
