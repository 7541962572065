import React, { PureComponent } from "react";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Brush,
  BarChart,
  CartesianGrid,
} from "recharts";
import { CHART, THEME_COLORS } from "../../constants/constants";
import { getMax } from "../../Utilities/common-utill";

export default class AreaChart extends PureComponent {

  state = {}

  customTooltipOnYourLine = (e, props) => {
    if (e.active && e.payload != null && e.payload[0] != null) {
      const listOfAllLegendsObj = {}
      if (this.props.companyOrDepartment && this.props.companyOrDepartment.length) {
        this.props.companyOrDepartment.map(d => {
          listOfAllLegendsObj[d] = 'N/A'
        });
      }
      const lineValues = Object.assign(listOfAllLegendsObj, e.payload[0].payload)
      this.setState({
        ...this.state,
        ...lineValues
      }, () => {
      })
      return "";
    }
    else {
      return "";
    }
  }
  CustomYAxisTickLeft = (props) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          textAnchor="end"
          fontSize="12"
          dy={"0.3em"}
          fontFamily={CHART.fontFamily}
          fontWeight={CHART.fontWeight}
          fill="#666"
        >
          {payload.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
        </text>
      </g>
    );
  };

  render() {
    const data = this.props.data;
    const companyOrDepartment = this.props.companyOrDepartment;

    const lineColors = THEME_COLORS;
    const lineDraw = () => {
      const lines = [];
      let lineColorIndex = 0;
      for (let i = 0; i < companyOrDepartment.length; i++) {
        lineColorIndex = lineColors[lineColorIndex] ? lineColorIndex : 0;
        const colorCode = lineColors[lineColorIndex];
        lineColorIndex++;
        lines.push(
          <Line
            type="monotone"
            dataKey={companyOrDepartment[i]}
            stroke1="blue"
            stroke={colorCode}
            strokeWidth={2}
            key={i}
          />
        );
      }
      return lines;
    };
    const renderLegend = (props) => {
      const { payload } = props;
      return (
        <ul>
          {
            payload.map((entry, index) => {
              const currentDotValue = this.state[entry.value];
              const legendValue = currentDotValue === undefined ?
                "" : currentDotValue === 'N/A' ? ': N/A'
                : `: ${parseFloat(currentDotValue).toFixed(1)}%`;
              return (
                <li style={{ color: entry.color }} key={`item-${index}`}>
                  {entry.value} {legendValue}
                </li>
              )
            }
            )
          }
        </ul>
      );
    }
    return (
      <ComposedChart
        width={this.props.width}
        height={this.props.height}
        data={data}
        margin={this.props.margin}
      >
        {/* <CartesianGrid stroke="#f5f5f5" /> */}
        <XAxis
          dataKey="month"
          tick={{
            fontSize: 12,
            fontFamily: CHART.fontFamily,
            fontWeight: CHART.fontWeight,
          }}
          angle={-45}
          interval={0}
          padding={{ top: 20 }}
          textAnchor="end"
          allowDataOverflow={false}
        />

        <YAxis
          // yAxisId="left"
          // domain={[0, maxYLeft]}
          tick={this.CustomYAxisTickLeft}
          //  tickCount={maxYLeft < 10 ? null : 10}
          label={{
            value: this.props.chartDetails.axisLabel.yLeft,
            angle: -90,
            position: "center",
            dx: -30,
            style: {
              fontSize: 14,
              fontFamily: CHART.fontFamily,
              fontWeight: CHART.fontWeight,
              fill: "#758088",
            },
          }}
          allowDataOverflow={false}
        //orientation="left"
        />

        <Tooltip
          content={this.customTooltipOnYourLine}
          cursor={{ strokeWidth: 3 }}
        // wrapperStyle={{ zIndex: 1 }}
        // formatter={(value, name, props) => [
        //   Number(value)
        //     .toFixed(2)
        //     .toString()
        //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"),
        //   name,
        // ]}
        />
        {/* <Legend layout="vertical" verticalAlign="top" align="right" wrapperStyle={{
            width: "200px",
            height: "100%",
            overflowY: "auto"
          }} /> */}

        {/* <Legend
  payload={
    data.map(
      (entry, index) => {
        console.log("entry", entry);
        return ({
          id: entry.name,
          type: "square",
          value: `${entry.name} (${entry.value}%)`,
          // color: colors[index % colors.length]
        })
      }
    )
  }
/> */}

        <Legend layout="vertical" verticalAlign="top" align="right" wrapperStyle={{
          textAlign: "left",
          width: "200px",
          height: "100%",
          overflowY: "auto"
        }} content={renderLegend} />


        {this.props.chartDetails.isAvgLine ? (
          <Area
            type="monotone"
            dataKey={this.props.chartDetails.avgLineDataKey}
            fill={this.props.color.shadedArea}
            stroke={this.props.color.avgLine}
            strokeWidth={2}
          />
        ) : null}

        {lineDraw()}
        {data.length > 12 ? (
          <Brush
            height={20}
            width={this.props.width - 50}
            y={this.props.height - 20}
            x={0}
            startIndex={data.length - 12}
          ></Brush>
        ) : null}
        {/* <Line type="monotone" dataKey="uv" stroke="#ff7300" />
        <Line type="monotone" dataKey="yw" stroke="#ff7300" /> */}
      </ComposedChart>
    );
  }
}
