import axios from "axios";
import config from "config";
import store from "../../store/index";
import { logoutAction } from "../../Modules/Login/Actions/LoginActions";

let instance = axios.create( {
  baseURL: config.API_URL  
} );

instance.interceptors.request.use( config => {
  // Do something before request is sent
  config.headers["x-access-token"] = `Bearer ${store.getState().Login.authToken}`;
  return config
}, error => {
  throw error;
}
);

instance.interceptors.response.use(
 
  response => {
    return response;
  },
  error => {
    if( error.response.status === 401 ) {
      store.dispatch( logoutAction() );
    } else {
      throw error;
    }
  }
);

export default instance;