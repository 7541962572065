import { generatePath } from "react-router-dom";
import { NOTIFICATIONS_TYPE } from "../constants/constants";

export const getMax = (array, fieldName) => {
  const list = array.map((o) => {
    return o[fieldName];
  });

  const max = Math.max.apply(Math, list);
  return max;
};

export const getMin = (array, fieldName) => {
  const list = array.map((o) => {
    return o[fieldName];
  });

  const max = Math.min.apply(Math, list);
  return max;
};

export const thousandsWithCommas = (amount) => {
  if (!amount || amount < 0) return 0;
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const toTitleCase = (str) => {
  return str.replace(/\[a-zA-Z]\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getNotificationTypeName = (id) =>
  Object.keys(NOTIFICATIONS_TYPE).filter(
    (key) => NOTIFICATIONS_TYPE[key].id === id,
  );

export const generateFileName = (name) => name.split(" ").join("-");
