import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Filter, Footer } from "../../../../UI";
import gotop from "../../../../static/images/top.png";
import pdficon from "../../../../static/images/pdficon.png";
// import RedemptionDashboardApi from "../../../../Api/RedemptionDashboardApi";
import * as AppreciationActions from "../Appreciation/Actions/AppreciationActions";
import * as RedemptionActions from "../RedemptionPattern/Actions/RedemptionActions";
import * as CommonActions from "../../../../Common/CommonStore/CommonActions";
import convertDataForDropdown from "../../../../Utilities/convertDataForDropdown";
import RedemptionTiles from "./Components/RedemptionTiles";
import AppreciationDashboardApi from "../../../../Api/AppreciationDashboardApi";
import BudgetUtilization from "./../RedemptionPattern/Components/BudgetUtilisation";
import MerchantBarChartWarpper from "./Components/MerchantBarChartWrapper";
import TimeOfRedemption from "./Components/TimeOfRedemption";
import AgeOfRedemption from "./Components/AgeOfRedemption";
import { handlePdfGeneration } from "../../../../Utilities/exportMethods";
import { EMPLOYEE_SEARCH_NAME } from "../../../../constants/constants";
// Redemption Dashboard
class RedemptionPatternContainer extends React.Component {
  constructor(props) {
    super(props);
    let date = this.props.filters.endDate
    let startDate = moment().startOf("year").format("DD MMM YYYY");
    let endDate = moment().subtract(1, 'days')
    if(date) {
      startDate = moment(date).startOf("year").format("DD MMM YYYY")
      endDate = date
    }
    this.state = {
      timeFrame: {
        startDate,
        endDate
        // endDate: ''
      },
      showStartDatePicker: false,
      showEndDatePicker: false,
      closeExpandedView: false,
      filterOverlay: false,
      dropDowns: {
        companyMultiSelect: [],
        departmentMultiSelect: [],
        ageMultiSelect: [],
        roleMultiSelect: [],
        employeeMultiSelect: [],
        employeeIdsMultiSelect: [],
        // redemptionModeMultiSelect: [],
        // redemptionCategoryMultiSelect: []
      },
      selectedCompanies: [],
      showMore: {
        companyMultiSelect: false,
        departmentMultiSelect: false,
        ageMultiSelect: false,
        roleMultiSelect: false,
        employeeMultiSelect: false,
        employeeIdsMultiSelect: false,
        // redemptionModeMultiSelect: false,
        // redemptionCategoryMultiSelect: false
      },
      showOptions: {
        companyMultiSelect: true,
        departmentMultiSelect: true,
        ageMultiSelect: true,
        roleMultiSelect: true,
        employeeMultiSelect: true,
        employeeIdsMultiSelect: false,
        // redemptionModeMultiSelect: true,
        // redemptionCategoryMultiSelect: true
      },
      redemptionTrend: {
        // redemptionTrendFrequency: "month",
        redemptionUDRFrequency: 12,
        switch: false
        // showCompanyDrillDown: false,
        // showDepartmentDrillDown: false,
        // companyDrillDownName: "",
        // departmentDrillDownName: ""
      },
      pointBasedRedemptionByBrand: {
        switch: false
      },
      pointBasedRedemptionByCategory: {
        switch: false
      },
      ageOfRedemptionSnapshot: {
        switch: false
      },
      ageOfRedemptionFrequency: {
        switch: false
      },
      ageOfRedemption: {
        feed: {
          ageRange: ''
        }
      },
      ageOfRedemptionTrend: {
        switch: false,
        isDepartment: false
      }
    };

    props.companyFilterDropdownRequest({...this.getFilterObject(), isfrom : 'redemption'});
    // props.departmentFilterDropdownRequest();
    // props.employeeFilterDropdownRequest({ org_id: [], dept_id: [], role: [] });
    // props.roleFilterDropdownRequest();
    // props.redCategoryFilterDropdownRequest();
    // props.redemptionTilesRequest({ ...this.getFilterObject() });
    // props.redemptionBudgetTrendRequest({ ...this.getFilterObject() });
    // props.redemptionBudgetSnapshotRequest({ ...this.getFilterObject() });
    // props.redemptionMerchantDetails({ ...this.getFilterObject() });
    // props.redemptionCategoryDetails({ ...this.getFilterObject() });
    // props.redemptionPointsEarnedRedeemedRequest({ ...this.getFilterObject() });
    // props.timeOfRedemption({ ...this.getFilterObject() });
    // props.redemptionPointsEarnedRedeemedByDriversRequest({ ...this.getFilterObject() });
    // props.redemptionTimeOfRedemptionByHourRequest({ ...this.getFilterObject() });
    // props.ageOfRedemptionSnapshotRequest({ ...this.getFilterObject() });
    // props.ageOfRedemptionFrequencyRequest({ ...this.getFilterObject() });
    // props.redemptionUserAgeDataRequest({ ...this.getFilterObject() });
    // props.ageOfRedemptionTrendRequest({ ...this.getFilterObject() });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  clearAllFilterSelected = (name) => {
    this.setState({
      ...this.state,
      dropDowns: {
        ...this.state.dropDowns,
        [name]: []
      },
      showOptions: {
        ...this.state.showOptions,
        [name]: false
      },
      showMore: {
        ...this.state.showMore,
        [name]: false
      }
    }, () => {
      let companyIds = this.state.dropDowns.companyMultiSelect.map(company => company.id);
      let deptIds = this.state.dropDowns.departmentMultiSelect.map(department => department.id);
      let roleName = this.state.dropDowns.roleMultiSelect.map(role => role.name);
      this.props.departmentFilterDropdownRequest({ org_id: companyIds });
      this.props.employeeFilterDropdownRequest({ org_id: companyIds, dept_id: deptIds, role: roleName });
    });
  }

  handleMultiSelect = (name, data) => {
    this.setState({
      ...this.state,
      dropDowns: {
        ...this.state.dropDowns,
        [name]: data || []
      },
      showOptions: {
        ...this.state.showOptions,
        [name]: true
      },
      showMore: {
        ...this.state.showMore,
        [name]: false
      }
    }, () => {
      if (name === "companyMultiSelect") {
        let companyIds = data.map(companies => companies.id);
        this.props.departmentFilterDropdownRequest({ org_id: companyIds });
        let deptId = this.state.dropDowns.departmentMultiSelect.map(department => department.id);
        let roleName = this.state.dropDowns.roleMultiSelect.map(role => role.name);
        this.props.employeeFilterDropdownRequest({ org_id: companyIds, dept_id: deptId, role: roleName });
      } else if (name === "departmentMultiSelect") {
        let orgId = []; let deptId = [], roleName = [];
        orgId = this.state.dropDowns.companyMultiSelect.map(company => company.id);
        deptId = this.state.dropDowns.departmentMultiSelect.map(department => department.id);
        roleName = this.state.dropDowns.roleMultiSelect.map(role => role.name);
        this.props.employeeFilterDropdownRequest({ org_id: orgId, dept_id: deptId, role: roleName });
      } else if (name === "roleMultiSelect") {
        let orgId = []; let deptId = [], roleName = [];
        orgId = this.state.dropDowns.companyMultiSelect.map(company => company.id);
        deptId = this.state.dropDowns.departmentMultiSelect.map(department => department.id);
        roleName = this.state.dropDowns.roleMultiSelect.map(role => role.name);
        this.props.employeeFilterDropdownRequest({ org_id: orgId, dept_id: deptId, role: roleName });
      }
    });
  }

  handleDeleteOptions = (name, index) => {
    let updatedChips = [...this.state.dropDowns[name]];
    updatedChips.splice(index, 1);
    this.setState({
      ...this.state,
      dropDowns: {
        ...this.state.dropDowns,
        [name]: updatedChips
      },
      showOptions: {
        ...this.state.showOptions,
        [name]: true
      }
    }, () => {
      if (name === "companyMultiSelect") {
        let companyIds = this.state.dropDowns.companyMultiSelect.map(companies => companies.id);
        this.props.departmentFilterDropdownRequest({ org_id: companyIds });
        let deptId = this.state.dropDowns.departmentMultiSelect.map(department => department.id);
        let roleName = this.state.dropDowns.roleMultiSelect.map(role => role.name);
        this.props.employeeFilterDropdownRequest({ org_id: companyIds, dept_id: deptId, role: roleName });
      } else if (name === "departmentMultiSelect") {
        let orgId = []; let deptId = [];
        orgId = this.state.dropDowns.companyMultiSelect.map(company => company.id);
        deptId = this.state.dropDowns.departmentMultiSelect.map(department => department.id);
        let roleName = this.state.dropDowns.roleMultiSelect.map(role => role.name);
        this.props.employeeFilterDropdownRequest({ org_id: orgId, dept_id: deptId, role: roleName });
      } else if (name === "roleMultiSelect") {
        let orgId = []; let deptId = [], roleName = [];
        orgId = this.state.dropDowns.companyMultiSelect.map(company => company.id);
        deptId = this.state.dropDowns.departmentMultiSelect.map(department => department.id);
        roleName = this.state.dropDowns.roleMultiSelect.map(role => role.name);
        this.props.employeeFilterDropdownRequest({ org_id: orgId, dept_id: deptId, role: roleName });
      }

      if (this.state.showMore[name] && this.state.dropDowns[name].length > 2) {
        this.setState({
          ...this.state,
          showMore: {
            ...this.state.showMore,
            [name]: true
          }
        });
      }
    });
  }

  handleSearchableChange = async (inputValue, changeHandlerFor) => {
    let trimmedInputValue = inputValue.trim();
    if (trimmedInputValue.length > 1) {
      let orgId = []; let deptId = [];
      orgId = this.state.dropDowns.companyMultiSelect.map(company => company.id);
      deptId = this.state.dropDowns.departmentMultiSelect.map(department => department.id);
      let roleName = this.state.dropDowns.roleMultiSelect.map(role => role.name);
      if(changeHandlerFor === EMPLOYEE_SEARCH_NAME.EMPLOYEES) {
        return await this.getEmployees(orgId, deptId, roleName, trimmedInputValue)
      } else if(changeHandlerFor === EMPLOYEE_SEARCH_NAME.EMPLOYEE_IDS) {
        return await this.getEmployeesById(orgId, deptId, roleName, trimmedInputValue)
      }
    }
  }

  getEmployees= async(orgId, deptId, roleName, trimmedInputValue)=>{
    const data = await AppreciationDashboardApi.getEmployeesDropdown({ org_id: orgId, dept_id: deptId, role: roleName, search_term: trimmedInputValue });
    const filtered = await convertDataForDropdown(data.response.data.result, "employeeName");
    return filtered;
  }

  getEmployeesById= async(orgId, deptId, roleName, trimmedInputValue)=>{
    const filterByUserId = true;
    const data = await AppreciationDashboardApi.getEmployeesDropdown({ org_id: orgId, dept_id: deptId, role: roleName, search_term: trimmedInputValue, filterByUserId });
    const filtered = await convertDataForDropdown(data.response.data.result, "employeeId");
    return filtered;
  }

  getFilterObject = () => {
    let selectedDeptIds = this.state.dropDowns.departmentMultiSelect.map(department => department.id);
    let allDeptIds = this.props.departmentFilter.map(department => department.id);
    let validDeptIds = [];
    selectedDeptIds.forEach(deptId => {
      if (allDeptIds.includes(deptId)) {
        validDeptIds.push(deptId);
      }
    });

    let selectedEmpIds = this.state.dropDowns.employeeMultiSelect.map(employee => employee.id);
    let allEmpIds = this.props.employeesFilter.map(employee => employee.id);
    let validEmpIds = [];
    selectedEmpIds.forEach(empId => {
      if (allEmpIds.includes(empId)) {
        validEmpIds.push(empId);
      }
    });
    let filters = {
      date: {
        from: moment(this.state.timeFrame.startDate).format("YYYY-MM-DD"),
        // from: this.state.timeFrame.startDate ? moment(this.state.timeFrame.startDate).format("YYYY-MM-DD") : moment().startOf("year").format("YYYY-MM-DD"),
        to: this.state.timeFrame.endDate ? moment(this.state.timeFrame.endDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")
      },
      org_id: this.state.dropDowns.companyMultiSelect.map(company => company.id),
      dept_id: validDeptIds,
      user_id: validEmpIds,
      role: this.state.dropDowns.roleMultiSelect.map(role => role.name),
      org: this.state.dropDowns.companyMultiSelect.map(company => company.value),
      dept: this.state.dropDowns.departmentMultiSelect.map(department => department.value),
      users: this.state.dropDowns.employeeMultiSelect.map(employee => employee.value)
      // redcategory: this.state.dropDowns.redemptionCategoryMultiSelect.map(redcategory => redcategory.value),
      // redemMode: this.state.dropDowns.redemptionModeMultiSelect.map(redemptionMode => redemptionMode.value)
    };

    const EmpIds = this.state.dropDowns.employeeIdsMultiSelect;
    for (let i =0; i< EmpIds.length; i++) {
      if(filters.user_id.indexOf(EmpIds[i].id)<0) {
        filters.user_id.push(EmpIds[i].id)
        filters.users.push(EmpIds[i].value)
      }
    }

    return filters;
  }

  // START OF SEARCH FILTERS EVENTS
  handleStartDateSelect = (date) => {
    this.setState({
      ...this.state,
      timeFrame: {
        ...this.state.timeFrame,
        startDate: date
      },
      showStartDatePicker: false,
      filterOverlay: false
    });
  }

  handleEndDateSelect = (date) => {
    this.setState({
      ...this.state,
      timeFrame: {
        ...this.state.timeFrame,
        endDate: date
      },
      showEndDatePicker: false,
      filterOverlay: false

    });
  }

  toggleStartDatepicker = () => {
    this.setState({
      ...this.state,
      showStartDatePicker: !this.state.showStartDatePicker,
      filterOverlay: !this.state.filterOverlay

    });
  }
  toggleEndDatepicker = () => {
    this.setState({
      ...this.state,
      showEndDatePicker: !this.state.showEndDatePicker,
      filterOverlay: !this.state.filterOverlay
    });
  }

  hideFilterOverlay = () => {
    this.setState({
      ...this.state,
      filterOverlay: false,
      showEndDatePicker: false,
      showStartDatePicker: false
    });
  }

  handleShowMoreMultiSelect = (name) => {
    this.setState({
      ...this.state,
      showMore: {
        ...this.state.showMore,
        [name]: true
      }
    });
  }

  handleFilterClick = (e) => {
    e.preventDefault();
    let selectedCompanies   = this.state.dropDowns.companyMultiSelect.map(company => company.id);
    let selectedDepartment  = this.state.dropDowns.departmentMultiSelect.map(department => department.id);
    this.setState({ // State changes before filter click
      ...this.state,
      selectedCompanies: [...selectedCompanies, ...selectedDepartment],
      redemptionTrend: {
        redemptionUDRFrequency: 12,
        switch: false
      },
      pointBasedRedemptionByBrand: {
        ...this.state.pointBasedRedemptionByBrand,
        switch: false
      },
      pointBasedRedemptionByCategory: {
        ...this.state.pointBasedRedemptionByCategory,
        switch: false
      },
      ageOfRedemptionSnapshot: {
        ...this.state.ageOfRedemptionSnapshot,
        switch: false
      },
      ageOfRedemptionFrequency: {
        ...this.state.ageOfRedemptionFrequency,
        switch: false
      },
      ageOfRedemptionTrend: {
        switch: false,
        isDepartment: false
      }
    }, () => {
      this.props.redemptionTilesRequest({ ...this.getFilterObject() });
      this.props.redemptionBudgetSnapshotRequest({ ...this.getFilterObject() });
      this.props.redemptionBudgetTrendRequest({ ...this.getFilterObject(), trendByRedeemed: this.state.redemptionTrend.switch });
      // this.switchHandler(false);
      this.props.redemptionMerchantDetails({ ...this.getFilterObject() });
      this.props.redemptionCategoryDetails({ ...this.getFilterObject() });
      this.props.timeOfRedemption({ ...this.getFilterObject() });
      this.props.redemptionPointsEarnedRedeemedRequest({ ...this.getFilterObject() });
      this.props.redemptionPointsEarnedRedeemedByDriversRequest({ ...this.getFilterObject() });
      this.props.redemptionTimeOfRedemptionByHourRequest({ ...this.getFilterObject() });
      this.props.ageOfRedemptionSnapshotRequest({ ...this.getFilterObject() });
      this.props.ageOfRedemptionFrequencyRequest({ ...this.getFilterObject() });
      this.props.redemptionUserAgeDataRequest({ ...this.getFilterObject() });
      this.props.ageOfRedemptionTrendRequest({ ...this.getFilterObject() });
    });
  }

  onTopClicked = (e) => {
    window.scrollTo(0, 0);
  }

  handleRedemptionBudgetTrendFrequency = (frequency) => {
    this.setState({
      ...this.state,
      redemptionTrend: {
        ...this.state.redemptionTrend,
        redemptionUDRFrequency: frequency
      }
    }, () => {
      this.props.redemptionBudgetTrendRequest({ ...this.getFilterObject(), parameter: this.state.redemptionTrend.redemptionUDRFrequency, trendByRedeemed: this.state.redemptionTrend.switch });
    });
  }


  switchHandler = (checked) => {
    this.setState({
      ...this.state,
      redemptionTrend: {
        ...this.state.redemptionTrend,
        switch: checked,
        redemptionUDRFrequency: 12
      }
    }, () => {
      let payloadForTreemap = {
        ...this.getFilterObject(),
        trendByRedeemed: checked ? true : false
      };

      this.props.redemptionBudgetTrendRequest(payloadForTreemap);
    });
  }

  ageOfRedemptionSnapshotHandler = (checked, type) => {
    if (type === 'Snapshot') {
      this.setState({
        ...this.state,
        ageOfRedemptionSnapshot: {
          ...this.state.ageOfRedemptionSnapshot,
          switch: checked
        }
      }, () => {
        let payloadForAgeOfRedemtion = {
          ...this.getFilterObject(),
          bydepartment: checked ? true : false
        };
        this.props.ageOfRedemptionSnapshotRequest(payloadForAgeOfRedemtion);
      })
    } else if (type === 'frequency') {
      this.setState({
        ...this.state,
        ageOfRedemptionFrequency: {
          ...this.state.ageOfRedemptionFrequency,
          switch: checked
        }
      }, () => {
        let payloadForAgeOfRedemtion = {
          ...this.getFilterObject(),
          byUnredeemed: checked ? true : false
        };
        this.props.ageOfRedemptionFrequencyRequest(payloadForAgeOfRedemtion);
        this.props.redemptionUserAgeDataRequest({ ...this.getFilterObject() });
      })
    } else if (type === 'trend') {
      this.setState({
        ...this.state,
        ageOfRedemptionTrend: {
          ...this.state.ageOfRedemptionTrend,
          switch: checked
        }
      }, () => {
        let payloadForAgeOfRedemtion = {
          ...this.getFilterObject(),
          byUnredeemed: checked ? true : false,
          isByDepartment: this.state.ageOfRedemptionTrend.isDepartment
        };
        this.props.ageOfRedemptionTrendRequest(payloadForAgeOfRedemtion);
      })
    }
  }

  ageOfRedemptionTrendHandler = (checked, type) => {
    this.setState({
      ...this.state,
      ageOfRedemptionTrend: {
        ...this.state.ageOfRedemptionTrend,
        isDepartment: checked
      }
    }, () => {
      let payloadForAgeOfRedemtion = {
        ...this.getFilterObject(),
        byUnredeemed: this.state.ageOfRedemptionTrend.switch ? true : false,
        isByDepartment: checked ? true : false
      };
      this.props.ageOfRedemptionTrendRequest(payloadForAgeOfRedemtion);
    })
  }

  pointBasedRedemptionHandler = (checked, fromMarchant = false) => {
    fromMarchant ?
      this.setState({
        ...this.state,
        pointBasedRedemptionByBrand: {
          ...this.state.pointBasedRedemptionByBrand,
          switch: checked
        }
      }, () => {
        let payloadForTreemap = {
          ...this.getFilterObject(),
          byNumberOfRedeemption: checked ? true : false
        };

        this.props.redemptionMerchantDetails(payloadForTreemap);
      })
      :
      this.setState({
        ...this.state,
        pointBasedRedemptionByCategory: {
          ...this.state.pointBasedRedemptionByCategory,
          switch: checked
        }
      }, () => {
        let payloadForTreemap = {
          ...this.getFilterObject(),
          byNumberOfRedeemption: checked ? true : false
        };

        this.props.redemptionCategoryDetails(payloadForTreemap);
      });
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.props.dashboardOverlay && !nextProps.dashboardOverlay) {
      this.setState({
        ...this.state,
        showEndDatePicker: false,
        showStartDatePicker: false
      });
    }
    if (this.props.filters.endDate !== nextProps.filters.endDate) {
      this.setState({
        ...this.state,
        timeFrame: {
          ...this.state.timeFrame,
          startDate: moment(nextProps.filters.endDate).startOf("year").format("DD MMM YYYY"),
          endDate: nextProps.filters.endDate
        }
      }, () => {
        // this.props.companyFilterDropdownRequest({ ...this.getFilterObject(), isfrom : 'redemption' });
        this.props.departmentFilterDropdownRequest();
        this.props.employeeFilterDropdownRequest({ org_id: [], dept_id: [], role: [] });
        this.props.roleFilterDropdownRequest();
        this.props.redemptionTilesRequest({ ...this.getFilterObject() });
        this.props.redemptionBudgetSnapshotRequest({ ...this.getFilterObject() });
        this.props.redemptionBudgetTrendRequest({ ...this.getFilterObject(), trendByRedeemed: this.state.redemptionTrend.switch });
        // this.switchHandler(false);
        this.props.redemptionMerchantDetails({ ...this.getFilterObject() });
        this.props.redemptionCategoryDetails({ ...this.getFilterObject() });
        this.props.timeOfRedemption({ ...this.getFilterObject() });
        this.props.redemptionPointsEarnedRedeemedRequest({ ...this.getFilterObject() });
        this.props.redemptionPointsEarnedRedeemedByDriversRequest({ ...this.getFilterObject() });
        this.props.redemptionTimeOfRedemptionByHourRequest({ ...this.getFilterObject() });
        this.props.ageOfRedemptionSnapshotRequest({ ...this.getFilterObject() });
        this.props.ageOfRedemptionFrequencyRequest({ ...this.getFilterObject() });
        this.props.redemptionUserAgeDataRequest({ ...this.getFilterObject() });
        this.props.ageOfRedemptionTrendRequest({ ...this.getFilterObject() });
      });
    }

    if (nextProps.companyFilter && nextProps.companyFilter.length === 1) {
      this.setState({
        ...this.state,
        dropDowns: {
          ...this.state.dropDowns,
          companyMultiSelect: [nextProps.companyFilter[0]]
        }
      }, () => {
        let selectedCompanies = this.state.dropDowns.companyMultiSelect.map(company => company.id);
        let selectedDepartment = this.state.dropDowns.departmentMultiSelect.map(department => department.id);
        this.setState({
          ...this.state,
          selectedCompanies: [...selectedCompanies, ...selectedDepartment]
        })
      });
    }
  }

  handleAgeRangeClicked = (clickedPayload) => {
    let { range } = clickedPayload;
    let apiPayload = {
      ageRange: range,
      byUnredeemed: this.state.ageOfRedemptionFrequency.switch ? true : false
    };

    this.setState({
      ...this.state,
      ageOfRedemption: {
        ...this.state.ageOfRedemption,
        feed: {
          ...this.state.ageOfRedemption.feed,
          ageRange: range
        }
      }
    }, () => {
      this.props.redemptionUserAgeDataRequest({ ...this.getFilterObject(), ...apiPayload });
    });
  }

  handlePdfGeneration = (e, id) => {
    this.props.startPDFexport();
    e.preventDefault();
    handlePdfGeneration(id, this.props.endPDFexport, 'Redemptions.pdf');
  }

  render() {
    return (
      <div className="appreciationContainer" id="redeptionContainer" >
        <div className="row">
          <Filter
            dashboardOverlay={this.props.dashboardOverlay}
            companyFilter={this.props.companyFilter}
            departmentFilter={this.props.departmentFilter}
            employeesFilter={this.props.employeesFilter}
            rolesFilter={this.props.rolesFilter}
            // redemptionCategoryFilter={ this.props.redcategoryFilter.filterCategories }
            // redemptionModeFilter={ this.props.redcategoryFilter.filterModes }
            handleStartDateSelect={this.handleStartDateSelect}
            showStartDatePicker={this.state.showStartDatePicker}
            showEndDatePicker={this.state.showEndDatePicker}
            handleEndDateSelect={this.handleEndDateSelect}
            timeFrame={this.state.timeFrame}
            filterOverlay={this.state.filterOverlay}
            hideFilterOverlay={this.hideFilterOverlay}
            toggleStartDatepicker={this.toggleStartDatepicker}
            toggleEndDatepicker={this.toggleEndDatepicker}
            handleMultiSelect={this.handleMultiSelect}
            handleDeleteOptions={this.handleDeleteOptions}
            dropDowns={this.state.dropDowns}
            handleShowMoreMultiSelect={this.handleShowMoreMultiSelect}
            showMore={this.state.showMore}
            showOptions={this.state.showOptions}
            handleSearchableChange={this.handleSearchableChange}
            handleFilterClick={this.handleFilterClick}
            clearAllSelected={this.clearAllFilterSelected}
          // redemptionFilter = {true}
          />

        {this.props.isChromeBasedBrowser ? 
          <div className="actionbtnContainer topExport">
            <div className={`exportPdfbtn ${this.props.pdfExport ? "btn-loader" : ""}`}>
              <button className="btn" onClick={ (e) => this.handlePdfGeneration(e, "outer-container") } >
                Export Dashboard 
                <img src={ pdficon } title="pdficon"/>
              </button>
            </div>
          </div>
        : null }

          <RedemptionTiles />

          <BudgetUtilization
            showEventModal={this.props.showEventModal}
            hideEventModal={this.props.hideEventModal}
            getFilterObject={this.getFilterObject}
            from={"budgetUtilization"}
            handleRedemptionBudgetTrendFrequency={this.handleRedemptionBudgetTrendFrequency}
            redemptionUDRFrequency={this.state.redemptionTrend.redemptionUDRFrequency}
            switch={this.state.redemptionTrend.switch}
            switchHandler={this.switchHandler}
          />

          <MerchantBarChartWarpper
            showEventModal={this.props.showEventModal}
            hideEventModal={this.props.hideEventModal}
            getFilterObject={this.getFilterObject}
            brandSwitch={this.state.pointBasedRedemptionByBrand.switch}
            categorySwitch={this.state.pointBasedRedemptionByCategory.switch}
            pointBasedRedemptionHandler={this.pointBasedRedemptionHandler}
          />

          <TimeOfRedemption
            showEventModal={this.props.showEventModal}
            hideEventModal={this.props.hideEventModal}
            getFilterObject={this.getFilterObject}
          />

          <AgeOfRedemption
            ageSnapshotSwitch={this.state.ageOfRedemptionSnapshot.switch}
            ageFrequencySwitch={this.state.ageOfRedemptionFrequency.switch}
            ageTrendSwitch={this.state.ageOfRedemptionTrend.switch}
            ageTrendDepartmentSwitch={this.state.ageOfRedemptionTrend.isDepartment}
            ageOfRedemptionSnapshotHandler={this.ageOfRedemptionSnapshotHandler}
            ageOfRedemptionTrendHandler={this.ageOfRedemptionTrendHandler}
            showEventModal={this.props.showEventModal}
            hideEventModal={this.props.hideEventModal}
            getFilterObject={this.getFilterObject}
            handleAgeRangeClicked={this.handleAgeRangeClicked}
            selectedCompanies={this.state.selectedCompanies}
          />

          <Footer />
          <div className="gotopbutton" id="gototopbutton" onClick={this.onTopClicked}>
            <img src={gotop} title="Go to Top" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isChromeBasedBrowser: state.Common.isChromeBasedBrowser,
    dashboardOverlay: state.Common.dashboardOverlay,
    loginReducer: state.Login,
    companyFilter: state.AppreciationDashboard.filterDropDown.company,
    departmentFilter: state.AppreciationDashboard.filterDropDown.department,
    employeesFilter: state.AppreciationDashboard.filterDropDown.employees,
    rolesFilter: state.AppreciationDashboard.filterDropDown.roles,
    // redcategoryFilter: state.RedemptionDashboard.filterDropDown.redcategory,
    // // redemptionModeFilter: state.AppreciationDashboard.filterDropDown.roles,
    pdfExport: state.Common.pdfExport,
    filters: state.AppreciationDashboard.filters
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showDashboardOverlay: () => dispatch(CommonActions.showDashboardOverlayAction()),
    hideDashboardOverlay: () => dispatch(CommonActions.hideDashboardOverlayAction()),
    startPDFexport: () => dispatch(CommonActions.startPDFexport()),
    endPDFexport: () => dispatch(CommonActions.endPDFexport()),
    showEventModal: (data) => dispatch(CommonActions.showEventModalAction(data)),
    companyFilterDropdownRequest: (data) => dispatch(AppreciationActions.companyFilterDropdownRequestAction(data)),
    departmentFilterDropdownRequest: (data) => dispatch(AppreciationActions.departmentFilterDropdownRequestAction(data)),
    employeeFilterDropdownRequest: (data) => dispatch(AppreciationActions.employeeFilterDropdownRequestAction(data)),
    ageGroupFilterDropdownRequest: () => dispatch(AppreciationActions.ageGroupFilterDropdownRequestAction()),
    roleFilterDropdownRequest: () => dispatch(AppreciationActions.roleFilterDropdownRequestAction()),
    // redCategoryFilterDropdownRequest: () => dispatch(RedemptionActions.redCategoryFilterDropdownRequestAction()),
    // // redModeFilterDropdownRequest: () => dispatch(RedemptionActions.redModeFilterDropdownRequestAction()),
    redemptionTilesRequest: (data) => dispatch(RedemptionActions.redemptionTilesRequestAction(data)),
    redemptionBudgetTrendRequest: (data) => dispatch(RedemptionActions.redemptionBudgetTrendRequestAction(data)),
    redemptionBudgetSnapshotRequest: (data) => dispatch(RedemptionActions.redemptionBudgetSnapshotRequestAction(data)),
    hideEventModal: () => dispatch(CommonActions.hideEventModalAction()),
    redemptionMerchantDetails: (data) => dispatch(RedemptionActions.redemptionMerchantRequestAction(data)),
    redemptionCategoryDetails: data => dispatch(RedemptionActions.redemptionCategoryRequestAction(data)),
    redemptionPointsEarnedRedeemedRequest: (data) => dispatch(RedemptionActions.redemptionPointsEarnedRedeemedRequestAction(data)),
    timeOfRedemption: (data) => dispatch(RedemptionActions.timeRedemptionRequestAction(data)),
    ageOfRedemptionSnapshotRequest: (data) => dispatch(RedemptionActions.ageOfRedemptionSnapshotRequestAction(data)),
    ageOfRedemptionFrequencyRequest: (data) => dispatch(RedemptionActions.ageOfRedemptionFrequencyRequestAction(data)),
    ageOfRedemptionTrendRequest: (data) => dispatch(RedemptionActions.ageOfRedemptionTrendRequestAction(data)),
    redemptionPointsEarnedRedeemedByDriversRequest: (data) => dispatch(RedemptionActions.redemptionPointsEarnedRedeemedByDriversRequestAction(data)),
    redemptionTimeOfRedemptionByHourRequest: (data) => dispatch(RedemptionActions.redemptionTimeOfRedemptionByHourRequestAction(data)),
    redemptionUserAgeDataRequest: (data) => dispatch(RedemptionActions.redemptionUserAgeDataRequestAction(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RedemptionPatternContainer);