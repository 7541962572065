import { takeEvery, put, call } from "redux-saga/effects";
import * as EngagementActionType from "../Actions/EngagementActionType";
import * as EngagementActions from "../Actions/EngagementActions";
import EngagementDashboardApi from "../../../../../Api/EngagementDashboardApi";

export function* getEngagementIsEventsEnabledData(action) {
  const { response, error } = yield call(
    EngagementDashboardApi.getIsEventsEnabledOnOrg,
    action.payload
  );
  if (response) {
    if (response.status_code === 200) {
      yield put(
        EngagementActions.engagementIsEventsEnabledSuccess(response.data)
      );
    } else if (response.status_code === 400) {
      yield put(EngagementActions.engagementIsEventsEnabledFail());
    }
  } else {
    yield put(EngagementActions.engagementIsEventsEnabledFail());
  }
}

export function* getEngagementTilesData(action) {
  const { response, error } = yield call(
    EngagementDashboardApi.getEngagementTilesDataDetails,
    action.payload
  );
  if (response) {
    if (response.status_code === 200) {
      yield put(EngagementActions.engagementTilesSuccess(response.data));
    } else if (response.status_code === 400) {
      yield put(EngagementActions.engagementTilesFail());
    }
  } else {
    yield put(EngagementActions.engagementTilesFail());
  }
}

export function* getEngagementNumOfUniqueUsersData(action) {
  const { response, error } = yield call(
    EngagementDashboardApi.getEngagementNumOfUniqueUsersDetails,
    action.payload
  );
  if (response) {
    if (response.status_code === 200) {
      yield put(
        EngagementActions.engagementNumOfUniqueUsersSuccess(response.data)
      );
    } else if (response.status_code === 400) {
      yield put(EngagementActions.engagementNumOfUniqueUsersFail());
    }
  } else {
    yield put(EngagementActions.engagementNumOfUniqueUsersFail());
  }
}


export function* getAvgAppUsersByDayAndTime(action) {
  const { response, error } = yield call(
    EngagementDashboardApi.getAvgAppUsersByDayAndTimeDetails,
    action.payload
  );
  if (response) {
    if (response.status_code === 200) {
      yield put(EngagementActions.engagementAvgAppUsersByDayAndTimeSuccess(response.data));
    } else if (response.status_code === 400) {
      yield put(EngagementActions.engagementAvgAppUsersByDayAndTimeFail());
    }
  } else {
    yield put(EngagementActions.engagementAvgAppUsersByDayAndTimeFail());
  }
}

export function* getengagementProportionOfUniqueUsersData(action) {
  const { response, error } = yield call(
    EngagementDashboardApi.getengagementProportionOfUniqueUsersDetails,
    action.payload
  );
  if (response) {
    if (response.status_code === 200) {
      yield put(
        EngagementActions.engagementProportionOfUniqueUsersSuccess(
          response.data
        )
      );
    } else if (response.status_code === 400) {
      yield put(EngagementActions.engagementProportionOfUniqueUsersFail());
    }
  } else {
    yield put(EngagementActions.engagementProportionOfUniqueUsersFail());
  }
}

export function* getFrequencyOfAppUsesData(action) {
  const { response, error } = yield call(
    EngagementDashboardApi.getFrequencyOfAppUsesDetails,
    action.payload
  );
  if (response) {
    if (response.status_code === 200) {
      yield put(
        EngagementActions.engagementFrequencyOfAppUsesSuccess(response.data)
      );
    } else if (response.status_code === 400) {
      yield put(EngagementActions.engagementFrequencyOfAppUsesFail());
    }
  } else {
    yield put(EngagementActions.engagementFrequencyOfAppUsesFail());
  }
}

export function* getFrequencyOfFeatureUsesData(action) {
  const { response, error } = yield call(
    EngagementDashboardApi.getFrequencyOfFeatureUsesDetails,
    action.payload
  );
  if (response) {
    if (response.status_code === 200) {
      yield put(
        EngagementActions.engagementFrequencyOfFeatureUsesSuccess(response.data)
      );
    } else if (response.status_code === 400) {
      yield put(EngagementActions.engagementFrequencyOfFeatureUsesFail());
    }
  } else {
    yield put(EngagementActions.engagementFrequencyOfFeatureUsesFail());
  }
}

export function* getPointEarningCategoryData(action) {
  const { response, error } = yield call(
    EngagementDashboardApi.getPointEarningCategoryDetails,
    action.payload
  );
  if (response) {
    if (response.status_code === 200) {
      yield put(
        EngagementActions.engagementPointEarningCategoriesSuccess(response.data)
      );
    } else if (response.status_code === 400) {
      yield put(EngagementActions.engagementPointEarningCategoriesFail());
    }
  } else {
    yield put(EngagementActions.engagementPointEarningCategoriesFail());
  }
}

export function* getPointEarningCategoryTrendData(action) {
  const { response, error } = yield call(
    EngagementDashboardApi.getPointEarningCategoryTrendDetails,
    action.payload
  );
  if (response) {
    if (response.status_code === 200) {
      yield put(
        EngagementActions.engagementPointEarningCategoriesTrendSuccess(
          response.data
        )
      );
    } else if (response.status_code === 400) {
      yield put(EngagementActions.engagementPointEarningCategoriesTrendFail());
    }
  } else {
    yield put(EngagementActions.engagementPointEarningCategoriesTrendFail());
  }
}

export function* getTopPointEarnerData(action) {
  const { response, error } = yield call(
    EngagementDashboardApi.getTopPointEarnerDetails,
    action.payload
  );
  if (response) {
    if (response.status_code === 200) {
      yield put(
        EngagementActions.engagementTopPointEarningReqSuccess(response.data)
      );
    } else if (response.status_code === 400) {
      yield put(EngagementActions.engagementTopPointEarningReqFail());
    }
  } else {
    yield put(EngagementActions.engagementTopPointEarningReqFail());
  }
}

export function* getTopPointEarnerDetailsData(action) {
  const { response, error } = yield call(
    EngagementDashboardApi.getTopPointEarnerBreakDownDetails,
    action.payload
  );
  if (response) {
    if (response.status_code === 200) {
      yield put(
        EngagementActions.engagementTopPointEarningDetailsSuccess(response.data)
      );
    } else if (response.status_code === 400) {
      yield put(EngagementActions.engagementTopPointEarningDetailsFail());
    }
  } else {
    yield put(EngagementActions.engagementTopPointEarningDetailsFail());
  }
}

export function* getEventsResponses(action) {
  const { response, error } = yield call(
    EngagementDashboardApi.getEventsResponsesDetails,
    action.payload
  );
  if (response) {
    if (response.status_code === 200) {
      yield put(EngagementActions.engagementEventsResponsesSuccess(response.data));
    } else if (response.status_code === 400) {
      yield put(EngagementActions.engagementEventsResponsesFail());
    }
  } else {
    yield put(EngagementActions.engagementEventsResponsesFail());
  }
}

export function* getNotifications(action) {
  const { response, error } = yield call(
    EngagementDashboardApi.getNotificationsDetails,
    action.payload
  );
  if (response) {
    if (response.status_code === 200) {
      yield put(EngagementActions.engagementNotificationsSuccess(response.data));
    } else if (response.status_code === 400) {
      yield put(EngagementActions.engagementNotificationsFail());
    }
  } else {
    yield put(EngagementActions.engagementNotificationsFail());
  }
}

export default function* rootEngagementSaga() {
  yield takeEvery(
    EngagementActionType.IS_EVENTS_ENABLED_REQ,
    getEngagementIsEventsEnabledData
  );
  
  yield takeEvery(EngagementActionType.TILES_REQ, getEngagementTilesData);

  yield takeEvery(
    EngagementActionType.NUMBER_OF_UNIQUE_USERS_REQ,
    getEngagementNumOfUniqueUsersData
  );

  yield takeEvery(
    EngagementActionType.AVG_APP_USERS_BY_DAY_AND_TIME_REQ,
    getAvgAppUsersByDayAndTime
  );

  yield takeEvery(
    EngagementActionType.PROPORTION_OF_UNIQUE_USER_REQ,
    getengagementProportionOfUniqueUsersData
  );
  yield takeEvery(
    EngagementActionType.FREQUENCY_OF_FEATURE_USES_REQ,
    getFrequencyOfFeatureUsesData
  );

  yield takeEvery(
    EngagementActionType.FREQUENCY_OF_APP_USES_REQ,
    getFrequencyOfAppUsesData
  );

  yield takeEvery(
    EngagementActionType.GET_POINT_EARNING_CATEGORIES_REQ,
    getPointEarningCategoryData
  );

  yield takeEvery(
    EngagementActionType.GET_POINT_EARNING_CATEGORIES_TREND_REQ,
    getPointEarningCategoryTrendData
  );

  yield takeEvery(
    EngagementActionType.GET_TOP_POINT_EARNER_REQUEST,
    getTopPointEarnerData
  );

  yield takeEvery(
    EngagementActionType.GET_TOP_POINT_EARNER_DETAILS_REQUEST,
    getTopPointEarnerDetailsData
  );

  yield takeEvery(
    EngagementActionType.EVENTS_RESPONSES_REQ,
    getEventsResponses
  );

  yield takeEvery(
    EngagementActionType.NOTIFICATIONS_REQ,
    getNotifications
  );
}
