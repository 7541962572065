import Api from "../Utilities/Api/ApiMethods";

const loginRequest = async params => {
  try {
    const response = await Api.post( "login", params );
    return { response: { ...response.data } };
  } catch ( error ) {
    return {
      error: {
        ...error
      }
    };
  }
};

export default {
  loginRequest
};