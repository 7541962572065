import React, { PureComponent } from "react";
import { Treemap, Tooltip, Text } from "recharts";
import { THEME_COLORS } from "../../constants/constants";
import * as d3 from "d3";
import { relative } from "path";

// const COLORS = ["#80deea", "#d69b27", "#f88601", "#a6e0b8", "#edc832", "#ff7d95", "#dbbf97", "#748796", "#e66fff", "#fd3b55"];
// 
const fontFamily = "Roboto";
class CustomizedContent extends PureComponent {
  constructor (props) {
    super(props);
    this.state = {
      name: "", 
      id: null,
      width: null,
      x: null,
      y: null,
      depth: null,
      showNameHover: false
    };
  }

    handleOnClick = (content) => {
      // handle click
      this.props.clickProps.handleEmployeeClicked(content);
    }
    componentDidMount () {
      if(this.props.clickProps.graphName === "treemap-expanded") {
        d3.selectAll(".treemap-expand .treemap-container svg.recharts-surface").attr("height", (this.props.clickProps.height + 70));
      } else {
        d3.selectAll(".cloud-container .treemap-container svg.recharts-surface").attr("height", (this.props.clickProps.height + 70));
      }

      if(this.state.depth === 2) {
        var text = d3.select(`#${this.state.id}`);
        var width = this.state.width;
        var name = this.state.name;
        var words = name.split(/\s+/).reverse();
        var word;
        var line = [];
        var lineNumber = 0;
        var lineHeight = 1.1; // ems
        var x = this.state.x;
        var y = this.state.y;
        // x = text.attr("x"),
        // y = text.attr("y"),
        var dy = 0; // parseFloat(text.attr("dy")),
        var tspan = text.text(null)
          .append("tspan")
          .attr("x", x)
          .attr("y", y+5)
          .attr("dy", dy + "em");
  
        while(word = words.pop()) {
          line.push(word);
          tspan.text(line.join(" "));
          if(tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text.append("tspan")
              .attr("x", x)
              .attr("y", y)
              .attr("dy", ++lineNumber * lineHeight + dy + "em")
              .text(word);
          }
        }
      }
    }

    componentWillMount () {
      let legendName = this.getLegendName();
      this.setState({
        name: legendName || this.props.name,
        id: `u${this.props.root.user_id}s${this.props.index}-${this.props.clickProps.graphName}`,
        width: this.props.width,
        x: this.props.x + this.props.width / 2,
        y: this.props.y + this.props.height / 2,
        depth: this.props.depth
      });
    }

    onNameHover = (e, props) => {
      
      let x = e.clientX;
      let y = e.clientY;

      let tooltip = d3.select(`#treemap-custom-tooltip-${props.clickProps.graphName}`);
      tooltip.style("visibility", "visible")
        .style("top", `${y + 10}px`)
        .style("left", `${x}px`);
      d3.select(`#treemap-custom-tooltip-label-${props.clickProps.graphName}`).style("color", props.color).text(`Total Appreciation: ${props.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`);
      d3.select(`#treemap-custom-tooltip-title-${props.clickProps.graphName}`).text(props.name);
      if( x > this.props.clickProps.width) {
        x = x - document.getElementById(`treemap-custom-tooltip-${props.clickProps.graphName}`).offsetWidth;
        let tooltip = d3.select(`#treemap-custom-tooltip-${props.clickProps.graphName}`);
        tooltip.style("visibility", "visible")
          .style("top", `${y + 10}px`)
          .style("left", `${x}px`);
        d3.select(`#treemap-custom-tooltip-label-${props.clickProps.graphName}`).style("color", props.color).text(`Total Appreciation: ${props.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`);
        d3.select(`#treemap-custom-tooltip-title-${props.clickProps.graphName}`).text(props.name);
      }
    }

    onNameHoverLeave = (props) => {
      let tooltip = d3.select(`#treemap-custom-tooltip-${props.clickProps.graphName}`);
      tooltip.style("visibility", "hidden");
    }

    onStrengthHover = (e, props) => {
      let x = e.clientX;
      let y = e.clientY;
      let tooltip = d3.select(`#treemap-custom-tooltip-${props.clickProps.graphName}`);
      tooltip.style("visibility", "visible")
        .style("top", `${y + 10}px`)
        .style("left", `${x}px`);
      d3.select(`#treemap-custom-tooltip-label-${props.clickProps.graphName}`).style("color", props.root.color).text(`${props.name}: ${props.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`);
      d3.select(`#treemap-custom-tooltip-title-${props.clickProps.graphName}`).text(props.root.name);
      if(x > this.props.clickProps.width) {
        x = x - document.getElementById(`treemap-custom-tooltip-${props.clickProps.graphName}`).offsetWidth;
        let tooltip = d3.select(`#treemap-custom-tooltip-${props.clickProps.graphName}`);
        tooltip.style("visibility", "visible")
          .style("top", `${y + 10}px`)
          .style("left", `${x}px`);
        d3.select(`#treemap-custom-tooltip-label-${props.clickProps.graphName}`).style("color", props.root.color).text(`${props.name}: ${props.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`);
        d3.select(`#treemap-custom-tooltip-title-${props.clickProps.graphName}`).text(props.root.name);
      }
    }

    onStrengthHoverLeave = (props) => {
      let tooltip = d3.select(`#treemap-custom-tooltip-${props.clickProps.graphName}`);
      tooltip.style("visibility", "hidden");
    }

    getLegendName = () => {
      let LegendName = null;
      let legendIndex = 1;
      let givenStrengthName = this.props.name ? this.props.name : null;
      if(this.props.clickProps.strengthData) {
        this.props.clickProps.strengthData.forEach(function (strength) {
          if(givenStrengthName && strength.name == givenStrengthName) {
            LegendName = "S" + legendIndex;
          }
          legendIndex++;
        });
      }
      return LegendName;
    }

    render () {
      const {
        root, depth, x, y, width, height, index, payload, rank, name, color
      } = this.props;

      let y1 = y;

      if(depth === 1) {
        if(y >= 1) {
          y1 = y + (this.props.clickProps.height - y ) + 15;
        } else {
          y1 = y - 5;
        }
        if(this.props.myXYdata.length > 0) {
          this.props.myXYdata.forEach(element => {
            if(element.x === x && element.y === y1 && y1 > 0 && element.name !== name ) {
              y1 = y1 + 15;
            }
          });
        }
        const found = this.props.myXYdata.some(el => el.name === name);
        if(!found) { this.props.myXYdata.push({ x: x, y: y1, name: name }); };
      }

      return (
        <g width={width} >
          <rect
            x={x}
            y={y}
            width={width}
            height={height}
            style={{
              fill: depth < 2 ? color : "none",
              stroke: "#fff",
              strokeWidth: 2 / (depth + 1e-10),
              strokeOpacity: 1 / (depth + 1e-10),
              cursor: "pointer"
              // fontFamily: `${fontFamily}, sans-serif`,
              // fontWeight:500
            }}
            onClick={ () => this.handleOnClick(this.props)}
          />
          {
            depth === 1
              ? <text
                x={x + 4}
                y={y1}
                fill={color}
                fontSize={16}
                fontFamily= {`${fontFamily}, sans-serif`}
                fontWeight= '500'
                strokeWidth= "0"
                width={width}
                textAnchor="start"
                onMouseEnter={ (e) => { this.onNameHover(e, this.props) } }
                onMouseLeave={ () => this.onNameHoverLeave(this.props) }
              >
                {(width / 10) < name.length ? `${name.substring(0, (width / 10.55))}` : name }
              </text>
              : null
          }
          {
            depth === 2 ? (
              <text
                x={x + width / 2 }
                y={y + height / 2 }
                textAnchor="middle"
                id={`u${this.props.root.user_id}s${this.props.index}-${this.props.clickProps.graphName}`}
                fill="#fff"
                fontSize={12}
                fontFamily= {`${fontFamily}, sans-serif`}
                fontWeight= '500'
                strokeWidth= "0"
                width={width}
                textAnchor="middle"
                onMouseEnter={ (e) => { this.onStrengthHover(e, this.props) } }
                onMouseLeave={ () => this.onStrengthHoverLeave(this.props) }
              >
                {name}
              </text>
            ) : null
          }
         
        </g>
      );
    }
}

// TREE MAP CHART
export default class TreeMapChart extends PureComponent {
  render () {
    var myXYdata = [];
    return (
      <div className="treemap-container position-relative">
        <Treemap
          width={this.props.width}
          height={this.props.height}
          data={this.props.data}
          dataKey="size"
          ratio={4 / 3}
          stroke="#fff"
          fill="#8884d8"
          isAnimationActive={false}
          content={<CustomizedContent clickProps={ { ...this.props } } myXYdata={myXYdata} />}
        >
        </Treemap>
        <div id={`treemap-custom-tooltip-${this.props.graphName}`} className="custom-tooltip" style={{ visibility: "visible", position: "fixed", top: "0px" }} >
          <p className={"recharts-tooltip-label"} id={`treemap-custom-tooltip-title-${this.props.graphName}`} ></p>
          <p className="label" id={`treemap-custom-tooltip-label-${this.props.graphName}`} ></p>        </div>
      </div>
    );
  }
}