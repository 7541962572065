import React from "react";

// Common Loader Container
const TableLoader = ( ) => {
  return (
    <div className="tableskeleton">        
        <table>
            <tr >
                <th class="animated-background"></th>
                <th class="animated-background"></th>
                <th class="animated-background"></th>
            </tr>
            <tr>
                <td class="animated-background"></td>
                <td class="animated-background"></td>
                <td class="animated-background"></td>
            </tr>
            <tr>
                <td class="animated-background"></td>
                <td class="animated-background"></td>
                <td class="animated-background"></td>
            </tr>
            <tr>
                <td class="animated-background"></td>
                <td class="animated-background"></td>
                <td class="animated-background"></td>
            </tr>
            <tr>
                 <td class="animated-background"></td>
                <td class="animated-background"></td>
                <td class="animated-background"></td>
            </tr>
            <tr>
                <td class="animated-background"></td>
                <td class="animated-background"></td>
                <td class="animated-background"></td>
            </tr>
            <tr>
                <td class="animated-background"></td>
                <td class="animated-background"></td>
                <td class="animated-background"></td>
            </tr>
            <tr>
                <td class="animated-background"></td>
                <td class="animated-background"></td>
                <td class="animated-background"></td>
            </tr>
            <tr>
                 <td class="animated-background"></td>
                <td class="animated-background"></td>
                <td class="animated-background"></td>
            </tr>
        </table>
    </div>
     
  );
};

export default TableLoader;