import React, { PureComponent } from "react";
import { Pie, Tooltip, PieChart, Label, Cell, Legend } from "recharts";
import { CHART } from "../../constants/constants";
export default class PieCharts extends PureComponent {
  render() {
    const innerRadius = this.props.innerRadius || 55;
    const outerRadius = this.props.outerRadius || 55;
    const centerOfHeight = this.props.height / 2.5;
    const centerOfWidth = this.props.width / (this.props.isSinglePie ? 1 : 2);
    const firstPie = {
      cx: centerOfWidth / 2,
      cy: centerOfHeight,
      innerRadius: innerRadius,
      outerRadius: outerRadius,
    };

    const secondPie = {
      cx: centerOfWidth + firstPie.cx,
      cy: centerOfHeight,
      innerRadius: innerRadius,
      outerRadius: outerRadius,
    };
    const data = this.props.data;
    const data01 = this.props.data01;
    const singleColor = "#6fd8e5";
    const colorRange = this.props.colorRange;
    const chartTitleStyle = {
      cursor: "pointer",
      fontFamily: CHART.fontFamily,
      fontWeight: CHART.fontWeight,
      fontSize: "16px",
      color: "#0b2d44",
    };

    return (
      <PieChart width={this.props.width} height={this.props.height}>
        <Pie
          dataKey={this.props.pieDateKey}
          type="number"
          isAnimationActive={false}
          data={data}
          cx={firstPie.cx}
          cy={firstPie.cy}
          innerRadius={firstPie.innerRadius}
          outerRadius={firstPie.outerRadius}
          fill1={singleColor}
          label1={
            this.props.showLabels
              ? ({
                  cx,
                  cy,
                  midAngle,
                  innerRadius,
                  outerRadius,
                  points,
                  index,
                }) => {
                  console.log("handling label?", this);
                  const RADIAN = Math.PI / 180;
                  // eslint-disable-next-line
                  const radius = 25 + innerRadius + (outerRadius - innerRadius);
                  // eslint-disable-next-line
                  const x = cx + radius * Math.cos(-midAngle * RADIAN);
                  // eslint-disable-next-line
                  const y = cy + radius * Math.sin(-midAngle * RADIAN);
                  return (
                    <text
                      x={x}
                      y={y}
                      fill2={singleColor}
                      textAnchor={x > cx ? "start" : "end"}
                      dominantBaseline="central"
                    >
                      {data[index].name}
                    </text>
                  );
                }
              : false
          }
        >
          <Label
            style={{ ...chartTitleStyle }}
            value={this.props.title}
            position="center"
          />

          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={colorRange ? colorRange[index] : singleColor}
            />
          ))}
        </Pie>

        {this.props.showLegends ? (
          <Legend verticalAlign="bottom" height={36} />
        ) : null}

        {data01 && data01.length > 0 ? (
          <Pie
            dataKey={this.props.pieDateKey}
            type="number"
            isAnimationActive={false}
            data={data01}
            cx={secondPie.cx}
            cy={secondPie.cy}
            innerRadius={secondPie.innerRadius}
            outerRadius={secondPie.outerRadius}
            fill1={singleColor}
            label1={({
              cx,
              cy,
              midAngle,
              innerRadius,
              outerRadius,
              points,
              index,
            }) => {
              console.log("handling label?");
              const RADIAN = Math.PI / 180;
              // eslint-disable-next-line
              const radius = 25 + innerRadius + (outerRadius - innerRadius);
              // eslint-disable-next-line
              const x = cx + radius * Math.cos(-midAngle * RADIAN);
              // eslint-disable-next-line
              const y = cy + radius * Math.sin(-midAngle * RADIAN);
              return (
                <text
                  x={x}
                  y={y}
                  fill1={singleColor}
                  textAnchor={x > cx ? "start" : "end"}
                  dominantBaseline="central"
                >
                  {"D-" + index}
                  {/* {data[index].name} */}
                </text>
              );
            }}
          >
            {data01.map((entry, index) => {
              return <Cell key={`cell1-${index}`} fill={colorRange[index]} />;
            })}

            <Label
              style={{ ...chartTitleStyle }}
              value={this.props.title01}
              position="center"
            />
          </Pie>
        ) : null}

        <Tooltip
          formatter={(value, name, props) => [
            Number(value)
              .toFixed(this.props.showDecimal ? this.props.decimalPoints : 0)
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
              (this.props.appendLabel ? " " + this.props.appendLabel : ""),
            name,
            ,
          ]}
        />
      </PieChart>
    );
  }
}
