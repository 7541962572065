import React, { Fragment } from "react";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  Brush,
  BarChart,
} from "recharts";

class Histogram extends React.Component {
  customBrushTickFormatter = (props) => {};
  renderLegend = (props) => {
    const { payload } = props;
    return (
      <ul>
        {payload.map((entry, index) => {
          return (
            <li
              key={`item-${index}`}
              style={{
                fontSize: "12",
                fontFamily: "roboto",
                fontWeight: "500",
              }}
            >
              {" "}
              <span style={{ backgroundColor: entry.color }}></span>{" "}
              {entry.value}
            </li>
          );
        })}
      </ul>
    );
  };

  CustomYAxisTickLeft = (props) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          textAnchor="end"
          fontSize="12"
          dy={"0.3em"}
          fontFamily="roboto"
          fontWeight="600"
          fill="#666"
        >
          {payload.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
        </text>
      </g>
    );
  };

  XaxisLabel = ({ axisType, x, y, width, height, stroke, children }) => {
    const isVert = axisType === "yAxis";
    const cx = isVert ? x : x + width / 2;
    const cy = isVert ? height / 2 + y : y + height + 10;
    const rot = isVert ? `270 ${cx} ${cy}` : 0;
    return (
      <text
        x={cx}
        y={cy}
        transform={`rotate(${rot})`}
        textAnchor="middle"
        stroke={stroke}
      >
        {children}
      </text>
    );
  };
  CustomYAxisTickRight = (props) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          textAnchor="start"
          dy={"0.3em"}
          fontSize="12"
          fontFamily="roboto"
          fontWeight="600"
          fill="#666"
        >
          {payload.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
        </text>
      </g>
    );
  };

  customYaxisLable = (props) => {
    let yPosition =
      this.props.height - this.props.margin.top - this.props.margin.bottom;
    return (
      // this.props.yAxisLabel2 ? <Fragment>
      //   {/* <text transform={`translate(20px, ${yPosition}) rotate(-90)`}>
      //     {" "}
      //     {this.props.yAxisLabel}{" "}
      //   </text> */}
      //   <text transform={`translate(10px, ${yPosition}) rotate(-90)`}>
      //     {" "}
      //     {this.props.yAxisLabel2}{" "}
      //   </text>
      // </Fragment>:
      <text transform={`translate(20px, ${yPosition}) rotate(-90)`}>
        {" "}
        {this.props.yAxisLabel}{" "}
      </text>
    );
  };

  render() {
    return (
      <ComposedChart
        width={this.props.width}
        height={this.props.height}
        margin={this.props.margin}
        data={this.props.data}
        reverseStackOrder={true}
      >
        <XAxis
          dataKey={this.props.xAxisNameId}
          tick={{ fontSize: 12, fontFamily: "roboto", fontWeight: 600 }}
          angle={this.props.xAxisAngle}
          interval={0}
          padding={{ top: 20 }}
          textAnchor={this.props.textAnchor}
          allowDataOverflow={false}
        >
          {this.props.xAxisLabel ? (
            <Label
              value={this.props.xAxisLabel}
              style={{
                fontSize: 14,
                fontFamily: "roboto",
                fontWeight: 500,
                fill: "#758088",
              }}
              offset={-60}
              position="insideBottom"
            />
          ) : null}
        </XAxis>

        <YAxis
          yAxisId="left"
          tick={this.CustomYAxisTickLeft}
          label={this.props.yAxisLabel}
          label={
            this.props.yAxisLabel /* && !this.props.yAxisLabel2*/
              ? {
                  value: this.props.yAxisLabel,
                  angle: -90,
                  position: "center",
                  dx: -30,
                  style: {
                    fontSize: 14,
                    fontFamily: "roboto",
                    fontWeight: 500,
                    fill: "#758088",
                  },
                }
              : null /*this.customYaxisLable*/
          }
          allowDataOverflow={false}
        ></YAxis>

        {this.props.showRightYAxis && this.props.maxRightAxisValue ? (
          <YAxis
            yAxisId="right"
            // domain={[0, this.props.maxRightAxisValue ]}
            tick={this.CustomYAxisTickRight}
            // type="number"
            // domain={[0, 'auto' ]}
            // allowDecimals={false}
            // tickCount={5}
            label={
              this.props.yAxisRightLabel
                ? {
                    value: this.props.yAxisRightLabel,
                    angle: -90,
                    position: "center",
                    dx: 20,
                    style: {
                      fontSize: 14,
                      fontFamily: "roboto",
                      fontWeight: 500,
                      fill: "#758088",
                    },
                  }
                : null
            }
            orientation="right"
             allowDataOverflow={false}
          />
        ) : null}

        {this.props.showRightYAxis && !this.props.maxRightAxisValue ? (
          <YAxis
            yAxisId="right"
            tick={this.CustomYAxisTickRight}
            label={
              this.props.yAxisRightLabel
                ? {
                    value: this.props.yAxisRightLabel,
                    angle: -90,
                    position: "center",
                    dx: 20,
                    style: {
                      fontSize: 14,
                      fontFamily: "roboto",
                      fontWeight: 500,
                      fill: "#758088",
                    },
                  }
                : null
            }
            orientation="right"
            allowDataOverflow={false}
          />
        ) : null}

        <Tooltip
        formatter = { (value,name,props)=>
         ( value %1 )== 0 ?
          [
            Number(value)
            .toFixed(0)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"),
          name,
          ] :
          [
            Number(value)
            .toFixed(1)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"),
          name,
          ]
        }
          // formatter={(value, name, props ) => props.payload.isdrivergraph ?  [
          //   Number( name === "Total # of Redemptions" ? props.payload.actual_number_of_redemption : value)
          //     .toFixed(name === "Total # of Redemptions" || name === "# of Unique Users Redeeming" ? 0 : 1 )
          //     .toString()
          //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"),
          //   name
          // ]: [
          //   Number( name === "Total # of Redemptions" ? props.payload.actual_number_of_redemption : value)
          //     .toFixed(1)
          //     .toString()
          //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"),
          //   name
          // ]}
        />
        <Legend
          verticalAlign="top"
          height={36}
          layout="horizontal"
          align={this.props.legendAlign ? this.props.legendAlign : "right"}
          margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
          wrapperStyle={{ marginTop: this.props.isFromImage ? "-20px" : null }}
        />
        {this.props.xAxisNames.map((name, index) => (
          <Bar
            yAxisId="left"
            key={index}
            dataKey={name}
            stackId="x"
            fill={this.props.colors[index]}
            barSize={this.props.barSize}
            onClick={
              this.props.isOnClickEvent
                ? (e) => this.props.clickHandler(e)
                : null
            }
          />
        ))}
        {/* <Brush
          dataKey={this.props.xAxisNameId}
          height={this.props.brushHeight}
          width={this.props.brushWidth - this.props.margin.right}
          y={this.props.height - 20}
          x={0}
          startIndex={0}
          dataKey={this.props.xAxisNameId}
        ></Brush> */}

        {this.props.showBrush ? (
          <Brush
            dataKey={this.props.xAxisNameId}
            height={this.props.brushHeight}
            width={this.props.width - 50}
            stroke="#0b2d44"
            y={this.props.height - this.props.brushHeight}
            x={0}
            startIndex={this.props.dataLength-12}
            // endIndex={15}
            travellerWidth={0}
            tickFormatter={this.customBrushTickFormatter}
          >
            <BarChart>
              {this.props.xAxisNames.map((name, index) => (
                <Bar
                  yAxisId="left"
                  name="yogesh"
                  key={`name-${index}`}
                  dataKey={name}
                  stackId="x"
                  fill={this.props.colors[index]}
                  barSize={30}
                  onClick={
                    this.props.isOnClickEvent
                      ? (e) => this.props.clickHandler(e)
                      : null
                  }
                />
              ))}
            </BarChart>
          </Brush>
        ) : null}

        {this.props.showRightYAxis ? (
          <Line
            legendType="rect"
            type="monotone"
            yAxisId="right"
            dataKey={
              this.props.rightAxisKey ? this.props.rightAxisKey : "Benchmark"
            }
            dot={{ strokeWidth: "1" }}
            stroke={
              this.props.linestrokeColor
                ? this.props.linestrokeColor
                : "#d69a26"
            }
            strokeWidth="2"
          />
        ) : null}
      </ComposedChart>
    );
  }
}

export default Histogram;
