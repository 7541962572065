import React from "react";
import { Tile, TileLoader } from "../../../../../UI";
import { TILES_DECIMAL_POINT } from "../../../../../constants/constants";
import { connect } from "react-redux";
class TilesSection extends React.Component {
  render () {
    let topCategories = [], topRedemers = [], topRewards = [], budgetDrawdown = [], avgRedemptionValue = 0, avgNoRedemptionByUser = 0, BudgetDrawdownTitle = null;
    if(this.props.tilesData) {
      if(this.props.tilesData) {
        topCategories = this.props.tilesData.topCategories;
        topRedemers = this.props.tilesData.topRedemers;
        topRewards = this.props.tilesData.topRewards;
        budgetDrawdown = this.props.tilesData.budgetDrawdown;
        if( this.props.tilesData.avgRedemptionValue ) {
          avgRedemptionValue =  this.props.tilesData.avgRedemptionValue;
        }

        // if( this.props.tilesData.budgetDrawdown ) {
        //   BudgetDrawdownTitle = "Credit Utilisation (" + (( this.props.tilesData.budgetDrawdown[0].utilized_credit/this.props.tilesData.budgetDrawdown[0].total_credit ) * 100).toFixed(TILES_DECIMAL_POINT) + "%)";
        // }

        if( this.props.tilesData.avgNoRedemptionByUser ) {
          avgNoRedemptionByUser = this.props.tilesData.avgNoRedemptionByUser.total_credit;
        }
      }
    }
    return (
      <div className="tilesSection" id="tilesSection" >
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">

            {!this.props.loader
              ? <Tile
                className={"lighter-skyblue"}
                showTopThree={true}
                title={'Credit Utilisation'}
                pointOne={budgetDrawdown && budgetDrawdown.length > 0 ? budgetDrawdown[0].name ? budgetDrawdown[0].name : "Organisation Credit" : ""}
                valueOne={budgetDrawdown && budgetDrawdown.length > 0 ? budgetDrawdown[0].total_credit ? budgetDrawdown[0].total_credit : "0" : "0"}
                pointTwo={budgetDrawdown && budgetDrawdown.length > 0 ? budgetDrawdown[0].name ? budgetDrawdown[0].name : "Org. Balance (based on redemption)" : ""}
                valueTwo={budgetDrawdown && budgetDrawdown.length > 0 ? budgetDrawdown[0].utilized_credit ? budgetDrawdown[0].utilized_credit : "0" : "0"}
                pointThree={budgetDrawdown && budgetDrawdown.length > 0 ? budgetDrawdown[0].name ? budgetDrawdown[0].name : "Org. Balance (based on awarding)" : ""}
                valueThree={budgetDrawdown && budgetDrawdown.length > 0 ? budgetDrawdown[0].balance_based_on_awarded ? budgetDrawdown[0].balance_based_on_awarded : "0" : "0"}
                showDecimal={false}
              /> : <TileLoader />}
          </div>
          <div className="col-12 col-md-6 col-lg-4 ">
            {!this.props.loader
            ? <Tile
              className={"lighter-pink"}
              text={"Avg # of Redemptions Per User"}
              text1={"(based on current staff strength)"}
              showAbsoluteValue={true}
              value={avgNoRedemptionByUser.toString()}
              showDecimal={ true }
              decimalPoint = {TILES_DECIMAL_POINT}
              ingoreRound={true}
            /> : <TileLoader />}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            {!this.props.loader
            ? <Tile
              className={"lighter-green"}
              text={"Avg Age of Unredeemed Points (days)"}
              showAbsoluteValue={true}
              value={avgRedemptionValue}
              showDecimal={ true }
              decimalPoint = {TILES_DECIMAL_POINT}
              ingoreRound={true}
            /> : <TileLoader />}
          </div>   
        </div>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            {!this.props.loader ? 
            <Tile className={"lighter-yellow"}
              showTopThree={true}
              title={"Top 3 Redeemers (by points) "}
              pointOne={topRedemers && topRedemers.length > 0 ? topRedemers[0].name ? topRedemers[0].name : "Anonymous" : "No Data Found"}
              valueOne={topRedemers && topRedemers.length > 0 ? topRedemers[0].count : ""}
              pointTwo={topRedemers && topRedemers.length > 1 ? topRedemers[1].name ? topRedemers[1].name : "Anonymous" : ""}
              valueTwo={topRedemers && topRedemers.length > 1 ? topRedemers[1].count : ""}
              pointThree={topRedemers && topRedemers.length > 2 ? topRedemers[2].name ? topRedemers[2].name : "Anonymous" : ""}
              valueThree={topRedemers && topRedemers.length > 2 ? topRedemers[2].count : ""}
              showDecimal={false}
            /> 
             : <TileLoader />}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            {!this.props.loader ? 
            <Tile className={"lighter-skyblue"}
              showTopThree={true}
              title={"Top 3 Reward Categories (by points)"}
              pointOne={topCategories && topCategories.length > 0 ? topCategories[0].name ? topCategories[0].name : "Anonymous" : "No Data Found"}
              valueOne={topCategories && topCategories.length > 0 ? topCategories[0].count : ""}
              pointTwo={topCategories && topCategories.length > 1 ? topCategories[1].name ? topCategories[1].name : "Anonymous" : ""}
              valueTwo={topCategories && topCategories.length > 1 ? topCategories[1].count : ""}
              pointThree={topCategories && topCategories.length > 2 ? topCategories[2].name ? topCategories[2].name : "Anonymous" : ""}
              valueThree={topCategories && topCategories.length > 2 ? topCategories[2].count : ""}
              showDecimal={false}
            /> 
             : <TileLoader />}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            {!this.props.loader ? 
            <Tile className={"light-pink"}
              showTopThree={true}
              title={"Top 3 Rewards (by points)"}
              pointOne={topRewards && topRewards.length > 0 ? topRewards[0].name ? topRewards[0].name : "Anonymous" : "No Data Found"}
              valueOne={topRewards && topRewards.length > 0 ? topRewards[0].count : ""}
              pointTwo={topRewards && topRewards.length > 1 ? topRewards[1].name ? topRewards[1].name : "Anonymous" : ""}
              valueTwo={topRewards && topRewards.length > 1 ? topRewards[1].count : ""}
              pointThree={topRewards && topRewards.length > 2 ? topRewards[2].name ? topRewards[2].name : "Anonymous" : ""}
              valueThree={topRewards && topRewards.length > 2 ? topRewards[2].count: ""}
              showDecimal={false}
            /> 
            : <TileLoader />}
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = state => {
  return {
    tilesData: state.RedemptionDashboard.tiles,
    loader: state.RedemptionDashboard.tiles.loader
  };
};

export default connect(mapStateToProps)(TilesSection);
