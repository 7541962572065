const API = {
  ENGAGEMENTS: {
    IS_EVENTS_ENABLED: "auth/engagement/is-events-enabled",
    TILES: "auth/engagement/engagement-tiles",
    NUMBER_OF_UNIQUE_USERS: "auth/engagement/number-of-unique-users",
    AVG_APP_USERS_BY_DAY_AND_TIME:
      "auth/engagement/average-app-users-by-day-and-time",
    PROPORTION_OF_UNIQUE_USER: "auth/engagement/proportion-of-unique-user",
    FREQUENCY_OF_APP_USES: "auth/engagement/frequency-of-app-uses",
    FREQUENCY_OF_FEATURE_USES: "auth/engagement/frequency-of-feature-uses",
    POINT_EARNING_CATEGORIES: "auth/engagement/point-earning-categories",
    POINT_EARNING_CATEGORIES_TREND:
      "auth/engagement/point-earning-categories-trend",
    TOP_POINT_EARNING: "auth/engagement/top-point-earners-",
    EVENTS_RESPONSES: "auth/engagement/events-responses",
    NOTIFICATIONS: "auth/engagement/notifications",
  },
};

export default API;
