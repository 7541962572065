import * as AppreciationActionTypes from "../Actions/AppreciationActionTypes";

const initialState = {
  filterDropDown: {
    company: [],
    department: [],
    employees: [],
    ageGroup: [],
    roles: []
  },
  filters: {
    endDate: null
  },
  tiles: {
    appreciation_given_percentage: null,
    appreciation_received_count: { appreciation_count: 0 },
    appreciation_received_percentage: null,
    appreciation_receiver_info: { fetched_count: 0, result: [] },
    avg_strength_per_emp: { average: 0 },
    stregths_data: { fetched_count: 0, result: [] },
    loader: true,
    totalGiversCount: 0,
    totalReceiversCount: 0
  },
  appreciationExchange: {
    histogram: [],
    histogramLoader: true,
    companyHistogramLoader: true,
    departmentHistogramLoader: true,
    companyDrilldownLoader: true,
    departmentDrilldownLoader: true,
    companyHistogram: [],
    departmentHistogram: [],
    companyDrilldown: [],
    departmentDrilldown: []
  },
  centerOfInfluence: {
    wordcloud: {
      giver: [],
      receipents: [],
      giverMax: 0,
      receipentMax: 0
    },
    sunburst: {
      parentStrengths: {
        children: [],
        name: ""
      },
      childDepartments: []
    },
    employeeStrength: {
      employeesList: [],

      loader: false
    },
    treeMapData: {
      treeMap: [],
      strengths: {},
      maxStrengthCount: 1,
      loader: true
    }
  },
  appreciationFlow: {
    sankey: {
      loader: true,
      sankeyData: { }
    },
    heatmap: {
      loader: true,
      data: {
        company: [],
        department: [],
        departmentNames: [],
        companyNames: [],
        maxDeptAppreciationCount: 0,
        maxCompanyAppreciationCount: 0
      }
    },
    messageExchange: {
      loader: false,
      data: []
    }
  },
  individualAppreciation: {
    employeeName: "",
    strenghtsData: [],
    strengthDropDown: [],
    maxAppreciationCount: 0,
    loader: true,
    feeds: {
      given: [],
      received: []
    }
  }
};

const AppreciationDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case AppreciationActionTypes.GET_COMPANY_FILTER_DROPDOWN_SUCCESS:
     
      return {
        ...state,
        filterDropDown: {
          ...state.filterDropDown,
          company: action.payload 
        }
      };
    case AppreciationActionTypes.GET_COMPANY_FILTER_DROPDOWN_FAIL:
      
      return {
        ...state,
        filterDropDown: {
          ...state.filterDropDown,
          company: []
        }
      };
    case AppreciationActionTypes.GET_DEPARTMENT_FILTER_DROPDOWN_SUCCESS:
      return {
        ...state,
        filterDropDown: {
          ...state.filterDropDown,
          department: action.payload
        }
      };
    case AppreciationActionTypes.GET_DEPARTMENT_FILTER_DROPDOWN_FAIL:
      return {
        ...state,
        filterDropDown: {
          ...state.filterDropDown,
          department: []
        }
      };
    case AppreciationActionTypes.GET_EMPLOYEES_FILTER_DROPDOWN_SUCCESS:
      return {
        ...state,
        filterDropDown: {
          ...state.filterDropDown,
          employees: action.payload
        }
      };
    case AppreciationActionTypes.GET_EMPLOYEES_FILTER_DROPDOWN_FAIL:
      return {
        ...state,
        filterDropDown: {
          ...state.filterDropDown,
          employees: []
        }
      };
    case AppreciationActionTypes.GET_AGE_GROUP_FILTER_DROPDOWN_SUCCESS:
      return {
        ...state,
        filterDropDown: {
          ...state.filterDropDown,
          ageGroup: action.payload
        }
      };
    case AppreciationActionTypes.GET_AGE_GROUP_FILTER_DROPDOWN_FAIL:
      return {
        ...state,
        filterDropDown: {
          ...state.filterDropDown,
          ageGroup: []
        }
      };  
    case AppreciationActionTypes.GET_ROLE_FILTER_DROPDOWN_SUCCESS:
      return {
        ...state,
        filterDropDown: {
          ...state.filterDropDown,
          roles: action.payload
        }
      };
    case AppreciationActionTypes.GET_ROLE_FILTER_DROPDOWN_FAIL:
      return {
        ...state,
        filterDropDown: {
          ...state.filterDropDown,
          roles: []
        }
      };
    case AppreciationActionTypes.GET_TILES_DATA_SUCCESS:
      return {
        ...state,
        tiles: {
          ...action.payload,
          totalGiversCount: action.payload.appreciation_given_percentage.appreciation_count,
          totalReceiversCount: action.payload.appreciation_received_percentage.appreciation_count,
          appreciation_given_percentage: (action.payload.appreciation_given_percentage.appreciation_count / action.payload.appreciation_given_percentage.user_count) * 100,
          appreciation_received_percentage: (action.payload.appreciation_received_percentage.appreciation_count / action.payload.appreciation_received_percentage.user_count) * 100,
          loader: false
        }
      };
    case AppreciationActionTypes.GET_TILES_DATA_REQUEST:
      return {
        ...state,
        tiles: {
          ...state.tiles,
          loader: true
        }
      };
    case AppreciationActionTypes.GET_TILES_DATA_FAIL:
      return {
        ...state,
        tiles: {
          ...state.tiles,
          loader: false
        }
      };
    case AppreciationActionTypes.GET_HISTOGRAM_DATA_REQUEST:
      return {
        ...state,
        appreciationExchange: {
          ...state.appreciationExchange,
          histogramLoader: true
        }
      };
    case AppreciationActionTypes.GET_HISTOGRAM_DATA_SUCCESS:
      return {
        ...state,
        appreciationExchange: {
          ...state.appreciationExchange,
          histogram: action.payload,
          histogramLoader: false
        }
      };
    case AppreciationActionTypes.GET_HISTOGRAM_DATA_FAIL:
      return {
        ...state,
        appreciationExchange: {
          ...state.appreciationExchange,
          histogram: [],
          histogramLoader: false
        }
      };
    case AppreciationActionTypes.GET_COMPANY_HISTOGRAM_DATA_REQUEST:
      return {
        ...state,
        appreciationExchange: {
          ...state.appreciationExchange,
          companyHistogramLoader: true
        }
      };
    case AppreciationActionTypes.GET_COMPANY_HISTOGRAM_DATA_SUCCESS:
      return {
        ...state,
        appreciationExchange: {
          ...state.appreciationExchange,
          companyHistogram: action.payload,
          companyHistogramLoader: false
        }
      };
    case AppreciationActionTypes.GET_COMPANY_HISTOGRAM_DATA_FAIL:
      return {
        ...state,
        appreciationExchange: {
          ...state.appreciationExchange,
          companyHistogram: [],
          companyHistogramLoader: false
        }
      };
    case AppreciationActionTypes.GET_DEPARTMENT_HISTOGRAM_DATA_REQUEST:
      return {
        ...state,
        appreciationExchange: {
          ...state.appreciationExchange,
          departmentHistogramLoader: true
        }
      };
    case AppreciationActionTypes.GET_DEPARTMENT_HISTOGRAM_DATA_SUCCESS:
      return {
        ...state,
        appreciationExchange: {
          ...state.appreciationExchange,
          departmentHistogram: action.payload,
          departmentHistogramLoader: false
        }
      };
    case AppreciationActionTypes.GET_DEPARTMENT_HISTOGRAM_DATA_FAIL:
      return {
        ...state,
        appreciationExchange: {
          ...state.appreciationExchange,
          departmentHistogram: [],
          departmentHistogramLoader: false
        }
      };
    case AppreciationActionTypes.GET_COMPANY_DRILLDOWN_DATA_REQUEST:
      return {
        ...state,
        appreciationExchange: {
          ...state.appreciationExchange,
          companyDrilldownLoader: true
        }
      };
    case AppreciationActionTypes.GET_COMPANY_DRILLDOWN_DATA_SUCCESS:
      return {
        ...state,
        appreciationExchange: {
          ...state.appreciationExchange,
          companyDrilldown: action.payload,
          companyDrilldownLoader: false
        }
      };
    case AppreciationActionTypes.GET_COMPANY_DRILLDOWN_DATA_FAIL:
      return {
        ...state,
        appreciationExchange: {
          ...state.appreciationExchange,
          companyDrilldown: [],
          companyDrilldownLoader: false
        }
      };
    case AppreciationActionTypes.GET_DEPARTMENT_DRILLDOWN_DATA_REQUEST:
      return {
        ...state,
        appreciationExchange: {
          ...state.appreciationExchange,
          departmentDrilldownLoader: true
        }
      };
    case AppreciationActionTypes.GET_DEPARTMENT_DRILLDOWN_DATA_SUCCESS:
      return {
        ...state,
        appreciationExchange: {
          ...state.appreciationExchange,
          departmentDrilldown: action.payload,
          departmentDrilldownLoader: false
        }
      };
    case AppreciationActionTypes.GET_DEPARTMENT_DRILLDOWN_DATA_FAIL:
      return {
        ...state,
        appreciationExchange: {
          ...state.appreciationExchange,
          departmentDrilldown: [],
          departmentDrilldownLoader: false
        }
      };
    case AppreciationActionTypes.GET_WORD_CLOUD_DATA_REQUEST:
      return {
        ...state,
        centerOfInfluence: {
          ...state.centerOfInfluence,
          wordcloud: {
            ...state.centerOfInfluence.wordcloud,
            loader: true
          }
        }
      };
      
    case AppreciationActionTypes.GET_WORD_CLOUD_DATA_SUCCESS:
      return {
        ...state,
        centerOfInfluence: {
          ...state.centerOfInfluence,
          wordcloud: {
            ...state.centerOfInfluence.wordcloud,
            giver: action.payload.giver.result,
            receipents: action.payload.receipents.result,
            giverMax: action.payload.giver.max,
            receipentMax: action.payload.receipents.max,
            loader: false
          }
        }
      };
    case AppreciationActionTypes.GET_WORD_CLOUD_DATA_FAIL:
      return {
        ...state,
        centerOfInfluence: {
          ...state.centerOfInfluence,
          wordcloud: {
            ...state.centerOfInfluence.wordcloud,
            loader: false
          }
        }
      };

    case AppreciationActionTypes.GET_SPIDER_DATA_SUCCESS:
      return {
        ...state,
        individualAppreciation: {
          ...state.individualAppreciation,
          employeeName: "",
          maxAppreciationCount: action.payload.maxAppreciation,
          strenghtsData: action.payload.result
        }
      };
    case AppreciationActionTypes.GET_SPIDER_DATA_FAIL:
      return {
        ...state,
        individualAppreciation: {
          ...state.individualAppreciation,
          employeeName: "",
          maxAppreciationCount: 0,
          strenghtsData: []
        }
      };
    case AppreciationActionTypes.GET_APPRECIATION_FEEDS_REQUEST:
      return {
        ...state,
        individualAppreciation: {
          ...state.individualAppreciation,
          loader: true
        }
      };  
    case AppreciationActionTypes.GET_APPRECIATION_FEEDS_SUCCESS:
      return {
        ...state,
        individualAppreciation: {
          ...state.individualAppreciation,
          loader: false,
          strengthDropDown: action.payload.stregths,
          feeds: {
            given: action.payload.given,
            received: action.payload.received
          }
        }
      };
    case AppreciationActionTypes.GET_APPRECIATION_FEEDS_FAIL:
      return {
        ...state,
        individualAppreciation: {
          ...state.individualAppreciation,
          loader: false,
          feeds: {
            ...state.individualAppreciation.feeds
          }
        }
      };  

    case AppreciationActionTypes.GET_SUNBURST_DATA_REQUEST:
      return {
        ...state,
        centerOfInfluence: {
          ...state.centerOfInfluence,
          sunburst: {
            ...state.centerOfInfluence.sunburst,
            loader: true
          }
        }
      };

    case AppreciationActionTypes.GET_SUNBURST_DATA_SUCCESS:
      return {
        ...state,
        centerOfInfluence: {
          ...state.centerOfInfluence,
          sunburst: {
            ...state.centerOfInfluence.sunburst,
            parentStrengths: action.payload.parentStrengths,
            childDepartments: action.payload.childDepartments,
            loader: false
          }
        }
      };  
    case AppreciationActionTypes.GET_SUNBURST_DATA_FAIL:
      return {
        ...state,
        centerOfInfluence: {
          ...state.centerOfInfluence,
          sunburst: {
            ...state.centerOfInfluence.sunburst,
            loader: false         
          }
        }
      };
    case AppreciationActionTypes.GET_STRENGTH_EMPLOYEE_LIST_REQUEST:
      return {
        ...state,
        centerOfInfluence: {
          ...state.centerOfInfluence,
          employeeStrength: {
            ...state.centerOfInfluence.employeeStrength,
            loader: true
          }
        }
      };
    case AppreciationActionTypes.GET_TREE_MAP_DATA_REQUEST:
      return {
        ...state,
        ...state,
        centerOfInfluence: {
          ...state.centerOfInfluence,
          treeMapData: {
            ...state.centerOfInfluence.treeMapData,
            treeMap: [],
            strengths: [],
            loader: true
          }
        }
      };  
    case AppreciationActionTypes.GET_STRENGTH_EMPLOYEE_LIST_SUCCESS:
      return {
        ...state,
        centerOfInfluence: {
          ...state.centerOfInfluence,
          employeeStrength: {
            ...state.centerOfInfluence.employeeStrength,
            employeesList: action.payload,
            loader: false
          }
        }
      };
    case AppreciationActionTypes.GET_STRENGTH_EMPLOYEE_LIST_FAIL:
      return {
        ...state,
        centerOfInfluence: {
          ...state.centerOfInfluence,
          employeeStrength: {
            ...state.centerOfInfluence.employeeStrength,
            loader: false
          }
        }
      };

    case AppreciationActionTypes.GET_TREE_MAP_DATA_SUCCESS:
      return {
        ...state,
        centerOfInfluence: {
          ...state.centerOfInfluence,
          treeMapData: {
            treeMap: action.payload.treeMapData,
            strengths: action.payload.strengths,
            maxStrengthCount: action.payload.maxStrengthCount,
            loader: false
          }
        }
      };
    case AppreciationActionTypes.GET_TREE_MAP_DATA_FAIL:
      return {
        ...state,
        centerOfInfluence: {
          ...state.centerOfInfluence,
          treeMapData: {
            treeMap: [],
            strengths: [],
            maxStrengthCount: 1,
            loader: false
          }
        }
      };
    case AppreciationActionTypes.RESET_STRENGTH_EMPLOYEE_LIST:
      return {
        ...state,
        centerOfInfluence: {
          ...state.centerOfInfluence,
          employeeStrength: {
            loader: false,
            employeesList: []
          }
        }
      };  
    case AppreciationActionTypes.GET_APPRECIATION_FLOW_REQUEST:
      return {
        ...state,
        appreciationFlow: {
          ...state.appreciationFlow,
          sankey: {
            ...state.appreciationFlow.sankey,
            loader: true,
            sankeyData: { ...state.appreciationFlow.sankey.sankeyData }
          }
        }
      };
    case AppreciationActionTypes.GET_APPRECIATION_FLOW_SUCCESS:
      return {
        ...state,
        appreciationFlow: {
          ...state.appreciationFlow,
          sankey: {
            ...state.appreciationFlow.sankey,
            loader: false,
            sankeyData: { ...action.payload.sankeyData }
          }
        }
      };
    case AppreciationActionTypes.GET_APPRECIATION_FLOW_FAIL:
      return {
        ...state,
        appreciationFlow: {
          ...state.appreciationFlow,
          sankey: {
            ...state.appreciationFlow.sankey,
            loader: false,
            sankeyData: { ...state.appreciationFlow.sankey.sankeyData }
          }
        }
      };
    case AppreciationActionTypes.GET_APPRECIATION_FLOW_HEATMAP_REQUEST:
      return {
        ...state,
        appreciationFlow: {
          ...state.appreciationFlow,
          heatmap: {
            ...state.appreciationFlow.heatmap,
            loader: true
          }
        }
      };
    case AppreciationActionTypes.GET_APPRECIATION_FLOW_HEATMAP_SUCCESS:
      return {
        ...state,
        appreciationFlow: {
          ...state.appreciationFlow,
          heatmap: {
            ...state.appreciationFlow.heatmap,
            loader: false,
            data: {
              company: action.payload.organization,
              department: action.payload.department,
              departmentNames: action.payload.departmentName,
              companyNames: action.payload.companiesName,
              maxDeptAppreciationCount: action.payload.maxDeptAppreciationCount,
              maxCompanyAppreciationCount: action.payload.maxCompanyAppreciationCount
            }
          }
        }
      };
    case AppreciationActionTypes.GET_APPRECIATION_FLOW_HEATMAP_FAIL:
      return {
        ...state,
        appreciationFlow: {
          ...state.appreciationFlow,
          heatmap: {
            ...state.appreciationFlow.heatmap,
            loader: false
          }
        }
      };
    case AppreciationActionTypes.GET_APPRECIATION_FLOW_HEATMAP_MESSAGE_EXCHANGE_REQUEST:
      return {
        ...state,
        appreciationFlow: {
          ...state.appreciationFlow,
          messageExchange: {
            ...state.appreciationFlow.messageExchange,
            loader: true
          }
        }
      };
    case AppreciationActionTypes.GET_APPRECIATION_FLOW_HEATMAP_MESSAGE_EXCHANGE_SUCCESS:
      return {
        ...state,
        appreciationFlow: {
          ...state.appreciationFlow,
          messageExchange: {
            ...state.appreciationFlow.messageExchange,
            loader: false,
            data: action.payload
          }
        }
      };
    case AppreciationActionTypes.GET_APPRECIATION_FLOW_HEATMAP_MESSAGE_EXCHANGE_FAIL:
      return {
        ...state,
        appreciationFlow: {
          ...state.appreciationFlow,
          messageExchange: {
            ...state.appreciationFlow.messageExchange,
            loader: false
          }
        }
      };  
    case AppreciationActionTypes.GET_APPRECIATION_FLOW_HEATMAP_MESSAGE_EXCHANGE_RESET:
      return {
        ...state,
        appreciationFlow: {
          ...state.appreciationFlow,
          messageExchange: {
            ...state.appreciationFlow.messageExchange,
            loader: false,
            data: []
          }
        }
      };  
    case AppreciationActionTypes.SET_FILTER_END_DATE: 
      return {
        ...state,
        filters: {
          endDate: action.payload
        }
      }; 
    case AppreciationActionTypes.RESET_APPRECIATION_DASHBOARD_REDUCER:
      return {
        ...initialState
      }      
    default: 
      return {
        ...state
      };
  }   
};

export default AppreciationDashboardReducer;