import React, { Fragment } from "react";
import { GraphActions } from "../../../../../UI";
// import RedemptionPieChart from "../../../../../Widgets/PieChart/RedemptionPieChart";
import PieChartRecharts from "../../../../../Widgets/PieChart/PieChartRecharts";
import { connect } from "react-redux";
import RedemptionPiechartLoader from "./../../../../../Widgets/PieChart/RedemptionPieChartLoader";
import "rc-slider/assets/index.css";
import { GRAPH_IDS, REDEMPTION_GRAPH_NAMES, THEME_COLORS } from "../../../../../constants/constants";
import NegativeBarChartD3 from "../../../../../Widgets/BarChart/NegativeBarChart";
import RedemptionStackedBarChart from "../../../../../Widgets/StackedBarChart/RedemptionStackedBarChart";
import RedemptionStackedBarChartLoader from "../../../../../Widgets/StackedBarChart/StackedBarChartLoader";
import Switch from "react-switch";
import RedemptionGroupedBarChart from "../../../../../Widgets/BarChart/GroupedBarChart";
import { StackedBarChart, Histogram } from "../../../../../Widgets";

const handleExpandChart = (props, graphName, redemptionFrequency = null ) => {
  let graph = "";
  if( props.subGraphTitle === REDEMPTION_GRAPH_NAMES.SNAPSHOT_PIE_CHART ) {
    graph = <div className="scroll-auto text-center" style={{ height: window.screen.height * 0.55 }} >
              {/* <RedemptionPieChart
                height={window.screen.height * 0.5}
                width={window.screen.width * 0.6}
                data={props.snapshotData.budgetSnapshotData}
                graphName={"redemption-pie-chart-expand"}
              /> */}
              <PieChartRecharts 
                  height={window.screen.height * 0.45}
                  width={window.screen.width * 0.45}
                  data={props.snapshotData.budgetSnapshotData}
                  legendAlign={'center'}
                />
            </div>;
  } else if( props.subGraphTitle === REDEMPTION_GRAPH_NAMES.SNAPSHOT_BAR_CHART ) {
    graph = <div className="scroll-auto text-center" style={{ height: window.screen.height * 0.55 }} >
              <NegativeBarChartD3
                height={window.screen.height * 0.4}
                width={window.screen.width * 0.6}
                data={props.snapshotData.budgetSnapshotData}
                expandScreen={true}
                graphName={"snapshot-negative"}
              />
            </div>;
  } else if( props.graphTitle === `Scenario Planning - ${props.switch ? "Based on Redemption" : "Based on Awarding" }: ` ) {
    graph = <div className="scroll-auto text-center" style={{ height: window.screen.height * 0.55 }} >
              <div className="graphsActionContainer" style={{display: "inline-flex"}}>
                <div className="UDRFilters"> <span><strong> Uniform Utilisation Rate (UUR) based on: </strong></span>
                  <div className="custom-control custom-radio custom-control-inline">
                  
                    <input type="radio" /*id="3month"*/ name="udrwisefilterexpand" readOnly editable={false} className="custom-control-input" checked={props.redemptionUDRFrequency === 3} disabled />
                    <label className="custom-control-label" htmlFor="3months">3 Months</label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" /*id="6month"*/ name="udrwisefilterexpand" readOnly editable={false} className="custom-control-input" checked={props.redemptionUDRFrequency === 6} disabled />
                    <label className="custom-control-label" htmlFor="6months">6 Months</label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" /*id="12month"*/ name="udrwisefilterexpand" readOnly editable={false} className="custom-control-input" checked={props.redemptionUDRFrequency === 12} disabled />
                    <label className="custom-control-label" htmlFor="12months">12 Months</label>
                  </div>
                </div>
              </div>
              <RedemptionStackedBarChart
                height={window.screen.height * 0.45}
                width={window.screen.width * 0.6}
                data={props.trendData.budgetTrendData}
                lastmonthdata={props.trendData.lastTransaction}
                expandscreen={true}
                UDRData={props.redemptionUDRFrequency}
                maxPercentValue={props.trendData.maxPercentValue}
                switch = {props.switch}
                graphname={"trend-chart_expand"}
                legendsname={['Redeemed','Unredeemed',`Organisation Balance ${props.switch ? "(based on redemption)" : "(based on awarding)" }`, 'Top Up Month', 'Uniform Utilisation Rate (UUR)', props.trendData.maxPercentValue > 100 ? 'Threshold Limit' : ''].filter(String)}
                colorcodes={[THEME_COLORS[1], THEME_COLORS[2] ,THEME_COLORS[4]]}
              />
            </div>
  } else if( props.graphTitle === REDEMPTION_GRAPH_NAMES.POINTS_ER_GROUPED_BAR_CHART ) {
    graph = <div className="scroll-auto text-center" style={{ maxWidth: window.screen.width * 0.75, height: window.screen.height * 0.55 }} >
              <RedemptionGroupedBarChart
                  height={window.screen.height * 0.5}
                  width={props.pointsEarnedRedeemedData.pointsEarnedRedeemed && props.pointsEarnedRedeemedData.pointsEarnedRedeemed.length > 12 ?  window.innerWidth * 0.45 * ( props.pointsEarnedRedeemedData.pointsEarnedRedeemed.length * 0.08) : window.screen.width * 0.75 }
                  data={props.pointsEarnedRedeemedData.pointsEarnedRedeemed}
                  YaxisName={`Number of Points`}
                  expandScreen={true}
                  graphname={"points-awarded-redeemed"}
              />
            </div>
  } else if( props.graphTitle === REDEMPTION_GRAPH_NAMES.POINTS_ER_HISTOGRAM_CHART ) {
    graph = <div className="scroll-auto text-center" style={{ maxWidth: window.screen.width * 0.75, height: window.screen.height * 0.55 }} >
      <Histogram
            width={props.pointsEarnedRedeemedByDriversData.pointsEarnedRedeemed && props.pointsEarnedRedeemedByDriversData.pointsEarnedRedeemed.length > 12 ?  window.innerWidth * 0.45 * ( props.pointsEarnedRedeemedByDriversData.pointsEarnedRedeemed.length * 0.08) : window.screen.width * 0.75 }
            height={window.screen.height * 0.5}
            xAxisNames= {["Total # of Redemptions", "# of Unique Users Redeeming"]} 
            data={props.pointsEarnedRedeemedByDriversData.pointsEarnedRedeemed}
            maxRightAxisValue={props.maxRightAxisValue}
            margin={{ top: 40, right: 50, left: 20, bottom: 30 }}
            colors={["#0d2d46", "#6fd8e5"]}
            showRightYAxis = { true }
            isOnClickEvent = { false }
            xAxisNameId={"xAxis"}
            xAxisAngle={-45}
            textAnchor="end"
            showRightYAxis={true}
            showBrush={false}
            brushWidth={window.screen.width}
            brushHeight={20}
            barSize={50}
            rightAxisKey="Avg Redemption Value"
            yAxisLabel = "# of Redemptions/# of Unique Users Redeeming"
            xAxisLabel={"Period"}
            yAxisRightLabel={"Avg Redemption Value (points)"}
            legendAlign={"center"}
            fromdrivergraph={true}
          /></div>
  }

  props.showEventModal({
    showEventModal: true,
    eventModalTitle: "",
    eventModalBody: "",
    showEventModalButtonOne: false,
    showEventModalButtonTwo: false,
    buttonOneEventModalHandler: () => props.hideEventModal(),
    buttonTwoEventModalHandler: null,
    buttonOneEventModalName: "",
    buttonTwoEventModalName: "",
    graphContent: graph,
    graphTitle: props.graphTitle,
    graphSubTitle: props.graphSubTitle,
    showGraph: true,
    graphName: graphName,
    graphId: ""
  });
};

class BudgetUtilization extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      value: 1,
      width: window.innerWidth,
      height: window.innerHeight
    };
  }

  updateDimensions() {
    if(window.innerWidth < 500) {
      this.setState({ width: 450, height: 102 });
    } else {
      let update_width  = window.innerWidth-100;
      let update_height = Math.round(update_width/4.4);
      this.setState({ width: update_width, height: update_height });
    }
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  render() {
    let screenWidth = window.screen.width;
    if(document.getElementById("tilesSection")) {
      screenWidth = document.getElementById("tilesSection").offsetWidth;
    }

    const onSwitchColorCode = "#888888";
    return (
      <div className="budgetUtilization">
        <div className="row" >
          <div className="col-sm-12">
            <div className="titleOuter">
              <h2>Credit Utilisation</h2>
            </div>
          </div>
        </div>

        {/* <div className="col-sm-5 budget-utilization-snapshot-div" > */}
        <div className="budgetUtilizationSec1 row mb-150">
          <div className="col-lg-12 col-xl-5 left-panel" >
            <div className="graphsActionContainer">
              <h3> Snapshot </h3>
              <div className="exportContainer">
                <GraphActions
                  showExpansion={!this.props.snapshotLoader && this.props.snapshotData && this.props.snapshotData.budgetSnapshotData && this.props.snapshotData.budgetSnapshotData.length > 0}
                  showCSVExport={!this.props.snapshotLoader && this.props.snapshotData && this.props.snapshotData.budgetSnapshotData && this.props.snapshotData.budgetSnapshotData.length > 0}
                  showImageExport={!this.props.snapshotData.loader && this.props.snapshotData.budgetSnapshotData && this.props.snapshotData.budgetSnapshotData.length > 0}
                  showInfo={true}
                  from={'Credit Utilisation Snapshot'}
                  apiUrl={"auth/redemption/snapshot"}
                  filterObjectForCSV={this.props.getFilterObject}
                  graphId="budgetUtilizationContainer"
                  imageName={`Budget Utilization Snapshot`}
                  infoMessage="All values are expressed as a percentage of Organisation Credit."
                  handleExpand={() => handleExpandChart({ ...this.props, graphTitle: REDEMPTION_GRAPH_NAMES.SNAPSHOT_CHART, subGraphTitle: this.props.snapshotData && this.props.snapshotData.budgetSnapshotData && this.props.snapshotData.budgetSnapshotData[2].value < 0 || this.props.snapshotData.budgetSnapshotData[1].value < 0 ? REDEMPTION_GRAPH_NAMES.SNAPSHOT_BAR_CHART : REDEMPTION_GRAPH_NAMES.SNAPSHOT_PIE_CHART })}
                />
              </div>
            </div>
            <div className="graphInfoNote" style={{height:"73px"}}><span>Note: </span> Please hover for more details.</div>
            <div className="budget-utilization-snapshot-container col-sm-12 text-center" >
              <div style={{height: window.innerHeight < 600 ? "450px" : "400px", float: "left"}} className="cloud-container col-sm-12 mb-5">
                {!this.props.snapshotLoader && this.props.snapshotData && this.props.snapshotData.budgetSnapshotData && this.props.snapshotData.budgetSnapshotData.length > 0
                ? 
                  this.props.snapshotData.budgetSnapshotData[2].value < 0 || this.props.snapshotData.budgetSnapshotData[1].value < 0
                  ?
                  <Fragment>
                    <NegativeBarChartD3
                      height={308}
                      width={380}
                      data={this.props.snapshotData.budgetSnapshotData}
                      expandScreen={false}
                      graphName={"snapshot-negative"}
                      // hideMargin={window.innerHeight < 600}
                    />
                    <div id="budgetUtilizationContainer" className="d-none">
                      <NegativeBarChartD3
                        height={308}
                        width={380}
                        data={this.props.snapshotData.budgetSnapshotData}
                        expandScreen={true}
                        graphName={"snapshot-negative"}
                      /> 
                    </div>
                  </Fragment>
                  :
                  <Fragment>
                    <PieChartRecharts 
                      data={this.props.snapshotData.budgetSnapshotData}
                      height={window.screen.height * 0.4}
                      width={screenWidth * 0.4}
                      marginTop={window.innerHeight < 600 ? 0 : 50}
                      legendAlign={'center'}
                      // hideMargin={window.innerHeight < 600}
                    />
                    <div id="budgetUtilizationContainer" className="d-none">
                      <PieChartRecharts 
                        data={this.props.snapshotData.budgetSnapshotData}
                        height={window.screen.height * 0.4}
                        width={screenWidth * 0.4}
                        marginTop={0}
                        legendAlign={'center'}
                      />
                    </div>
                  </Fragment>
                :
                  <RedemptionPiechartLoader/>
                }
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-xl-7 right-panel" >
            <div className="graphsActionContainer" style={{width: "110%"}}>
              <div className="titlewithSlider d-flex align-items-center">
              <h3 className="mb-1 mr-2"><span className="d-inline-block"><strong> Scenario Planning - {this.props.switch ? "Based on Redemption" : "Based on Awarding" }: </strong></span></h3>
                <div className="switchBtnouter">
                  <label className="m-0">
                    <Switch onChange={this.props.switchHandler}
                      checked={this.props.switch}
                      uncheckedIcon={null}
                      checkedIcon={null}
                      onColor={onSwitchColorCode}
                    />
                  </label>
                </div>
              </div>
              <div className="exportContainer">
                <GraphActions
                  showExpansion={!this.props.trendLoader && this.props.trendData && this.props.trendData.budgetTrendData && this.props.trendData.budgetTrendData.length > 0 && !this.props.trendData.isBalanceNegative}
                  showCSVExport={!this.props.trendLoader && this.props.trendData && this.props.trendData.budgetTrendData && this.props.trendData.budgetTrendData.length > 0 && !this.props.trendData.isBalanceNegative}
                  showImageExport={!this.props.trendLoader && this.props.trendData && this.props.trendData.budgetTrendData && this.props.trendData.budgetTrendData.length > 0 && !this.props.trendData.isBalanceNegative}
                  showInfo={true}
                  from={'Credit Utilisation Scenario Planning'}
                  apiUrl={"auth/redemption/trend"}
                  filterObjectForCSV={this.props.getFilterObject}
                  graphId="budgetUtilizationTrendContainer"
                  imageName={`Scenario Planning - ${this.props.switch ? "Based on Redemption" : "Based on Awarding" }: `}
                  infoMessage={`This chart compares your credit utilisation rate with the uniform utilisation rate for 3/6/12 months. Credit utilisation is expressed as a % of starting credit based on the "From" date selected.`}
                  ExternalStyleSheet=""
                  handleExpand={() => handleExpandChart({ ...this.props,
                     graphTitle: `Scenario Planning - ${this.props.switch ? "Based on Redemption" : "Based on Awarding" }: `,
                    redemptionFrequency: this.props.redemptionUDRFrequency })}
                />
              </div>
            </div>
            <div className="graphInfoNote" style={{width: "110%", height:"73px"}}><span>Note: </span> Please toggle to see your credit balance based on awarding or redemption. Use the UUR line to identify when to step up your points awarding, push for redemption or top up your organisation credit.</div>
            <div id="budgetUtilizationTrendContainer" /*className="d-none"*/>
              <div className="graphsActionContainer" style={{width:'120%'}}>
                <div className="UDRFilter"> <span><strong> Uniform Utilisation Rate (UUR) based on: </strong></span>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="3month" name="udrwisefilter" className="custom-control-input" checked = { this.props.redemptionUDRFrequency === 3 } onChange={ () => this.props.handleRedemptionBudgetTrendFrequency(3) } />
                    <label className="custom-control-label" htmlFor="3month">3 Months</label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="6month" name="udrwisefilter" className="custom-control-input" checked = { this.props.redemptionUDRFrequency === 6 } onChange={ () => this.props.handleRedemptionBudgetTrendFrequency(6) } />
                    <label className="custom-control-label" htmlFor="6month">6 Months</label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="12month" name="udrwisefilter" className="custom-control-input" checked = { this.props.redemptionUDRFrequency === 12 } onChange={ () => this.props.handleRedemptionBudgetTrendFrequency(12) } />
                    <label className="custom-control-label" htmlFor="12month">12 Months</label>
                  </div>
                </div>
              </div>
              <div className="budget-utilization-trend-container col-sm-12 text-center" style={{width: "110%"}} >
                <div style={{height: window.screen.height * 0.5, float: "left"}} className="cloud-container col-sm-12 mb-5">
                  { 
                    this.props.trendData.isBalanceNegative ? 
                      <div className="dataNotFound" style={{width: "110%"}}>
                        No/negative budget balance. Please top up your budget to view this chart.
                      </div>
                    :
                    !this.props.trendLoader && this.props.trendData && this.props.trendData.budgetTrendData ?
                    this.props.trendData.budgetTrendData.length > 0 ? 
                    <RedemptionStackedBarChart
                      height={window.screen.height * 0.4}
                      // width={screenWidth * 0.55}
                      width={600}
                      data={this.props.trendData.budgetTrendData}
                      lastmonthdata={this.props.trendData.lastTransaction}
                      UDRData={this.props.redemptionUDRFrequency}
                      maxPercentValue={this.props.trendData.maxPercentValue}
                      switch = {this.props.switch}
                      expandscreen={false}
                      graphname={"trend-chart"}
                      legendsname={['Redeemed','Unredeemed',`Organisation Balance ${this.props.switch ? "(based on redemption)" : "(based on awarding)" }`, 'Top Up Month', 'Uniform Utilisation Rate (UUR)', this.props.trendData.maxPercentValue > 100 ? 'Threshold Limit' : ''].filter(String)}
                      colorcodes={[THEME_COLORS[1], THEME_COLORS[2] ,THEME_COLORS[4]]}
                    />
                    :  <div className="dataNotFound">
                    No Data Found
                    </div>
                  : <RedemptionStackedBarChartLoader
                    height={window.screen.height * 0.4}
                    width={screenWidth * 0.52}
                  />
                  }
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="col-lg-12 col-xl-6 left-panel" >
          <div className="graphsActionContainer" style={{height: "60px"}}>
              <h3> {REDEMPTION_GRAPH_NAMES.POINTS_ER_GROUPED_BAR_CHART} </h3>
              <div className="exportContainer">
                <GraphActions
                  showExpansion={!this.props.pointsEarnedRedeemedDataLoader && this.props.pointsEarnedRedeemedData && this.props.pointsEarnedRedeemedData.pointsEarnedRedeemed && this.props.pointsEarnedRedeemedData.pointsEarnedRedeemed.length > 0}
                  showCSVExport={this.props.pointsEarnedRedeemedData && this.props.pointsEarnedRedeemedData.pointsEarnedRedeemed && this.props.pointsEarnedRedeemedData.pointsEarnedRedeemed.length > 0}
                  showImageExport={!this.props.pointsEarnedRedeemedData.loader && this.props.pointsEarnedRedeemedData.pointsEarnedRedeemed && this.props.pointsEarnedRedeemedData.pointsEarnedRedeemed.length > 0}
                  showInfo={true}
                  from={REDEMPTION_GRAPH_NAMES.POINTS_ER_GROUPED_BAR_CHART}
                  apiUrl={"auth/redemption/rate"}
                  filterObjectForCSV={this.props.getFilterObject}
                  graphId="pointsAwardedAndRedeemed"
                  imageName={`Points Earned and Redeemed`}
                  infoMessage="Compare the trends of these two variables to spot any slowdown in points redemption or awarding."
                  ExternalStyleSheet=""
                  handleExpand={() => handleExpandChart({ ...this.props, graphTitle: REDEMPTION_GRAPH_NAMES.POINTS_ER_GROUPED_BAR_CHART })}
                />
              </div>
          </div>
          <div className="graphInfoNote" style={{height: "80px"}}><span>Note: </span> Please hover for more details.</div>
          <div className="budget-utilization-snapshot-container col-sm-12 text-center" style={{minWidth: 490, overflowX: "auto",overflowY: "hidden"}} >
          {!this.props.pointsEarnedRedeemedDataLoader && this.props.pointsEarnedRedeemedData && this.props.pointsEarnedRedeemedData.pointsEarnedRedeemed ?
            this.props.pointsEarnedRedeemedData.pointsEarnedRedeemed.length > 0 ?
              <Fragment>
                <RedemptionGroupedBarChart
                  height={350}
                  width={this.props.pointsEarnedRedeemedData.pointsEarnedRedeemed && this.props.pointsEarnedRedeemedData.pointsEarnedRedeemed.length > 11 ?  window.innerWidth * 0.45 * ( this.props.pointsEarnedRedeemedData.pointsEarnedRedeemed.length * 0.08) : 470 }
                  data={this.props.pointsEarnedRedeemedData.pointsEarnedRedeemed}
                  YaxisName={`Number of Points`}
                  expandscreen={false}
                  graphname={"points-awarded-redeemed"}
                /> 
                <div id="pointsAwardedAndRedeemed" className="d-none" >
                  <RedemptionGroupedBarChart
                      height={window.innerHeight * 0.61}
                      // width={window.innerWidth * 0.37}
                      isFromImage={true}
                      width={this.props.pointsEarnedRedeemedData.pointsEarnedRedeemed && this.props.pointsEarnedRedeemedData.pointsEarnedRedeemed.length > 11 ?  window.innerWidth * 0.45 * ( this.props.pointsEarnedRedeemedData.pointsEarnedRedeemed.length * 0.08) : window.innerWidth * 0.37 }
                      data={this.props.pointsEarnedRedeemedData.pointsEarnedRedeemed}
                      YaxisName={`Number of Points`}
                      expandscreen={false}
                      graphname={"points-awarded-redeemed"}
                  />
                </div>
              </Fragment>: <div className="dataNotFound">
                No Data Found
                </div>
              : <RedemptionStackedBarChartLoader
                height={350}
                width={470}
              /> }
          </div>
        </div>

        <div className="col-lg-12 col-xl-6 right-panel" >
          <div className="graphsActionContainer" style={{height: "60px"}}>
                <h3> {REDEMPTION_GRAPH_NAMES.POINTS_ER_HISTOGRAM_CHART} </h3>
                <div className="exportContainer">
                  <GraphActions
                    showExpansion={!this.props.pointsEarnedRedeemedByDriversDataLoader && this.props.pointsEarnedRedeemedByDriversData && this.props.pointsEarnedRedeemedByDriversData.pointsEarnedRedeemed && this.props.pointsEarnedRedeemedByDriversData.pointsEarnedRedeemed.length > 0}
                    showCSVExport={this.props.pointsEarnedRedeemedByDriversData && this.props.pointsEarnedRedeemedByDriversData.pointsEarnedRedeemed && this.props.pointsEarnedRedeemedByDriversData.pointsEarnedRedeemed.length > 0}
                    showImageExport={!this.props.pointsEarnedRedeemedByDriversData.loader && this.props.pointsEarnedRedeemedByDriversData.pointsEarnedRedeemed && this.props.pointsEarnedRedeemedByDriversData.pointsEarnedRedeemed.length > 0}
                    showInfo={true}
                    from={REDEMPTION_GRAPH_NAMES.POINTS_ER_HISTOGRAM_CHART}
                    apiUrl={"auth/redemption/driver"}
                    filterObjectForCSV={this.props.getFilterObject}
                    graphId="pointsAwardedAndRedeemedByDriver"
                    imageName={`Points Awarded and Redeemed By Driver`}
                    infoMessage="Compare the number of unique users who are redeeming (light blue bar) to the total number of redemption transactions (dark blue bar) to determine whether a few users with several redemptions or a lot of users with fewer redemptions contribute to overall redemptions. Next, compare the trends of these bars with that of the average redemption value (line) to see whether redemptions in the given period are driven by volume or value."
                    ExternalStyleSheet=""
                    handleExpand={() => handleExpandChart({ ...this.props, graphTitle: REDEMPTION_GRAPH_NAMES.POINTS_ER_HISTOGRAM_CHART, maxRightAxisValue : this.props.pointsEarnedRedeemedByDriversData.maxRightAxisValue }, REDEMPTION_GRAPH_NAMES.POINTS_ER_HISTOGRAM_CHART)}
                  />
                </div>
          </div>

          <div className="graphInfoNote" style={{height: "80px"}}><span>Note: </span> Compare volume versus value drivers of redemption. Monitor how many unique users redeem points each month.</div>
          <div className="budget-utilization-snapshot-container col-sm-12 text-center" style={{/*width:"100%" ,*/ height: "388px", minWidth: '505px', overflowX: "auto", overflowY: "hidden"}} >
          {!this.props.pointsEarnedRedeemedByDriversDataLoader && this.props.pointsEarnedRedeemedByDriversData && this.props.pointsEarnedRedeemedByDriversData.pointsEarnedRedeemed ?
            this.props.pointsEarnedRedeemedByDriversData.pointsEarnedRedeemed.length > 0 ?
              <Fragment>
                <Histogram
                  width={this.props.pointsEarnedRedeemedByDriversData.pointsEarnedRedeemed && this.props.pointsEarnedRedeemedByDriversData.pointsEarnedRedeemed.length > 11 ?  window.innerWidth * 0.45 * ( this.props.pointsEarnedRedeemedByDriversData.pointsEarnedRedeemed.length * 0.08) : 485 }
                  height={355}
                  xAxisNames= {["Total # of Redemptions", "# of Unique Users Redeeming"]}
                  data={this.props.pointsEarnedRedeemedByDriversData.pointsEarnedRedeemed}
                  maxRightAxisValue={parseFloat(this.props.pointsEarnedRedeemedByDriversData.maxRightAxisValue)}
                  margin={{ top: 40, right: 20, left: 20, bottom: 30 }}
                  colors={["#0d2d46", "#6fd8e5"]}
                  showRightYAxis = { true }
                  isOnClickEvent = { false }
                  xAxisNameId={"xAxis"}
                  xAxisAngle={-45}
                  textAnchor="end"
                  showRightYAxis={true}
                  showBrush={false}
                  brushWidth={screenWidth}
                  brushHeight={20}
                  barSize={50}
                  rightAxisKey="Avg Redemption Value"
                  yAxisLabel = "# of Redemptions/# of Unique Users Redeeming"
                  // yAxisLabel2 = "# of Unique Users Redeeming"
                  xAxisLabel={"Period"}
                  yAxisRightLabel={"Avg Redemption Value (points)"}
                  legendAlign={"center"}
                  isFromImage={true}
                />
                <div id="pointsAwardedAndRedeemedByDriver" className="d-none" style={{height: "550px"}} >
                  <Histogram
                    height={453}
                    width={this.props.pointsEarnedRedeemedByDriversData.pointsEarnedRedeemed && this.props.pointsEarnedRedeemedByDriversData.pointsEarnedRedeemed.length > 11 ?  window.innerWidth * 0.45 * ( this.props.pointsEarnedRedeemedByDriversData.pointsEarnedRedeemed.length * 0.08) : 800 }
                    xAxisNames= {["Total # of Redemptions", "# of Unique Users Redeeming"]}
                    data={this.props.pointsEarnedRedeemedByDriversData.pointsEarnedRedeemed}
                    maxRightAxisValue={parseFloat(this.props.pointsEarnedRedeemedByDriversData.maxRightAxisValue)}
                    margin={{ top: 10, right: 50, left: 20, bottom: 30 }}
                    colors={["#0d2d46", "#6fd8e5"]}
                    showRightYAxis = { true }
                    isOnClickEvent = { false }
                    xAxisNameId={"xAxis"}
                    xAxisAngle={-45}
                    textAnchor="end"
                    showRightYAxis={true}
                    showBrush={false}
                    brushWidth={screenWidth}
                    brushHeight={20}
                    barSize={50}
                    rightAxisKey="Avg Redemption Value"
                    yAxisLabel = "# of Redemptions/# of Unique Users Redeeming"
                    // yAxisLabel2 = "# of Unique Users Redeeming"
                    xAxisLabel={"Period"}
                    yAxisRightLabel={"Avg Redemption Value (points)"}
                    legendAlign={"center"}
                    isFromImage={true}
                  />
                </div>
              </Fragment>
              : <div className="dataNotFound">
                No Data Found
                </div>
              : <RedemptionStackedBarChartLoader
              height={window.innerHeight > 680 ? window.innerHeight * 0.55 : window.innerHeight * 0.53 }
              width={485}
            /> }
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = state => {
  return {
    snapshotData: state.RedemptionDashboard.snapshotData,
    snapshotLoader: state.RedemptionDashboard.snapshotData.loader,
    trendData: state.RedemptionDashboard.trendData,
    trendLoader: state.RedemptionDashboard.trendData.loader,
    pointsEarnedRedeemedData: state.RedemptionDashboard.pointsEarnedRedeemedData,
    pointsEarnedRedeemedDataLoader: state.RedemptionDashboard.pointsEarnedRedeemedData.loader,
    pointsEarnedRedeemedByDriversData: state.RedemptionDashboard.pointsEarnedRedeemedByDriversData,
    pointsEarnedRedeemedByDriversDataLoader: state.RedemptionDashboard.pointsEarnedRedeemedByDriversData.loader

  };
};

export default connect(mapStateToProps)(BudgetUtilization);