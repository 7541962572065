import React from "react";
import { GraphActions } from "../../../../../UI";
import Heatmap from "../../../../../Widgets/HeatMap/Heatmap";
import HeatmapLoader from "../../../../../Widgets/HeatMap/HeatmapLoader";
import { connect } from "react-redux";
import "rc-slider/assets/index.css";
import { GRAPH_IDS, REDEMPTION_GRAPH_NAMES, THEME_COLORS } from "../../../../../constants/constants";
import RedemptionStackedBarChartLoader from "../../../../../Widgets/StackedBarChart/StackedBarChartLoader";
import RedemptionGroupedBarChart from "../../../../../Widgets/BarChart/timeofRedemptionGroupBarchart";
import WeeklyBarChart from "../../../../../Widgets/BarChart/weeklyBarChart";

const heatmapXAxis = [ '12AM-3AM','3AM-6AM', '6AM-9AM', '9AM-12PM', '12PM-3PM', '3PM-6PM','6PM-9PM', '9PM-12AM'];
const heatmapYAxis = ["Sunday", "Saturday", "Friday", "Thursday", "Wednesday", "Tuesday", "Monday"];
const handleExpandChart = (props, graphName ) => {
  let graph = "";
  if( props.graphTitle === REDEMPTION_GRAPH_NAMES.TIME_OF_REDEEMPTION_HEATMAP_CHART ) {
    graph = <div className="scroll-auto text-center" style={{ height: window.screen.height * 0.5 }} >
              <Heatmap 
                height = {window.screen.height * 0.50}
                width = {window.screen.width * 0.60}
                data = { props.timeOfRedemptionByHourData.heatmapData }
                dataNames = { heatmapYAxis }
                dataXAxisNames= { heatmapXAxis }
                maxCount = { props.timeOfRedemptionByHourData.heatmapMaxValue }
                lightColor={"#ffe6e8"}
                darkColor = {"#ff3344"}
                margin={{top: 20, right: 20, bottom: 85, left: 120}}
                xAxisName={"Time"}
                yAxisName={"Day"}
                labelName={"# of Transactions"}
                ignoreLeftTextLength={true}
              />
            </div>;
  } else if (props.graphTitle === REDEMPTION_GRAPH_NAMES.TIME_OF_REDEMPTION) {
      graph = (
          <WeeklyBarChart
              height={window.screen.height * 0.5}
              width={window.screen.width * 0.5}
              xAxisLabel={"Day"}
              yAxisLabel={"Average # of Redemptions"}
              data={props.timeOfRedemptionData}
              xMaxValue={parseFloat( props.timeOfRedemptionXMaxValue)}
              //              legendNames={["Avg # of Weekday Redemptions per Week","Avg # of Weekend Redemptions per Week"]}
              // colorCodes={[THEME_COLORS[3], THEME_COLORS[23]]}
              colorCodes={[THEME_COLORS[3], "#cca9dd"]}
              graphName={`time_of_redemption`}
              expandScreen={true}
          />
      );
  }

  props.showEventModal({
    showEventModal: true,
    eventModalTitle: "",
    eventModalBody: "",
    showEventModalButtonOne: false,
    showEventModalButtonTwo: false,
    buttonOneEventModalHandler: () => props.hideEventModal(),
    buttonTwoEventModalHandler: null,
    buttonOneEventModalName: "",
    buttonTwoEventModalName: "",
    graphContent: graph,
    graphTitle: props.graphTitle,
    graphSubTitle: props.graphSubTitle,
    showGraph: true,
    graphName: graphName,
    graphId: ""
  });
};

class TimeOfRedemption extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      value: 1,
    };
  }

  render() {
    let screenWidth = window.screen.width / 2;
    return (
      <div className="redemption-pref-beh">
        {/* <div className="row" >
          <div className="col-sm-12">
            <div className="titleOuter">
              <h2>Time of Redemption</h2>
            </div>
          </div>
        </div> */}
        <div className="col-lg-12 col-xl-6 left-panel" >
          <div className="graphsActionContainer" style={{height: "60px"}}>
              <h3> Average Redemptions by Day </h3>
              <div className="exportContainer">
                  <GraphActions
                      showExpansion={this.props.timeOfRedemptionData && this.props.timeOfRedemptionData.length > 0}
                      showCSVExport={this.props.timeOfRedemptionData && this.props.timeOfRedemptionData.length > 0}
                      showImageExport={this.props.timeOfRedemptionData && this.props.timeOfRedemptionData.length > 0}
                      showInfo={true}
                      from={"Weekday vs Weekend Redemptions"}
                      apiUrl={"auth//redemption/graph/getTimeOfRedemtion"}
                      filterObjectForCSV={this.props.getFilterObject}
                      graphId={"timeOfRedemptionsContainer"}
                      imageName={`Average Redemptions by Day`}
                      infoMessage={"This chart enables you to identify the day(s) on which you should send push notifications to remind your users to redeem their points. For more details on specific time slots, please refer to the next chart."}
                      // ExternalStyleSheet=""
                      handleExpand={graphId =>
                          handleExpandChart({
                              ...this.props,
                              graphTitle: REDEMPTION_GRAPH_NAMES.TIME_OF_REDEMPTION
                          })}
                  />
              </div>
          </div>
          <div className="graphInfoNote" style={{height: "80px"}}><span>Note: </span> Please hover for more details on the avg number of redemption transactions on weekdays/weekends of the filtered period.</div>
          <div className="budget-utilization-snapshot-container col-sm-12 text-center" >
              {!this.props.timeOfRedemptionLoader ?
                  this.props.timeOfRedemptionData && this.props.timeOfRedemptionData.length > 0 
                     ?
                      <div>
                          <WeeklyBarChart
                            height={365}
                            width={window.innerWidth * 0.37}
                            xAxisLabel={"Day"}
                            yAxisLabel={"Average # of Redemptions"}
                            data={this.props.timeOfRedemptionData}
                            xMaxValue={parseFloat( this.props.timeOfRedemptionXMaxValue)}
                            // legendNames={["Avg # of Weekday Redemptions per Week","Avg # of Weekend Redemptions per Week"]}
                            colorCodes={[THEME_COLORS[3], "#cca9dd"]}
                            // colorCodes={[THEME_COLORS[3], THEME_COLORS[23]]}
                            graphName={`time_of_redemption`}
                          />
                          <div
                              id="timeOfRedemptionsContainer"
                              className="d-none"
                          >
                            <WeeklyBarChart
                              height={365}
                              width={window.innerWidth * 0.5}
                              xAxisLabel={"Day"}
                              yAxisLabel={"Average # of Redemptions"}
                              // data={[{'range': "Weekday", 'value': this.props.timeOfRedemptionData.result[0].weekday}, {'range': "Weekend", 'value': this.props.timeOfRedemptionData.result[0].weekend}]}
                              data={this.props.timeOfRedemptionData}
                              xMaxValue={parseFloat( this.props.timeOfRedemptionXMaxValue)}
                              // legendNames={["Avg # of Weekday Redemptions per Week","Avg # of Weekend Redemptions per Week"]}
                              colorCodes={[THEME_COLORS[3], "#cca9dd"]}
                              // colorCodes={[THEME_COLORS[3], THEME_COLORS[23]]}
                              graphName={`time_of_redemption`}
                            />
                          </div>
                      </div>
                      : <div className="dataNotFound">
                          No Data Found
                        </div>
                  : <RedemptionStackedBarChartLoader
                      height={window.innerHeight * 0.62}
                      width={window.innerWidth * 0.38}
                  />}
          </div>
      </div>
        <div className="col-lg-12 col-xl-6 right-panel" >
          <div className="graphsActionContainer" style={{height: "60px"}}>
                <h3> {REDEMPTION_GRAPH_NAMES.TIME_OF_REDEEMPTION_HEATMAP_CHART} </h3>
                <div className="exportContainer">
                  <GraphActions
                    showExpansion={!this.props.timeOfRedemptionByHourDataLoader && this.props.timeOfRedemptionByHourData && this.props.timeOfRedemptionByHourData.heatmapData && this.props.timeOfRedemptionByHourData.heatmapData.length > 0 && this.props.timeOfRedemptionByHourData.heatmapMaxValue > 0}
                    showCSVExport={this.props.timeOfRedemptionByHourData && this.props.timeOfRedemptionByHourData.heatmapData && this.props.timeOfRedemptionByHourData.heatmapData.length > 0 && this.props.timeOfRedemptionByHourData.heatmapMaxValue > 0}
                    showImageExport={!this.props.timeOfRedemptionByHourData.loader && this.props.timeOfRedemptionByHourData.heatmapData && this.props.timeOfRedemptionByHourData.heatmapData.length > 0 && this.props.timeOfRedemptionByHourData.heatmapMaxValue > 0}
                    showInfo={true}
                    from={"Weekday vs Weekend Redemptions (by time)"}
                    apiUrl={"auth/redemption/heatmap"}
                    filterObjectForCSV={this.props.getFilterObject}
                    graphId="timeOfRedeemptionHeatmap"
                    imageName={`Time of Redeemption`}
                    infoMessage="The colour intensity is proportional to the average number of redemption transactions across the various day and time combinations."
                    ExternalStyleSheet=""
                    handleExpand={() => handleExpandChart({ ...this.props, graphTitle: REDEMPTION_GRAPH_NAMES.TIME_OF_REDEEMPTION_HEATMAP_CHART })}
                  />
                </div>
          </div>

          <div className="graphInfoNote" style={{height: "80px"}}><span>Note: </span> Please hover over a cell to retrieve the average number of redemptions made per week in the selected time and day combination during the filtered period. Time is presented in UTC.</div>
          <div className="budget-utilization-snapshot-container col-sm-12 text-center" id="timeOfRedeemptionHeatmap">
          {!this.props.timeOfRedemptionByHourDataLoader && this.props.timeOfRedemptionByHourData && this.props.timeOfRedemptionByHourData.heatmapData ?
            this.props.timeOfRedemptionByHourData.heatmapData.length > 0 && this.props.timeOfRedemptionByHourData.heatmapMaxValue > 0 ?
              <Heatmap
                height = {window.screen.height * 0.45}
                width = {screenWidth * 0.75}
                data = { this.props.timeOfRedemptionByHourData.heatmapData }
                dataNames = { heatmapYAxis }
                dataXAxisNames= { heatmapXAxis }
                maxCount = { this.props.timeOfRedemptionByHourData.heatmapMaxValue }
                lightColor={"#ffe6e8"}
                darkColor = {"#ff3344"}
                margin={{top: 20, right: 20, bottom: 90, left: 120}}
                xAxisName={"Time"}
                yAxisName={"Day"}
                labelName={"# of Transactions"}
                ignoreLeftTextLength={true}
              />
              : <div className="dataNotFound">
                No Data Found
                </div>
              : <HeatmapLoader
                    height = {window.innerHeight * 0.5}
                    width={window.innerWidth * 0.37}
                /> } 
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = state => {
  return {
    timeOfRedemptionByHourData: state.RedemptionDashboard.timeOfRedemptionByHourData,
    timeOfRedemptionByHourDataLoader: state.RedemptionDashboard.timeOfRedemptionByHourData.loader,
    timeOfRedemptionData: state.RedemptionDashboard.timeRedeemedData.timeOfRedemtionData,
    timeOfRedemptionXMaxValue: state.RedemptionDashboard.timeRedeemedData.xMaxValue,
    timeOfRedemptionLoader: state.RedemptionDashboard.timeRedeemedData.loader
  };
};

export default connect(mapStateToProps)(TimeOfRedemption);