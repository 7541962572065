import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from "recharts";

class DynamicBarChart extends React.Component {
  render() {
  	return (
    	<BarChart 
        width={this.props.width} 
        height={this.props.height} 
        data={this.props.data}
        margin={this.props.margin}
      >    
        <XAxis dataKey="x" />
        <YAxis/>
        <CartesianGrid strokeDasharray="3 3"/>
        <Tooltip/>
        <Legend align="right" verticalAlign="top" />
        { this.props.xAxisNames.map( ( names, index ) => 
          <Bar key={index} dataKey={names} fill={this.props.colors[index]} />
        ) }
      </BarChart>
    //  </ResponsiveContainer>
    );
  }
}

export default DynamicBarChart;