import React, { PureComponent } from "react";
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Line, ComposedChart, ResponsiveContainer, Label, Brush
} from "recharts";
const CustomYAxisTick = props => {
  const { x, y, payload } = props;
  return (
    (<g transform={`translate(${25},${y})`}>
      <text x={0} y={0}
        textAnchor="start"
        fontSize= "12" 
        fontFamily= "roboto"
        fontWeight= "600" 
        fill="#666">{ payload.value.length > 15 ? `${payload.value.slice(0, 12)}...` : payload.value }</text>
    </g>)
  );
};
export default class StackedBarChart extends PureComponent {
  constructor (props) {
    super(props);
    this.state = {
      startIndex: 0,
      endIndex: 10,
      interval: 0
    };
  }

  customBrushTickFormatter = (props) => {
  }
   renderLegend = (props) => {
     const { payload } = props;
     return (
       <ul>
         {
           payload.map((entry, index) => {
             return (<li key={`item-${index}`}
               style={{ fontSize: "12", 
                 fontFamily: "roboto",
                 fontWeight: "500" }}
             > <span style={{ backgroundColor: entry.color }} ></span> {entry.value}</li>);
           })
         }
       </ul>
     );
   };
   render () {
     return (
       <BarChart
         width={this.props.width}
         height={this.props.height}
         data={this.props.data}
         margin={this.props.margin}
         reverseStackOrder={true}
       >
         <XAxis dataKey={this.props.xAxisNameId} tick={{ fontSize: 12, fontFamily: "roboto", fontWeight: 600 }} angle={this.props.xAxisAngle} interval={0} padding={{ top: 20 }} textAnchor={ this.props.textAnchor } allowDataOverflow={true}>
           { this.props.xAxisLabel ? <Label value={this.props.xAxisLabel} style={ { fontSize: 14, fontFamily: "roboto", fontWeight: 500, fill: "#758088" } } offset={-30} position="insideBottom" /> : null }
         </XAxis>
         <YAxis yAxisId="left" tick={{ fontSize: 12, fontFamily: "roboto", fontWeight: 600 }} label= { this.props.yAxisLabel ? { value: this.props.yAxisLabel, angle: -90, position: "insideLeft", style: { fontSize: 14, fontFamily: "roboto", fontWeight: 500, fill: "#758088" } } : null } allowDataOverflow={true} />
         { this.props.showRightYAxis ? <YAxis yAxisId="right" orientation="right" /> : null }
         <Tooltip wrapperStyle={{ margin: "0px",
           backgroundColor: "rgb(255, 255, 255)",
           "white-space": "nowrap",
           "font-style": "roboto, sans-serif",
           fontSize: "14px",
           fontWeight: "500px"
         }}
         formatter = { (value, name, props) =>  [ value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"), name ]  }
         
         />
         <Legend 
           verticalAlign="top" 
           height={36} 
           layout="horizontal" 
           align="right" 
           margin = { { top: 0, left: 0, right: 0, bottom: 0 } } />
         { this.props.xAxisNames.map((name, index) => 
           <Bar yAxisId="left" key={index} dataKey={name} stackId="x" fill={this.props.colors[index]} barSize={this.props.barSize} onClick = { this.props.isOnClickEvent ? (e) => this.props.clickHandler(e) : null } />            
         ) }
         {
           this.props.showBrush
             ? <Brush 
               dataKey={this.props.xAxisNameId} 
               height={this.props.brushHeight}
               width={this.props.brushWidth - this.props.margin.right } 
               stroke="#0b2d44" 
               y={this.props.height - this.props.brushHeight } 
               x={0}
               startIndex={0}
               endIndex={10}
               travellerWidth={0}
               tickFormatter={this.customBrushTickFormatter}
             >
               <BarChart>
                 { this.props.xAxisNames.map((name, index) => 
                   <Bar yAxisId="left" key={`name-${index}`} dataKey={name} stackId="x" fill={this.props.colors[index]} barSize={30} onClick = { this.props.isOnClickEvent ? (e) => this.props.clickHandler(e) : null } />            
                 ) }
               </BarChart>
             </Brush> : null}
       </BarChart>
     );
   }
}
