import React from "react";
import next from "../../static/images/next.png";
import moment from "moment";
import GraphActions from "../GraphActionsComponent/Graphnactions";

class Tab extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      selectedTab: "recieved"
    };
  }
  
  handleChangeSelectedTab = (name) => {
    this.setState({
      ...this.state,
      selectedTab: name
    });
  }

  render () {
    return (
      <div className="tabContainer">
        <div className="tablinkouter">
          <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item">
              <a className={`nav-link tab-recive ${this.state.selectedTab === "recieved" ? "active" : ""}`} role="tab" data-toggle="tab" onClick={ (e) => { e.preventDefault(); this.handleChangeSelectedTab("recieved"); } } >Received <span>{ this.props.feeds.received.length }</span></a>
            </li>
            <li className="nav-item">
              <a className={`nav-link tab-given ${this.state.selectedTab === "given" ? "active" : ""}`} role="tab" data-toggle="tab" onClick={ (e) => { e.preventDefault(); this.handleChangeSelectedTab("given"); } } >Given <span>{this.props.feeds.given.length}</span></a>
            </li>
                    
          </ul>   
          <div className="exportCsv">
            <GraphActions 
              from={this.props.from}
              showExpansion={false}
              showCSVExport={true}
              showImageExport={false}
              showInfo={false}
              filterObjectForCSV={this.props.filterObjectForCSV}
              export_parameter={this.props.export_parameter}
              apiUrl={this.props.apiUrl}
              selectedDate={this.props.selectedDate}
              individualUserId={this.props.individualUserId}
              parameter={this.state.selectedTab} 
            />
          </div>    
        </div> 
            
        <div className="tab-content">
         
          <div role="tabpanel" className={`tab-pane ${this.state.selectedTab === "recieved" ? "active" : ""}`} id="recived">
            { 
              this.props.feeds.received.length > 0
                ? this.props.feeds.received.map((feed, index) => {
                  let message = feed.transaction_message.replace(/\\u[\dA-F]{4}/gi, function (match) {
                    return String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16)); 
                  });
                  return (
                    <div className="appreciationList" key={`individual-${index}`} >
                      <div className="appreciation">
                        <p>{message}</p>
                      </div>
                      <div className="appreciationInfo">
                        <div className="pointsCount">
                                        Points <span>{feed.points}</span> 
                        </div>
                        <div className="Info">
                          <div className="personName">
                        from <span>{ feed.giver_first_name ? `${feed.giver_first_name} ${feed.giver_last_name}` : "Anonymous" } </span> to <span>{ feed.receiver_first_name ? ` ${feed.receiver_first_name} ${feed.receiver_last_name}` : "Anonymous" }</span>
                          </div>
                          <div className="date">
                                            Date : <span>{ moment(feed.created).format("DD MMM YYYY") }</span>
                          </div>
                        </div>
                      </div>
                    </div> 
                  );
                }) 
                : <div className="dataNotFound">
                No Data Found
                </div>
            }

          </div> 
          <div role="tabpanel" className={`tab-pane ${this.state.selectedTab === "given" ? "active" : ""}`} id="given">
            { 
              this.props.feeds.given.length > 0
                ? this.props.feeds.given.map(feed => {
                  let message = feed.transaction_message.replace(/\\u[\dA-F]{4}/gi, function (match) {
                    return String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16)); 
                  });
                  return (
                    <div className="appreciationList">
                      <div className="appreciation">
                        <p>{message}</p>
                      </div>
                      <div className="appreciationInfo">
                        <div className="pointsCount">
                                    Points <span>{feed.points}</span> 
                        </div>
                        <div className="Info">
                          <div className="personName">
                    from <span>{ feed.giver_first_name ? `${feed.giver_first_name} ${feed.giver_last_name}` : "Anonymous" } </span> to <span>{ feed.receiver_first_name ? `${feed.receiver_first_name} ${feed.receiver_last_name}` : "Anonymous"}</span>
                          </div>
                          <div className="date">
                                        Date : <span>{ moment(feed.created).format("DD MMM YYYY") }</span>
                          </div>
                        </div>
                      </div>
                    </div> 
                  );
                })
                : <div className="dataNotFound">
                      No Data Found                     
                      
                </div> 
            }
          </div> 
          
          <div role="tabpanel" className="tab-pane fade" id="all">
            <div className="appreciationList">
              <div className="datanotFound">
                                No data Found
              </div>
            </div>
          </div>
        </div>
      </div>    

    );
  }
}

export default Tab;