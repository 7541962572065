import React from "react";

// Common Loader Container
const TreeMapChartLoader = (props) => {
  return (
    <div className="treemapLoader animated-background lg-width" style={{height:props.height}} >
      
    </div>
  );
};

export default TreeMapChartLoader;