import React from "react";
import { Link, withRouter } from "react-router-dom";
import appreciationsIcon from "../../static/images/appreciations.png";
import redemptionPattern from "../../static/images/redemptionPattern.png";
import engagement from "../../static/images/engagement.png";
// import redemptionPatternDisabled from "../../static/images/redemptionPatternDisabled.png";
// import engagementDisabled from "../../static/images/engagementDisabled.png";

import { connect } from "react-redux";

// Dashboard Sidebar container
class SideBarContainer extends React.Component {
  render() {
    let { location } = this.props;
    return (
      <nav id="sidebar" className={this.props.expandedSideBar ? "active" : ""} >
        <ul className="sidebarMenulist">                      
          { this.props.isAppreciationsEnabled ? <li className={ location.pathname.includes("redemption") || location.pathname.includes("engagement") ? "disable" : "active" }>
            <Link to="/appreciation">  {<div> <span>Appreciations</span>  <img src={appreciationsIcon} alt="Appreciations" title="Appreciations"/> </div>}</Link>
          </li> : null }
          <li className={ location.pathname.includes( "redemption" ) ? "active" : "disable" }>
            <Link to="/redemption"> { <div> <span>Redemption Patterns</span>  <img src={redemptionPattern} alt="redemptionPattern" title="Redemption Patterns"/></div>}</Link>
          </li>
          <li className={ location.pathname.includes( "engagement" ) ? "active" : " disable" }>
            <Link to="/engagement"> {<div> <span>Engagement</span>  <img src={engagement} alt="engagement" title="Engagement"/></div>}</Link>            
          </li>
        </ul>
      </nav>

    );
  }
}
const mapStateToProp = state => {
  return {
    expandedSideBar: state.Common.expandedSideBar,
    isAppreciationsEnabled: state.Login.isAppreciationsEnabled
  };
};

export default withRouter( connect( mapStateToProp )( SideBarContainer ) );