import React, {Fragment} from "react";
import * as d3 from "d3";
import { THEME_COLORS } from "./../../constants/constants";

// Heatmap Chart
class ExtendedLineChart extends React.Component {
  componentDidMount() {
    this.loadChart();
  }

  loadChart = () => { 
  let data = this.props.data;

  let width     = this.props.width;
  let height    = this.props.height;
  let graphName = this.props.graphName;

  let isDepartment  = this.props.isDepartment, isUnRedeemed  = this.props.isUnRedeemed, isExpand = this.props.isExpand;

  let margin = 50, duration = 250;
  
  let lineOpacity = "0.50", lineOpacityHover = "0.85", otherLinesOpacityHover = "0.1", lineStroke = "3px", lineStrokeHover = "3.5px"; 
  let circleOpacity = '0.85', circleOpacityOnLineHover = "0.25", circleRadius = 5, circleRadiusHover = 6;
  
  let x = d3.scaleBand().rangeRound([0, width-margin]);

  let years = data[0].values.map(function(d) { return d.abc; });
  let names = data.map(function(d) { return d.name; });

  x.domain(years);

  var xScale = d3.scaleLinear()
  .domain([0, data[0].values.length]) // input
  .range([0, width-margin]); // output
  
  let yScale = d3.scaleLinear()
    .domain([0, this.props.yAxisMaxValue])
    .range([height-margin, 0]);
  
  let color = d3.scaleOrdinal(THEME_COLORS);
  
  /* Add SVG */
  let svg = d3.select(this.node).append("svg")
    .attr("width", (width+margin)+"px")
    .attr("height", (height+margin)+"px")
    .append('g')
    .attr("transform", `translate(${margin}, ${margin})`);
  
  /* Add line into SVG */
  let line = d3.line()
    .x(function(d, i) { return xScale(i + 0.5 -( i * 0.005))})
    .y(d => yScale(d.price));
  
  let lines = svg.append('g')
    .attr('class', 'lines');
  
  lines.selectAll('.line-group')
    .data(data).enter()
    .append('g')
    .attr('class', 'line-group')
    .attr("transform", `none`)
    .on("mouseover", function(d, i) {
        svg.append("text")
          .attr("class", "title-text")
          .style("fill", () =>  {  
            let colorIndex = names.findIndex( name => name === d.name);  
            colorIndex = colorIndex > 23 ? colorIndex - 24: colorIndex;
            return color( colorIndex > 23 ? colorIndex - 24: colorIndex )})       
          .text(d.name)
          .attr("text-anchor", "middle")
          .attr("x", (width-margin)/2)
          .attr("y", 5);
      })
    .on("mouseout", function(d) {
        svg.select(".title-text").remove();
      })
    .append('path')
    .attr('class', 'ageline')  
    .attr('d', d => line(d.values))
    .style("stroke", (d, i) => color( i > 23 ? i - 24: i ))       
    .style('stroke-width', '2')
    .style('opacity', lineOpacity)
    .style('fill', 'none')
    .on("mouseover", function(d) {
        d3.selectAll('.ageline').style('opacity', otherLinesOpacityHover);
        d3.selectAll('.circle').style('opacity', circleOpacityOnLineHover);
        d3.select(this).style('opacity', lineOpacityHover).style("stroke-width", lineStrokeHover).style("cursor", "pointer");
      })
    .on("mouseout", function(d) {
        d3.selectAll(".ageline").style('opacity', lineOpacity);
        d3.selectAll('.circle').style('opacity', circleOpacity);
        d3.select(this).style("stroke-width", lineStroke).style("cursor", "none");
      });
  
  
  /* Add circles in the line */
  lines.selectAll("circle-group")
    .data(data).enter()
    .append("g")
    .style("fill", (d, i) => color( i > 23 ? i - 24: i ))
    .selectAll("circle")
    .data(d => d.values).enter()
    .append("g")
    .attr("class", "circle")  
    .on("mouseover", function(d, index) {
        let div = d3.select(`#tooltip-${graphName}${isExpand}`)
        .attr("class", "redemption-tooltip")
        .style("opacity", 0);

        let e = 0, x = 0, y = 0;
        e = window.event, x = e.clientX, y = e.clientY-40;
  
        let colorIndex = names.findIndex( name => name === d.name);  
        colorIndex = colorIndex > 23 ? colorIndex - 24: colorIndex;

        div.transition().duration(200).style("opacity", 1.0);
        div.html( `Period: ${d.abc} <br/>
            <span style="color:${THEME_COLORS[colorIndex]}"> ${isDepartment ? 'Department' : 'Company'}: ${d.name} </span><br/>
            <span style="color:${THEME_COLORS[colorIndex]}"> Average Age of ${isUnRedeemed ? 'Unredeemed' : 'Redeemed'} Points : ${d.price} </span><br/>`)
          .style("top", `${y-50}px`)
          .style("left", `${x-100}px`)
      })
    .on("mouseout", function(d) {
        let div = d3.select(`#tooltip-${graphName}${isExpand}`)
        div.transition().duration(500).style("opacity", 0);
      })
    .append("circle")
    // .attr("cx", d => xScale(d.abc))
    .attr("cx", function(d, i) {
          return xScale(i + 0.5 -( i * 0.005 ))
    })
    .attr("cy", d => yScale(d.price))
    .attr("r", circleRadius)
    .style('opacity', circleOpacity)
    .on("mouseover", function(d) {
          d3.select(this)
            .transition()
            .duration(duration)
            .attr("r", circleRadiusHover);
        })
      .on("mouseout", function(d) {
          d3.select(this) 
            .transition()
            .duration(duration)
            .attr("r", circleRadius);  
        });
  
  
  /* Add Axis into SVG */
  let yAxis = d3.axisLeft(yScale).ticks(10);
  
  let XAxisElement = svg.append("g")
  .attr("class", "x-axis")
  .attr("transform", `translate(0, ${height-margin})`)
  .call(d3.axisBottom(x));

  let YAxisElement = svg.append("g")
    .attr("class", "y-axis")
    .call(yAxis)
    .append('text')
    .attr("y", 15)
    .attr("transform", "rotate(-90)")
    .attr("fill", "#000")
    // .text("Total values")
    ;

    XAxisElement.selectAll('.tick').selectAll('text').attr("transform", "rotate(-45)");
    svg.selectAll('.x-axis').selectAll('.tick').selectAll('text').attr("x", "-15").attr("y", "15").style("font-size", 12)
    .style("font-weight", 700)
    .style("fill", "#758088")
    .style("font-family", "roboto");
    svg.selectAll('.y-axis').selectAll('.tick').selectAll('text').attr("x", "-10").attr("y", "-0").style("font-size", 12)
    .style("font-weight", 700)
    .style("fill", "#758088")
    .style("font-family", "roboto");

    svg.append("text")
    .attr("transform", "rotate(-90)")
    .attr("y", -margin)
    .attr("x", 0 - ((height) / 2.4))
    .attr("dy", "1em")
    .style("text-anchor", "middle")
    .style("font-family", "roboto")
    .style("font-weight", 500)
    .style("font-size", 14)
    .style("fill", "#758088")
    .text(this.props.yAxisLabel);
}
    
render() {
    let graphName = this.props.graphName, isExpand = this.props.isExpand;
    let counter = 0;
    return (
        <div /*id="ageOfRedemptionTrend"*/> 
            <div ref={(node) => { this.node = node; }}  style={{ float: "left" }} >
                <div>
                <div id={`tooltip-${graphName}${isExpand}`} ></div>
                </div>
            </div>

            <div className="col-sm-4 text-left mb-5 mt-5" style={{ float: "left" }}>
                <h6 className="mb-3">{this.props.isDepartment ? 'Department' : 'Company'} Legends</h6>
                {this.props.data ? <ul className="strengthName scroll-auto" style={{ flexWrap: "wrap", height: this.props.height * 0.75, alignContent: "left", float: "left" }}>
                {
                    this.props.data.map((entry, index) => {
                    if( index == 23 || index == 45 || index == 67 ) {
                        counter = 0;
                    }
                    return (<li key={`item-${index}`}>
                        <label style={{ backgroundColor: `${THEME_COLORS[counter++]}`, width: "15px", height: "12px", margin: "3px 3px 0px 3px", fontSize: "15px", paddingLeft: "0px", color: "#595959", fontFamily: "roboto", fontWeight: "500", display:"inline-flex" }} ></label>
                        <label style={{ margin: "0px", fontSize: "12", fontWeight: "400",fontFamily: "roboto", display: "inline-flex" }}>{entry.name}</label>
                    </li>);
                    
                    })
                }
                </ul>
                : <div className="dataNotFound">
                    No Legends Found
                </div>
                }
            </div>
        </div>
    );
  }
}
  
export default ExtendedLineChart;