import React from "react";
import * as d3 from "d3";
// Heatmap Chart
class HeatMap extends React.Component {
  componentDidMount () {
    this.loadChart();
  }

  componentDidUpdate () {
    this.loadChart();
  }

  handleClick = (clickedContent, props) => {
    // HANDLE CLICKED CONTENT
    this.props.handleClicked ? this.props.handleClicked(clickedContent) : null;
  }

    loadChart = () => {
      var margin = this.props.margin;
      var width = this.props.width - margin.left - margin.right;
      var height = this.props.height - margin.top - margin.bottom;
      var graphName = this.props.graphName;

      // d3.select(`#${this.props.graphName}Heatmap`).select("svg").remove();

      // create a tooltip
      var tooltip = d3.select(this.node)
        .append("div")
        .style("opacity", 0)
        .attr("class", "tooltip")
        .style("background-color", "white")
        .style("border", "solid")
        .style("border-width", "2px")
        .style("border-radius", "5px")
        .style("padding", "5px");

      // Three function that change the tooltip when user hover / move / leave a cell
      var mouseover = function (d) {
        // tooltip
        //   .style("opacity", 1);
        d3.select(this)
          .style("stroke", "#333")
          .style("stroke-width", 1);
        // .style("opacity", 1)
        // .style("filter", `url(#${graphName}Shadow)`);
      };
      var mousemove = function (d) {
        tooltip
          .html("The exact value of<br>this cell is: " + d.value)
          .style("left", (d3.mouse(this)[0] + 70) + "px")
          .style("top", (d3.mouse(this)[1]) + "px");
      };
      var mouseleave = function (d) {
        // tooltip
        //   .style("opacity", 0);
        d3.select(this)
          .style("stroke", "#333")
          .style("stroke-width", 0.2);
        // .style("stroke", "none")
        // .style("opacity", 0.8)
        // .style("filter", "none");
      };

      // append the svg object to the body of the page
      d3.select(this.node).select("svg").remove();
      var svg = d3.select(this.node).append("svg")

        .attr("width", width + margin.left + margin.right + this.props.axisNameWidth)
        .attr("height", height + margin.top + margin.bottom + (this.props.axisNameWidth ) )
        .append("g")
        .attr("transform",
          "translate(" + (110) + "," + (margin.top + this.props.axisNameWidth / 2) + ")");

      // Labels of row and columns
      var myGroups = this.props.dataNames;

      // Build X scales and axis:

      var x = d3.scaleBand();
      x.range([ 0, width - 110 ])
        .domain(myGroups)
        .padding(0.01);
      svg.append("g")
        .attr("transform", `translate(0, ${height})`)
        .attr("id", "heatmapExpand-xaxis")
        .call(d3.axisBottom(x).tickSize(0))
        .selectAll("text")	
        .style("text-anchor", "end")
        .style("font-size", 12)
        .style("font-weight", 700)
        .style("font-family", "roboto")
        .style("fill", "#595959")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-45)")
        .text(text => text);

      // .call(wrap, 50)

      function wrap (text, width) {
        text.each(function () {
          var text = d3.select(this);
          var words = text.text().split(/\s+/).reverse();
          var word;
          var line = [];
          var lineNumber = 0;
          var lineHeight = 1.1; // ems
          var y = text.attr("y");
          var dy = parseFloat(text.attr("dy"));
          var tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");
          while(word = words.pop()) {
            line.push(word);
            tspan.text(line.join(" "));
            if(tspan.node().getComputedTextLength() > width) {
              line.pop();
              tspan.text(line.join(" "));
              line = [word];
              tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
            }
          }
        });
      }

      // Build X scales and axis:
      var y = d3.scaleBand();
      y.range([ height, 0 ])
        .domain(myGroups)
        .padding(0.01);
      svg.append("g")
        .call(d3.axisLeft(y).tickSize(0))
        .attr("id", "heatmapExpand-yaxis")
        .selectAll("text")
        .style("font-size", 12)
        .style("font-family", "roboto")
        .style("font-weight", 700)
        .style("fill", "#595959")
        .text(text => text);
      // Build color scale
      var myColor = d3.scaleLinear()
        .range([this.props.lightColor, this.props.darkColor])
        .domain([0, this.props.maxCount]);

      // Read the data

      let data = this.props.data;
      var g = svg.selectAll()
        .data(data, function (d) { return d.receiver + ":" + d.giver; })
        .enter()
        .append("g").attr("class", "heatmapCell");

      d3.selectAll(".tick")
        .append("title")
        .text(d => d);

      g.append("rect")
        .attr("x", function (d) { return x(d.receiver); })
        .attr("y", function (d) { return y(d.giver); })
        .attr("width", x.bandwidth())
        .attr("height", y.bandwidth())
        .on("click", (d) => this.handleClick(d))
        .style("cursor", "pointer")
        .style("stroke", "#333")
        .style("stroke-width", 0.2)
        .on("mouseover", mouseover)
        // .on("mousemove", mousemove)
        .on("mouseleave", mouseleave)
        .style("fill", function (d) { return d.value == 0 ? "#fff" : myColor(d.value); });
      g.append("title")
        .text(d =>
          d.giver + " → " + 
                        d.receiver + "\n" + "Appreciation: " + d.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));
      // add the link titles
      // link.append("title")
      //   .text(function (d) {
      //     return d.source.name + " → " + 
      //                   d.target.name + "\n" + format(d.value); 
      //   });
      // g.append("text")
      //   .attr("x", function (d) { return x(d.giver)  })
      //   .attr("y", function (d) { return y(d.receiver) })
      //   .attr("width", x.bandwidth())
      //   .attr("height", y.bandwidth())
      //   .attr("stroke", "#000")
      //   .style("font-size",12)
      //   .text((d) => d.value);

      // text label for the x axis
      svg.append("text")             
        .attr("transform",
          "translate(" + ((width - 110) / 2) + " ," + 
                           (height + (this.props.axisNameWidth > 80 ? this.props.axisNameWidth / 2 : this.props.axisNameWidth + 15 )) + ")")
        .style("text-anchor", "middle")
        .style("font-family", "roboto")
        .style("font-weight", 500)
        .style("font-size", 14)
        .style("fill", "#758088")
        .text("Appreciation Receiver");

      // text label for the y axis
      svg.append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", -110)
        .attr("x", 0 - (height / 2))
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .style("font-family", "roboto")
        .style("font-weight", 500)
        .style("font-size", 14)
        .style("fill", "#758088")
        .text("Appreciation Giver"); 

      d3.selectAll(".heatmapCell") // Move All cell as per width of axis name
        .attr("transform",
          "translate(" + this.props.axisNameWidth + " ," + 
                         ( - this.props.axisNameWidth/2) + ")");

      d3.select("#heatmapExpand-xaxis") // Move x-axis as per width of axis name
        .attr("transform",
          "translate(" + this.props.axisNameWidth + " ," + 
                         (height - this.props.axisNameWidth/2) + ")");  
        
      d3.select("#heatmapExpand-yaxis") // Move y-axis as per width of axis name
        .attr("transform",
          "translate(" + this.props.axisNameWidth + " ," + 
                         (- this.props.axisNameWidth/2) + ")");   
    }

    render () {
      return (
        <div className="scroll-auto" ref={ (node) => { this.node = node; } } style={ this.props.isExpand ? { height: this.props.scrollHeight, width: this.props.scrollWidth } : {} } >
          <svg>
            <defs>
              <filter id={`${this.props.graphName}Shadow`} x="-40%" y="-40%" width="180%" height="180%" filterUnits="userSpaceOnUse">
                <feOffset result="offOut" in="SourceAlpha" dx="20" dy="20" />
                <feGaussianBlur result="blurOut" in="offOut" stdDeviation="10" />
                <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
              </filter>
            </defs> 

          </svg>
        </div>
      );
    }
}

export default HeatMap;