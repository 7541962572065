import React from "react";

const TileLoader = props => {
  return (
    <div className= "tileContainer animated-background lg-width">
      <div className="percentContent">
        <div className="highlightNumber">
           
        </div>
        <div className="tileText">

        </div>     
      </div>        
    </div>
  );
};

export default TileLoader;