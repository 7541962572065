/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import Switch from "react-switch";
import {
  MESSAGES,
  THEME_COLORS,
  TILES_DECIMAL_POINT,
  SLOTS_3HR,
  WEEKS_DAY,
  ENGAGEMENT_GRAPH_NAME,
} from "../../../../../constants/constants";
import { GraphActions } from "../../../../../UI";
import API from "../../../../../Utilities/Api/Apis";
import {
  HeatmapLoader,
  Histogram,
  PiechartLoader,
} from "../../../../../Widgets";
import AreaChart from "../../../../../Widgets/Recharts/areaChart";
import ComposedBarChart from "../../../../../Widgets/Recharts/ComposedBarChart";
import PieCharts from "../../../../../Widgets/Recharts/PieCharts";
import StackedBarChartLoader from "../../../../../Widgets/StackedBarChart/StackedBarChartLoader";
import Heatmap from "../../../../../Widgets/HeatMap/Heatmap";
import {
  generateFileName,
  getMax,
} from "../../../../../Utilities/common-utill";
import CustomizedLineChart from "../../../../../Widgets/Recharts/d3/CustomizedLineChart";

class OverallAppUsageSection extends Component {
  IS_PARENT_ORG_LOGIN = JSON.parse(sessionStorage.getItem("isParentOrgLogin"));
  handleExpandChart = (props) => {
    props.showEventModal({
      showEventModal: true,
      eventModalTitle: "",
      eventModalBody: "",
      showEventModalButtonOne: false,
      showEventModalButtonTwo: false,
      buttonOneEventModalHandler: () => props.hideEventModal(),
      buttonTwoEventModalHandler: null,
      buttonOneEventModalName: "",
      buttonTwoEventModalName: "",
      graphContent: props.graph,
      graphTitle: props.graphTitle,
      graphSubTitle: props.graphSubTitle,
      showGraph: true,
      graphName: "Bar Chart",
      graphId: "",
    });
  };
  render() {
    let screenWidth = window.screen.width / 2;

    const frequencyOfAppUses = (
      width = screenWidth * 0.75,
      height = window.screen.height * 0.45,
    ) => (
      <ComposedBarChart
        roundFigYaxis={1}
        width={width}
        height={height}
        margin={{ top: 40, right: 50, left: 15, bottom: 40 }}
        composedBarChartData={this.props.frequencyAppUsesData}
        showDecimal={true}
        decimalPoints={TILES_DECIMAL_POINT}
        chartDetails={{
          hideLegends: true,
          xKey: "per_month",
          leftYKey: "days",
          barKey: "days",
          dataLength: this.props.frequencyAppUsesData.length,
          axisLabel: {
            yLeft: "Avg # of Usage Days per Engaged User",
            bar: "Avg # of Usage Days per Engaged User",
          },
          lineStroke: "#185f7d",
          barStroke: "#185f7d",
        }}
      ></ComposedBarChart>
    );

    const avgAppUsersByDayAndTimeFlag =
      this.props.avgAppUsersByDayAndTimeData &&
      this.props.avgAppUsersByDayAndTimeData.length;

    const avgAppUsersByDayAndTime = (
      width = screenWidth * 0.75,
      height = window.screen.height * 0.52,
    ) => {
      const maxValue = getMax(this.props.avgAppUsersByDayAndTimeData, "value"); //value is key from Response for userCount
      return (
        <Heatmap
          width={width}
          height={height}
          data={this.props.avgAppUsersByDayAndTimeData}
          dataNames={[...WEEKS_DAY]}
          dataXAxisNames={[...SLOTS_3HR]}
          maxCount={maxValue}
          lightColor={"#ffe6e8"}
          darkColor={"#ff3344"}
          margin={{ top: 20, right: 20, bottom: 85, left: 120 }}
          xAxisName={"Time"}
          yAxisName={"Day"}
          labelName={"Avg Users Count"}
          chartDataKeys={{
            xAxis: "slot",
            yAxis: "dow",
          }}
          ignoreLeftTextLength={true}
        />
      );
    };

    const frequencyOfFeatureUses = ({
      width = screenWidth * 0.75,
      height = window.screen.height * 0.45,
      innerRadius = 70,
      outerRadius = 100,
    }) => {
      return (
        <PieCharts
          width={width}
          height={height}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          data={this.props.frequencyFeatureUsesData}
          title={"By Feature"}
          pieDateKey="days"
          appendLabel="days/month"
          colorRange={THEME_COLORS}
          isSinglePie={true}
          showLabels={false}
          showLegends={true}
          showDecimal={true}
          decimalPoints={TILES_DECIMAL_POINT}
          labelDataKey={"days"}
        ></PieCharts>
      );
    };

    const frequencyFeatureUsesFlag =
      this.props.frequencyFeatureUsesData &&
      this.props.frequencyFeatureUsesData.length;

    const frequencyAppUsesFlag =
      this.props.frequencyAppUsesData && this.props.frequencyAppUsesData.length;

    const numOfUniqueUsersFlag =
      this.props.numOfUniqueUsersData && this.props.numOfUniqueUsersData.length;

    const numOfUniqueUsers = (width, height) => {
      return (
        <Histogram
          width={width}
          height={height}
          xAxisNames={["First-time Users", "Existing Users"]}
          data={this.props.numOfUniqueUsersData}
          // maxRightAxisValue={100}
          margin={{ top: 40, right: 20, left: 20, bottom: 30 }}
          colors={["#6fd8e5", "#0d2d46"]}
          showRightYAxis={true}
          isOnClickEvent={false}
          xAxisNameId={"Month"}
          xAxisAngle={-45}
          textAnchor="end"
          showRightYAxis={true}
          showBrush={this.props.numOfUniqueUsersData.length > 12}
          brushWidth={false}
          dataLength={this.props.numOfUniqueUsersData.length}
          brushHeight={20}
          barSize={50}
          rightAxisKey="Month-on-Month Retention%" // right-yAxis-obj-key for fetch the obj data
          yAxisLabel="# of Unique Users" //yAxis left label
          // yAxisLabel2 = "# of Unique Users Redeeming"
          xAxisLabel={"Period"}
          yAxisRightLabel={"Month-on-Month Retention %"} //yAxis right label
          legendAlign={"center"}
          isFromImage={true}
        />
      );
    };

    const proportionsOfUniqueUserFlag =
      !this.props.proportionsOfUniqueUserLoader &&
      this.props.proportionsOfUniqueUserData.data &&
      this.props.proportionsOfUniqueUserData.data.length &&
      ((this.IS_PARENT_ORG_LOGIN &&
        this.props.proportionOfUniqueUsersSwitchByCompany) ||
        this.props.isSingleCompanyOrSingleDeprtmentSelected);

    const proportionOfUniqueUsersAreaChart = (
      width = screenWidth * 0.55,
      height = window.screen.height * 0.47,
      isExpand = false,
    ) => {
      let filteredCompanyAverageLabel = "Filtered Company - All depts";
      if (
        !this.props.proportionOfUniqueUsersSwitchByCompany &&
        this.props.proportionsOfUniqueUserData &&
        this.props.proportionsOfUniqueUserData.company
      ) {
        filteredCompanyAverageLabel =
          this.props.proportionsOfUniqueUserData.company + " - All depts";
      }

      const averageLineLabel = this.props.proportionOfUniqueUsersSwitchByCompany
        ? "All Companies Overall"
        : filteredCompanyAverageLabel;

      let yAxisMaxValue = 100;
      if (this.props.proportionsOfUniqueUserData.data) {
        this.props.proportionsOfUniqueUserData.data.forEach((data) => {
          data.values.forEach((one) => {
            if (one.value > yAxisMaxValue) {
              yAxisMaxValue = one.value;
            }
          });
        });
      }

      yAxisMaxValue = Math.ceil(yAxisMaxValue / 10) * 10;

      return (
        <CustomizedLineChart
          width={width}
          height={height}
          data={this.props.proportionsOfUniqueUserData.data}
          yDataKey="value"
          xDataKey="period"
          yAxisMaxValue={yAxisMaxValue}
          graphName={"proportion-of-unique-user"}
          isExpand={isExpand}
          yAxisLabel={`% of Engaged Users`}
          avgLineName="Company Average"
          averageLineLabel={averageLineLabel}
          avgLineColor="#808080"
          avgLineUniqueId="idCompanyAverage"
          avgLineStroke={4}
        ></CustomizedLineChart>
      );
    };

    const proportionOfUniqueUsersChartRender = () =>
      this.props.proportionsOfUniqueUserLoader ? (
        <div style={{ overflow: "hidden" }}>
          <StackedBarChartLoader
            width={document.getElementById("tilesSection").offsetWidth}
            height={window.screen.height * 0.46}
          />
        </div>
      ) : this.props.proportionsOfUniqueUserData.data &&
        this.props.proportionsOfUniqueUserData.data.length >= 1 ? (
        proportionOfUniqueUsersAreaChart(
          window.innerWidth * 0.55,
          window.screen.height * 0.47,
        )
      ) : (
        <div className="dataNotFound">{MESSAGES.NO_DATA_FOUND}</div>
      );

    return (
      <div className="budgetUtilization">
        <div className="row">
          <div className="col-sm-12">
            <div className="titleOuter">
              <h2>{ENGAGEMENT_GRAPH_NAME.OVERALL_APP_USAGE}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-xl-6 col-lg1-12 mb-150">
            <div className="graphsActionContainer">
              <h3>{ENGAGEMENT_GRAPH_NAME.NUMBER_OF_UNIQUE_USERS}</h3>
              <div className="exportContainer">
                <GraphActions
                  showExpansion={numOfUniqueUsersFlag}
                  showCSVExport={numOfUniqueUsersFlag}
                  filterObjectForCSV={this.props.getFilterObject}
                  showImageExport={numOfUniqueUsersFlag}
                  showInfo={true}
                  from={generateFileName(
                    ENGAGEMENT_GRAPH_NAME.NUMBER_OF_UNIQUE_USERS,
                  )}
                  apiUrl={API.ENGAGEMENTS.NUMBER_OF_UNIQUE_USERS}
                  graphId="NumberOfUniqueUsers"
                  imageName={generateFileName(
                    ENGAGEMENT_GRAPH_NAME.NUMBER_OF_UNIQUE_USERS,
                  )}
                  infoMessage="New users refers to those who have logged onto the app for the first time; the number of new users would decrease once the app has been fully launched.  Next, the month-on-month % change can be used to track changes in overall unique app users."
                  handleExpand={(graphId) =>
                    this.handleExpandChart({
                      ...this.props,
                      graphTitle: ENGAGEMENT_GRAPH_NAME.OVERALL_APP_USAGE,
                      graphSubTitle:
                        ENGAGEMENT_GRAPH_NAME.NUMBER_OF_UNIQUE_USERS,
                      screenWidth: screenWidth,
                      graph: numOfUniqueUsers(
                        window.innerWidth * 0.8,
                        window.screen.height * 0.5,
                      ),
                    })
                  }
                />
              </div>
            </div>
            <div className="graphInfoNote mt-3">
              <span>Note: </span>Please hover for more details.
            </div>

            <div id="NumberOfUniqueUsers">
              {this.props.numOfUniqueUsersLoader ? (
                <StackedBarChartLoader
                  width={screenWidth * 0.75}
                  height={window.screen.height * 0.48}
                />
              ) : this.props.numOfUniqueUsersData &&
                this.props.numOfUniqueUsersData.length >= 1 ? (
                numOfUniqueUsers(
                  screenWidth * 0.75,
                  window.screen.height * 0.55,
                )
              ) : (
                <div className="dataNotFound">{MESSAGES.NO_DATA_FOUND}</div>
              )}
            </div>
          </div>

          <div className="col-md-12 col-xl-6 col-lg1-12 mb-150">
            <div className="graphsActionContainer">
              <h3>{ENGAGEMENT_GRAPH_NAME.AVERAGE_APP_USERS_BY_DAY_AND_TIME}</h3>
              <div className="exportContainer">
                <GraphActions
                  showExpansion={avgAppUsersByDayAndTimeFlag}
                  showCSVExport={avgAppUsersByDayAndTimeFlag}
                  filterObjectForCSV={this.props.getFilterObject}
                  showImageExport={avgAppUsersByDayAndTimeFlag}
                  showInfo={true}
                  from={generateFileName(
                    ENGAGEMENT_GRAPH_NAME.AVERAGE_APP_USERS_BY_DAY_AND_TIME,
                  )}
                  apiUrl={API.ENGAGEMENTS.AVG_APP_USERS_BY_DAY_AND_TIME}
                  graphId="IdAvgAppUsersByDayAndTime"
                  imageName={generateFileName(
                    ENGAGEMENT_GRAPH_NAME.AVERAGE_APP_USERS_BY_DAY_AND_TIME,
                  )}
                  infoMessage="This displays a list of notifications and shows how many notifications have been sent and read. Please click on a notification for more details on activity specific to the notification."
                  handleExpand={(graphId) =>
                    this.handleExpandChart({
                      ...this.props,
                      graphTitle: ENGAGEMENT_GRAPH_NAME.OVERALL_APP_USAGE,
                      graphSubTitle:
                        ENGAGEMENT_GRAPH_NAME.AVERAGE_APP_USERS_BY_DAY_AND_TIME,
                      screenWidth: screenWidth,
                      graph: avgAppUsersByDayAndTime(
                        window.innerWidth * 0.6,
                        window.screen.height * 0.5,
                      ),
                    })
                  }
                />
              </div>
            </div>
            <div className="graphInfoNote" style={{ marginBottom: "40px" }}>
              <span>Note: </span>Please use the dropdown list to filter by the
              type of initiative, then hover over the bar for more details. Time is presented in UTC.
            </div>

            <div id="IdAvgAppUsersByDayAndTime">
              {this.props.avgAppUsersByDayAndTimeLoader ? (
                <HeatmapLoader
                  width={screenWidth * 0.75}
                  height={window.screen.height * 0.48}
                />
              ) : this.props.avgAppUsersByDayAndTimeData &&
                this.props.avgAppUsersByDayAndTimeData.length >= 1 ? (
                avgAppUsersByDayAndTime()
              ) : (
                <div className="dataNotFound">{MESSAGES.NO_DATA_FOUND}</div>
              )}
            </div>
          </div>

          <div className="col-md-12 col-xl-12 col-lg1-12 mb-150">
            <div className="graphsActionContainer">
              <div className=" d-flex align-items-center">
                <h3 className="mb-1 mr-2">
                  {ENGAGEMENT_GRAPH_NAME.PROPORTION_OF_UNIQUE_USERS}
                  {this.IS_PARENT_ORG_LOGIN
                    ? this.props.proportionOfUniqueUsersSwitchByCompany
                      ? " By Company "
                      : " By Department "
                    : " By Department "}
                </h3>
                {this.IS_PARENT_ORG_LOGIN ? (
                  <div className="switchBtnouter">
                    <label className="m-0">
                      <Switch
                        onChange={() =>
                          this.props.proportionOfUniqueUsersSwitchHandler()
                        }
                        checked={
                          !this.props.proportionOfUniqueUsersSwitchByCompany
                        }
                        uncheckedIcon={null}
                        checkedIcon={null}
                        onColor={"#888888"}
                      />
                    </label>
                  </div>
                ) : null}
              </div>
              <div className="exportContainer">
                <GraphActions
                  showExpansion={proportionsOfUniqueUserFlag}
                  showCSVExport={proportionsOfUniqueUserFlag}
                  filterObjectForCSV={this.props.getFilterObject}
                  postPayload={{
                    is_by_company:
                      this.props.proportionOfUniqueUsersSwitchByCompany,
                  }}
                  showImageExport={proportionsOfUniqueUserFlag}
                  showInfo={true}
                  from={generateFileName(
                    ENGAGEMENT_GRAPH_NAME.PROPORTION_OF_UNIQUE_USERS,
                  )}
                  apiUrl={API.ENGAGEMENTS.PROPORTION_OF_UNIQUE_USER}
                  graphId="IdProportionOfUniqueUsers"
                  imageName={generateFileName(
                    ENGAGEMENT_GRAPH_NAME.PROPORTION_OF_UNIQUE_USERS,
                  )}
                  infoMessage={
                    this.props.proportionOfUniqueUsersSwitchByCompany
                      ? "Compare the proportion of engaged users across companies over time, benchmarked against the overall organisation average."
                      : "Compare the proportion of engaged users across the selected departments over time, benchmarked against the company average. Please download the csv file to view data for all departments at once."
                  }
                  handleExpand={(graphId) =>
                    this.handleExpandChart({
                      ...this.props,
                      graphTitle: ENGAGEMENT_GRAPH_NAME.OVERALL_APP_USAGE,
                      graphSubTitle: `${
                        ENGAGEMENT_GRAPH_NAME.PROPORTION_OF_UNIQUE_USERS
                      }
                      ${
                        this.IS_PARENT_ORG_LOGIN
                          ? this.props.proportionOfUniqueUsersSwitchByCompany
                            ? " By Company "
                            : " By Department "
                          : " By Department "
                      }`,
                      screenWidth: screenWidth,
                      graph: proportionOfUniqueUsersAreaChart(
                        window.innerWidth * 0.6,
                        window.screen.height * 0.5,
                        true,
                      ),
                    })
                  }
                />
              </div>
            </div>
            <div className="graphInfoNote mt-3">
              <span>Note: </span>
              {this.props.proportionOfUniqueUsersSwitchByCompany &&
              this.IS_PARENT_ORG_LOGIN ? (
                <>
                  Please hover over a point to display the name of the entity
                  and corresponding proportion of engaged users. The grey shaded
                  area denotes the overall proportion of engaged users across
                  ALL companies in the filtered period, regardless of department
                  and company selection.
                </>
              ) : null}
              {!this.props.proportionOfUniqueUsersSwitchByCompany ? (
                this.IS_PARENT_ORG_LOGIN ? (
                  this.props.selectedCompanies &&
                  this.props.selectedCompanies.length > 1 &&
                  this.props.selectedDepartments &&
                  this.props.selectedDepartments.length > 1 ? (
                    <>
                      Please filter a single company or up to 10 departments
                      within a single company to view the proportion of unique
                      users over time by department.
                    </>
                  ) : this.props.selectedCompanies &&
                    this.props.selectedCompanies.length === 1 &&
                    this.props.selectedDepartments &&
                    this.props.selectedDepartments.length === 0 ? (
                    <>
                      The chart below shows the top 10 most engaged departments
                      in the latest month. Please filter up to 10 departments to
                      view the proportion of unique users over time by
                      department.
                    </>
                  ) : this.props.selectedDepartments &&
                    this.props.selectedDepartments.length > 0 ? (
                    <>
                      Please hover over a point to display the name of the
                      entity and corresponding proportion of engaged users. The
                      grey shaded area denotes the overall proportion of engaged
                      users across the whole company, not limited to the
                      specific departments filtered in this chart.
                    </>
                  ) : null
                ) : this.props.isSingleCompanyOrSingleDeprtmentSelected ? (
                  <>
                    The chart below shows the top 10 most engaged departments in
                    the latest month. Please filter up to 10 departments to view
                    the proportion of unique users over time by department.
                  </>
                ) : (
                  <>
                    Please hover over a point to display the name of the entity
                    and corresponding proportion of engaged users. The grey
                    shaded area denotes the overall proportion of engaged users
                    across the whole company, not limited to the specific
                    departments filtered in this chart.
                  </>
                )
              ) : null}
            </div>

            <div id="IdProportionOfUniqueUsers">
              {this.IS_PARENT_ORG_LOGIN &&
              !this.props.proportionOfUniqueUsersSwitchByCompany ? (
                !this.props.isSingleCompanyOrSingleDeprtmentSelected ||
                (this.props.selectedDepartments &&
                  this.props.selectedDepartments.length > 10) ? (
                  !this.props.isSingleCompanyOrSingleDeprtmentSelected ? (
                    <div className="dataNotFound">
                      {MESSAGES.PENDING_SELECTION}
                    </div>
                  ) : (
                    <div className="dataNotFound">
                      "Please limit your selection to up to 10 departments."
                    </div>
                  )
                ) : (
                  proportionOfUniqueUsersChartRender()
                )
              ) : (
                proportionOfUniqueUsersChartRender()
              )}
            </div>
          </div>

          <div className="col-md-12 col-xl-6 col-lg1-12 mb-150">
            <div className="graphsActionContainer">
              <h3>{ENGAGEMENT_GRAPH_NAME.AVG_FREQUENCY_OF_APP_USES}</h3>
              <div className="exportContainer">
                <GraphActions
                  showExpansion={frequencyAppUsesFlag}
                  showCSVExport={frequencyAppUsesFlag}
                  filterObjectForCSV={this.props.getFilterObject}
                  showImageExport={frequencyAppUsesFlag}
                  showInfo={true}
                  from={generateFileName(
                    ENGAGEMENT_GRAPH_NAME.AVG_FREQUENCY_OF_APP_USES,
                  )}
                  apiUrl={API.ENGAGEMENTS.FREQUENCY_OF_APP_USES}
                  graphId="FrequencyOfAppUses"
                  imageName={generateFileName(
                    ENGAGEMENT_GRAPH_NAME.AVG_FREQUENCY_OF_APP_USES,
                  )}
                  infoMessage="An engaged day is one where the user accesses the app at least once."
                  handleExpand={(graphId) =>
                    this.handleExpandChart({
                      ...this.props,
                      graphTitle: ENGAGEMENT_GRAPH_NAME.OVERALL_APP_USAGE,
                      graphSubTitle:
                        ENGAGEMENT_GRAPH_NAME.AVG_FREQUENCY_OF_APP_USES,
                      screenWidth: screenWidth,
                      graph: frequencyOfAppUses(
                        window.innerWidth * 0.8,
                        window.screen.height * 0.5,
                      ),
                    })
                  }
                />
              </div>
            </div>
            <div className="graphInfoNote mt-3">
              <span>Note: </span>App usage frequency is measured in terms of
              days per engaged user on average in the given period.
            </div>
            <div id="FrequencyOfAppUses">
              {this.props.frequencyAppUsesLoader ? (
                <StackedBarChartLoader
                  width={screenWidth * 0.75}
                  height={window.screen.height * 0.46}
                />
              ) : this.props.frequencyAppUsesData &&
                this.props.frequencyAppUsesData.length >= 1 ? (
                frequencyOfAppUses(
                  screenWidth * 0.8,
                  window.screen.height * 0.52,
                )
              ) : (
                <div className="dataNotFound">{MESSAGES.NO_DATA_FOUND}</div>
              )}
            </div>
          </div>
          <div className="col-md-12 col-xl-6 col-lg1-12 mb-150">
            <div className="graphsActionContainer">
              <h3>
                {ENGAGEMENT_GRAPH_NAME.AVG_FREQUENCY_OF_FEATURE_USE_PER_MONTH}
              </h3>
              <div className="exportContainer">
                <GraphActions
                  showExpansion={frequencyFeatureUsesFlag}
                  showCSVExport={frequencyFeatureUsesFlag}
                  filterObjectForCSV={this.props.getFilterObject}
                  showImageExport={frequencyFeatureUsesFlag}
                  showInfo={true}
                  from={generateFileName(
                    ENGAGEMENT_GRAPH_NAME.AVG_FREQUENCY_OF_FEATURE_USE_PER_MONTH,
                  )}
                  apiUrl={API.ENGAGEMENTS.FREQUENCY_OF_FEATURE_USES}
                  graphId="FrequencyOfFeatureUses"
                  imageName={generateFileName(
                    ENGAGEMENT_GRAPH_NAME.AVG_FREQUENCY_OF_FEATURE_USE_PER_MONTH,
                  )}
                  infoMessage="Use this chart to assess the popularity of various features across all users over the selected period, normalised to a 30-day month."
                  handleExpand={(graphId) =>
                    this.handleExpandChart({
                      ...this.props,
                      graphTitle: ENGAGEMENT_GRAPH_NAME.OVERALL_APP_USAGE,
                      graphSubTitle:
                        ENGAGEMENT_GRAPH_NAME.AVG_FREQUENCY_OF_FEATURE_USE_PER_MONTH,
                      screenWidth: screenWidth,
                      graph: frequencyOfFeatureUses({
                        width: screenWidth * 0.9,
                        height: window.screen.height * 0.5,
                        innerRadius: 95,
                        outerRadius: 130,
                      }),
                    })
                  }
                />
              </div>
            </div>
            <div className="graphInfoNote mt-3">
              <span>Note: </span>Feature usage frequency is measured by the
              average number of days in a month that the specific feature is
              accessed per user during the filtered period.
            </div>
            <div id="FrequencyOfFeatureUses">
              {this.props.frequencyFeatureUsesLoader ? (
                <PiechartLoader
                  width={screenWidth * 0.4}
                  height={screenWidth * 0.4}
                />
              ) : this.props.frequencyFeatureUsesData &&
                this.props.frequencyFeatureUsesData.length ? (
                frequencyOfFeatureUses({})
              ) : (
                <div className="dataNotFound">{MESSAGES.NO_DATA_FOUND}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    numOfUniqueUsersData: state.EngagementDashboard.numOfUniqueUsers.data,
    numOfUniqueUsersLoader: state.EngagementDashboard.numOfUniqueUsers.loader,

    avgAppUsersByDayAndTimeData:
      state.EngagementDashboard.avgAppUsersByDayAndTime.data,
    avgAppUsersByDayAndTimeLoader:
      state.EngagementDashboard.avgAppUsersByDayAndTime.loader,

    proportionsOfUniqueUserData:
      state.EngagementDashboard.proportionsOfUniqueUser.data,
    proportionsOfUniqueUserLoader:
      state.EngagementDashboard.proportionsOfUniqueUser.loader,

    frequencyAppUsesData: state.EngagementDashboard.frequencyAppUses.data,
    frequencyAppUsesLoader: state.EngagementDashboard.frequencyAppUses.loader,

    frequencyFeatureUsesData:
      state.EngagementDashboard.frequencyFeatureUses.data,
    frequencyFeatureUsesLoader:
      state.EngagementDashboard.frequencyFeatureUses.loader,
  };
};

const mapDispatchToProps = (dispatch) => {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OverallAppUsageSection);
