import * as ActionTypes from "./AppreciationActionTypes";

export function companyFilterDropdownRequestAction (data) {
  return {
    type: ActionTypes.GET_COMPANY_FILTER_DROPDOWN_REQUEST,
    payload: data
  };
};

export function companyFilterDropdownSuccessAction (data) {
  return {
    type: ActionTypes.GET_COMPANY_FILTER_DROPDOWN_SUCCESS,
    payload: data
  };
};

export function companyFilterDropdownFailAction (data) {
  return {
    type: ActionTypes.GET_COMPANY_FILTER_DROPDOWN_FAIL,
    payload: data
  };
};

export function departmentFilterDropdownRequestAction (data) {
  return {
    type: ActionTypes.GET_DEPARTMENT_FILTER_DROPDOWN_REQUEST,
    payload: data
  };
};
  
export function departmentFilterDropdownSuccessAction (data) {
  return {
    type: ActionTypes.GET_DEPARTMENT_FILTER_DROPDOWN_SUCCESS,
    payload: data
  };
};
  
export function departmentFilterDropdownFailAction (data) {
  return {
    type: ActionTypes.GET_DEPARTMENT_FILTER_DROPDOWN_FAIL,
    payload: data
  };
};

export function employeeFilterDropdownRequestAction (data) {
  return {
    type: ActionTypes.GET_EMPLOYEES_FILTER_DROPDOWN_REQUEST,
    payload: data
  };
};
    
export function employeeFilterDropdownSuccessAction (data) {
  return {
    type: ActionTypes.GET_EMPLOYEES_FILTER_DROPDOWN_SUCCESS,
    payload: data
  };
};
    
export function employeeFilterDropdownFailAction (data) {
  return {
    type: ActionTypes.GET_EMPLOYEES_FILTER_DROPDOWN_FAIL,
    payload: data
  };
};

export function ageGroupFilterDropdownRequestAction (data) {
  return {
    type: ActionTypes.GET_AGE_GROUP_FILTER_DROPDOWN_REQUEST
  };
};
    
export function ageGroupFilterDropdownSuccessAction (data) {
  return {
    type: ActionTypes.GET_AGE_GROUP_FILTER_DROPDOWN_SUCCESS,
    payload: data
  };
};
    
export function ageGroupFilterDropdownFailAction (data) {
  return {
    type: ActionTypes.GET_AGE_GROUP_FILTER_DROPDOWN_FAIL,
    payload: data
  };
};

export function roleFilterDropdownRequestAction (data) {
  return {
    type: ActionTypes.GET_ROLE_FILTER_DROPDOWN_REQUEST
  };
};
    
export function roleFilterDropdownSuccessAction (data) {
  return {
    type: ActionTypes.GET_ROLE_FILTER_DROPDOWN_SUCCESS,
    payload: data
  };
};
    
export function roleFilterDropdownFailAction (data) {
  return {
    type: ActionTypes.GET_ROLE_FILTER_DROPDOWN_FAIL,
    payload: data
  };
};

export function tilesDropdownRequestAction (data) {
  return {
    type: ActionTypes.GET_TILES_DATA_REQUEST,
    payload: data
  };
};
    
export function tilesDropdownSuccessAction (data) {
  return {
    type: ActionTypes.GET_TILES_DATA_SUCCESS,
    payload: data
  };
};
    
export function tilesDropdownFailAction (data) {
  return {
    type: ActionTypes.GET_TILES_DATA_FAIL,
    payload: data
  };
};

export function histogramDataRequestAction (data) {
  return {
    type: ActionTypes.GET_HISTOGRAM_DATA_REQUEST,
    payload: data
  };
};
    
export function histogramDataSuccessAction (data) {
  return {
    type: ActionTypes.GET_HISTOGRAM_DATA_SUCCESS,
    payload: data
  };
};
    
export function histogramDataFailAction (data) {
  return {
    type: ActionTypes.GET_HISTOGRAM_DATA_FAIL,
    payload: data
  };
};

export function companyHistogramDataRequestAction (data) {
  return {
    type: ActionTypes.GET_COMPANY_HISTOGRAM_DATA_REQUEST,
    payload: data
  };
};
    
export function companyHistogramDataSuccessAction (data) {
  return {
    type: ActionTypes.GET_COMPANY_HISTOGRAM_DATA_SUCCESS,
    payload: data
  };
};
    
export function companyHistogramDataFailAction (data) {
  return {
    type: ActionTypes.GET_COMPANY_HISTOGRAM_DATA_FAIL,
    payload: data
  };
};

export function departmentHistogramDataRequestAction (data) {
  return {
    type: ActionTypes.GET_DEPARTMENT_HISTOGRAM_DATA_REQUEST,
    payload: data
  };
};
    
export function departmentHistogramDataSuccessAction (data) {
  return {
    type: ActionTypes.GET_DEPARTMENT_HISTOGRAM_DATA_SUCCESS,
    payload: data
  };
};
    
export function departmentHistogramDataFailAction (data) {
  return {
    type: ActionTypes.GET_DEPARTMENT_HISTOGRAM_DATA_FAIL,
    payload: data
  };
};

export function companyDrilldownHistogramDataRequestAction (data) {
  return {
    type: ActionTypes.GET_COMPANY_DRILLDOWN_DATA_REQUEST,
    payload: data
  };
};
    
export function companyDrilldownHistogramDataSuccessAction (data) {
  return {
    type: ActionTypes.GET_COMPANY_DRILLDOWN_DATA_SUCCESS,
    payload: data
  };
};
    
export function companyDrilldownHistogramDataFailAction (data) {
  return {
    type: ActionTypes.GET_COMPANY_DRILLDOWN_DATA_FAIL,
    payload: data
  };
};

export function departmentDrilldownHistogramDataRequestAction (data) {
  return {
    type: ActionTypes.GET_DEPARTMENT_DRILLDOWN_DATA_REQUEST,
    payload: data
  };
};
    
export function departmentDrilldownHistogramDataSuccessAction (data) {
  return {
    type: ActionTypes.GET_DEPARTMENT_DRILLDOWN_DATA_SUCCESS,
    payload: data
  };
};
    
export function departmentDrilldownHistogramDataFailAction (data) {
  return {
    type: ActionTypes.GET_DEPARTMENT_DRILLDOWN_DATA_FAIL,
    payload: data
  };
};

export function wordCloudDataRequestAction (data) {
  return {
    type: ActionTypes.GET_WORD_CLOUD_DATA_REQUEST,
    payload: data
  };
};
    
export function wordCloudDataSuccessAction (data) {
  return {
    type: ActionTypes.GET_WORD_CLOUD_DATA_SUCCESS,
    payload: data
  };
};
    
export function wordCloudDataFailAction (data) {
  return {
    type: ActionTypes.GET_WORD_CLOUD_DATA_FAIL,
    payload: data
  };
};

export function spiderDataRequestAction (data) {
  return {
    type: ActionTypes.GET_SPIDER_DATA_REQUEST,
    payload: data
  };
};
    
export function spiderDataSuccessAction (data) {
  return {
    type: ActionTypes.GET_SPIDER_DATA_SUCCESS,
    payload: data
  };
};
    
export function spiderDataFailAction (data) {
  return {
    type: ActionTypes.GET_SPIDER_DATA_FAIL,
    payload: data
  };
};

export function appreciationFeedRequestAction (data) {
  return {
    type: ActionTypes.GET_APPRECIATION_FEEDS_REQUEST,
    payload: data
  };
};
    
export function appreciationFeedSuccessAction (data) {
  return {
    type: ActionTypes.GET_APPRECIATION_FEEDS_SUCCESS,
    payload: data
  };
};
    
export function appreciationFeedFailAction (data) {
  return {
    type: ActionTypes.GET_APPRECIATION_FEEDS_FAIL,
    payload: data
  };
};

export function sunburstDataRequestAction (data) {
  return {
    type: ActionTypes.GET_SUNBURST_DATA_REQUEST,
    payload: data
  };
};
export function treeMapDataRequestAction (data) {
  return {
    type: ActionTypes.GET_TREE_MAP_DATA_REQUEST,
    payload: data
  };
};
    
export function sunburstDataSuccessAction (data) {
  return {
    type: ActionTypes.GET_SUNBURST_DATA_SUCCESS,
    payload: data
  };
}
export function treeMapDataSuccessAction (data) {
  return {
    type: ActionTypes.GET_TREE_MAP_DATA_SUCCESS,
    payload: data
  };
};
    
export function sunburstDataFailAction (data) {
  return {
    type: ActionTypes.GET_SUNBURST_DATA_FAIL,
    payload: data
  };
};

export function strengthEmployeeListRequestAction (data) {
  return {
    type: ActionTypes.GET_STRENGTH_EMPLOYEE_LIST_REQUEST,
    payload: data
  };
};
    
export function strengthEmployeeListSuccessAction (data) {
  return {
    type: ActionTypes.GET_STRENGTH_EMPLOYEE_LIST_SUCCESS,
    payload: data
  };
};
    
export function strengthEmployeeListFailAction (data) {
  return {
    type: ActionTypes.GET_STRENGTH_EMPLOYEE_LIST_FAIL,
    payload: data
  };
}
export function treeMapDataFailAction (data) {
  return {
    type: ActionTypes.GET_TREE_MAP_DATA_FAIL,
    payload: data
  };
};

export function resetEmployeeListAction () {
  return {
    type: ActionTypes.RESET_STRENGTH_EMPLOYEE_LIST
  };
}

export function appreciationFlowRequestAction (data) {
  return {
    type: ActionTypes.GET_APPRECIATION_FLOW_REQUEST,
    payload: data
  };
};
    
export function appreciationFlowSuccessAction (data) {
  return {
    type: ActionTypes.GET_APPRECIATION_FLOW_SUCCESS,
    payload: data
  };
};
    
export function appreciationFlowFailAction (data) {
  return {
    type: ActionTypes.GET_APPRECIATION_FLOW_FAIL,
    payload: data
  };
}

export function appreciationFlowHeatmapRequestAction (data) {
  return {
    type: ActionTypes.GET_APPRECIATION_FLOW_HEATMAP_REQUEST,
    payload: data
  };
};
    
export function appreciationFlowHeatmapSuccessAction (data) {
  return {
    type: ActionTypes.GET_APPRECIATION_FLOW_HEATMAP_SUCCESS,
    payload: data
  };
};
    
export function appreciationFlowHeatmapFailAction (data) {
  return {
    type: ActionTypes.GET_APPRECIATION_FLOW_HEATMAP_FAIL,
    payload: data
  };
}

export function appreciationFlowHeatmapMessageExchangeRequestAction (data) {
  return {
    type: ActionTypes.GET_APPRECIATION_FLOW_HEATMAP_MESSAGE_EXCHANGE_REQUEST,
    payload: data
  };
};
    
export function appreciationFlowHeatmapMessageExchangeSuccessAction (data) {
  return {
    type: ActionTypes.GET_APPRECIATION_FLOW_HEATMAP_MESSAGE_EXCHANGE_SUCCESS,
    payload: data
  };
};
    
export function appreciationFlowHeatmapMessageExchangeFailAction (data) {
  return {
    type: ActionTypes.GET_APPRECIATION_FLOW_HEATMAP_MESSAGE_EXCHANGE_FAIL,
    payload: data
  };
}

export function appreciationFlowHeatmapMessageExchangeResetAction () {
  return {
    type: ActionTypes.GET_APPRECIATION_FLOW_HEATMAP_MESSAGE_EXCHANGE_RESET
  };
}

export function setEndDateRangeFilterAction (data) {
  return {
    type: ActionTypes.SET_FILTER_END_DATE,
    payload: data
  };
}

export function resetAppreciationReducerAction () {
  return {
    type: ActionTypes.RESET_APPRECIATION_DASHBOARD_REDUCER
  };
}