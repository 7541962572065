import { takeEvery, put, call } from "redux-saga/effects";
import { LOGIN_REQUEST } from "../Actions/LoginActionTypes"; 
import LoginApi from "../../../Api/LoginApi";
import { loginSuccessAction, loginFailAction } from "../Actions/LoginActions";
import { LOGIN_FAIL_MESSAGE } from "../../../constants/constants";
export function * loginAsync( action ) {

  const { response, error } = yield call(
    LoginApi.loginRequest,
    action.payload
  );
  if( response ) {
    if( response.status_code === 200 ) {
      yield put( loginSuccessAction( response.data ) );
    } else if( response.status_code === 400 ) {
      yield put( loginFailAction( { statusCode: response.status_code, message: LOGIN_FAIL_MESSAGE } ) );
    } else if( response.status_code === 401 ) {
      yield put( loginFailAction( { message: LOGIN_FAIL_MESSAGE } ) );
    } 
  } else {
    yield put( loginFailAction( { statusCode: error.response.status, message: LOGIN_FAIL_MESSAGE } ) );
  }
}

export default function * rootLoginSaga() {
  yield takeEvery( LOGIN_REQUEST, loginAsync );
}