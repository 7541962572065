import React from "react";

// Common Loader Container
const TabLoader = ( { isloading, loadingSizeCLass } ) => {
  return (
    <div className="tabskeleton">  
      <div className="tabskeltontabOuter">
        <div className="tabbtn animated-background lg-width"></div> 
        <div className="tabbtn animated-background lg-width"></div> 
      </div> 
      <div className="rowOuter">
        <div className="tabRow animated-background lg-width"> </div>
        <div className="tabRow animated-background lg-width"></div>
        <div className="tabRow animated-background lg-width"></div>
      </div>  
    </div>
     
  );
};

export default TabLoader;