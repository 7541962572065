import * as ActionTypes from "./EngagementActionType";


export function engagementIsEventsEnabledReq(data) {
  return {
    type: ActionTypes.IS_EVENTS_ENABLED_REQ,
    payload: data,
  };
}

export function engagementIsEventsEnabledSuccess(data) {
  return {
    type: ActionTypes.IS_EVENTS_ENABLED_SUCCESS,
    payload: data,
  };
}

export function engagementIsEventsEnabledFail(data) {
  return {
    type: ActionTypes.IS_EVENTS_ENABLED_FAIL,
    payload: data,
  };
}

export function engagementTilesReq(data) {
  return {
    type: ActionTypes.TILES_REQ,
    payload: data,
  };
}

export function engagementTilesSuccess(data) {
  return {
    type: ActionTypes.TILES_SUCCESS,
    payload: data,
  };
}

export function engagementTilesFail(data) {
  return {
    type: ActionTypes.TILES_FAIL,
    payload: data,
  };
}

export function engagementNumOfUniqueUsersReq(data) {
  return {
    type: ActionTypes.NUMBER_OF_UNIQUE_USERS_REQ,
    payload: data,
  };
}

export function engagementNumOfUniqueUsersSuccess(data) {
  return {
    type: ActionTypes.NUMBER_OF_UNIQUE_USERS_SUCCESS,
    payload: data,
  };
}

export function engagementNumOfUniqueUsersFail(data) {
  return {
    type: ActionTypes.NUMBER_OF_UNIQUE_USERS_FAIL,
    payload: data,
  };
}

export function engagementAvgAppUsersByDayAndTimeReq(data) {
  return {
    type: ActionTypes.AVG_APP_USERS_BY_DAY_AND_TIME_REQ,
    payload: data,
  };
}

export function engagementAvgAppUsersByDayAndTimeSuccess(data) {
  return {
    type: ActionTypes.AVG_APP_USERS_BY_DAY_AND_TIME_SUCCESS,
    payload: data,
  };
}

export function engagementAvgAppUsersByDayAndTimeFail(data) {
  return {
    type: ActionTypes.AVG_APP_USERS_BY_DAY_AND_TIME_FAIL,
    payload: data,
  };
}

export function engagementProportionOfUniqueUsersReq(data) {
  return {
    type: ActionTypes.PROPORTION_OF_UNIQUE_USER_REQ,
    payload: data,
  };
}

export function engagementProportionOfUniqueUsersSuccess(data) {
  return {
    type: ActionTypes.PROPORTION_OF_UNIQUE_USER_SUCCESS,
    payload: data,
  };
}

export function engagementProportionOfUniqueUsersFail(data) {
  return {
    type: ActionTypes.PROPORTION_OF_UNIQUE_USER_FAIL,
    payload: data,
  };
}

export function engagementFrequencyOfAppUsesReq(data) {
  return {
    type: ActionTypes.FREQUENCY_OF_APP_USES_REQ,
    payload: data,
  };
}

export function engagementFrequencyOfAppUsesSuccess(data) {
  return {
    type: ActionTypes.FREQUENCY_OF_APP_USES_SUCCESS,
    payload: data,
  };
}

export function engagementFrequencyOfAppUsesFail(data) {
  return {
    type: ActionTypes.FREQUENCY_OF_APP_USES_FAIL,
    payload: data,
  };
}

export function engagementFrequencyOfFeatureUsesReq(data) {
  return {
    type: ActionTypes.FREQUENCY_OF_FEATURE_USES_REQ,
    payload: data,
  };
}

export function engagementFrequencyOfFeatureUsesSuccess(data) {
  return {
    type: ActionTypes.FREQUENCY_OF_FEATURE_USES_SUCCESS,
    payload: data,
  };
}

export function engagementFrequencyOfFeatureUsesFail(data) {
  return {
    type: ActionTypes.FREQUENCY_OF_FEATURE_USES_FAIL,
    payload: data,
  };
}

export function engagementPointEarningCategoriesReq(data) {
  return {
    type: ActionTypes.GET_POINT_EARNING_CATEGORIES_REQ,
    payload: data,
  };
}

export function engagementPointEarningCategoriesSuccess(data) {
  return {
    type: ActionTypes.GET_POINT_EARNING_CATEGORIES_SUCCESS,
    payload: data,
  };
}

export function engagementPointEarningCategoriesFail(data) {
  return {
    type: ActionTypes.GET_POINT_EARNING_CATEGORIES_FAIL,
    payload: data,
  };
}

export function engagementPointEarningCategoriesTrendReq(data) {
  return {
    type: ActionTypes.GET_POINT_EARNING_CATEGORIES_TREND_REQ,
    payload: data,
  };
}

export function engagementPointEarningCategoriesTrendSuccess(data) {
  return {
    type: ActionTypes.GET_POINT_EARNING_CATEGORIES_TREND_SUCCESS,
    payload: data,
  };
}

export function engagementPointEarningCategoriesTrendFail(data) {
  return {
    type: ActionTypes.GET_POINT_EARNING_CATEGORIES_TREND_FAIL,
    payload: data,
  };
}

export function engagementTopPointEarningReq(data) {
  return {
    type: ActionTypes.GET_TOP_POINT_EARNER_REQUEST,
    payload: data,
  };
}

export function engagementTopPointEarningReqSuccess(data) {
  return {
    type: ActionTypes.GET_TOP_POINT_EARNER_SUCCESS,
    payload: data,
  };
}

export function engagementTopPointEarningReqFail(data) {
  return {
    type: ActionTypes.GET_TOP_POINT_EARNER_FAIL,
    payload: data,
  };
}

export function setP2P(data) {
  return {
    type: ActionTypes.SET_IS_P2P_FLAG,
    payload: data,
  };
}

export function engagementTopPointEarningDetailsReq(data) {
  return {
    type: ActionTypes.GET_TOP_POINT_EARNER_DETAILS_REQUEST,
    payload: data,
  };
}

export function engagementTopPointEarningDetailsSuccess(data) {
  return {
    type: ActionTypes.GET_TOP_POINT_EARNER_DETAILS_SUCCESS,
    payload: data,
  };
}

export function engagementTopPointEarningDetailsFail(data) {
  return {
    type: ActionTypes.GET_TOP_POINT_EARNER_DETAILS_FAIL,
    payload: data,
  };
}

export function engagementEventsResponsesReq(data) {
  return {
    type: ActionTypes.EVENTS_RESPONSES_REQ,
    payload: data,
  };
}

export function engagementEventsResponsesSuccess(data) {
  return {
    type: ActionTypes.EVENTS_RESPONSES_SUCCESS,
    payload: data,
  };
}

export function engagementEventsResponsesFail(data) {
  return {
    type: ActionTypes.EVENTS_RESPONSES_FAIL,
    payload: data,
  };
}

export function engagementNotificationsReq(data) {
  return {
    type: ActionTypes.NOTIFICATIONS_REQ,
    payload: data,
  };
}

export function engagementNotificationsSuccess(data) {
  return {
    type: ActionTypes.NOTIFICATIONS_SUCCESS,
    payload: data,
  };
}

export function engagementNotificationsFail(data) {
  return {
    type: ActionTypes.NOTIFICATIONS_FAIL,
    payload: data,
  };
}