import React, {Fragment} from "react";
import * as d3 from "d3";
import { THEME_COLORS } from "./../../constants/constants";

// Heatmap Chart
class HorizontalBarChart extends React.Component {
  componentDidMount() {
    this.loadChart1();
  }

  componentDidUpdate() {
    this.loadChart1();
  }

  loadChart1 = () => {
    let isExpandScreen = this.props.expandScreen;
    let legendNames = this.props.legendNames;

    let data = this.props.data;
    let graphName = this.props.graphName;
    let xMaxValue = this.props.xMaxValue;
    let maxLength = this.props.maxLength;

    //sort bars based on redemption_age
    data = data.sort(function (a, b) {
        return d3.ascending(a.redemption_age, b.redemption_age);
    })


    let margin = {top: 20, right: 20, bottom: 50, left: maxLength * 8.5},
    width = this.props.width - (margin.left+10) - margin.right,
    height = this.props.height - margin.top - margin.bottom;

    d3.select(this.node).select("svg").remove();

    let svg = d3.select(this.node).append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    let x = d3.scaleLinear()
        .range([0, width])
        .domain([0, d3.max(data, function (d) {
            // return d.redemption_age||d.unredeemed_age;
            return xMaxValue+((xMaxValue*10)/100);
        })]);

    // let y = d3.scaleOrdinal()
    let y = d3.scaleBand()
// --     .rangeRoundBands([height, 0], .1)
        .range([height, 0], .1)
        .domain(data.map(function (d) {
            return d.name;
        }));

    //make y axis to show bar names
    let yAxis = d3.axisLeft(y);

    let yAxisElement = svg.append("g").attr("class", "y-axis").call(yAxis);

    let xAxisElement = svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x));

    let bars = svg.selectAll(".bar")
        .data(data)
        .enter()
        .append("g")
        .attr("class", "mainbar");

    // let div = d3.select(this.node).append("div")
    //   .attr("class", "redemption-tooltip")
    //   .style("opacity", 0);

    let mousemove = function (d) {
      let div = d3.select(`#tooltip-${graphName}`)
      .attr("class", "redemption-tooltip")
      .style("opacity", 0);

      let e = 0, x = 0, y = 0;
      e = window.event, x = e.clientX, y = e.clientY-40;

      div.transition().duration(200).style("opacity", 1.0);

      div.html( `${legendNames[2]}: ${d.name} <br/>
          <span style="color:${THEME_COLORS[9]}">${legendNames[0]}: ${d.redemption_age === 0 ? 'NA' : d.redemption_age} </span><br/>
          <span style="color:${THEME_COLORS[8]}">${legendNames[1]}: ${d.unredeemed_age} </span><br/>`)
        .style("top", `${isExpandScreen ? y-50 : y-50}px`)
        .style("left", `${isExpandScreen? x-100: x-100}px`)
      ;
    }

    let mouseout = function(d) {
      let div = d3.select(`#tooltip-${graphName}`)
      div.transition().duration(500).style("opacity", 0);
    }
        
      //append rects
    bars.append("rect")
      .attr("class", "bar")
      .style('fill', THEME_COLORS[9])
      .attr("y", function (d) {
          return y(d.name);
      })
      .attr("height", y.bandwidth()-5)
      .attr("x", 0)
      .attr("width", function (d) {
          return x(d.redemption_age);
      })
      .on("mousemove", mousemove)
      .on("mouseout", mouseout);

      svg.selectAll("text")
      .style("font-size", "12px")
      .style("fill", "#595959" )
      .style("font-family", "Roboto")
      .style("font-weight", "500" )

    //add a redemption_age label to the right of each bar
    bars.append("text")
      .attr("class", "label")
      .attr("y", function (d) { return y(d.name) + y.bandwidth() / 2 + 4; })
      .attr("x", function (d) { return x(d.redemption_age) + 3; })
      .text(function (d) { return d.redemption_age === 0 ? 'NA' : d.redemption_age })
      .style("font-size", "15px")
      .style("fill", THEME_COLORS[9] )
      .style("font-family", "Roboto")
      .style("font-weight", "1000" )
      .on("mousemove", mousemove)
      .on("mouseout", mouseout);

    // Custom textual account 
    bars.append("text")
      .attr("class", "label")
      //y position of the label is halfway down the bar
      .attr("y", function (d) {
          return y(d.name) + y.bandwidth() / 2 + 4;
      })
      //x position is 3 pixels to the right of the bar
      .attr("x", function (d) {
          return x(d.unredeemed_age)-5;
      })
      .text(function (d) {
          return 'X';
      })
      .style("font-size", "15px")
      .style("fill", THEME_COLORS[8] )
      .style("font-family", "Roboto")
      .style("font-weight", "1000" )
      .on("mousemove", mousemove)
      .on("mouseout", mouseout);
      
      bars.append("text")
      .attr("class", "label")
      //y position of the label is halfway down the bar
      .attr("y", function (d) {
          return y(d.name) + y.bandwidth() / 2 + 4;
      })
      //x position is 3 pixels to the right of the bar
      .attr("x", function (d) {
          // return width-margin.right;
          return x(d.unredeemed_age)+ 10;
      })
      .text(function (d) {
          return d.unredeemed_age;
      })
      .style("font-size", "15px")
      .style("fill", THEME_COLORS[8] )
      .style("font-family", "Roboto")
      .style("font-weight", "1000" )
      .on("mousemove", mousemove)
      .on("mouseout", mouseout);

      svg.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -margin.left-2)
      .attr("x", 0 - ((height) / 2))
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .style("font-family", "roboto")
      .style("font-weight", 500)
      .style("font-size", 14)
      .style("fill", "#758088")
      .text(this.props.yAxisLabel);

    svg.append("text")
      .attr("transform",
        "translate(" + ((width) / 2) + " ," +
        (height + (margin.bottom-5 ) ) + ")")
      .style("text-anchor", "middle")
      .style("font-family", "roboto")
      .style("font-weight", 500)
      .style("font-size", 14)
      .style("fill", "#758088")
      .text( this.props.xAxisLabel );

      xAxisElement.selectAll('.tick').selectAll('text').attr("transform", "rotate(-45)");
      xAxisElement.selectAll('.tick').selectAll('text').attr("x", "-15").attr("y", "10");

      // yAxisElement.selectAll('.tick').selectAll('line').attr("stroke", "");
      // yAxisElement.selectAll('.tick').selectAll('text').attr("x", "5").attr("y", "-15");
      
      function wrap (text, width) {
        text.each(function () {
          var text = d3.select(this);
          var words = text.text().split(/\s+/).reverse();
          var word;
          var line = [];
          var lineNumber = 0;
          var lineHeight = 1.1; // ems
          var y = text.attr("y");
          var dy = parseFloat(text.attr("dy"));
          var tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");
          // eslint-disable-next-line no-cond-assign
          while(word = words.pop()) {
            line.push(word);
            tspan.text(line.join(" "));
            if(tspan.node().getComputedTextLength() > width) {
              line.pop();
              tspan.text(line.join(" "));
              line = [word];
              tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
            }
          }
        });
      }

  }
    
  render() {
      let graphName = this.props.graphName;
      return (
        <div ref={(node) => { this.node = node; }} >
          <div className="legendContainer" style={{marginTop: "19px"}}>
                {this.props.legendNames ? <div className="sunbustLegend">
                    {
                        this.props.legendNames.map((entry, index) => {
                        return (<span className="legendItem" style={{ display: "inline-block", marginRight: "10px" }} key={`item-${index}`}
                        >
                        {index < 2 ?
                          <React.Fragment>
                            {index != 1 ?<label style={{ backgroundColor: `${this.props.colorCodes[index]}`, width: "15px", height: "12px", margin: "3px 3px 0px 3px", fontSize: "15px", paddingLeft: "0px", color: "#595959", fontFamily: "roboto", fontWeight: "500", display:"inline-block" }} ></label> : <label style={{ border:"1px solid #595959", color: `${this.props.colorCodes[index]}`, width: "20px", height: "21px", margin: "3px 3px 5px 3px", fontSize: "13px", paddingLeft: "0px", fontFamily: "roboto", fontWeight: "500", display:"inline-block"}} > X </label> }
                          <label style={{ margin: "0px", fontSize: "12", fontWeight: "400",/* color: "#758088",*/fontFamily: "roboto" }}>{entry}</label></React.Fragment>
                        : null }
                        </span>);
                        })
                    }
                    </div>
                    : <div className="dataNotFound">
                    No Legends Found
                </div>
                }
                </div>

            <div>
              <div id={`tooltip-${graphName}`} ></div>
            </div>
        </div>
      );
    }
  }
    
export default HorizontalBarChart;