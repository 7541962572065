import React from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { hideDashboardOverlayAction } from "../../Common/CommonStore/CommonActions";
import Appreciation from "./Components/Appreciation/AppreciationContainer";
import RedemptionPattern from "./Components/RedemptionPattern/RedeptionPatternContainer";
import Engagement from "./Components/Engagement/EngagementContainer";
import { SideBar, Header } from "../../UI";
// Dashboard Container
class DashboardContainer extends React.Component {

  render() {
    return (
      <React.Fragment>
        <Header
        />
        <div className="body-container">
          <SideBar
          />
          <div className={this.props.expandedSideBar ? "outer-container" : "outer-container active"} id="outer-container" >
            <div className="container-fluid" id="container-fluid">
              <Switch>
                <Route exact path={`${this.props.match.url}appreciation`} component={Appreciation} />
                <Route exact path={`${this.props.match.url}redemption`} component={RedemptionPattern} />
                <Route exact path={`${this.props.match.url}engagement`} component={Engagement} />
              </Switch>
            </div>

          </div>

        </div>
        <div className={`dashBoardOverlay ${!this.props.dashboardOverlay ? "hide" : ""}`} onClick={() => this.props.hideDashboardOverlay()} ></div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    dashboardOverlay: state.Common.dashboardOverlay,
    expandedSideBar: state.Common.expandedSideBar

  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideDashboardOverlay: () => dispatch( hideDashboardOverlayAction() )
  };
};

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( DashboardContainer ) );