import React from "react";
import { GraphActions, MultiSelect, Table, TableLoader } from "../../../../../UI";
import { WordCloud, PieChart, PieChartD3, TreeMapChart, PiechartLoader, TreeMapChartLoader } from "../../../../../Widgets";
import { connect } from "react-redux";
import InputRange from "rc-slider";
import Switch from "react-switch";
import "rc-slider/assets/index.css";
import { GRAPH_IDS } from "../../../../../constants/constants";
import * as AppreciationActions from "../Actions/AppreciationActions";

const handleExpandChart = (props, graphName, state, departmentList = null, strengthSelectionData = null) => {
  let graph = "";
  if(graphName === "wordCloudRecipents") {
    graph =
      <div style={{ width: window.screen.width * 0.8 }} >
        <WordCloud
          wordsData={props.centerofInfluenceData.wordcloud.receipents}
          maxValue={props.centerofInfluenceData.wordcloud.receipentMax}
          isGiver={false}
          isReceipents={true}
          width={window.screen.width * 0.84}
          height={window.screen.height * 0.6}
          lowColor={"#91c8ed"}
          highColor={"#0b2d44"}
          maxFontSize={50}
          minFontSize={10}
          handleWordClicked={(e) => props.handleWordClicked(e)}
          clickEvent={false}
        />
      </div>;
  } else if(graphName === "wordCloudGiver") {
    graph =
      <div style={{ width: window.screen.width * 0.8 }}>

        <WordCloud
          wordsData={props.centerofInfluenceData.wordcloud.giver}
          isGiver={true}
          isReceipents={false}
          lowColor={"#fad1d1"}
          highColor={"#ea2c2d"}
          maxFontSize={50}
          minFontSize={10}
          width={window.screen.width * 0.84}
          height={window.screen.height * 0.6}
          maxValue={props.centerofInfluenceData.wordcloud.giverMax}
          handleWordClicked={(e) => props.handleWordClicked(e)}
          clickEvent={false}
        />
      </div>;
  } else if(graphName === GRAPH_IDS.SUNBURST_CHART) {
    graph = <div className="scroll-auto text-center" id={GRAPH_IDS.SUNBURST_CHART} style={{ height: window.screen.height * 0.55 }} >
      <PieChartD3
        height={window.screen.height * 0.7}
        width={window.screen.width * 0.5}
        data={props.centerofInfluenceData.sunburst.parentStrengths}
        ClickedEvent={false}
        legendDepartmentList={departmentList}
        childrenData={strengthSelectionData}
        myState={state}
      />
      <div className="sunburst-container col-sm-3 text-left mb-5 mt-5" style={{ float: "right" }}>
        <h6 className="mb-3">Department Legends</h6>
        {departmentList.length > 0
          ? <ul className="strengthName scroll-auto" style={{ flexWrap: "wrap", height: window.screen.height * 0.45, alignContent: "left", float: "left" }}>
            {
              departmentList.map((entry, index) => {
                return (<li key={`item-${index}`}
                > <label>D{index + 1}: {entry}</label></li>);
              })
            }
          </ul>
          : <div className="dataNotFound">
            No Department Legends Found
          </div>
        }
      </div>
    </div>;
  } else if(graphName === "byStrengthsAllRounders") {
    graph =
      <div className="treemap-expand scroll-auto" style={{ width: window.screen.width * 0.83, height: window.screen.height * 0.51 }}>
        {props.strengthData.length > 0
          ? <ul style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }} className="strengthName">
            {
              props.strengthData.map((entry, index) => {
                return (<li style={{ padding: "5px 15px 0px 0px" }} key={`item-${index}`}
                > <label style={{ fontSize: "15px", paddingLeft: "0px", color: "#595959", fontFamily: "'Roboto', sans-serif", fontWeight: 500 }}>S{index + 1}: {entry.name}</label></li>);
              })
            }
          </ul>
          : <div className="dataNotFound">
            No Legends Found
          </div>
        }
        <TreeMapChart
          data={props.treeMapData}
          width={window.screen.width * 0.80}
          height={window.screen.height * 0.32}
          graphName={"treemap-expanded"}
          strengthData={props.strengthData}
        />
      </div>;
  }
  props.showEventModal({
    showEventModal: true,
    eventModalTitle: "",
    eventModalBody: "",
    showEventModalButtonOne: false,
    showEventModalButtonTwo: false,
    buttonOneEventModalHandler: () => props.hideEventModal(),
    buttonTwoEventModalHandler: null,
    buttonOneEventModalName: "",
    buttonTwoEventModalName: "",
    graphContent: graph,
    graphTitle: props.graphTitle,
    graphSubTitle: props.graphSubTitle,
    showGraph: true,
    graphName: "Bar Chart",
    graphId: ""
  });
};

class CenterofInfluence extends React.Component {
  constructor() {
    super();
    this.state = {
      value: 1,
      strengthMultiSelect: [],
      strengths: [],
      showMoreStrength: false,
      isFilterApplied: false,
      employeeList: {
        strengthName: "",
        departmentName: ""
      },
      rangeSlidermaxValue: 1,
      sunburstTitle: {
        strengthName: "",
        departmentName: ""
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.strengthData && this.props.strengthData != nextProps.strengthData) {
      let updateData = nextProps.strengthData.map(strength => {
        return ({ ...strength, value: strength.label });
      });
      this.setState({
        ...this.state,
        strengths: updateData,
        rangeSlidermaxValue: nextProps.maxStrengthCount,
        sunburstTitle: {
          strengthName: "",
          departmentName: ""
        }
      });
    }
  }

  handleShowMoreMultiSelect = () => {
    this.setState({
      ...this.state,
      showMoreStrength: true
    });
  }

  handleStrengthClicked = (clickedPayload) => {
    let { strengthId, deptId, name, parentStrength } = clickedPayload;
    let apiPayload = {
      strength_id: strengthId,
      department_id: deptId || null
    };
    this.setState({
      ...this.state,
      employeeList: {
        ...this.state.employeeList,
        strengthName: parentStrength || name,
        departmentName: parentStrength ? name : null
      }
    }, () => {
      this.props.strengthEmployeeListRequest({ ...this.props.getFilterObject(), ...apiPayload });
    });
  }
  employeeClickHandler = (employeClicked) => {
    let payload = {
      ...employeClicked,
      name: `${employeClicked.receiver_first_name} ${employeClicked.receiver_last_name}`
    };
    this.props.handleWordClicked(payload);
  }
  closeShowMore = () => {
    this.setState({
      ...this.state,
      showMoreStrength: false
    });
  }
  handleStrengthSearch = (e) => {
    e.preventDefault();
    let payload = {
      strengths: this.props.strengthMultiSelect.map(strength => strength.name)
    };
    this.props.handleTreeMap(payload);
  }

  handleStrengthClickedSunburst = (clickedPayload) => {
    if(clickedPayload) {
      this.setState({
        ...this.state,
        sunburstTitle: {
          ...this.state.sunburstTitle,
          strengthName: clickedPayload.parentStrength ? clickedPayload.parentStrength : clickedPayload.name,
          departmentName: clickedPayload.parentStrength ? clickedPayload.name : "",
          departmentId: clickedPayload.dept_id
        }
      }, () => {
        this.props.handleStrengthClicked(clickedPayload);
      });
    } else {
      this.setState({
        ...this.state,
        sunburstTitle: {
          ...this.state.sunburstTitle,
          strengthName: "",
          departmentName: ""
        }
      }, () => {
        this.props.resetEmployeeList();
      })
    }
  }

  departmentSelection = () => {
    let departmentName = [];
    if(this.props.centerofInfluenceData.sunburst.parentStrengths.children.length > 0) {
      this.props.centerofInfluenceData.sunburst.parentStrengths.children.forEach((deptData, index) => {
        deptData.children.forEach((dept, childIndex) => {
          if(!departmentName.includes(dept.name)) {
            departmentName.push(dept.name);
          }
        });
      });
    }
    return departmentName;
  }

  strengthSelection = () => {
    let selectedStrengthData = {};
    if(this.props.centerofInfluenceData.sunburst.parentStrengths.children.length > 0) {
      this.props.centerofInfluenceData.sunburst.parentStrengths.children.forEach((deptData, index) => {
        if(deptData.name === this.state.sunburstTitle.strengthName) {
          selectedStrengthData = { ...deptData };
        }
      });
    }
    return selectedStrengthData;
  }
  render() {
    let screenWidth = window.screen.width;
    if(document.getElementById("tilesSection")) {
      screenWidth = document.getElementById("tilesSection").offsetWidth;
    }
    let graphTitle = "Center of Influence";
    let wordCloudNameGiver = "Top Appreciation Givers";
    let wordCloudNameRecipents = "Top Appreciation Recipients";
    const onSwitchColorCode = "#888888";
    const maxStrengthCount = this.state.rangeSlidermaxValue;
    let marks = {};
    let i;

    for(i = 1; i <= maxStrengthCount; i++) {
      marks[i] = i.toString();
    }

    if(this.state.showMoreStrength) {
      document.body.addEventListener("click", this.closeShowMore, true);
    }
    let sunburstTitle = "Please click on a Strength/Strength & Department to view corresponding employee list. Click on \"Strengths\" to revert to the original chart.";
    if(this.state.sunburstTitle.strengthName && this.state.sunburstTitle.departmentName) {
      sunburstTitle = `Strength selected - ${this.state.sunburstTitle.strengthName}; Department selected - ${this.state.sunburstTitle.departmentName}. Click on "Strengths" to revert to the original chart.`;
    } else if(this.state.sunburstTitle.strengthName && !this.state.sunburstTitle.departmentName) {
      sunburstTitle = `Strength selected - ${this.state.sunburstTitle.strengthName}. Click on "Strengths" to revert to the original chart.`;
    }

    let employeeListTitle = <h3></h3>;
    if(this.props.centerofInfluenceData.employeeStrength.employeesList.length > 0) {
      if(this.state.sunburstTitle.strengthName && this.state.sunburstTitle.departmentName) {
        employeeListTitle = <h3> Strength: <span className="strengthBydept" >{this.state.sunburstTitle.strengthName}</span>  Department: <span className="strengthBydept">{this.state.sunburstTitle.departmentName}</span> </h3>;
      } else if(this.state.sunburstTitle.strengthName && !this.state.sunburstTitle.departmentName) {
        employeeListTitle = <h3> Strength: <span className="strengthBydept">{this.state.sunburstTitle.strengthName}</span> </h3>;
      }
    } else {
      employeeListTitle = <h3></h3>;
    }

    let legendDepartmentList = this.departmentSelection();

    return (
      <div className="centerOfInfluence">
        <div className="row" >
          <div className="col-sm-12">
            <div className="titleOuter">
              <h2>Center <span> of </span> Influence</h2>
            </div>
          </div>
        </div>
        <div className="row d-flex" >
          <div className=" col-md-12 col-lg-6 d-flex flex-column" >
            <div className="graphsActionContainer">
              <h3>{wordCloudNameRecipents}</h3>
              <div className="exportContainer">
                <GraphActions 
                  showExpansion={this.props.centerofInfluenceData.wordcloud.receipents.length > 0}
                  showCSVExport={true}
                  showImageExport={this.props.centerofInfluenceData.wordcloud.receipents.length > 0}
                  showInfo={true}
                  from={this.props.from}
                  apiUrl={"auth/graph/info"}
                  parameter={"received"}
                  filterObjectForCSV={this.props.getFilterObject}
                  graphId="wordCloudByRecipents"
                  imageName={`${graphTitle}-${wordCloudNameRecipents}`}
                  infoMessage={"The size of the name is proportional to the # of appreciations received by the individual. The bigger the name, the more appreciations the person has received."}
                  handleExpand={ () => handleExpandChart({ ...this.props, graphTitle: graphTitle, graphSubTitle: wordCloudNameRecipents }, "wordCloudRecipents") } 
                /> 
              </div>
            </div>
            <div className="graphInfoNote">
              <span>Note: </span>Please click on a name to assess the individual's strengths vs the company benchmark.
            </div>
            <div className="cloud-container" id="wordCloudByRecipents" style={{ width: this.props.screenResolution.width * 0.4 }} >
              
              <WordCloud 
                wordsData={this.props.centerofInfluenceData.wordcloud.receipents}
                maxValue ={this.props.centerofInfluenceData.wordcloud.receipentMax}
                isGiver = { false }
                isReceipents={true}
                width= { screenWidth * 0.5 }
                height={ window.screen.height * 0.4 }
                maxFontSize={30}
                minFontSize={5}
                loader= {this.props.centerofInfluenceData.wordcloud.loader}
                lowColor={"#91c8ed"}
                highColor={"#0b2d44"}
                handleWordClicked = { (e) => this.props.handleWordClicked(e) }
                clickEvent = { true }
              />
            </div>
              
          </div>
          <div className="col-md-12 col-lg-6 d-flex flex-column" >
            <div className="graphsActionContainer">
              
              <h3>{wordCloudNameGiver}</h3>
              <div className="exportContainer">
                <GraphActions 
                  showExpansion={this.props.centerofInfluenceData.wordcloud.giver.length > 0}
                  showCSVExport={true}
                  showImageExport={this.props.centerofInfluenceData.wordcloud.giver.length > 0}
                  apiUrl={"auth/graph/info"}
                  from={this.props.from}
                  parameter={"given"}
                  filterObjectForCSV={this.props.getFilterObject}
                  showInfo={true}
                  graphId="wordCloudByGiver"
                  imageName={`${graphTitle}-${wordCloudNameGiver}`}
                  infoMessage={"The size of the name is proportional to the # of appreciations given by the individual. The bigger the name, the more appreciations the person has given."}
                  handleExpand={ () => handleExpandChart({ ...this.props, graphTitle: graphTitle, graphSubTitle: wordCloudNameGiver }, "wordCloudGiver") } 
  
                /> 
              </div>
            </div>
            <div className="graphInfoNote">
              <span>Note: </span> Please click on a name to assess the individual's strengths vs the company benchmark.
            </div>
            <div className="cloud-container" id="wordCloudByGiver" style={{ width: this.props.screenResolution.width * 0.4 }}>
              <WordCloud 
                wordsData={this.props.centerofInfluenceData.wordcloud.giver}
                maxValue ={this.props.centerofInfluenceData.wordcloud.giverMax}
                isGiver = { true }
                isReceipents={false}
                width= { screenWidth * 0.5 }
                maxFontSize={30}
                minFontSize={5}
                height={ window.screen.height * 0.4 }
                loader= {this.props.centerofInfluenceData.wordcloud.loader}
                lowColor={"#fad1d1"}
                highColor={"#ea2c2d"}
                handleWordClicked = { (e) => this.props.handleWordClicked(e) }
                clickEvent = { true }
              />
            </div> 
          </div>
        </div>

        <div className="row bystrenghDeptDrillDown" >
          <div className="col-sm-12" >
            <div className="graphsActionContainer">
              <h3> By Strength <span> and </span>  Department </h3>
              <div className="exportContainer">
                <GraphActions
                  showExpansion={!this.props.centerofInfluenceData.sunburst.loader && this.props.centerofInfluenceData.sunburst.parentStrengths && this.props.centerofInfluenceData.sunburst.parentStrengths.children.length > 0}
                  showCSVExport={this.props.centerofInfluenceData.sunburst && this.props.centerofInfluenceData.sunburst.parentStrengths && this.props.centerofInfluenceData.sunburst.parentStrengths.children.length > 0}
                  showImageExport={!this.props.centerofInfluenceData.sunburst.loader && this.props.centerofInfluenceData.sunburst.parentStrengths && this.props.centerofInfluenceData.sunburst.parentStrengths.children.length > 0}
                  from={this.props.from}
                  apiUrl={"auth/graph/sunburst"}
                  showInfo={true}
                  filterObjectForCSV={this.props.getFilterObject}
                  graphId={GRAPH_IDS.SUNBURST_CHART}
                  imageName={"By Strengths - Department Drill-down"}
                  infoMessage="Please click on a strength or strength & department combo to view list of corresponding employees in the table below."
                  handleExpand={() => handleExpandChart({ ...this.props, graphTitle: "By Strengths - Department Drill-down", graphSubTitle: "" }, GRAPH_IDS.SUNBURST_CHART, this.state, legendDepartmentList, this.strengthSelection())}

                />
              </div>
            </div>
            <div className="graphInfoNote"><span>Note: </span> {sunburstTitle}</div>
            <div className="sunburst-container col-sm-12 text-center" id={GRAPH_IDS.SUNBURST_CHART} >
              <div className="cloud-container col-sm-8 mb-5" style={{ height: window.screen.height * 0.7, float: "left" }}>
                {!this.props.centerofInfluenceData.sunburst.loader && this.props.centerofInfluenceData.sunburst.parentStrengths && this.props.centerofInfluenceData.sunburst.parentStrengths.children.length > 0
                  ? <PieChartD3
                    height={window.screen.height * 0.7}
                    width={screenWidth * 0.6}
                    data={this.props.centerofInfluenceData.sunburst.parentStrengths}
                    childrenData={false}
                    handleClicked={this.handleStrengthClickedSunburst}
                    ClickedEvent={true}
                    drillDownName={this.props.employeeList.departmentName ? this.props.employeeList.departmentName : this.props.employeeList.strengthName}
                    legendDepartmentList={legendDepartmentList}
                  />
                  : this.props.centerofInfluenceData.sunburst.loader
                    ? <PiechartLoader />
                    : <div className="dataNotFound">
                      No Data Found
                    </div>
                }
              </div>
              <div className="col-sm-4 text-left mb-5 mt-5" style={{ float: "left" }}>
                <h6 className="mb-3">Department Legends</h6>
                {legendDepartmentList.length > 0
                  ? <ul className="strengthName scroll-auto" style={{ flexWrap: "wrap", height: window.screen.height * 0.6, alignContent: "left", float: "left" }}>
                    {
                      legendDepartmentList.map((entry, index) => {
                        return (<li key={`item-${index}`}
                        > <label>D{index + 1}: {entry}</label></li>);
                      })
                    }
                  </ul>
                  : <div className="dataNotFound">
                    No Department Legends Found
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="col-sm-12" >
            <div className="tableTitleContainer">
              {employeeListTitle}
              <div className="exportContainer">
                <GraphActions
                  showExpansion={false}
                  showCSVExport={this.props.centerofInfluenceData.employeeStrength && this.props.centerofInfluenceData.employeeStrength.employeesList && this.props.centerofInfluenceData.employeeStrength.employeesList.length > 0}
                  showImageExport={false}
                  showInfo={true}
                  from={"sunburst"}
                  infoMessage="Please click on an individual's name to view the strengths for which he/she is appreciated vs the company benchmark."
                  payLoadData={this.state.sunburstTitle}
                  apiUrl={"auth/graph/sunburst"}
                  parameter={"received"}
                  filterObjectForCSV={this.props.getFilterObject}
                  graphId="wordCloudByRecipents"
                />
              </div>
            </div>
            {this.props.centerofInfluenceData.employeeStrength.loader
              ? <TableLoader />
              : <Table
                tableHeading={["Name", "# of Appreciations Received", "Email"]}
                data={this.props.centerofInfluenceData.employeeStrength.employeesList}
                rowClickHandler={this.employeeClickHandler}
                loader={this.props.centerofInfluenceData.employeeStrength.loader}
              />
            }

          </div>

        </div>
        <div className="row">
          <div className="by-rounders">
            <div className="col-sm-12 ">
              <div className="titlewithSlider">
                <h3>{this.props.switch ? "All Rounders - By Specific Strengths" : "All Rounders - By No. of Strengths"}</h3>

                <div className="switchBtnouter">
                  <label>
                    <Switch onChange={this.props.switchHandler}
                      checked={this.props.switch}
                      uncheckedIcon={null}
                      checkedIcon={null}
                      onColor={onSwitchColorCode}
                    />
                  </label>
                </div>
              </div>
              <div className="graphInfoNote">
                <span>Note: </span> Please toggle to select either the # of strengths or the specific strengths of interest that the employee must minimally possess.
              </div>
              <div className="graphsActionContainer">
                {!this.props.switch
                  ? <div className="inputsliderOuetr">
                    <InputRange
                      min={1}
                      max={this.state.rangeSlidermaxValue}
                      defaultValue={this.props.rangeSliderValue}
                      InputRangeClassNames={"range-slider"}
                      onAfterChange={value => this.props.handleRangeSlider(value)}
                      marks={marks}
                      step={1}
                    />

                  </div>
                  : <div className="multiSelectwithlabel">
                    <label>Strength</label>
                    <MultiSelect
                      handleMultiSelect={this.props.handleMultiSelect}
                      name="strengthMultiSelect"
                      data={this.state.strengths}
                      handleDeleteOptions={this.props.handleDeleteStrengthOptions}
                      selectedValue={this.props.strengthMultiSelect}
                      handleShowMore={this.handleShowMoreMultiSelect}
                      showMore={this.props.strengthMultiSelect.length > 0 && this.state.showMoreStrength}
                      showOptions={this.props.strengthMultiSelect.length > 0 && this.props.strengthMultiSelect}
                      clearAllSelected={this.props.clearAllSelectedStrengths}
                    />
                    <div className="StrengthSearch">
                      <button type="submit" className="btn btn-secondary" onClick={this.handleStrengthSearch}> Search </button>
                    </div>
                  </div>}
                {this.props.treeMapData && this.props.treeMapData.length > 0
                  ? <div className="exportContainer">
                    <GraphActions
                      filterObjectForCSV={this.props.getFilterObject}
                      showExpansion={this.props.treeMapData && this.props.treeMapData.length > 0}
                      showCSVExport={true}
                      selectedStregth={this.props.strengthMultiSelect}
                      payLoadData={this.props.individualAppreciation}
                      apiUrl={"auth/graph/treemap"}
                      from={"treemap"}
                      showImageExport={this.props.treeMapData && this.props.treeMapData.length > 0}
                      showInfo={true}
                      graphId="byStrengthsAllRounders"
                      imageName='By Strengths - All Rounders'
                      infoMessage={"After selecting either the # of strengths/specific strengths, please click on an individual's name to view the strengths for which he/she is appreciated vs the benchmark."}
                      handleExpand={() => handleExpandChart({ ...this.props, graphTitle: graphTitle, graphSubTitle: this.props.switch ? "All Rounders - By Specific Strengths" : "All Rounders - By No. of Strengths" }, "byStrengthsAllRounders")}
                    />
                  </div> : null}
              </div>
            </div>

            <div className="col-sm-12">
              {!this.props.treemapLoader
                ? this.props.treeMapData && this.props.treeMapData.length > 0
                  ? <div className="cloud-container" id="byStrengthsAllRounders" style={{ height: window.screen.height * 0.50 }}>
                    {this.state.strengths.length > 0
                      ? <ul className="strengthName">
                        {
                          this.state.strengths.map((entry, index) => {
                            return (<li key={`item-${index}`}
                            > <label>S{index + 1}: {entry.name}</label></li>);
                          })
                        }
                      </ul>
                      : <div className="dataNotFound">
                        No Legends Found
                      </div>
                    }
                    <TreeMapChart
                      data={this.props.treeMapData}
                      width={screenWidth * 0.963}
                      height={window.screen.height * 0.35}
                      handleEmployeeClicked={(e) => this.props.handleWordClicked(e)}
                      graphName={"treemap-notexpanded"}
                      strengthData={this.state.strengths}
                    />
                  </div>
                  : <div className="dataNotFound">
                    No Data Found
                  </div>
                : <TreeMapChartLoader height={window.screen.height * 0.35} />
              }

            </div>
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = state => {
  return {
    centerofInfluenceData: state.AppreciationDashboard.centerOfInfluence,
    screenResolution: state.Common.screenResolution,
    treeMapData: state.AppreciationDashboard.centerOfInfluence.treeMapData.treeMap,
    strengthData: state.AppreciationDashboard.centerOfInfluence.treeMapData.strengths,
    maxStrengthCount: state.AppreciationDashboard.centerOfInfluence.treeMapData.maxStrengthCount,
    treemapLoader: state.AppreciationDashboard.centerOfInfluence.treeMapData.loader
  };
};

const mapDispatchToProps = dispatch => {
  return {
    strengthEmployeeListRequest: (data) => dispatch(AppreciationActions.strengthEmployeeListRequestAction(data)),
    resetEmployeeList: () => dispatch(AppreciationActions.resetEmployeeListAction())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CenterofInfluence);