import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Dashboard from "./Modules/Dashboard/DashboardContainer";
import Login from "./Modules/Login/LoginContainer";
import initialize from "./Utilities/initializeState";
import { loginSuccessAction, loginFailAction } from "./Modules/Login/Actions/LoginActions";

class Home extends React.Component {
  constructor( props ) {
    super( props );
    initialize( props );
  }

  render() {
    let authToken = sessionStorage.getItem( "Auth-Token" );
    let username = sessionStorage.getItem( "username" );

    return (
      <Switch>
        <Route exact path="/login" render = { () => {
          return authToken && username && authToken.length > 4
            ? (this.props.isAppreciationsEnabled ? <Redirect exact to="/appreciation" /> : <Redirect exact to="/redemption" />)
            : <Login props={ { ...this.props } } />;
        } } />
        <Route path="/" render = { () => {
          return authToken && username && authToken.length > 4
            ? <Dashboard />
            : <Redirect exact to="/login" />;
        } } />

      </Switch>
    );
  }
}

const mapStateToProps = state => {
  return {
    authToken: state.Login.authToken,
    username: state.Login.username,
    isAppreciationsEnabled: state.Login.isAppreciationsEnabled
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loginSucceed: ( payload ) => dispatch( loginSuccessAction( payload ) ),
    loginFail: ( payload ) => dispatch( loginFailAction( payload ) )
  };
};

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( Home ) );