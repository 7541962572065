import React from "react";

const MessegeExchangeLoader = props => {
  return (
    <div className="messageExLoader">
      <div className="animated-background lg-width"></div>
      <div className="animated-background lg-width"></div>
      <div className="animated-background lg-width"></div>
      <div className="animated-background lg-width"></div>
      <div className="animated-background lg-width"></div>
    </div>
  );
};

export default MessegeExchangeLoader;