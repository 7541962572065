import React from "react";
import HeatmapTabContent from "./HeatmapTabContent";
import * as AppreciationActions from "../../../Actions/AppreciationActions";
import { connect } from "react-redux";
import * as CommonActions from "../../../../../../../Common/CommonStore/CommonActions";

class HeatMapSection extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      selectedTab: "company",
      messageExchange: {
        giverName: "",
        receiverName: "",
        messageExchangePayload: {

        }
      }
    };
  }
  
  handleClicked = (clickedData) => {
    this.setState({ 
      ...this.state,
      messageExchange: {
        ...this.state.messageExchange,
        giverName: clickedData.giver,
        receiverName: clickedData.receiver,
        messageExchangePayload: {
          [ clickedData.giver_org_id ? "from_org_id" : "from_dept_id" ]: clickedData.giver_org_id ? clickedData.giver_org_id : clickedData.giver_dept_id,
          [ clickedData.receiver_org_id ? "to_org_id" : "to_dept_id" ]: clickedData.receiver_org_id ? clickedData.receiver_org_id : clickedData.receiver_dept_id,
          date: this.props.selectedDate
     
        }
      }
    }, () => {
      if(clickedData.count > 0) {
        
        let payload = {
          user_id: this.props.getFilterObject().user_id,
          role: this.props.getFilterObject().role,
          [ clickedData.giver_org_id ? "from_org_id" : "from_dept_id" ]: clickedData.giver_org_id ? clickedData.giver_org_id : clickedData.giver_dept_id,
          [ clickedData.receiver_org_id ? "to_org_id" : "to_dept_id" ]: clickedData.receiver_org_id ? clickedData.receiver_org_id : clickedData.receiver_dept_id,
          date: this.props.selectedDate
     
        };
        this.props.appreciationFlowHeatmapMessageExchangeRequest(payload);
      } else {
        this.props.appreciationFlowHeatmapMessageExchangeReset();
      }
    });
  }

  resetMessageExchange = (categoryName) => {
    this.setState({ 
      ...this.state,
      selectedTab: categoryName,
      messageExchange: {
        ...this.state.messageExchange,
        giverName: "",
        receiverName: ""
      }
    }, () => {
      this.props.appreciationFlowHeatmapMessageExchangeReset();
    });
  }

  handleRadioSelect = (categoryWise, name) => {
    this.props.handleRadioSelect(categoryWise, name);
    this.resetMessageExchange(this.state.selectedTab);
  }

  render () {
    return (
      <div className="heatMapsection">
        <div className="tablinkouter">
          <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item">
              <a className={`nav-link tab-recive ${this.state.selectedTab === "company" ? "active" : ""}`} role="tab" data-toggle="tab" onClick={ () => this.resetMessageExchange("company") } >Appreciations by Company </a>
            </li>
            <li className="nav-item">
              <a className={`nav-link tab-given ${this.state.selectedTab === "department" ? "active" : ""}`} role="tab" data-toggle="tab" onClick={ () => this.resetMessageExchange("department") }>Appreciations by Department </a>
            </li>
                        
          </ul>   
  
        </div>   
        <div className="tab-content">
        
          <div className={`tab-pane ${this.state.selectedTab === "company" ? "active" : ""}`}>
            <HeatmapTabContent 
              heatmapData ={this.props.heatmapData.company}
              axisNames = { this.props.heatmapData.companyNames }
              graphName={"heatmapByCompany"}
              apiUrl={this.props.apiUrl}
              selectedDate={this.props.selectedDate}
              export_parameter={'organization'}
              from={'heatMapExchange'}
              payLoadData={this.state.messageExchange.messageExchangePayload}
              parameter={this.props.heatmapState.radioSelect}
              filterObjectForCSV={this.props.getFilterObject}
              maxAppreciationCount={this.props.heatmapData.maxCompanyAppreciationCount}
              heatmapState= { this.props.heatmapState.companyWise }
              radioSelect = {this.props.heatmapState.radioSelect}
              handleRadioSelect = { (name) => this.handleRadioSelect("companyWise", name) }
              handleClicked = { this.handleClicked }
              heatmapLoader = { this.props.heatMapLoader }
              messageExchangeData = { this.props.messageExchangeData }
              messageExchangeLoader = { this.props.messageExchangeLoader }
              messageExchangeState = { this.state.messageExchange }
              heatMapTitle = { "Appreciations by Company" }
              showEventModal={this.props.showEventModal}
              hideEventModal={this.props.hideEventModal}
              lightColor={"#ffe6e8"}
              darkColor = {"#ff3344"}
              selectedTab = {this.state.selectedTab}
              noteMessage={"Please click on a cell to retreive the relevant appreciation messages."}
            />
          </div>
          <div className={`tab-pane ${this.state.selectedTab === "department" ? "active" : ""}`}>
            <HeatmapTabContent 
              heatmapData ={this.props.heatmapData.department}
              axisNames={this.props.heatmapData.departmentNames}
              graphName={"heatmapByDepartment"}
              apiUrl={this.props.apiUrl}
              from={'heatMapExchange'}
              export_parameter={'department'}
              selectedDate={this.props.selectedDate}
              filterObjectForCSV={this.props.getFilterObject}
              payLoadData={this.state.messageExchange.messageExchangePayload}
              parameter={this.props.heatmapState.radioSelect}
              maxAppreciationCount={this.props.heatmapData.maxDeptAppreciationCount}
              heatmapState= { this.props.heatmapState.departmentWise }
              radioSelect = {this.props.heatmapState.radioSelect}
              handleRadioSelect = { (name) => this.handleRadioSelect("departmentWise", name) }
              handleClicked = { this.handleClicked }
              heatmapLoader = { this.props.heatMapLoader }
              messageExchangeData = { this.props.messageExchangeData }
              messageExchangeLoader = { this.props.messageExchangeLoader }
              messageExchangeState = { this.state.messageExchange }
              heatMapTitle = { "Appreciations by Department" }
              showEventModal={this.props.showEventModal}
              hideEventModal={this.props.hideEventModal}
              lightColor={"#d4ecf7"}
              darkColor = {"#08202b"}
              selectedTab = {this.state.selectedTab}
              selectedCompanies ={this.props.selectedCompanies}
              noteMessage={this.props.selectedCompanies.length > 0 ? " Please click on a cell to retreive the relevant appreciation messages." : "Please filter a company or specific department(s) to retrieve the appreciations by department. Next, click on a cell to retreive the relevant appreciation messages."}
            />
          </div> 
        </div>
      </div>

    );
  }
}

const mapStateToProps = state => {
  return {
    heatmapData: state.AppreciationDashboard.appreciationFlow.heatmap.data,
    heatMapLoader: state.AppreciationDashboard.appreciationFlow.heatmap.loader,
    messageExchangeData: state.AppreciationDashboard.appreciationFlow.messageExchange.data,
    messageExchangeLoader: state.AppreciationDashboard.appreciationFlow.messageExchange.loader
  };
};

const mapDispatchToProps = dispatch => {
  return {
    appreciationFlowHeatmapMessageExchangeRequest: (data) => dispatch(AppreciationActions.appreciationFlowHeatmapMessageExchangeRequestAction(data)),
    showEventModal: (data) => dispatch(CommonActions.showEventModalAction(data)),
    hideEventModal: () => dispatch(CommonActions.hideEventModalAction()),
    appreciationFlowHeatmapMessageExchangeReset: () => dispatch(AppreciationActions.appreciationFlowHeatmapMessageExchangeResetAction())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeatMapSection);