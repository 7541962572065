import React from 'react';

class Footer extends React.Component{
    render(){
        return(
            <div className="footerwaper">
              Copyright { new Date().getFullYear() } Rewardz Pte Ltd
            </div>
        )
    }
}

export default Footer;