import React from "react";
import { Tile, TileLoader } from "../../../../../UI";
import { TILES_DECIMAL_POINT } from "../../../../../constants/constants";
import { connect } from "react-redux";

class EngagementTiles extends React.PureComponent {
  state = {
    avgMonthlyActiveUser: 0,
    engagementRate: 0,
    avgPointEarnPerUser: 0,
    usersArePointEarners: {
      avgUsers: 0,
      pointEarners: 0,
    },
    // avgNoOfFeatureUsedPerWeek: 0.0,
    topFeaturesPerWeek: null,
    topPointEarningUsers: null,
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.props.tilesData !== nextProps.tilesData) {
      this.setState({
        ...this.state,
        avgMonthlyActiveUser: nextProps.tilesData.avgMonthlyActiveUser,
        engagementRate: nextProps.tilesData.engagementRate,
        avgPointEarnPerUser: nextProps.tilesData.avgPointEarnPerUser,
        usersArePointEarners: {
          avgUsers: nextProps.tilesData.usersArePointEarners.avgUsers,
          pointEarners: nextProps.tilesData.usersArePointEarners.pointEarners,
        },
        topFeaturesPerWeek: nextProps.tilesData.topFeaturesPerWeek,
        topPointEarningUsers: nextProps.tilesData.topPointEarningUsers,
      });
    }
  };
  render() {
    return (
      <div className="tilesSection" id="tilesSection">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            {!this.props.loader ? (
              <Tile
                className={"lighter-skyblue"}
                text={"Number of Engaged Users"}
                value={this.state.avgMonthlyActiveUser}
                showDecimal={false}
                // decimalPoint={TILES_DECIMAL_POINT}
                //ingoreRound={true}
              />
            ) : (
              <TileLoader />
            )}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            {!this.props.loader ? (
              <Tile
                className={"lighter-pink"}
                suffix={
                  this.state.engagementRate &&
                  this.state.engagementRate === "Infinity"
                    ? ""
                    : "%"
                }
                text={"Engagement Rate"}
                showAbsoluteValue={true}
                value={this.state.engagementRate}
                showDecimal={true}
                decimalPoint={TILES_DECIMAL_POINT}
                ingoreRound={true}
              />
            ) : (
              <TileLoader />
            )}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            {!this.props.loader ? (
              <Tile
                className={"lighter-green"}
                title={"Top 3 Features by # of Engaged Users"}
                showTopThree={true}
                pointOne={
                  this.state.topFeaturesPerWeek &&
                  this.state.topFeaturesPerWeek[0]
                    ? this.state.topFeaturesPerWeek[0].feature
                    : null
                }
                pointTwo={
                  this.state.topFeaturesPerWeek &&
                  this.state.topFeaturesPerWeek[1]
                    ? this.state.topFeaturesPerWeek[1].feature
                    : null
                }
                pointThree={
                  this.state.topFeaturesPerWeek &&
                  this.state.topFeaturesPerWeek[2]
                    ? this.state.topFeaturesPerWeek[2].feature
                    : null
                }
                valueOne={
                  this.state.topFeaturesPerWeek &&
                  this.state.topFeaturesPerWeek[0]
                    ? this.state.topFeaturesPerWeek[0].feature_usage
                    : null
                }
                valueTwo={
                  this.state.topFeaturesPerWeek &&
                  this.state.topFeaturesPerWeek[1]
                    ? this.state.topFeaturesPerWeek[1].feature_usage
                    : null
                }
                valueThree={
                  this.state.topFeaturesPerWeek &&
                  this.state.topFeaturesPerWeek[2]
                    ? this.state.topFeaturesPerWeek[2].feature_usage
                    : null
                }
                showDecimal={false}
              />
            ) : (
              <TileLoader />
            )}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            {!this.props.loader ? (
              <Tile
                className={"lighter-yellow"}
                text={"Users are Point Earners"}
                suffix={"%"}
                showAbsoluteValue={true}
                absoluteValue={this.state.usersArePointEarners.pointEarners}
                value={this.state.usersArePointEarners.avgUsers}
                showDecimal={true}
                decimalPoint={TILES_DECIMAL_POINT}
                ingoreRound={true}
              />
            ) : (
              <TileLoader />
            )}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            {!this.props.loader ? (
              <Tile
                className={"light-green"}
                text={"Average # of Points Earned per Point Earner"}
                value={this.state.avgPointEarnPerUser}
                showDecimal={true}
                decimalPoint={TILES_DECIMAL_POINT}
                // decimalPoint={TILES_DECIMAL_POINT}
                // ingoreRound={true}
              />
            ) : (
              <TileLoader />
            )}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            {!this.props.loader ? (
              <Tile
                className={"light-pink"}
                title={"Top 3 Point Earners"}
                showTopThree={true}
                pointOne={
                  this.state.topPointEarningUsers &&
                  this.state.topPointEarningUsers[0]
                    ? this.state.topPointEarningUsers[0].user
                    : null
                }
                pointTwo={
                  this.state.topPointEarningUsers &&
                  this.state.topPointEarningUsers[1]
                    ? this.state.topPointEarningUsers[1].user
                    : null
                }
                pointThree={
                  this.state.topPointEarningUsers &&
                  this.state.topPointEarningUsers[2]
                    ? this.state.topPointEarningUsers[2].user
                    : null
                }
                valueOne={
                  this.state.topPointEarningUsers &&
                  this.state.topPointEarningUsers[0]
                    ? this.state.topPointEarningUsers[0].points
                    : null
                }
                valueTwo={
                  this.state.topPointEarningUsers &&
                  this.state.topPointEarningUsers[1]
                    ? this.state.topPointEarningUsers[1].points
                    : null
                }
                valueThree={
                  this.state.topPointEarningUsers &&
                  this.state.topPointEarningUsers[2]
                    ? this.state.topPointEarningUsers[2].points
                    : null
                }
                showDecimal={false}
              />
            ) : (
              <TileLoader />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tilesData: state.EngagementDashboard.tiles.data,
    loader: state.EngagementDashboard.tiles.loader,
  };
};

export default connect(mapStateToProps)(EngagementTiles);
