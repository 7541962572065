import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import EngagementTiles from "./Components/EngagementTiles";
import PointEarningBehaviourSection from "./Components/PointEarningBehaviourSection/PointEarningBehaviourSection";
import { Filter, Footer } from "../../../../UI";
import {
  EMPLOYEE_SEARCH_NAME,
  ENGAGEMENTS,
  NOTIFICATIONS_TYPE_DROPDOWN,
} from "../../../../constants/constants";
import gotop from "../../../../static/images/top.png";
import pdficon from "../../../../static/images/pdficon.png";
import * as CommonActions from "../../../../Common/CommonStore/CommonActions";
import * as AppreciationActions from "../Appreciation/Actions/AppreciationActions";
import convertDataForDropdown from "../../../../Utilities/convertDataForDropdown";
import AppreciationDashboardApi from "../../../../Api/AppreciationDashboardApi";
import * as EngagementActions from "./Actions/EngagementActions";
import { handlePdfGeneration } from "../../../../Utilities/exportMethods";
import OverallAppUsageSection from "./Components/OverallAppUsageSection";
import InteractionEffectiveness from "./Components/InteractionEffectivenessSection";

let topPointEarnerApiEndingWith = "";

let SELECTED_COLOR_FOR_TOP_POINTS =
  ENGAGEMENTS.TOP_POINT_EARNING_AWARDING_TABS.byCategory.chartColor;

class Engagement extends React.Component {
  IS_PARENT_ORG_LOGIN = JSON.parse(sessionStorage.getItem("isParentOrgLogin"));
  constructor(props) {
    super(props);
    let date = this.props.filters.endDate;
    let startDate = moment().startOf("year").format("DD MMM YYYY");
    let endDate = moment().subtract(1, "days");
    if (date) {
      startDate = moment(date).startOf("year").format("DD MMM YYYY");
      endDate = date;
    }
    this.state = {
      timeFrame: {
        startDate,
        endDate,
        // endDate: ''
      },
      showStartDatePicker: false,
      showEndDatePicker: false,
      closeExpandedView: false,
      filterOverlay: false,
      dropDowns: {
        companyMultiSelect: [],
        departmentMultiSelect: [],
        ageMultiSelect: [],
        roleMultiSelect: [],
        employeeMultiSelect: [],
        employeeIdsMultiSelect: [],
        // redemptionModeMultiSelect: [],
        // redemptionCategoryMultiSelect: []
      },
      selectedDepartments: [],
      selectedCompanies: [],
      showMore: {
        companyMultiSelect: false,
        departmentMultiSelect: false,
        ageMultiSelect: false,
        roleMultiSelect: false,
        employeeMultiSelect: false,
        employeeIdsMultiSelect: false,
        // redemptionModeMultiSelect: false,
        // redemptionCategoryMultiSelect: false
      },
      showOptions: {
        companyMultiSelect: true,
        departmentMultiSelect: true,
        ageMultiSelect: true,
        roleMultiSelect: true,
        employeeMultiSelect: true,
        employeeIdsMultiSelect: false,
        // redemptionModeMultiSelect: true,
        // redemptionCategoryMultiSelect: true
      },

      isSingleCompanyOrSingleDeprtmentSelected: false,
      isOnlySingleCompanySelected: false,

      //THIS NAME IS SAME AS TOP_POINT_EARNING_AWARDING_TABS from const file
      topPointEarnerTabsSwitch: {
        byCategory: false,
        byDepartment: false,
        byUser: false,
      },
      topPointEarnerOrAwarderSwitch: true,
      topPointEarnerOrAwarderDetails: {
        selectedTabApi: "",
      },

      proportionOfUniqueUsersSwitchByCompany: this.IS_PARENT_ORG_LOGIN,

      selectedNotificationType: NOTIFICATIONS_TYPE_DROPDOWN[0],
      eventsUsersRatioSelections: true, // for % of unique users , false for # of unique users
    };

    props.companyFilterDropdownRequest({
      ...this.getFilterObject(),
      isfrom: "engagement", // we are using redemption exchange table for ref
    });
    // props.departmentFilterDropdownRequest();
    // props.employeeFilterDropdownRequest({ org_id: [], dept_id: [], role: [] });
    // props.roleFilterDropdownRequest();
    // this.commonMethodsCalls();
  }

  // componentDidMount() {
  //   this.commonMethodsCalls();
  // }
  getFilterObject = () => {
    let selectedDeptIds = this.state.dropDowns.departmentMultiSelect.map(
      (department) => department.id,
    );
    let allDeptIds = this.props.departmentFilter.map(
      (department) => department.id,
    );
    let validDeptIds = [];
    selectedDeptIds.forEach((deptId) => {
      if (allDeptIds.includes(deptId)) {
        validDeptIds.push(deptId);
      }
    });

    let selectedEmpIds = this.state.dropDowns.employeeMultiSelect.map(
      (employee) => employee.id,
    );
    let allEmpIds = this.props.employeesFilter.map((employee) => employee.id);
    let validEmpIds = [];
    selectedEmpIds.forEach((empId) => {
      if (allEmpIds.includes(empId)) {
        validEmpIds.push(empId);
      }
    });
    const days =
      this.state.timeFrame &&
      this.state.timeFrame.startDate &&
      this.state.timeFrame.endDate
        ? moment(this.state.timeFrame.endDate).diff(
            moment(this.state.timeFrame.startDate),
            "days",
          )
        : 30;

    let filters = {
      days,
      date: {
        from: moment(this.state.timeFrame.startDate).format("YYYY-MM-DD"),
        to: this.state.timeFrame.endDate
          ? moment(this.state.timeFrame.endDate).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
      },
      org_id: this.state.dropDowns.companyMultiSelect.map(
        (company) => company.id,
      ),
      dept_id: validDeptIds,
      user_id: validEmpIds,
      role: this.state.dropDowns.roleMultiSelect.map((role) => role.name),
      org: this.state.dropDowns.companyMultiSelect.map(
        (company) => company.value,
      ),
      dept: this.state.dropDowns.departmentMultiSelect.map(
        (department) => department.value,
      ),
      users: this.state.dropDowns.employeeMultiSelect.map(
        (employee) => employee.value,
      ),
    };
    const EmpIds = this.state.dropDowns.employeeIdsMultiSelect;
    filters.employee_id = [];
    for (let i = 0; i < EmpIds.length; i++) {
      filters.employee_id.push(EmpIds[i].employee_id);
      if (filters.user_id.indexOf(EmpIds[i].id) < 0) {
        filters.user_id.push(EmpIds[i].id);
        filters.users.push(EmpIds[i].value);
      }
    }

    return filters;
  };

  onTopClicked = (e) => {
    window.scrollTo(0, 0);
  };

  handleStartDateSelect = (date) => {
    this.setState({
      ...this.state,
      timeFrame: {
        ...this.state.timeFrame,
        startDate: date,
      },
      showStartDatePicker: false,
      filterOverlay: false,
    });
  };

  handleEndDateSelect = (date) => {
    this.setState({
      ...this.state,
      timeFrame: {
        ...this.state.timeFrame,
        endDate: date,
      },
      showEndDatePicker: false,
      filterOverlay: false,
    });
  };

  hideFilterOverlay = () => {
    this.setState({
      ...this.state,
      filterOverlay: false,
      showEndDatePicker: false,
      showStartDatePicker: false,
    });
  };

  toggleStartDatepicker = () => {
    this.setState({
      ...this.state,
      showStartDatePicker: !this.state.showStartDatePicker,
      filterOverlay: !this.state.filterOverlay,
    });
  };

  toggleEndDatepicker = () => {
    this.setState({
      ...this.state,
      showEndDatePicker: !this.state.showEndDatePicker,
      filterOverlay: !this.state.filterOverlay,
    });
  };

  handleMultiSelect = (name, data) => {
    this.setState(
      {
        ...this.state,
        dropDowns: {
          ...this.state.dropDowns,
          [name]: data || [],
        },
        showOptions: {
          ...this.state.showOptions,
          [name]: true,
        },
        showMore: {
          ...this.state.showMore,
          [name]: false,
        },
      },
      () => {
        if (name === "companyMultiSelect") {
          let companyIds = data.map((companies) => companies.id);
          this.props.departmentFilterDropdownRequest({ org_id: companyIds });
          let deptId = this.state.dropDowns.departmentMultiSelect.map(
            (department) => department.id,
          );
          let roleName = this.state.dropDowns.roleMultiSelect.map(
            (role) => role.name,
          );
          this.props.employeeFilterDropdownRequest({
            org_id: companyIds,
            dept_id: deptId,
            role: roleName,
          });
        } else if (name === "departmentMultiSelect") {
          let orgId = [];
          let deptId = [],
            roleName = [];
          orgId = this.state.dropDowns.companyMultiSelect.map(
            (company) => company.id,
          );
          deptId = this.state.dropDowns.departmentMultiSelect.map(
            (department) => department.id,
          );
          roleName = this.state.dropDowns.roleMultiSelect.map(
            (role) => role.name,
          );
          this.props.employeeFilterDropdownRequest({
            org_id: orgId,
            dept_id: deptId,
            role: roleName,
          });
        } else if (name === "roleMultiSelect") {
          let orgId = [];
          let deptId = [],
            roleName = [];
          orgId = this.state.dropDowns.companyMultiSelect.map(
            (company) => company.id,
          );
          deptId = this.state.dropDowns.departmentMultiSelect.map(
            (department) => department.id,
          );
          roleName = this.state.dropDowns.roleMultiSelect.map(
            (role) => role.name,
          );
          this.props.employeeFilterDropdownRequest({
            org_id: orgId,
            dept_id: deptId,
            role: roleName,
          });
        }
      },
    );
  };

  handleDeleteOptions = (name, index) => {
    let updatedChips = [...this.state.dropDowns[name]];
    updatedChips.splice(index, 1);
    this.setState(
      {
        ...this.state,
        dropDowns: {
          ...this.state.dropDowns,
          [name]: updatedChips,
        },
        showOptions: {
          ...this.state.showOptions,
          [name]: true,
        },
      },
      () => {
        if (name === "companyMultiSelect") {
          let companyIds = this.state.dropDowns.companyMultiSelect.map(
            (companies) => companies.id,
          );
          this.props.departmentFilterDropdownRequest({ org_id: companyIds });
          let deptId = this.state.dropDowns.departmentMultiSelect.map(
            (department) => department.id,
          );
          let roleName = this.state.dropDowns.roleMultiSelect.map(
            (role) => role.name,
          );
          this.props.employeeFilterDropdownRequest({
            org_id: companyIds,
            dept_id: deptId,
            role: roleName,
          });
        } else if (name === "departmentMultiSelect") {
          let orgId = [];
          let deptId = [];
          orgId = this.state.dropDowns.companyMultiSelect.map(
            (company) => company.id,
          );
          deptId = this.state.dropDowns.departmentMultiSelect.map(
            (department) => department.id,
          );
          let roleName = this.state.dropDowns.roleMultiSelect.map(
            (role) => role.name,
          );
          this.props.employeeFilterDropdownRequest({
            org_id: orgId,
            dept_id: deptId,
            role: roleName,
          });
        } else if (name === "roleMultiSelect") {
          let orgId = [];
          let deptId = [],
            roleName = [];
          orgId = this.state.dropDowns.companyMultiSelect.map(
            (company) => company.id,
          );
          deptId = this.state.dropDowns.departmentMultiSelect.map(
            (department) => department.id,
          );
          roleName = this.state.dropDowns.roleMultiSelect.map(
            (role) => role.name,
          );
          this.props.employeeFilterDropdownRequest({
            org_id: orgId,
            dept_id: deptId,
            role: roleName,
          });
        }

        if (
          this.state.showMore[name] &&
          this.state.dropDowns[name].length > 2
        ) {
          this.setState({
            ...this.state,
            showMore: {
              ...this.state.showMore,
              [name]: true,
            },
          });
        }
      },
    );
  };

  handleShowMoreMultiSelect = (name) => {
    this.setState({
      ...this.state,
      showMore: {
        ...this.state.showMore,
        [name]: true,
      },
    });
  };

  handleSearchableChange = async (inputValue, changeHandlerFor) => {
    let trimmedInputValue = inputValue.trim();
    if (trimmedInputValue.length > 1) {
      let orgId = [];
      let deptId = [];
      orgId = this.state.dropDowns.companyMultiSelect.map(
        (company) => company.id,
      );
      deptId = this.state.dropDowns.departmentMultiSelect.map(
        (department) => department.id,
      );
      let roleName = this.state.dropDowns.roleMultiSelect.map(
        (role) => role.name,
      );
      if (changeHandlerFor === EMPLOYEE_SEARCH_NAME.EMPLOYEES) {
        return await this.getEmployees(
          orgId,
          deptId,
          roleName,
          trimmedInputValue,
        );
      } else if (changeHandlerFor === EMPLOYEE_SEARCH_NAME.EMPLOYEE_IDS) {
        return await this.getEmployeesById(
          orgId,
          deptId,
          roleName,
          trimmedInputValue,
        );
      }
    }
  };

  getEmployees = async (orgId, deptId, roleName, trimmedInputValue) => {
    const data = await AppreciationDashboardApi.getEmployeesDropdown({
      org_id: orgId,
      dept_id: deptId,
      role: roleName,
      search_term: trimmedInputValue,
    });
    const filtered = await convertDataForDropdown(
      data.response.data.result,
      "employeeName",
    );
    return filtered;
  };

  getEmployeesById = async (orgId, deptId, roleName, trimmedInputValue) => {
    const filterByUserId = true;
    const data = await AppreciationDashboardApi.getEmployeesDropdown({
      org_id: orgId,
      dept_id: deptId,
      role: roleName,
      search_term: trimmedInputValue,
      filterByUserId,
    });
    const filtered = await convertDataForDropdown(
      data.response.data.result,
      "employeeId",
    );
    return filtered;
  };

  handelSelectedCompaniesOrDepartmentCount = (
    selectedCompanies,
    selectedDepartments,
  ) => {
    const singleCompany =
      this.props.companyFilter.length === 1 || selectedCompanies.length === 1;
    const isSingleCompanyOrSingleDeprtmentSelected = singleCompany
      ? true
      : selectedCompanies.length !== 1
      ? selectedDepartments.length === 1
        ? true
        : false
      : false;
    return isSingleCompanyOrSingleDeprtmentSelected;
  };

  handleFilterClick = (e) => {
    e.preventDefault();
    let selectedCompanies = this.state.dropDowns.companyMultiSelect.map(
      (company) => company.id,
    );
    let selectedDepartments = this.state.dropDowns.departmentMultiSelect.map(
      (department) => department.id,
    );

    const singleCompanyOrDepartmentSelected =
      this.handelSelectedCompaniesOrDepartmentCount(
        selectedCompanies,
        selectedDepartments,
      );

    this.setState(
      {
        ...this.state,
        selectedCompanies: [...selectedCompanies],
        selectedDepartments: [...selectedDepartments],
        topPointEarnerOrAwarderSwitch: true,
        proportionOfUniqueUsersSwitchByCompany: this.IS_PARENT_ORG_LOGIN,
        isSingleCompanyOrSingleDeprtmentSelected:
          singleCompanyOrDepartmentSelected,
      },
      () => {
        this.commonMethodsCalls();
      },
    );
  };

  commonMethodsCalls = () => {
    this.props.getTilesDataRequest({ ...this.getFilterObject() });
    this.props.getNumOfUniqueUsersRequest({ ...this.getFilterObject() });
    this.numberOfUsersOverTimeHandler();
    this.props.pointEarningRequest({ ...this.getFilterObject() });
    this.props.getFrequencyOfAppUsesRequest({ ...this.getFilterObject() });
    this.props.getFrequencyOfFeatureUsesRequest({ ...this.getFilterObject() });
    this.props.avgAppUsersByDayAndTimeRequest({ ...this.getFilterObject() });
    this.notificationSelectHandler(
      "notificationSelect",
      NOTIFICATIONS_TYPE_DROPDOWN[0],
    );
    this.eventsUsersRatioSelectionsHandler(true);
    this.topPointEarnerOrAwarderTabHandler();

    if (this.props.isEventsEnabled === false) {
      this.props.getIsEventsEnabledRequest({});
    }
  };

  topPointEarnerOrAwarderTabHandler = (
    switchKey = ENGAGEMENTS.TOP_POINT_EARNING_AWARDING_TABS.byCategory.name,
  ) => {
    const isTopPointEarner = this.state.topPointEarnerOrAwarderSwitch;
    const pointEarnerSwitch = {
      byCategory: false,
      byDepartment: false,
      byUser: false,
    };
    pointEarnerSwitch[switchKey] = true;
    topPointEarnerApiEndingWith =
      ENGAGEMENTS.TOP_POINT_EARNING_TAB_API_NAME[switchKey] +
      `?isTopPointEarner=${isTopPointEarner}`;

    SELECTED_COLOR_FOR_TOP_POINTS =
      ENGAGEMENTS.TOP_POINT_EARNING_AWARDING_TABS[switchKey].chartColor;

    this.setState(
      {
        ...this.state,
        topPointEarnerTabsSwitch: {
          ...pointEarnerSwitch,
        },
        topPointEarnerOrAwarderDetails: {
          selectedTabApi: topPointEarnerApiEndingWith,
        },
      },
      () => {
        // call api only if single company or department is selected
        // ABOVE IS OLD comment , NEW =>PASSING TRUE BCZ REQUIREMENT IS NOW, NO NEED SELECTION OF 1 COMPANY OR DEPARTMENT TO SHOW SECTION 3.3 and 3.4
        // if (
        //   pointEarnerSwitch.byDepartment &&
        //   !this.state.isSingleCompanyOrSingleDeprtmentSelected
        // )
        //   return false;
        let payload = {
          ...this.getFilterObject(),
          apiEndingWith: topPointEarnerApiEndingWith,
        };
        this.props.topPointEarningRequest(payload);
      },
    );
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.props.dashboardOverlay && !nextProps.dashboardOverlay) {
      this.setState({
        ...this.state,
        showEndDatePicker: false,
        showStartDatePicker: false,
      });
    }
    if (this.props.filters.endDate !== nextProps.filters.endDate) {
      this.setState(
        {
          ...this.state,
          timeFrame: {
            ...this.state.timeFrame,
            startDate: moment(nextProps.filters.endDate)
              .startOf("year")
              .format("DD MMM YYYY"),
            endDate: nextProps.filters.endDate,
          },
        },
        () => {
          this.commonMethodsCalls();
        },
      );
    }

    if (nextProps.companyFilter && nextProps.companyFilter.length === 1) {
      let selectedCompanies = nextProps.companyFilter.map(
        (company) => company.id,
      );
      let selectedDepartments = [];
      const singleCompanyOrDepartmentSelected =
        this.handelSelectedCompaniesOrDepartmentCount(
          selectedCompanies,
          selectedDepartments,
        );
      this.setState(
        {
          ...this.state,
          dropDowns: {
            ...this.state.dropDowns,
            companyMultiSelect: [nextProps.companyFilter[0]],
          },
          isSingleCompanyOrSingleDeprtmentSelected:
            singleCompanyOrDepartmentSelected,
        },
        () => {
          let selectedCompanies = this.state.dropDowns.companyMultiSelect.map(
            (company) => company.id,
          );
          let selectedDepartments =
            this.state.dropDowns.departmentMultiSelect.map(
              (department) => department.id,
            );
          this.setState({
            ...this.state,
            isSingleCompanyOrSingleDeprtmentSelected:
              singleCompanyOrDepartmentSelected,
            selectedCompanies: [...selectedCompanies],
            selectedDepartments: [...selectedDepartments],
          });
        },
      );
    }
  };

  topPointEarnerOrAwarderSwitchHandler = () => {
    this.setState(
      {
        ...this.state,
        topPointEarnerOrAwarderSwitch:
          !this.state.topPointEarnerOrAwarderSwitch,
      },
      () => {
        const obj = { ...this.state.topPointEarnerTabsSwitch };
        const switchTabKey = Object.keys(obj).filter((k) => obj[k] === true);
        this.topPointEarnerOrAwarderTabHandler(switchTabKey);
      },
    );
  };

  clearAllFilterSelected = (name) => {
    this.setState(
      {
        ...this.state,
        dropDowns: {
          ...this.state.dropDowns,
          [name]: [],
        },
        showOptions: {
          ...this.state.showOptions,
          [name]: false,
        },
        showMore: {
          ...this.state.showMore,
          [name]: false,
        },
      },
      () => {
        let companyIds = this.state.dropDowns.companyMultiSelect.map(
          (company) => company.id,
        );
        let deptIds = this.state.dropDowns.departmentMultiSelect.map(
          (department) => department.id,
        );
        let roleName = this.state.dropDowns.roleMultiSelect.map(
          (role) => role.name,
        );
        this.props.departmentFilterDropdownRequest({ org_id: companyIds });
        this.props.employeeFilterDropdownRequest({
          org_id: companyIds,
          dept_id: deptIds,
          role: roleName,
        });
      },
    );
  };

  handlePdfGeneration = (e, id) => {
    this.props.startPDFexport();
    e.preventDefault();
    handlePdfGeneration(id, this.props.endPDFexport, "Engagements.pdf");
  };

  proportionOfUniqueUsersSwitchHandler = () => {
    this.setState(
      {
        ...this.state,
        proportionOfUniqueUsersSwitchByCompany:
          !this.state.proportionOfUniqueUsersSwitchByCompany,
      },
      () => {
        this.numberOfUsersOverTimeHandler(
          this.state.proportionOfUniqueUsersSwitchByCompany,
        );
      },
    );
  };

  numberOfUsersOverTimeHandler = (isByCompany = true) => {
    this.props.getProportionOfUniqueUsersReq({
      ...this.getFilterObject(),
      is_by_company: this.state.proportionOfUniqueUsersSwitchByCompany,
    });
  };

  notificationSelectHandler = (a = "notificationSelect", selectedType) => {
    this.setState(
      {
        ...this.state,
        selectedNotificationType: selectedType,
      },
      () => {
        const selectedValue = this.state.selectedNotificationType.value;
        this.props.notificationsRequest({
          ...this.getFilterObject(),
          type: selectedValue,
        });
      },
    );
  };

  eventsUsersRatioSelectionsHandler = (isPercentageWise) => {
    this.setState(
      {
        ...this.state,
        eventsUsersRatioSelections: isPercentageWise,
      },
      () => {
        this.props.eventsResponsesRequest({
          ...this.getFilterObject(),
          isPercentageWise: this.state.eventsUsersRatioSelections,
        });
      },
    );
  };

  render() {
    return (
      <div className="engagementContainer" id="engagementContainer">
        <div className="row">
          <Filter
            dashboardOverlay={this.props.dashboardOverlay}
            companyFilter={this.props.companyFilter}
            departmentFilter={this.props.departmentFilter}
            employeesFilter={this.props.employeesFilter}
            rolesFilter={this.props.rolesFilter}
            // redemptionCategoryFilter={ this.props.redcategoryFilter.filterCategories }
            // redemptionModeFilter={ this.props.redcategoryFilter.filterModes }
            handleStartDateSelect={this.handleStartDateSelect}
            showStartDatePicker={this.state.showStartDatePicker}
            showEndDatePicker={this.state.showEndDatePicker}
            handleEndDateSelect={this.handleEndDateSelect}
            timeFrame={this.state.timeFrame}
            filterOverlay={this.state.filterOverlay}
            hideFilterOverlay={this.hideFilterOverlay}
            toggleStartDatepicker={this.toggleStartDatepicker}
            toggleEndDatepicker={this.toggleEndDatepicker}
            handleMultiSelect={this.handleMultiSelect}
            handleDeleteOptions={this.handleDeleteOptions}
            dropDowns={this.state.dropDowns}
            handleShowMoreMultiSelect={this.handleShowMoreMultiSelect}
            showMore={this.state.showMore}
            showOptions={this.state.showOptions}
            handleSearchableChange={this.handleSearchableChange}
            handleFilterClick={this.handleFilterClick}
            clearAllSelected={this.clearAllFilterSelected}
            // redemptionFilter = {true}
          />

        {this.props.isChromeBasedBrowser ? 
          <div className="actionbtnContainer topExport">
            <div
              className={`exportPdfbtn ${
                this.props.pdfExport ? "btn-loader" : ""
              }`}
            >
              <button
                className="btn"
                onClick={(e) => this.handlePdfGeneration(e, "outer-container")}
              >
                Export Dashboard
                <img src={pdficon} title="pdficon" />
              </button>
            </div>
          </div>
        : null }

          <EngagementTiles />

          <OverallAppUsageSection
            getFilterObject={this.getFilterObject}
            showEventModal={this.props.showEventModal}
            hideEventModal={this.props.hideEventModal}
            selectedCompanies={this.state.selectedCompanies}
            selectedDepartments={this.state.selectedDepartments}
            isSingleCompanyOrSingleDeprtmentSelected={
              this.state.isSingleCompanyOrSingleDeprtmentSelected
            }
            proportionOfUniqueUsersSwitchHandler={
              this.proportionOfUniqueUsersSwitchHandler
            }
            proportionOfUniqueUsersSwitchByCompany={
              this.state.proportionOfUniqueUsersSwitchByCompany
            }
          ></OverallAppUsageSection>

          <PointEarningBehaviourSection
            getFilterObject={this.getFilterObject}
            showEventModal={this.props.showEventModal}
            hideEventModal={this.props.hideEventModal}
            selectedCompanies={this.state.selectedCompanies}
            isSingleCmpOrDeprtSelected={
              true // PASSING TRUE BCZ REQUIREMENT IS NOW, NO NEED SELECTION OF 1 COMPANY OR DEPARTMENT TO SHOW SECTION 3.3 and 3.4
              // this.state.isSingleCompanyOrSingleDeprtmentSelected
            }
            topPointEarnerSwitch={this.state.topPointEarnerTabsSwitch}
            topPointEarnerHandler={this.topPointEarnerOrAwarderTabHandler}
            topPointEarnerApiEndingWith={topPointEarnerApiEndingWith}
            selectedColorForTopPoints={SELECTED_COLOR_FOR_TOP_POINTS}
            topPointEarnerOrAwarderSwitchHandler={
              this.topPointEarnerOrAwarderSwitchHandler
            }
            isPointEarnerActive={this.state.topPointEarnerOrAwarderSwitch}
            topPointHasIsP2P={this.props.topPointHasIsP2P}
            topPointEarnerOrAwarderDetails={
              this.state.topPointEarnerOrAwarderDetails
            }
          ></PointEarningBehaviourSection>

          <InteractionEffectiveness
            getFilterObject={this.getFilterObject}
            showEventModal={this.props.showEventModal}
            hideEventModal={this.props.hideEventModal}
            selectedNotificationType={this.state.selectedNotificationType}
            notificationSelectHandler={this.notificationSelectHandler}
            eventsUsersRatioSelectionsHandler={
              this.eventsUsersRatioSelectionsHandler
            }
            eventsUsersRatioSelections={this.state.eventsUsersRatioSelections}
          ></InteractionEffectiveness>

          <Footer />
          <div
            className="gotopbutton"
            id="gototopbutton"
            onClick={this.onTopClicked}
          >
            <img src={gotop} title="Go to Top" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isChromeBasedBrowser: state.Common.isChromeBasedBrowser,
    isEventsEnabled: state.EngagementDashboard.isEventsEnabled,
    dashboardOverlay: state.Common.dashboardOverlay,
    companyFilter: state.AppreciationDashboard.filterDropDown.company,
    departmentFilter: state.AppreciationDashboard.filterDropDown.department,
    employeesFilter: state.AppreciationDashboard.filterDropDown.employees,
    rolesFilter: state.AppreciationDashboard.filterDropDown.roles,
    filters: state.AppreciationDashboard.filters,
    topPointHasIsP2P: state.EngagementDashboard.topPointToggleFlag_isP2P,
    pdfExport: state.Common.pdfExport,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    startPDFexport: () => dispatch(CommonActions.startPDFexport()),
    endPDFexport: () => dispatch(CommonActions.endPDFexport()),
    showEventModal: (data) =>
      dispatch(CommonActions.showEventModalAction(data)),
    companyFilterDropdownRequest: (data) =>
      dispatch(AppreciationActions.companyFilterDropdownRequestAction(data)),
    departmentFilterDropdownRequest: (data) =>
      dispatch(AppreciationActions.departmentFilterDropdownRequestAction(data)),
    employeeFilterDropdownRequest: (data) =>
      dispatch(AppreciationActions.employeeFilterDropdownRequestAction(data)),
    ageGroupFilterDropdownRequest: () =>
      dispatch(AppreciationActions.ageGroupFilterDropdownRequestAction()),
    roleFilterDropdownRequest: () =>
      dispatch(AppreciationActions.roleFilterDropdownRequestAction()),
    showEventModal: (data) =>
      dispatch(CommonActions.showEventModalAction(data)),
    hideEventModal: () => dispatch(CommonActions.hideEventModalAction()),

    getIsEventsEnabledRequest: (data) =>
      dispatch(EngagementActions.engagementIsEventsEnabledReq(data)),

    getTilesDataRequest: (data) =>
      dispatch(EngagementActions.engagementTilesReq(data)),

    getNumOfUniqueUsersRequest: (data) =>
      dispatch(EngagementActions.engagementNumOfUniqueUsersReq(data)),

    getProportionOfUniqueUsersReq: (data) =>
      dispatch(EngagementActions.engagementProportionOfUniqueUsersReq(data)),

    getFrequencyOfAppUsesRequest: (data) =>
      dispatch(EngagementActions.engagementFrequencyOfAppUsesReq(data)),

    getFrequencyOfFeatureUsesRequest: (data) =>
      dispatch(EngagementActions.engagementFrequencyOfFeatureUsesReq(data)),

    pointEarningRequest: (data) =>
      dispatch(EngagementActions.engagementPointEarningCategoriesReq(data)),
    topPointEarningRequest: (data) =>
      dispatch(EngagementActions.engagementTopPointEarningReq(data)),

    avgAppUsersByDayAndTimeRequest: (data) =>
      dispatch(EngagementActions.engagementAvgAppUsersByDayAndTimeReq(data)),

    eventsResponsesRequest: (data) =>
      dispatch(EngagementActions.engagementEventsResponsesReq(data)),

    notificationsRequest: (data) =>
      dispatch(EngagementActions.engagementNotificationsReq(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Engagement);
